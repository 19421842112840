import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },
  grid: {
    paddingRight: spacing(2),
    paddingLeft: spacing(2)
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: spacing(1),
    paddingRight: spacing(2),
    paddingLeft: spacing(2)
  }
}));