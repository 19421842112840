import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, map, head, filter, prop, propEq, assoc } from 'ramda';

import PaymentMethodsRegistration from './PaymentMethodsRegistration';
import Paper from '@material-ui/core/Paper';
import MTable from '../../../components/MTable';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen } from '@fortawesome/free-solid-svg-icons';

import { formatFilters } from '../../../helpers/formatters';

import { loadFilters } from '../../../components/Filter/store/thunk';
import { loadPaymentMethods, loadSinglePaymentMethod } from '../store/thunk';
import { handleRegistration, clearRegistration } from '../store/actions';

const header = [
  { title: 'Nome', field: 'paymentMethodName', sortable: false },
  { title: 'Tipo de pagamento', field: 'paymentType', sortable: false },
  { title: 'Taxa', field: 'convertedReceivingFee', sortable: false }
];

const width = window.innerWidth;
const tableRowWidth = width < 768 ? '120' : '100';

export default function PaymentMethods() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const { list: fetchList, total, isLoading, registration } = useSelector(state => state.paymentMethods);
  const { selects, qp } = useSelector(state => state.filters);

  const filters = formatFilters(['status', 'primitivePaymentMethod'], selects);

  const formatter = paymentMethod => {
    const id = paymentMethod.primitivePaymentMethodId;
    const primitive = filter(propEq('id', 'primitivePaymentMethod'), filters);
    const options = prop('options', head(primitive));
    const paymentType = compose(prop('label'), head, filter(propEq('value', id)))(options);

    return assoc('paymentType', paymentType, paymentMethod);
  }

  const list = map(formatter, fetchList);

  const requestPaymentMethods = ({ page, qp }) => {
    dispatch(loadPaymentMethods({ userId, establishmentId, page, pageSize, qp }));
  }

  useEffect(() => {
    if(establishmentId) {
      requestPaymentMethods({ page, pageSize, qp });
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  const onRegister = () => {
    dispatch(clearRegistration());
    dispatch(handleRegistration(true));
  }

  const onUpdate = account => {
    const { establishmentPaymentMethodId } = account;
    dispatch(loadSinglePaymentMethod(userId, establishmentId, establishmentPaymentMethodId));
  }

  return(
    <Paper>
      {registration && (
        <PaymentMethodsRegistration isOpen={registration} />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          hasSearchTerm
          filters={filters}
          handlePageRequest={requestPaymentMethods}
          actionButton={
            <Button
              type="button"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#FFFFFF"
                  size="xs"
                />
              }
              onClick={onRegister}
            >
              Adicionar Meio de Pagamento
            </Button>
          }
        />
      ) : (
        <Filter
          filter
          hasSearchTerm
          filters={filters}
          handlePageRequest={requestPaymentMethods}
          actionButton={
            <Button
              type="button"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#FFFFFF"
                  size="xs"
                />
              }
              onClick={onRegister}
            >
              Adicionar Meio de Pagamento
            </Button>
          }
        />
      )}
      <MTable
        loading={isLoading}
        headers={header}
        data={list}
        actions={[
          rowData =>
            rowData.paymentMethodName == 'Dinheiro'
            ? null
            : ({
                icon: () => (
                  <FontAwesomeIcon
                    icon={faPen}
                    color="#022A5C"
                    size="xs"
                  />
                ),
                tooltip: 'Editar',
                onClick: (event, rowData) => onUpdate(rowData)
              })
        ]}
        actionColumnWidth={tableRowWidth}
      />
      <Pagination
        pageSize={10}
        totalItems={total}
        onSelect={requestPaymentMethods}
      />
    </Paper>
  );
}