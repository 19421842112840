import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Chip as MuiChip } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& .MuiChip-label': {
      color: props => props.labelColor
    },
    '& .MuiChip-icon': {
      color: props => props.iconColor
    }
  }
});

export default function Chip({ label, labelColor, icon, iconColor, borderColor, variant, onClick, style }) {
  const classes = useStyles({
    labelColor,
    iconColor
  });

  return(
    <MuiChip
      style={{
        borderColor,
        ...style
      }}
      className={classes.root}
      label={label}
      labelColor={labelColor}
      icon={icon}
      iconColor={iconColor}
      variant={variant}
      onClick={onClick}
    />
  );
}