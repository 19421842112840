import { fetchSupplierList, fetchSupplierExport } from './services';
import { setIsLoading, setSupplierList } from './actions';
import { handleExporting } from 'components/Filter/store/actions';

export const loadSupplierList = (userId, establishmentId, page, qp) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchSupplierList(userId, establishmentId, page, qp)
    .then(({ data }) => dispatch(setSupplierList(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadSupplierExport = (userId, establishmentId, qp) => dispatch => {
  dispatch(handleExporting(true));

  return fetchSupplierExport(userId, establishmentId, qp)
    .then(({ data }) => data)
    .finally(() => dispatch(handleExporting(false)));
}