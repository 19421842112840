import {
  SET_IS_LOADING,
  SET_CONFIGURATIONS,
  SET_IMAGE_URL,
  SET_IMAGE_PATH,
  CLEAR_CONFIGURATIONS
} from './constants';

const defaultState = {
  receipt: {
    pathLogo: null,
    urlLogo: null,
    print: null,
    printNote: null
  },
  operation: {
    serviceOnly: null,
    manualCovenant: null,
    manualTime: null,
    prePaidExit: null,
    recurrentClientEntryOption: null,
    requireReceiptCovantTypePrice: null,
    requireReceiptExpense: null,
    requiredClientRegisterInfo: null
  },
  cashier: {
    cashReserve: null,
    withdrawal: null
  },
  vehicleEntry: {
    requiredPlateOrPhoto: null,
    requiredVehiclePlate: null,
    requiredVehicleModel: null,
    requiredVehicleColor: null,
    requiredVehicleDamage: null,
    requiredEntryPhoto: null
  },
  settingHistoryDateTime: null,
  settingHistoryUserName: null,
  isLoading: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_CONFIGURATIONS: {
      return {
        ...state,
        receipt: {
          ...state.receipt,
          urlLogo: action.data.urlLogo,
          pathLogo: action.data.pathLogo,
          print: action.data.print,
          printNote: action.data.printNote
        },
        operation: {
          ...state.operation,
          serviceOnly: action.data.serviceOnly,
          manualCovenant: action.data.manualCovenant,
          manualTime: action.data.manualTime,
          prePaidExit: action.data.prePaidExit,
          recurrentClientEntryOption: action.data.recurrentClientEntryOption,
          recurrentClientEntryOption: action.data.recurrentClientEntryOption,
          requireReceiptCovantTypePrice: action.data.requireReceiptCovantTypePrice,
          requireReceiptExpense: action.data.requireReceiptExpense,
          requiredClientRegisterInfo: action.data.requiredClientRegisterInfo
        },
        cashier: {
          ...state.cashier,
          cashReserve: action.data.cashReserve,
          withdrawal: action.data.withdrawal
        },
        vehicleEntry: {
          ...state.vehicleEntry,
          requiredPlateOrPhoto: action.data.requiredPlateOrPhoto,
          requiredVehiclePlate: action.data.requiredVehiclePlate,
          requiredVehicleModel: action.data.requiredVehicleModel,
          requiredVehicleColor: action.data.requiredVehicleColor,
          requiredVehicleDamage: action.data.requiredVehicleDamage,
          requiredEntryPhoto: action.data.requiredEntryPhoto
        },
        settingHistoryDateTime: !!action.data.settingHistoryDateTime ? action.data.settingHistoryDateTime : state.settingHistoryDateTime,
        settingHistoryUserName: !!action.data.settingHistoryUserName ? action.data.settingHistoryUserName : state.settingHistoryUserName
      }
    }
    case SET_IMAGE_URL: {
      return {
        ...state,
        receipt: {
          ...state.receipt,
          urlLogo: action.path
        }
      }
    }
    case SET_IMAGE_PATH: {
      return {
        ...state,
        receipt: {
          ...state.receipt,
          pathLogo: action.path
        }
      }
    }
    case CLEAR_CONFIGURATIONS: {
      state = defaultState;
      return state;
    }
    default: {
      return state;
    }
  }
}