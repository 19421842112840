import {
  SET_IS_INITIAL_ONBOARDING_OPEN,
  SET_IS_FINAL_ONBOARDING_OPEN,
  SET_IS_FROM_REGISTRATION
} from './constants';

const defaultState = {
  isInitialOnboardingOpen: true,
  isFinalOnboardingOpen: true,
  isFromRegistration: false
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_IS_INITIAL_ONBOARDING_OPEN: {
      return {
        ...state,
        isInitialOnboardingOpen: action.status
      }
    }
    case SET_IS_FINAL_ONBOARDING_OPEN: {
      return {
        ...state,
        isFinalOnboardingOpen: action.status
      }
    }
    case SET_IS_FROM_REGISTRATION: {
      return {
        ...state,
        isFromRegistration: action.status
      }
    }
    default: {
      return state;
    }
  }
}