import {
  SET_PRODUCT_FILTERS,
  SET_PRODUCT_LIST,
  SET_PRODUCT_LIST_LOADING
} from './constants';

const defaultState = {
  filters: {
    storages: [],
    areasOfUse: [],
    measurementUnits: [],
    productBrands: [],
    productTypes: [],
    suppliers: []
  },
  list: {
    content: [],
    total: 0
  },
  isLoading: false
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_PRODUCT_FILTERS: {
      const {
        storages,
        areasOfUse,
        measurementUnits,
        productBrands,
        productTypes,
        suppliers
      } = action.filters;

      return {
        ...state,
        filters: {
          storages,
          areasOfUse,
          measurementUnits,
          productBrands,
          productTypes,
          suppliers
        }
      }
    }
    case SET_PRODUCT_LIST: {
      const { data, total } = action.data;

      return {
        ...state,
        list: {
          content: data,
          total
        }
      }
    }
    case SET_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    default: {
      return state;
    }
  }
}