import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Card from 'components/Card';
import Loader from 'components/Loader';
import NumericInput from 'components/NumericInput';
import Input from 'components/Input';
import Select from 'components/Select';
import Status from 'components/Status';

import { setMeasurementUnitGroupId } from '../../../store/actions';

import { useStyles } from './styles';

export default function Basic({ formikProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { values, errors, handleChange, setFieldValue } = formikProps;

  const { areasOfUse, measurementUnits, productBrands, productTypes } = useSelector(state => state.products.filters);
  const { basicAndStorageResponseErrors, isLoading } = useSelector(state => state.productsRegistration);

  const allowedMeasurementUnitsId = [1, 2, 5, 12, 15];

  useEffect(() => {
    const groupId = measurementUnits
      ?.find(unit => +unit?.id == +values.measurementUnitId)
      ?.groupId || '';

    dispatch(setMeasurementUnitGroupId(groupId));
  }, [values.measurementUnitId]);

  const formattedAreasOfUse = areasOfUse?.map(area => {
    return {
      value: area?.id?.toString(),
      label: area?.description
    }
  });

  const formattedMeasurementUnits = measurementUnits
    ?.filter(unit => allowedMeasurementUnitsId.includes(unit?.id))
    ?.map(unit => {
      return {
        value: unit?.id,
        label: `${unit?.description} (${unit?.abbreviation})`
      }
    });

  const formattedProductBrand = productBrands?.map(brand => {
    return {
      value: brand?.id,
      label: brand?.name
    }
  });

  const productTypesParentsId = productTypes
    ?.map(product => product.parentProductTypeId)
    ?.filter((product, index, array) => array?.indexOf(product) == index);

  const productTypesParents = productTypes
    ?.filter(product => productTypesParentsId?.includes(product?.id));

  const formattedProductType = productTypes
    ?.map(type => {
      return {
        parent: productTypesParents?.find(product => +product?.id == +type?.parentProductTypeId)?.description,
        value: type?.id,
        label: type?.description
      }
    })
    ?.filter(type => !!type.parent);

  return(
    <Card title="Insira as informações básicas do produto">
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Input
                  name="name"
                  type="text"
                  label="Nome do Produto"
                  value={values?.name}
                  onChange={handleChange}
                  error={errors?.name || !!basicAndStorageResponseErrors?.name}
                  helperText={errors?.name || basicAndStorageResponseErrors?.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Status
                  name="visible"
                  value={+values?.visible}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="productBrandId"
                  classes={classes}
                  options={formattedProductBrand}
                  value={formattedProductBrand?.find(brand => +brand.value == +values.productBrandId) || {}}
                  onChange={(_, params) => setFieldValue("productBrandId", params?.value || '')}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="primary"
                      label="Marca"
                      error={errors?.productBrandId || !!basicAndStorageResponseErrors?.productBrandId}
                      helperText={errors?.productBrandId || basicAndStorageResponseErrors?.productBrandId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="productTypeId"
                  classes={classes}
                  options={formattedProductType}
                  groupBy={option => option?.parent}
                  value={formattedProductType?.find(type => +type.value == +values.productTypeId) || {}}
                  onChange={(_, params) => setFieldValue("productTypeId", params?.value || '')}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="primary"
                      label="Categoria do Produto"
                      error={errors?.productTypeId || !!basicAndStorageResponseErrors?.productTypeId}
                      helperText={errors?.productTypeId || basicAndStorageResponseErrors?.productTypeId}
                    />
                  )}
                  filterOptions={(options, { inputValue }) => {
                    const parents = options
                      ?.map(option => option?.parent?.toLowerCase())
                      ?.filter(option => !!option);

                    const parentList = [...new Set(parents)];

                    if(parentList?.includes(inputValue?.toLocaleLowerCase())) {
                      const availableOptions = options?.filter(option => option?.parent?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase()));
                      return availableOptions || [];
                    }

                    return options?.filter(option => option?.label?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase())) || [];
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="areasOfUse"
                  multiple
                  disableCloseOnSelect
                  limitTags={3}
                  classes={classes}
                  options={formattedAreasOfUse}
                  value={formattedAreasOfUse?.filter(area => formikProps?.values?.areasOfUse?.includes(area.value)) || []}
                  onChange={(_, areasOfUse) => setFieldValue("areasOfUse", areasOfUse?.map(area => area?.value))}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="primary"
                      label="Aplicação do Produto"
                      error={errors?.areasOfUse || !!basicAndStorageResponseErrors?.areasOfUse}
                      helperText={errors?.areasOfUse || basicAndStorageResponseErrors?.areasOfUse}
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <li color="primary">
                      <Checkbox checked={selected} />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  name="measurementUnitId"
                  label="Unidade de Medida"
                  options={formattedMeasurementUnits}
                  value={values.measurementUnitId}
                  onChange={handleChange}
                  error={errors?.measurementUnitId || !!basicAndStorageResponseErrors?.measurementUnitId}
                  helperText={errors?.measurementUnitId || basicAndStorageResponseErrors?.measurementUnitId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericInput
                  name="netContent"
                  type="text"
                  label="Quantidade de unidade de medida por item"
                  hasMaxValue
                  maxValue={999999.99}
                  value={values.netContent}
                  onChange={value => setFieldValue("netContent", value)}
                  error={errors?.netContent || !!basicAndStorageResponseErrors?.measurementUnitId}
                  helperText={errors?.netContent || basicAndStorageResponseErrors?.measurementUnitId}
                />
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}