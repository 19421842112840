import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';

import { Grid, Typography } from '@material-ui/core';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';

export default function Storage({ formikProps }) {
  const history = useHistory();

  const isEdit = history.location.pathname == '/editar-produto-uso-e-venda';

  const { values, errors, handleChange, setFieldValue } = formikProps;

  const submitBasic = useSelector(state => state.productsUseAndSellRegistration.basic);
  const { basicAndStorageResponseErrors, isLoading } = useSelector(state => state.productsUseAndSellRegistration);
  const { storages, measurementUnits } = useSelector(state => state.products.filters);

  const formattedStorages = storages?.map(storage => {
    return {
      value: storage?.id,
      label: storage?.name
    }
  });

  const formattedMeasurementUnits = measurementUnits?.map(unit => {
    return {
      value: unit?.id,
      label: `${unit?.description} (${unit?.abbreviation})`
    }
  });

  const selectedMeasurementUnit = measurementUnits
    ?.find(unit => +unit?.id == +submitBasic?.measurementUnitId)
    ?.description;

  return(
    <Card title="Cadastre as informações de quantidade do estoque">
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  color="primary"
                  variant="h6"
                >
                  Estoque para venda
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  disabled
                  name="storageId"
                  label="Estoque"
                  options={formattedStorages}
                  value={values?.storageId}
                  error={errors?.storageId || !!basicAndStorageResponseErrors?.storageId}
                  helperText={errors?.storageId || basicAndStorageResponseErrors?.storageId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  disabled={isEdit}
                  name="quantityToSell"
                  type="number"
                  label="Estoque Atual"
                  value={values?.quantityToSell}
                  onChange={handleChange}
                  error={errors?.quantityToSell || !!basicAndStorageResponseErrors?.quantityToSell}
                  helperText={errors?.quantityToSell || basicAndStorageResponseErrors?.quantityToSell}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  name="minQuantityToSell"
                  type="number"
                  label="Estoque Mínimo"
                  value={values?.minQuantityToSell}
                  onChange={handleChange}
                  error={errors?.minQuantityToSell || !!basicAndStorageResponseErrors?.minQuantityToSell}
                  helperText={errors?.minQuantityToSell || basicAndStorageResponseErrors?.minQuantityToSell}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  color="primary"
                  variant="h6"
                  style={{ marginTop: 10 }}
                >
                  Estoque para consumo interno
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  disabled
                  name="storageId"
                  label="Estoque"
                  options={formattedStorages}
                  value={values?.storageId}
                  error={errors?.storageId || !!basicAndStorageResponseErrors?.storageId}
                  helperText={errors?.storageId || basicAndStorageResponseErrors?.storageId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumericInput
                  disabled={isEdit}
                  name="quantityToUse"
                  type="number"
                  label="Estoque Atual"
                  value={values?.quantityToUse}
                  onChange={value => setFieldValue("quantityToUse", value)}
                  error={errors?.quantityToUse || !!basicAndStorageResponseErrors?.quantityToUse}
                  helperText={errors?.quantityToUse || basicAndStorageResponseErrors?.quantityToUse}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                <Input
                  name="minQuantityToUse"
                  type="number"
                  label="Estoque Mínimo"
                  value={values?.minQuantityToUse}
                  onChange={handleChange}
                  error={errors?.minQuantityToUse || !!basicAndStorageResponseErrors?.minQuantityToUse}
                  helperText={errors?.minQuantityToUse || basicAndStorageResponseErrors?.minQuantityToUse}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  disabled
                  name="measurementUnitId"
                  label="Unidade de Medida"
                  options={formattedMeasurementUnits}
                  value={submitBasic?.measurementUnitId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumericInput
                  disabled
                  name="netContent"
                  type="number"
                  label="Quantidade de unidade de medida por produto"
                  value={submitBasic?.netContent}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="primary">
                  Total em estoque: {!!+values?.quantityToUse ? numericFormatter((+values?.quantityToUse * submitBasic?.netContent).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }) : '0'}
                  {` ${selectedMeasurementUnit?.toLowerCase()} (s)`}
                </Typography>
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}