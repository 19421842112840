import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Grid, Typography } from '@material-ui/core';
import Card from '../../../../../../components/Card';
import Input from '../../../../../../components/Input';

export default function Authentication({ formikProps }) {
  const {
    configType,
    dataPage,
    authentication: {
      certificate: isCertificate,
      login: isLogin,
      password: isPassword
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  useEffect(() => {
    if(!!formikProps?.errors?.autenticatorToken || !!formikProps?.errors?.autenticatorUser || !!formikProps?.errors?.autenticatorUserPassword) {
      toast.error('Preencha o campo de autenticação para avançar.');
    }
  }, [formikProps?.errors]);

  const getUserAndPasswordFields = () => {
    if(isCertificate && isLogin && !isPassword) {
      return(
        <Card title="Usuário" style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="autenticatorUser"
                  type="user"
                  label="Usuário"
                  value={formikProps.values.autenticatorUser || ""}
                  error={formikProps.errors.autenticatorUser}
                  helperText={formikProps.errors.autenticatorUser}
                  onChange={e => formikProps.handleChange(e)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      );
    }

    if(isLogin && isPassword) {
      return(
        <Card title="Usuário e senha" style={{ marginTop: 20, marginBottom: 20 }}>
          {dataPage && !!dataPage.helpTipoAutenticacao && (
            <Grid
              container
              style={{
                flexDirection: 'column',
                padding: 15,
                backgroundColor: 'rgb(241, 244, 249)',
                border: '1px solid #38a8d8',
                borderRadius: 10
              }}
            >
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Atenção:
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  {dataPage && dataPage.helpTipoAutenticacao.usuario}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="autenticatorUser"
                  type="user"
                  label="Usuário"
                  value={formikProps.values.autenticatorUser || ""}
                  error={formikProps.errors.autenticatorUser}
                  helperText={formikProps.errors.autenticatorUser}
                  onChange={e => formikProps.handleChange(e)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="autenticatorUserPassword"
                  type="password"
                  label="Senha"
                  value={formikProps.values.autenticatorUserPassword || ""}
                  error={formikProps.errors.autenticatorUserPassword}
                  helperText={formikProps.errors.autenticatorUserPassword}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      );
    }

    return(
      <></>
    );
  }

  return(
    <>
      {(isPassword && !isLogin) && !!formikProps.values.cityCode && (
        <Card title="Token ou chave de Acesso" style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid item xs={12}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                padding: 15,
                backgroundColor: 'rgb(241, 244, 249)',
                border: '1px solid #38a8d8',
                borderRadius: 10
              }}
            >
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Atenção:
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  Para obtê-la acesse o portal da NFS-e da prefeitura e após se
                  autenticar vá no menu Notas Fiscais na opção Web Service -
                  Gerar Chave Autenticação. Clique em "Gerar Chave".
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="autenticatorToken"
                  type="text"
                  label="Chave de acesso"
                  value={formikProps.values.autenticatorToken || ""}
                  error={formikProps.errors.autenticatorToken}
                  helperText={formikProps.errors.autenticatorToken}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
      {!!formikProps.values.cityCode && getUserAndPasswordFields()}
    </>
  );
}