import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Container, Grid, Box, Typography } from '@material-ui/core';
import Loader from 'components/Loader';

import PeriodRange from '../PeriodRange';

import { convertToReal } from '../../../../helpers/converters';

import { useStyles } from '../styles';

const ResumeValues = ({ value }) => {
  return(
    <Grid container justify="center">
      <Grid item>
        <Typography variant="h4" color="primary">
          R$ {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

const Resume = ({ resume, isLoading }) => {
  const classes = useStyles();

  return(
    <Container className={classes.container}>
      <Box mt={2}>
        <Box pt={0} pb={5}>
          <Grid
            container
            spacing={2}
            justify="flex-end"
            alignItems="center"
          >
            <Grid item xs={12} alignContent="flex-end">
              <Grid container justify="flex-end" direction="row">
                <PeriodRange />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Paper align="center" className={classes.paperSuccess}>
              <Loader isLoading={isLoading}>
                <Typography paragraph color="primary">
                  Entradas
                </Typography>
                <ResumeValues value={convertToReal(resume.receipts, false)} />
              </Loader>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper align="center" className={classes.paperError}>
              <Loader isLoading={isLoading}>
                <Typography paragraph color="primary">
                  Saídas
                </Typography>
                <ResumeValues value={convertToReal(resume.payments, false)} />
              </Loader>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper align="center" className={classes.paperPrimary}>
              <Loader isLoading={isLoading}>
                <Typography paragraph color="primary">
                  Total
                </Typography>
                <ResumeValues value={convertToReal(resume.balance, false)} />
              </Loader>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

ResumeValues.propTypes = {
  value: PropTypes.string.isRequired
};

Resume.propTypes = {
  resume: PropTypes.arrayOf(
    PropTypes.shape({
      receipts: PropTypes.string,
      payments: PropTypes.string,
      balance: PropTypes.string
    })
  ).isRequired
};

export default Resume;