import { setFilters } from './actions';
import { fetchFilters } from './services';

export const loadFilters = (userId, establishmentId) => async dispatch => {
  let url = `${userId}/filters`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/filters`;
  }

  const response = await fetchFilters(url);
  return dispatch(setFilters(response && response.data));
}