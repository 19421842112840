import { makeStyles } from '@material-ui/core/styles';

export const containerStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    borderTop: `1px solid ${palette.grey[300]}`,
    padding: spacing(1.3),
  },
}));

export const filterStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1),
    maxWidth: props => (props.isAdvanced ? 500 : 350),
  },
}));

export const buttonStyles = makeStyles(theme => {
  return {
    root: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
  };
});
