import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Search from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import {
  registerFieldChange,
  handleSearch,
  handleExporting,
  clearSearch,
  persistQueryParamsSearch,
  handleFilterButtonClick
} from '../store/actions';

import { setActivePage } from '../../Pagination/store/actions';
import { formatQuery } from '../../../helpers/formatters';
import { containerStyles } from './styles';

import FilterFields from './FilterFields';
import Button from '../../Button';
import ExportButtons from '../../ExportButtons';
import NewExportButtons from '../../NewExportButtons';

const Filter = ({
  actionButton,
  hasRemoveButton,
  hasLinkButton,
  linkButtonText,
  hasModalButton,
  toggleModal,
  inlineCreateForm,
  hasExportButton,
  exportType,
  itemsToRemove,
  handleToRemoveItems,
  inputText,
  onPropsTextChange,
  handleToRegisterItem,
  filters,
  fileName,
  exportHeaders,
  handlePageRequest,
  formatComponentData,
  insertTransactionSelect,
  handleRegistration,
  hasSearchTerm,
  newExportButton,
  functionNewExportButton,
  alternativeExport,
  functionAlternativeExport,
  isDisabledXlsExport,
  isDisabledPdfExport,
  htmlExport,
  htmlExportLoading,
  htmlExportFunction,
  xs,
  md
}) => {
  const containerClasses = containerStyles();

  const dispatch = useDispatch();

  const { establishmentId } = useSelector(state => state.businessInfo);
  const pageSize = useSelector(state => state.pagination.size);
  const { isSearching, search, filtrate, isExporting } = useSelector(state => state.filters);

  useEffect(() => {
    dispatch(clearSearch());
    dispatch(handleExporting(false));

    return () => {
      dispatch(clearSearch());
    }
  }, []);

  const onCancelSearch = () => {
    dispatch(clearSearch());
    dispatch(handleSearch(false));
    dispatch(setActivePage(1));
    handlePageRequest({ page: 1, pageSize, qp: '' });
  }

  const onSearch = () => {
    dispatch(handleFilterButtonClick(true));

    const query = formatQuery(search, filtrate);

    if(!!search || !!filtrate) {
      dispatch(handleSearch(true));
    }

    dispatch(setActivePage(1));
    dispatch(persistQueryParamsSearch(query));
    handlePageRequest({ page: 1, pageSize, qp: query });
  }

  const fieldChange = registerFieldChange;
  const onTextChange = func => e => dispatch(func(e.target.id, e.target.value));

  const newFilters = establishmentId
    ? filters
    : filters.filter(f => !f.advanced);

  return(
    <Grid container classes={containerClasses}>
      <FilterFields
        xs={xs}
        md={md}
        filters={newFilters}
      />
      <Grid
        container
        xs={12}
        spacing={2}
        style={{
          flexGrow: 1,
          justifyContent: 'flex-end',
          marginTop: 5,
          marginLeft: '-3px',
        }}
      >
        <Grid
          style={{
            marginBottom: 12,
            display: 'flex',
            alignItems: 'flex-end',
          }}
          spacing={1}
          xs={hasSearchTerm ? 7 : 9}
        >
          {actionButton && (
            <Grid item style={{ marginRight: 5 }}>
              {actionButton}
            </Grid>
          )}
          {hasLinkButton && (
            <Grid item style={{ marginRight: 5 }}>
              <Link style={{ textDecoration: 'none' }} to={hasLinkButton}>
                <Button type="button" color="success">
                  {linkButtonText}
                </Button>
              </Link>
            </Grid>
          )}
          {hasModalButton && (
            <Grid item>
              <Button type="button" color="success" onClick={toggleModal}>
                {hasModalButton}
              </Button>
            </Grid>
          )}
          {hasRemoveButton && (
            <Grid item>
              <Button
                color="error"
                disabled={itemsToRemove.length === 0}
                onClick={handleToRemoveItems}
              >
                Excluir
              </Button>
            </Grid>
          )}
          {newExportButton && (
            <Grid item>
              <NewExportButtons
                isExporting={isExporting}
                handleExportation={functionNewExportButton}
              />
            </Grid>
          )}
          {hasExportButton && (
            <Grid item>
              <ExportButtons
                color="tertiary"
                variant="outlined"
                fileName={fileName}
                exportType={exportType}
                exportHeaders={exportHeaders}
                handlePageRequest={handlePageRequest}
                formatComponentData={formatComponentData}
              />
            </Grid>
          )}
          {alternativeExport && (
            <Grid item>
              <ExportButtons
                color="tertiary"
                variant="outlined"
                isDisabledXlsExport={isDisabledXlsExport}
                isDisabledPdfExport={isDisabledPdfExport}
                fileName={fileName}
                exportType={exportType}
                exportHeaders={exportHeaders}
                handlePageRequest={functionAlternativeExport}
                formatComponentData={formatComponentData}
              />
            </Grid>
          )}
          {htmlExport && (
            <Grid item style={{ padding: 5 }}>
              <Button
                color='success'
                type='button'
                loading={htmlExportLoading}
                onClick={htmlExportFunction}
              >
                Exportar
              </Button>
            </Grid>
          )}
          {insertTransactionSelect && (
            <>
              <Grid item style={{ marginLeft: 5 }}>
                <Button
                  fullWidth
                  color="primary"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      color="#FFFFFF"
                      size="xs"
                    />
                  }
                  onClick={() => handleRegistration(true, 'receipt')}
                >
                  Nova receita
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: 5 }}>
                <Button
                  fullWidth
                  color="primary"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      color="#FFFFFF"
                      size="xs"
                    />
                  }
                  onClick={() => handleRegistration(true, 'payment')}
                >
                  Nova despesa
                </Button>
              </Grid>
            </>
          )}
          {inlineCreateForm && (
            <Grid md={4}>
              <Tooltip
                title="Adicione mais de um cartão. Ex: 0-10"
                placement="top"
              >
                <OutlinedInput
                  id="inputText"
                  margin="dense"
                  placeholder="Número do novo cartão"
                  value={inputText}
                  onChange={onPropsTextChange}
                  onKeyPress={e => e.key === 'Enter' && handleToRegisterItem(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        onClick={handleToRegisterItem}
                      >
                        <Add />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid
          spacing={1}
          xs={hasSearchTerm ? 5 : 3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 12,
            flexDirection: 'row',
          }}
        >
          {hasSearchTerm && (
            <Grid xs={isSearching ? 6 : 8} item style={{ paddingRight: 10 }}>
              <OutlinedInput
                id="search"
                margin="dense"
                placeholder="Pesquisar"
                value={search}
                onChange={onTextChange(fieldChange)}
                onKeyPress={e => e.key === 'Enter' && onSearch()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={() => onSearch()}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          )}
          <Grid
            xs={hasSearchTerm ? (isSearching ? 6 : 4) : 12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'row',
            }}
            item
          >
            <Grid xs={isSearching ? 6 : 12} item>
              <Button
                fullWidth
                type="button"
                color="success"
                onClick={() => onSearch()}
              >
                Filtrar
              </Button>
            </Grid>
            {isSearching && (
              <Grid style={{ marginLeft: 10 }} xs={6} item>
                <Button
                  fullWidth
                  type="button"
                  color="red"
                  onClick={() => onCancelSearch()}
                >
                  Limpar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Filter.defaultProps = {
  filters: [],
  exportType: '',
  onPropsTextChange: () => true,
  handleRegistration: () => true,
  handleToRemoveItems: () => {},
  handleToRegisterItem: () => {},
  toggleModal: () => {},
  formatComponentData: () => {},
  hasRemoveButton: false,
  hasExportButton: false,
  newExportButton: false,
  inlineCreateForm: false,
  hasLinkButton: '',
  hasModalButton: '',
  linkButtonText: '',
  fileName: '',
  inputText: '',
  itemsToRemove: [],
  exportHeaders: [],
  filter: false,
  hasSearchTerm: false,
  insertTransactionSelect: false
};

Filter.propTypes = {
  handlePageRequest: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  hasRemoveButton: PropTypes.bool,
  newExportButton: PropTypes.bool,
  hasLinkButton: PropTypes.string,
  hasExportButton: PropTypes.bool,
  hasModalButton: PropTypes.string,
  linkButtonText: PropTypes.string,
  toggleModal: PropTypes.func,
  inlineCreateForm: PropTypes.bool,
  exportType: PropTypes.string,
  itemsToRemove: PropTypes.arrayOf(),
  handleToRemoveItems: PropTypes.func,
  inputText: PropTypes.string,
  onPropsTextChange: PropTypes.func,
  handleToRegisterItem: PropTypes.func,
  formatComponentData: PropTypes.func,
  filter: PropTypes.bool,
  hasSearchTerm: PropTypes.bool,
  fileName: PropTypes.string,
  exportHeaders: PropTypes.arrayOf(PropTypes.shape()),
  insertTransactionSelect: PropTypes.bool,
  handleRegistration: PropTypes.func
};

export default Filter;