export const onTextChange = (fn, validation) => e => {
  if (validation) {
    validation(e);
  }

  return fn(e.target.id, e.target.value);
};

export const onCheckboxChange = (fn, validation, type) => e => {
  const numberCheckbox = e.target.checked ? 1 : 0;
  const defaultCheckbox = e.target.checked;

  const checkboxValue = type === 3 ? defaultCheckbox : numberCheckbox;
  if (validation) {
    validation(e);
  }
  return fn(e.target.id, checkboxValue);
};

export const onSelectChange = (fn, validation) => e => {
  if (validation) {
    validation(e.target.name, e.target.value);
  }
  return fn(e.target.name, e.target.value);
};

const getBase64 = (id, file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(id, reader.result);
  };
  reader.onerror = error => {
    console.log('Base 64 error: ', error);
  };
};

export const onUploadFile = (fn, validation, file) => e => {
  if (e.target.files.length > 0) {
    if (validation) {
      validation(e.target.id, e.target.files[0]);
    }

    return getBase64(e.target.id, e.target.files[0], (id, base64) => {
      return fn(id, base64);
    });
  }

  return fn(e.target.id, file || '');
};
