import * as types from './constants';

export const setNumberOfPages = total => ({
  type: types.SET_NUMBER_OF_PAGES,
  total,
});

export const setPageSize = size => ({
  type: types.SET_PAGE_SIZE,
  size,
});

export const setActivePage = page => ({
  type: types.SET_ACTIVE_PAGE,
  page,
});

export const clearPagination = () => ({
  type: types.CLEAR_PAGINATION,
});
