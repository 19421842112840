import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, FormControlLabel, Checkbox, TextField, Typography, Chip } from '@material-ui/core';
import Drawer from 'components/Drawer';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import ExportPDF from 'components/ExportPDF';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';

import { loadComissionedFilters, loadComissionedList } from 'pages/ComissionedList/store/thunk';
import { loadTransaction, loadResume } from 'pages/Transactions/store/thunk';

import {
  loadPayrollExport,
  loadPayrolls,
  upsertPayroll,
  payPayroll,
  undoPayPayroll,
  payPartialPayroll,
  undoPayPartialPayroll
} from '../../pages/ComissionedPayrolls/store/thunk';

import { convertFormattedMoneyToNumber, convertToReal } from 'helpers/converters';

export default function Payroll({ isPayrollModalOpen, setIsPayrollModalOpen, pageOrigin }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { submit, employeeUuid, isLoadingSinglePayroll } = useSelector(state => state.comissionedPayrolls);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp } = useSelector(state => state.filters);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);

  const payrollCategories = useSelector(state => state.comissionedList.filters?.categories)?.option?.map(category => {
    return {
      label: category?.name,
      value: category?.id
    }
  });

  const [isPaymentPartial, setIsPaymentPartial] = useState(false);
  const [isPaymentIntegral, setIsPaymentIntegral] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [confirmUndoPayment, setConfirmUndoPayment] = useState(false);
  const [isPartialPaymentRequest, setIsPartialPaymentRequest] = useState(false);
  const [currentPartialPaymentUuid, setCurrentPartialPaymentUuid] = useState(null);

  const [payrollData, setPayrollData] = useState({
    name: null,
    employeeRoleName: null,
    dueDate: moment(),
    paymentDateTime: moment(),
    fixedAmount: null,
    amount: null,
    paidAmount: null,
    partialPayment: null,
    payrollCategoryId: null,
    account: null,
    situationId: null,
    transaction: {},
    partialPaymentList: []
  });

  const [payrollUndoPaymentData, setPayrollUndoPaymentData] = useState({
    reason: null,
    userPassword: null
  });

  const paymentType = +payrollData.partialPaymentList?.length > 0 ? 'partial' : 'integral';
  const isPayrollOpen = +payrollData.situationId == 1;
  const isPayrollPaid = +payrollData.situationId == 2;

  const isValueLeftToPay = (convertFormattedMoneyToNumber(submit?.amount) - convertFormattedMoneyToNumber(submit?.paidAmount)) >= 0;
  const isPartialPaymentAndPaid = payrollData.partialPaymentList?.length > 0 && isPayrollPaid;
  const isPayrollClosed = (!isPaymentIntegral && !isPaymentPartial && !isPayrollPaid && !(payrollData.partialPaymentList?.length > 0));

  useEffect(() => {
    if(userId) {
      dispatch(loadComissionedFilters({ userId, establishmentId }));
    }
  }, [userId]);

  useEffect(() => {
    setPayrollData({
      ...submit,
      fixedAmount: submit?.amount,
      amount: convertToReal(parseFloat(convertFormattedMoneyToNumber(submit?.amount) - convertFormattedMoneyToNumber(submit?.paidAmount)).toFixed(2)),
      paymentDateTime: !isPayrollPaid
        ? moment().format('YYYY-MM-DD')
        : submit?.paymentDateTime
    });
  }, [submit]);

  useEffect(() => {
    if(isPartialPaymentRequest) {
      const currentPartialPaymentTotal = convertFormattedMoneyToNumber(submit?.amount) - payrollData.partialPaymentList?.reduce((total, partialPayment) => +total + convertFormattedMoneyToNumber(partialPayment?.amount), 0);

      setPayrollData({
        ...payrollData,
        amount: convertToReal(currentPartialPaymentTotal)
      });
    }
  }, [payrollData.partialPaymentList]);

  const loadTransactionsAndResume = () => {
    dispatch(
      loadTransaction({
        qp,
        page: page || 1,
        pageSize: pageSize || 10,
        extraProps: { userId, establishmentId, startDate, endDate }
      })
    );

    dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp }));
  }

  const handlePayment = payrollUuid => {
    const paymentDate = moment(payrollData.paymentDateTime).format('YYYY-MM-DD');
    const paymentTime = moment().format('HH:mm:ss');

    const params = {
      paymentDateTime: `${paymentDate} ${paymentTime}`,
      paidAmount: convertFormattedMoneyToNumber(payrollData.paidAmount)
    }

    setConfirmPayment(false);

    dispatch(payPayroll(userId, establishmentId, payrollUuid, params))
      .then(() => {
        const loadPayrollsParams = {
          userId,
          establishmentId,
          pg: 1,
          employeeId: employeeUuid,
          limit: '',
          orderBy: 'dueDate',
          sort: 'asc'
        }

        setIsPayrollModalOpen(false);

        if(pageOrigin == '/funcionarios') {
          return dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        }

        dispatch(loadPayrolls(loadPayrollsParams));
      });
  }

  const handleUndoPayment = payrollUuid => {
    dispatch(undoPayPayroll(userId, establishmentId, payrollUuid, payrollUndoPaymentData))
      .then(() => {
        const loadPayrollsParams = {
          userId,
          establishmentId,
          pg: 1,
          employeeId: employeeUuid,
          limit: '',
          orderBy: 'dueDate',
          sort: 'asc'
        }

        dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        dispatch(loadPayrolls(loadPayrollsParams));

        if(pageOrigin == 'transactions') {
          loadTransactionsAndResume();
        }

        setIsPayrollModalOpen(false);
        setConfirmUndoPayment(false);
      });
  }

  const handlePartialPayment = payrollUuid => {
    const paymentDate = moment(payrollData.paymentDateTime).format('YYYY-MM-DD');
    const paymentTime = moment().format('HH:mm:ss');

    const params = {
      paymentDateTime: `${paymentDate} ${paymentTime}`,
      amount: +convertFormattedMoneyToNumber(payrollData.amount),
      description: payrollData.description
    }

    setConfirmPayment(false);

    dispatch(payPartialPayroll(userId, establishmentId, payrollUuid, params))
      .then(() => setIsPartialPaymentRequest(true));
  }

  const handleUndoPartialPayment = (payrollUuid, uuid) => {
    dispatch(undoPayPartialPayroll(userId, establishmentId, payrollUuid, uuid, payrollUndoPaymentData))
      .then(() => {
        setConfirmUndoPayment(false);
        setIsPartialPaymentRequest(true);

        if(pageOrigin == 'transactions') {
          loadTransactionsAndResume();
        }
      });
  }

  const handleSavePayroll = payrollUuid => {
    const params = {
      amount: +convertFormattedMoneyToNumber(payrollData.fixedAmount),
      dueDate: moment(payrollData?.dueDate).format('YYYY-MM-DD')
    }

    dispatch(upsertPayroll(userId, establishmentId, payrollUuid, params))
      .then(() => {
        dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        setIsPayrollModalOpen(false);
      });
  }

  const chipSituation = () => {
    if(isPayrollPaid) {
      return 'Paga';
    }

    if(isPayrollOpen) {
      return 'Aberta';
    }

    return 'Aberta';
  }

  const chipStyle = () => {
    let style = {
      marginLeft: 10
    }

    if(isPayrollPaid) {
      return {
        ...style,
        backgroundColor: 'rgba(76, 175, 80, 0.2)',
        color: '#4CAF50'
      }
    }

    return {
      ...style,
      backgroundColor: 'rgba(255, 152, 0, 0.2)',
      color: '#FF9800'
    }
  }

  const labelStatus = () => {
    return(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          Folha de pagamento
        </Typography>
        <Chip
          label={chipSituation()}
          style={chipStyle()}
        />
      </div>
    );
  }

  return(
    <Drawer
      id='payroll'
      title={labelStatus()}
      open={isPayrollModalOpen}
      onClose={() => {
        if((payrollData?.partialPaymentList?.length > 0) && isPartialPaymentRequest) {
          const loadPayrollsParams = {
            userId,
            establishmentId,
            pg: 1,
            employeeId: employeeUuid,
            limit: '',
            orderBy: 'dueDate',
            sort: 'asc'
          }

          if(!!employeeUuid) {
            dispatch(loadPayrolls(loadPayrollsParams));
          }

          dispatch(loadComissionedList({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
      }

        setIsPayrollModalOpen(false);
      }}
    >
      {confirmPayment && (
        <Modal
          id='confirmPayment'
          title={<Typography color='primary'>Confirmar pagamento</Typography>}
          scroll='body'
          maxWidth='sm'
          open={confirmPayment}
          onClose={() => setConfirmPayment(false)}
        >
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FCFCFC', border: '1px solid #D7D7D7', borderRadius: 5, padding: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography color='primary'>
                  Data de pagamento:
                </Typography>
                <Typography color='textSecondary'>
                  {moment(payrollData.paymentDateTime, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography color='primary'>
                  Valor:
                </Typography>
                {isPaymentIntegral ? (
                  <Typography color='textSecondary'>
                    {convertToReal(convertFormattedMoneyToNumber(payrollData.paidAmount))}
                  </Typography>
                ) : (
                  <Typography color='textSecondary'>
                    {convertToReal(convertFormattedMoneyToNumber(payrollData.amount))}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 10 }}>
              <Button
                type='button'
                color='tertiary'
                variant='outlined'
                onClick={() => setConfirmPayment(false)}
              >
                Cancelar
              </Button>
              <Button
                type='button'
                color='primary'
                onClick={() => {
                  (isPaymentPartial || paymentType == 'partial')
                    ? handlePartialPayment(submit?.uuid)
                    : handlePayment(submit?.uuid)
                }}
              >
                Confimar
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
      <Loader isLoading={isLoadingSinglePayroll}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} spacing={2}>
                <Input
                  disabled
                  name='name'
                  label='Funcionário'
                  value={payrollData.name || ''}
                  onChange={event => setPayrollData({ ...payrollData, name: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Input
                  disabled
                  name='employeeRoleName'
                  label='Cargo'
                  value={payrollData.employeeRoleName || ''}
                  onChange={event => setPayrollData({ ...payrollData, employeeRoleName: event.target.value })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} spacing={2}>
                <Input
                  disabled={!isPayrollClosed}
                  name='amount'
                  label='Valor'
                  type='money'
                  value={convertToReal(convertFormattedMoneyToNumber(payrollData?.fixedAmount))}
                  onChange={event => setPayrollData({ ...payrollData, fixedAmount: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Select
                  disabled
                  name='payrollCategoryId'
                  label='Categoria da despesa'
                  options={payrollCategories}
                  value={+payrollData.payrollCategoryId}
                  onChange={event => setPayrollData({ ...payrollData, payrollCategoryId: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={isPayrollPaid}
                    autoFocus={false}
                    label='Data de vencimento'
                    inputFormat='DD/MM/YYYY'
                    value={payrollData.dueDate}
                    onChange={date => setPayrollData({ ...payrollData, dueDate: date })}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        name='dueDate'
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableEnforceFocus: true
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={isPayrollPaid || payrollData.partialPaymentList?.length > 0}
                  label='Pagar Integral'
                  control={
                    <Checkbox
                      name='payIntegral'
                      type='checkbox'
                      checked={isPaymentIntegral || (isPayrollPaid && paymentType == 'integral')}
                      onChange={event => {
                        setPayrollData({ ...payrollData, paidAmount: payrollData.amount });
                        setIsPaymentIntegral(event.target.checked);
                        setIsPaymentPartial(false);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  disabled={isPayrollPaid || payrollData.partialPaymentList?.length > 0}
                  label='Pagar Parcial'
                  control={
                    <Checkbox
                      name='payPartial'
                      type='checkbox'
                      checked={isPaymentPartial || (isPayrollPaid && paymentType == 'partial') || payrollData.partialPaymentList?.length > 0}
                      onChange={event => {
                        setIsPaymentPartial(event.target.checked);
                        setIsPaymentIntegral(false);
                      }}
                    />
                  }
                />
              </Grid>
              {isPayrollPaid && (!!payrollData.transaction?.userName && !!payrollData.transaction?.paymentDateTime) && (
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <p>Pago por {payrollData.transaction?.userName} ás {moment(payrollData.transaction?.paymentDateTime).format('DD/MM/YYYY')} {payrollData.transaction?.paymentDateTime?.split(' ')[1]}</p>
                </Grid>
              )}
            </Grid>
            {(isPaymentIntegral || (isPayrollPaid && paymentType == 'integral')) && (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled={isPayrollPaid}
                      autoFocus={false}
                      label='Data de pagamento'
                      inputFormat='DD/MM/YYYY'
                      value={payrollData.paymentDateTime}
                      onChange={date => setPayrollData({ ...payrollData, paymentDateTime: date })}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          name='paymentDateTime'
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps
                          }}
                          SelectProps={{
                            MenuProps: {
                              disableEnforceFocus: true
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    disabled={isPayrollPaid}
                    name='amount'
                    label='Valor pago'
                    type='money'
                    value={convertToReal(convertFormattedMoneyToNumber(payrollData.paidAmount))}
                    onChange={event => setPayrollData({ ...payrollData, paidAmount: event.target.value })}
                  />
                </Grid>
              </Grid>
            )}
            {(isPaymentPartial || (isPayrollPaid && paymentType == 'partial') || payrollData.partialPaymentList?.length > 0) && (
              <>
                {payrollData?.partialPaymentList?.length > 0 && (
                  <>
                    {payrollData?.partialPaymentList?.map(partialPayment => (
                      <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              disabled
                              autoFocus={false}
                              label='Data de pagamento'
                              inputFormat='DD/MM/YYYY'
                              value={partialPayment.paymentDateTime || ""}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name='paymentDateTime'
                                  variant='outlined'
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      disableEnforceFocus: true
                                    }
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                            disabled
                            name='amount'
                            label='Valor pago'
                            type='money'
                            value={convertToReal(convertFormattedMoneyToNumber(partialPayment.amount)) || ""}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Input
                            disabled
                            name='userName'
                            label='Pago por'
                            type='text'
                            value={partialPayment?.userName || ""}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            type='button'
                            color='error'
                            style={{ height: 50 }}
                            onClick={() => {
                              setCurrentPartialPaymentUuid(partialPayment?.uuid);
                              setConfirmUndoPayment(true);
                            }}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              color="#FFFFFF"
                              icon={faTrash}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
                {!isPartialPaymentAndPaid && (
                  <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disabled={isPayrollPaid}
                          autoFocus={false}
                          label='Data de pagamento'
                          inputFormat='DD/MM/YYYY'
                          value={payrollData.paymentDateTime}
                          onChange={date => setPayrollData({ ...payrollData, paymentDateTime: date })}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              name='paymentDateTime'
                              variant='outlined'
                              inputProps={{
                                ...params.inputProps
                              }}
                              SelectProps={{
                                MenuProps: {
                                  disableEnforceFocus: true
                                }
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <Input
                        disabled={isPayrollPaid}
                        name='amount'
                        label='Valor a ser pago'
                        type='money'
                        value={convertToReal(convertFormattedMoneyToNumber(payrollData.amount))}
                        onChange={event => setPayrollData({ ...payrollData, amount: event.target.value })}
                      />
                    </Grid>
                    {isValueLeftToPay && (
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          type='button'
                          color='primary'
                          style={{ height: 50 }}
                          startIcon={
                            <FontAwesomeIcon
                              size="lg"
                              color="#FFFFFF"
                              icon={faPlusCircle}
                            />
                          }
                          onClick={() => setConfirmPayment(true)}
                        >
                          Pagar Parcela
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
            {confirmUndoPayment && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color='primary' style={{ borderBottom: '1px solid #E4E7EA' }}>
                    <b>Cancelamento</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name='reason'
                    label='Motivo do cancelamento'
                    type='text'
                    value={payrollUndoPaymentData.reason}
                    onChange={event => setPayrollUndoPaymentData({ ...payrollUndoPaymentData, reason: event.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name='userPassword'
                    label='Confirme sua senha'
                    type='password'
                    value={payrollUndoPaymentData.userPassword}
                    onChange={event => setPayrollUndoPaymentData({ ...payrollUndoPaymentData, userPassword: event.target.value })}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                  <Button
                    type='button'
                    color='tertiary'
                    variant='outlined'
                    onClick={() => setConfirmUndoPayment(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type='button'
                    color='success'
                    onClick={() => {
                      (isPaymentPartial || paymentType == 'partial')
                        ? handleUndoPartialPayment(submit?.uuid, currentPartialPaymentUuid)
                        : handleUndoPayment(submit?.uuid);
                    }}
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginTop: 10 }}>
                <ExportPDF
                  type='button'
                  color='tertiary'
                  variant='outlined'
                  exportType='receiptPayrollExtract'
                  fileName='folha-de-pagamento'
                  requestData={() => dispatch(loadPayrollExport(userId, establishmentId, submit?.uuid))}
                  startIcon={
                    <FontAwesomeIcon
                      size='lg'
                      icon={faDownload}
                    />
                  }
                >
                  Baixar Recibo
                </ExportPDF>
                {isPayrollClosed && (
                  <Button
                    type='submit'
                    color='success'
                    onClick={() => handleSavePayroll(submit?.uuid)}
                  >
                    Salvar
                  </Button>
                )}
                {(isPaymentIntegral && !isPayrollPaid) && (
                  <Button
                    type='submit'
                    color='primary'
                    onClick={() => setConfirmPayment(true)}
                  >
                    Pagar
                  </Button>
                )}
                {(paymentType != 'partial' && isPayrollPaid) && (
                  <Button
                    type='submit'
                    color='error'
                    variant='outlined'
                    onClick={() => setConfirmUndoPayment(true)}
                  >
                    Desfazer Pagamento
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </Loader>
    </Drawer>
  );
}