import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Box, Container, Grid, Typography, Chip } from '@material-ui/core';
import Loader from '../../../../components/Loader';

import { convertFormattedMoneyToNumber, convertToBrl } from '../../../../helpers/converters';

import { useStyles } from '../styles';

function ResumeValues({ value }) {
  return(
    <Grid container justify="center">
      <Grid item>
        <Typography variant="h4" color="primary">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

const Resume = ({ resume, loading }) => {
  const classes = useStyles();

  const { confirmed, pendent } = resume;

  return(
    <Container className={classes.container}>
      <Box style={{ paddingTop: 20 }}>
        <Chip
          style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
          label={
            <Typography color='primary' variant='subtitle1'>Realizado</Typography>
          }
        />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Paper align="center" className={classes.paperSuccess}>
                <Loader isLoading={loading}>
                  <Typography paragraph color="primary">
                    Total de despesas
                  </Typography>
                  <ResumeValues value={convertToBrl(convertFormattedMoneyToNumber(confirmed.outbound?.toString()))} />
                </Loader>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper align="center" className={classes.paperError}>
                <Loader isLoading={loading}>
                  <Typography paragraph color="primary">
                    Total de receitas
                  </Typography>
                  <ResumeValues value={convertToBrl(convertFormattedMoneyToNumber(confirmed.inbound?.toString()))} />
                </Loader>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper align="center" className={classes.paperPrimary}>
                <Loader isLoading={loading}>
                  <Typography paragraph color="primary">
                    Saldo realizado
                  </Typography>
                  <ResumeValues value={convertToBrl(convertFormattedMoneyToNumber(confirmed.balance?.toString()))} />
                </Loader>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box style={{ paddingTop: 20 }}>
        <Chip
          style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
          label={
            <Typography color='primary' variant='subtitle1'>Previsto</Typography>
          }
        />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Paper align="center" className={classes.paperSuccess}>
                <Loader isLoading={loading}>
                  <Typography paragraph color="primary">
                    Total de despesas
                  </Typography>
                  <ResumeValues value={convertToBrl(convertFormattedMoneyToNumber(pendent.outbound?.toString()))} />
                </Loader>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper align="center" className={classes.paperError}>
                <Loader isLoading={loading}>
                  <Typography paragraph color="primary">
                    Total de receitas
                  </Typography>
                  <ResumeValues value={convertToBrl(convertFormattedMoneyToNumber(pendent.inbound?.toString()))} />
                </Loader>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper align="center" className={classes.paperPrimary}>
                <Loader isLoading={loading}>
                  <Typography paragraph color="primary">
                    Saldo realizado
                  </Typography>
                  <ResumeValues value={convertToBrl(convertFormattedMoneyToNumber(pendent.balance?.toString()))} />
                </Loader>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

ResumeValues.propTypes = {
  value: PropTypes.string.isRequired
};

Resume.defaultProps = {
  resume: {
    confirmed: {
      inbound: 0,
      outbound: 0,
      balance: 0
    },
    pendent: {
      inbound: 0,
      outbound: 0,
      balance: 0
    }
  }
};

Resume.propTypes = {
  resume: PropTypes.shape({
    confirmed: PropTypes.shape({
      inbound: PropTypes.number,
      outbound: PropTypes.number,
      balance: PropTypes.number
    }),
    pendent: PropTypes.shape({
      inbound: PropTypes.number,
      outbound: PropTypes.number,
      balance: PropTypes.number
    })
  }),
  loading: PropTypes.bool.isRequired
};

export default Resume;