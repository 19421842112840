export const REGISTER_FIELD_CHANGE = 'VEHICLE_REGISTER/REGISTER_FIELD_CHANGE';
export const SET_VEHICLES = 'VEHICLE_REGISTER/SET_VEHICLES';
export const SET_CARDS = 'VEHICLE_REGISTER/SET_CARDS';
export const CLEAR_CARD_REGISTER = 'VEHICLE_REGISTER/CLEAR_CARD_REGISTER';
export const CLEAR_VEHICLE_REGISTER = 'VEHICLE_REGISTER/CLEAR_VEHICLE_REGISTER';
export const HANDLE_VEHICLE_LOADING = 'VEHICLE_REGISTER/HANDLE_VEHICLE_LOADING';
export const HANDLE_CARD_LOADING = 'VEHICLE_REGISTER/HANDLE_CARD_LOADING';
export const TOGGLE_TO_REMOVE_VEHICLE =
  'VEHICLE_REGISTER/TOGGLE_TO_REMOVE_VEHICLE';
export const TOGGLE_TO_REMOVE_CARD = 'VEHICLE_REGISTER/TOGGLE_TO_REMOVE_CARD';
