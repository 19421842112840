import * as type from './constants';

export const setSessions = (data, resume, total, establishments) => ({
  type: type.SET_SESSION,
  data,
  resume,
  total,
  establishments,
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status,
});
