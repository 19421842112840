import { toast } from 'react-toastify';
import { createCategory, updateCategory, fetchCategories } from './services';
import { handleLoading, clearRegistration, setCategories } from './actions';

export const loadCategories = ({ userId, establishmentId, page, pageSize, qp = '' }) => dispatch => {
  dispatch(handleLoading(true));

  const url = `${userId}/establishment/${establishmentId}/categories?pg=${page}&limit=${pageSize}${qp}`;

  return fetchCategories(url)
    .then(({ data }) => dispatch(setCategories(data.content, data.total)))
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertCategory = (userId, establishmentId, params, properties, categoryId) => dispatch => {
  const { page, pageSize, qp } = properties;

  dispatch(handleLoading(true));

  if(!categoryId) {
    return createCategory(userId, establishmentId, params)
      .then(() => {
        toast.success('Categoria cadastrada com sucesso.');
        dispatch(clearRegistration());
        dispatch(loadCategories({ userId, establishmentId, page, pageSize, qp }));
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateCategory(userId, establishmentId, params, categoryId)
    .then(() => {
      toast.success('Categoria atualizada com sucesso.');
      dispatch(clearRegistration());
      dispatch(loadCategories({ userId, establishmentId, page, pageSize, qp }));
    })
    .finally(() => dispatch(handleLoading(false)));
}