import React from 'react';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function SellOrderRemotion({ lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader activityData={activityData} />
      </Grid>
      <Grid container style={{ marginTop: 10 }}>
        <Grid xs={12}>
          <Grid style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
            <Row>
              <Typography color='primary'>
                <b>N° Pedido</b>
              </Typography>
              <Typography color='textSecondary'>{lastRegister?.orderCode}</Typography>
            </Row>
            <Row>
              <Typography color='primary'>
                <b>Registro</b>
              </Typography>
              <Typography color='textSecondary'>{moment(lastRegister?.registrationDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
            </Row>
            <Row>
              <Typography color='primary'>
                <b>Valor</b>
              </Typography>
              <Typography color='textSecondary'>{convertToReal(lastRegister?.totalAmount)}</Typography>
            </Row>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}