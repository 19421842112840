import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';

import { TextField, InputAdornment, CircularProgress } from '@mui/material';

import { fetchAddress } from 'helpers/externalServices';

export default function CEP({ ...props }) {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const postalCode = VMasker.toNumber(value || '');

    if(postalCode.length == 8) {
      setIsLoading(true);

      (async () => {
        const address = await fetchAddress(postalCode);

        setIsLoading(false);

        if(address.erro) {
          toast.error('O CEP digitado é inválido');
        }

        return props.onChange(address);
      })();
    }
  }, [value]);

  return(
    <TextField
      fullWidth
      variant="outlined"
      type="text"
      disabled={props.disabled}
      name={props.name}
      placeholder={props.placeholder || "00000-000"}
      label={props.label || "CEP"}
      value={VMasker.toPattern(value || '', '99999-999')}
      error={props.error}
      helperText={props.helperText}
      onChange={event => setValue(event.target.value)}
      InputProps={{
        startAdornment: isLoading && (
          <InputAdornment position="start">
            <CircularProgress
              color="primary"
              size={24}
            />
          </InputAdornment>
        )
      }}
      inputProps={{
        maxLength: 9,
        style: {
          color: "#1E5168"
        }
      }}
      sx={{
        '& label.Mui-focused': {
          color: "#1E5168"
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: "#1E5168"
          }
        }
      }}
    />
  );
}