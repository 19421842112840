import { setRestrictions, handleLoading } from './actions';
import { fetchClient } from '../../BasicInfo/store/services';

export const loadRestrictions = params => dispatch => {
  dispatch(handleLoading(true));

  return fetchClient(params)
    .then(({ data }) => {
      dispatch(setRestrictions(data));
      dispatch(handleLoading(false));
      return data;
    })
    .catch(() => dispatch(handleLoading(false)));
};