import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    backgroundColor: palette.primary.light,
    alignItems: 'center',
    [breakpoints.down('md')]: {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
    [breakpoints.up('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  dateContent: {
    backgroundColor: palette.primary.light,
    justifyContent: 'flex-end',
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  autocomplete: {
    fontSize: 16,
  },
}));
