import {
  SET_IS_LOADING,
  SET_COMISSIONED,
  SET_COMISSIONED_ERRORS,
  CLEAR_COMISSIONED_ERRORS,
  CLEAR_COMISSIONED
} from './constants';

const defaultState = {
  isLoading: false,
  submit: {
    name: null,
    status: null,
    remuneration: null,
    roleId: null,
    userUuid: null,
    establishments: [],
    establishmentUuid: null,
    closingDays: null,
    payDay: null,
    frequencyId: null,
    firstPaymentDate: null,
    payrollCategoryId: null
  },
  submitErrors: {
    nameError: null,
    roleIdError: null,
    establishmentUuidError: null,
    payDayError: null,
    frequencyIdError: null,
    payrollCategoryIdError: null,
    firstPaymentDateError: null
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_COMISSIONED: {
      return {
        ...state,
        submit: {
          name: action.data?.name,
          status: action.data?.status,
          remuneration: action.data?.remuneration,
          roleId: action.data?.roleId,
          userUuid: action.data?.userUuid,
          establishmentUuid: action.data?.establishmentUuid,
          payDay: action.data?.payDay,
          frequencyId: action.data?.frequencyId,
          payrollCategoryId: action.data?.payrollCategoryId
        }
      }
    }
    case SET_COMISSIONED_ERRORS: {
      return {
        ...state,
        submitErrors: {
          nameError: action.data?.name ? action.data?.name[0] : null,
          roleIdError: action.data?.roleId ? action.data?.roleId[0] : null,
          establishmentUuidError: action.data?.establishmentUuid ? action.data?.establishmentUuid[0] : null,
          payDayError: action.data?.payDay ? action.data?.payDay[0] : null,
          frequencyIdError: action.data?.frequencyId ? action.data?.frequencyId[0] : null,
          payrollCategoryIdError: action.data?.payrollCategoryId ? action.data?.payrollCategoryId[0] : null,
          firstPaymentDateError: action.data?.firstPaymentDate ? action.data?.firstPaymentDate[0] : null
        }
      }
    }
    case CLEAR_COMISSIONED_ERRORS: {
      return {
        ...state,
        submitErrors: defaultState.submitErrors
      }
    }
    case CLEAR_COMISSIONED: {
      return {
        ...state,
        submit: {
          ...defaultState.submit
        }
      }
    }
    default: {
      return state;
    }
  }
}