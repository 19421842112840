import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchComissionConfigList = async ({ userId, establishmentId, serviceId }) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/commissionsettings?serviceId=${serviceId}`);
  return response.data;
}

export const fetchComissionConfig = async (userId, uuid) => {
  const response = await newAPI.get(`${userId}/commissionsettings/${uuid}`);
  return response.data;
}

export const createComissionConfig = async (userId, establishmentId, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/commissionsettings`
    : `${userId}/commissionsettings`;

  delete params.establishmentId;
  delete params.uuid;

  const response = await newAPI.post(url, qs.stringify(params));
  return response.data;
}

export const updateComissionConfig = async (userId, establishmentId, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/commissionsettings/${params.uuid}`
    : `${userId}/commissionsettings/${params.uuid}`;

  delete params.establishmentId;

  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}