import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Header from '../Header';
import Subheader from '../Subheader';
import Footer from '../Footer';

import { useStyles } from './styles';

const Layout = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Header />
      <Subheader {...props} />
      <Container maxWidth="lg" className={classes.container}>
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
