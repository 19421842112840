import { makeStyles } from '@material-ui/core/styles';

export const containerStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    borderTop: `1px solid ${palette.grey[300]}`,
    padding: spacing(1.3)
  }
}));

export const filterStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1),
    maxWidth: props => (props.isAdvanced ? 500 : 350)
  }
}));

export const InputStyles = makeStyles(() => ({
  root: {
    margin: 0
  },
  container: {
    margin: '0 5px'
  }
}));

export const AutocompleteStyles = makeStyles(({ palette }) => ({
  select: {
    fontWeight: props => props.displayEmpty && 500
  },
  iconOutlined: {
    color: palette.primary.main
  },
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.displayEmpty && palette.primary.main,
      borderWidth: props => props.displayEmpty && 1.5
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.displayEmpty && palette.primary.main,
      borderWidth: props => props.displayEmpty && 2.5
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 2
  }
}));