import * as type from './constants';

export const setIsLoading = isLoading => ({
  type: type.SET_IS_LOADING,
  isLoading
});

export const setComissioned = data => ({
  type: type.SET_COMISSIONED,
  data
});

export const setComissionedErrors = data => ({
  type: type.SET_COMISSIONED_ERRORS,
  data
});

export const clearComissionedErrors = () => ({
  type: type.CLEAR_COMISSIONED_ERRORS
});

export const clearComissioned = () => ({
  type: type.CLEAR_COMISSIONED
});