import { set, lensProp } from 'ramda';
import { HANDLE_SERVICES_LINK_LOADING } from './constants';

const defaultState = {
  isLoading: false,
  submit: {
    servicesLink: [],
    loadingServicesLink: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_SERVICES_LINK_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    default: {
      return state;
    }
  }
};
