import { newAPI } from 'services';
import qs from 'qs';

export const fetchStorageToSellHistoryResume = async (userId, establishmentId, storageProductUuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/histories/to-sell/summary/${storageProductUuid}`);
  return response.data;
}

export const fetchStorageHistoryResume = async (userId, establishmentId, storageProductUuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/histories/to-use/summary/${storageProductUuid}`);
  return response.data;
}

export const fetchStorageHistoryList = async (userId, establishmentId, storageProductUuid, page) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/histories/${storageProductUuid}?page=${page}`);
  return response.data;
}

export const updateIncreaseStorage = async (userId, establishmentId, storageProductUuid, isStorageMovementSell, params) => {
  const url = isStorageMovementSell
    ? `${userId}/establishment/${establishmentId}/storage/products/increase-storage-to-sell/${storageProductUuid}`
    : `${userId}/establishment/${establishmentId}/storage/products/increase-storage-to-use/${storageProductUuid}`;

  const response = await newAPI.patch(url, qs.stringify(params));
  return response.data;
}

export const updateDecraseStorage = async (userId, establishmentId, storageProductUuid, isStorageMovementSell, params) => {
  const url = isStorageMovementSell
    ? `${userId}/establishment/${establishmentId}/storage/products/decrease-storage-to-sell/${storageProductUuid}`
    : `${userId}/establishment/${establishmentId}/storage/products/decrease-storage-to-use/${storageProductUuid}`;

  const response = await newAPI.patch(url, qs.stringify(params));
  return response.data;
}

export const updateAccuseExceedingStorage = async (userId, establishmentId, storageProductUuid, isStorageMovementSell, params) => {
  const url = isStorageMovementSell
    ? `${userId}/establishment/${establishmentId}/storage/products/accuse-exceeding-storage-to-sell/${storageProductUuid}`
    : `${userId}/establishment/${establishmentId}/storage/products/accuse-exceeding-storage-to-use/${storageProductUuid}`;

  const response = await newAPI.patch(url, qs.stringify(params));
  return response.data;
}

export const updateAccuseEmptyStorage = async (userId, establishmentId, storageProductUuid, isStorageMovementSell, params) => {
  const url = isStorageMovementSell
    ? `${userId}/establishment/${establishmentId}/storage/products/accuse-empty-storage-to-sell/${storageProductUuid}`
    : `${userId}/establishment/${establishmentId}/storage/products/accuse-empty-storage-to-use/${storageProductUuid}`;

  const response = await newAPI.patch(url, isStorageMovementSell && qs.stringify(params));
  return response.data;
}