import { toast } from 'react-toastify';
import { fetchSessions, finishSessionRequest } from './services';
import { setSessions, handleLoading } from './actions';

export const loadSessions = ({
  userId,
  establishmentId,
  page,
  pageSize,
  qp = '',
  establishments,
}) => dispatch => {
  const url = `/sessions?pg=${page}&limit=${pageSize}&orderBy=startDateTime&sort=desc${qp}`;

  dispatch(handleLoading(true));

  return fetchSessions(userId, establishmentId, url)
    .then(({ data }) => {
      dispatch(
        setSessions(data.content, data.resume, data.total, establishments)
      );
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const finishSession = params => () => {
  return finishSessionRequest(params)
    .then(() => toast.success('Sessão encerrada com sucesso'));
}