import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  select: {
    fontWeight: props => props.displayEmpty && 500
  },
  iconOutlined: {
    color: palette.primary.main
  },
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.displayEmpty && palette.primary.main,
      borderWidth: props => props.displayEmpty && 1.5
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.displayEmpty && palette.primary.main,
      borderWidth: props => props.displayEmpty && 2.5
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 2
  },
}));