import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import {
  compose,
  props,
  map,
  lensProp,
  prop,
  assoc,
  last,
  set,
  lensIndex,
  split,
  over,
  when,
  test,
  lensPath,
  pick,
  remove
} from 'ramda';

import {
  REGISTER_FIELD_CHANGE,
  SET_SERVICE_ORDER,
  SET_SERVICE_ORDER_ID,
  SET_UPDATE,
  SET_REMOVE,
  SET_RECEIPT,
  HANDLE_LOADING,
  REMOVE_SERVICE,
  CLEAR_SERVICE_ORDER,
  CLEAR_CHANGES,
  HANDLE_CHANGE_DATE
} from './constants';

import { separateDate } from '../../../helpers/common';
import { convertToReal, convertDateToDB } from '../../../helpers/converters';
import { formatPermanence, formatDateTime, formatDocument } from '../../../helpers/formatters';

momentDurationFormatSetup(moment);

const defaultState = {
  serviceOrderUser: null,
  serviceOrderEstablishment: null,
  serviceOrderId: null,
  isUpdating: false,
  isRemoving: false,
  isSending: false,
  isLoading: false,
  submit: {
    userPassword: '',
    reason: '',
    services: [],
    discount: {}
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      const separateField = split('.', action.field);

      if (separateField.length === 2) {
        const toInt = when(test(/\d/), Number.parseInt);
        const index = compose(last, map(toInt))(separateField);

        return over(
          lensPath(['submit', 'services']),
          over(lensIndex(index), set(lensProp('amount'), action.value)),
          state
        );
      }

      if (
        action.field === 'clientName' ||
        action.field === 'clientEmail' ||
        action.field === 'clientDocument'
      ) {
        return set(lensProp(action.field), action.value, state);
      }

      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case SET_SERVICE_ORDER: {
      const { services, discount } = action.data;

      const formatDate = date => {
        if (!date) return false;
        return set(lensProp(date), formatDateTime(prop(date, action.data)));
      }

      const getDateTime = date => {
        return date?.split(' ')[1] || '';
      }

      const serviceOrder = compose(
        formatDate('entryDateTime'),
        formatDate('exitDateTime'),
        formatDate('paymentDateTime'),
        over(lensProp('clientDocument'), formatDocument),
        over(lensProp('permanence'), formatPermanence),
        assoc(
          'permanence',
          props(['exitDateTime', 'entryDateTime'], action.data)
        ),
        assoc('entryDate', separateDate('entryDateTime', action.data)),
        assoc('entryTime', getDateTime(action?.data?.entryDateTime)),
        assoc('exitDate', separateDate('exitDateTime', action.data)),
        assoc('exitTime', getDateTime(action?.data?.exitDateTime)),
        assoc(
          'paymentTime',
          action.data.paymentDateTime &&
          getDateTime(action?.data?.paymentDateTime)
        ),
        assoc(
          'paymentDate',
          action.data.paymentDateTime &&
            separateDate('paymentDateTime', action.data)
        ),
        over(
          lensProp('services'),
          map(over(lensProp('amount'), convertToReal))
        ),
        over(lensProp('amount'), convertToReal),
        over(lensProp('amountTotal'), convertToReal)
      )(action.data);

      const convertedServices =
        services.length > 0
          ? map(over(lensProp('amount'), convertToReal), services)
          : services;

      return {
        ...state,
        ...serviceOrder,
        isLoading: false,
        submit: {
          ...state.submit,
          services: convertedServices,
          discount
        }
      }
    }
    case SET_SERVICE_ORDER_ID: {
      const ids = compose(
        set(lensProp('serviceOrderUser'), action.userId),
        set(lensProp('serviceOrderEstablishment'), action.establishmentId),
        set(lensProp('serviceOrderId'), action.id)
      );

      return ids(state);
    }
    case SET_UPDATE: {
      return compose(
        set(lensProp('isUpdating'), true),
        set(
          lensProp('submit'),
          pick(
            [
              'clientId',
              'plate',
              'services',
              'amountServices',
              'amount',
              'entryDate',
              'entryTime',
              'exitDate',
              'exitTime',
              'paymentMethodId',
              'paymentDate',
              'paymentTime',
            ],
            state
          )
        )
      )(state);
    }
    case SET_REMOVE: {
      return set(lensProp('isRemoving'), true, state);
    }
    case SET_RECEIPT: {
      return set(lensProp('isSending'), true, state);
    }
    case REMOVE_SERVICE: {
      return over(
        lensPath(['submit', 'services']),
        remove(action.index, 1),
        state
      );
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_CHANGES: {
      return compose(
        set(lensProp('isUpdating'), false),
        set(lensProp('isRemoving'), false),
        set(lensProp('isSending'), false),
        set(lensPath(['submit', 'userPassword']), ''),
        set(lensPath(['submit', 'reason']), '')
      )(state);
    }
    case CLEAR_SERVICE_ORDER: {
      return defaultState;
    }
    case HANDLE_CHANGE_DATE: {
      const dateTime = `${convertDateToDB(action.date)}`;
      return set(lensPath(['submit', action.field]), dateTime, state);
    }
    default:
      return state;
  }
}