import { newAPI } from '../../../services';

export const fetchClients = async url => {
  const result = await newAPI.get(url);
  return result.data;
}

export const closeInvoice = async (userId, establishmentId, clientId, invoiceDateTime) => {
  const result = await newAPI.post(`${userId}/establishment/${establishmentId}/invoices/close/${clientId}/${invoiceDateTime}`);
  return result.data;
}

export const fetchClientExporation = async url => {
  const result = await newAPI.get(url);
  return result.data;
}