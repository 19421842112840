import * as yup from 'yup';

export const authenticationValidations = yup.object().shape({
  autenticatorPassword: yup
    .string()
    .nullable()
    .when(['isCertificate', 'certificateFile'], {
      is: (isCertificate, certificateFile) => !!isCertificate & !!certificateFile,
      then: yup.string().required('A senha é obrigatória')
    }),
  autenticatorPasswordConfirm: yup
    .string()
    .when('autenticatorPassword', (autenticatorPassword, schema) =>
      autenticatorPassword ? schema.required('Confirme a senha') : schema
    )
    .oneOf(
      [yup.ref('autenticatorPassword'), null],
      'A senha e a confirmação devem ser iguais'
    )
    .nullable()
});