import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchAccount = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchAccounts = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const createAccount = async params => {
  const response = await newAPI.post(`${params.userId}/establishment/${params.establishmentId}/accounts/new`, qs.stringify(params));
  return response.data;
}

export const updateAccount = async (url, params) => {
  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}