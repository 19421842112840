import { pick, merge } from 'ramda';
import { loadFilters } from '../components/Filter/store/thunk';

export const setInitialLoader = (fn, props) => {
  const { userId, establishmentId, dispatch } = props;

  if (establishmentId) {
    if (fn) {
      fn(props);
    }

    dispatch(loadFilters(userId, establishmentId));
  }
}

export const updateToLoader = async (prevProps, props, functions) => {
  const { userId, establishmentId, startDate, endDate, dispatch } = props;

  if (
    startDate !== prevProps.startDate ||
    endDate !== prevProps.endDate ||
    establishmentId !== prevProps.establishmentId
  ) {
    await (async () => {
      if (functions) {
        functions.forEach(async fn => {
          await fn();
        });
      }

      await dispatch(loadFilters(userId, establishmentId));
    })();
  }
}

export const setParamsToLoader = (props, params, fn, extra) => {
  const mergeProps = merge(props, params);
  const { userId, establishmentId, page, pageSize, qp } = mergeProps;
  const extraProps = extra ? pick(extra, mergeProps) : '';

  return fn({
    userId,
    establishmentId,
    page,
    pageSize,
    qp,
    extraProps,
  });
}