import { useEffect, useRef } from 'react';

export default function useDidMount() {
  const didComponentMount = useRef(true);

  useEffect(() => {
    didComponentMount.current = false;
  }, []);

  return didComponentMount.current;
}