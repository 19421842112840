import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { assoc } from 'ramda';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { verifyPasswordToken, resetPassword } from '../store/thunk';

import { validations } from './validations';

import { useStyles } from './styles';

const logo = require('../../../assets/img/logo_login.svg');

export default function PasswordReset() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();
  const { token, email } = useParams();

  const { isLoading } = useSelector(state => state.profile);
  const { name } = useSelector(state => state.profile.submit);

  const initialState = {
    password: '',
    passwordConfirm: ''
  }

  useEffect(() => {
    dispatch(verifyPasswordToken(token, email)).then(error => {
      if (error) {
        history.push('/');
      }
    });
  }, []);

  const handlePasswordReset = (values, actions) => {
    const { resetForm } = actions;
    const params = assoc('code', token, values);

    return dispatch(resetPassword(params)).then(error => {
      if (!error) {
        resetForm();
        history.push('/');
      }
    });
  }

  return(
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item md={4} xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <img
            src={logo}
            className="mb-4"
            width="100"
            height="100"
            alt="Jump Park"
          />
          <Typography paragraph variant="h6" color="primary">
            Olá
            <Typography
              variant="h5"
              color="primary"
              display="inline"
              className={classes.title}
            >
              {name && <>, {name}</>}
            </Typography>
            <br />
            Digite sua nova senha
          </Typography>

          <Formik
            initialValues={initialState}
            validationSchema={validations}
            onSubmit={(values, actions) => handlePasswordReset(values, actions)}
          >
            {({ ...formikProps }) => (
              <Form style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Input
                  name="password"
                  type="password"
                  label="Senha"
                  placeholder="Senha"
                  value={formikProps.values.password}
                  error={formikProps.errors.password}
                  helperText={formikProps.errors.password}
                  onChange={formikProps.handleChange}
                />
                <Input
                  name="passwordConfirm"
                  type="password"
                  label="Confirmar senha"
                  placeholder="Confirmar senha"
                  value={formikProps.values.passwordConfirm}
                  error={formikProps.errors.passwordConfirm}
                  helperText={formikProps.errors.passwordConfirm}
                  onChange={formikProps.handleChange}
                />

                <Button
                  fullWidth
                  verticalGutter
                  color="secondary"
                  loading={isLoading}
                >
                  Enviar nova senha
                </Button>
              </Form>
            )}
          </Formik>
          <hr />
          <Link to="/">Voltar para o login</Link>
        </Paper>
      </Grid>
    </Grid>
  );
}