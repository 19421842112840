import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchCostCenters = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const createCostCenter = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/costcenters/new`, qs.stringify(params));
  return response.data;
}

export const updateCostCenter = async (userId, establishmentId, params, costCenterId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/costcenters/${costCenterId}`, qs.stringify(params));
  return response.data;
}

export const deleteCostCenter = async (userId, establishmentId, data) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/costcenters/delete`, data);
  return response.data;
}