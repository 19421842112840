import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

import { confirmTermsOfUse } from '../../store/thunk';
import { setTermsOfUseDate } from 'pages/Login/store/actions';

export default function TermsOfUseModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { userId } = useSelector(state => state.profile);
  const { isTermsOfUseLoading } = useSelector(state => state.provisionalReceiptConfig);

  const [isExceeded, setIsExceeded] = useState(false);
  const [isCreditCardMandatory, setIsCreditCardMandatory] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleConfirmTermsOfUse = () => {
    return dispatch(confirmTermsOfUse(userId))
      .then(() => {
        dispatch(setTermsOfUseDate(moment(moment.now())));
        setIsOpen(false);
      });
  }

  return(
    <Modal
      id="termsOfUseModal"
      title="Aceite os termos para usar a funcionalidade de nota fiscal"
      scroll="body"
      maxWidth="md"
      open={isOpen}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography color='primary' variant='h6'>
            Limites
          </Typography>
          <div style={{ border: '0.5px solid #757575', margin: '10px 0 10px 0', opacity: 0.5 }} />
          <Typography color='textSecondary'>
            Caso ultrapasse o limite gratuito de 500 notas mensais, será cobrado R$ 0.15 por nota excedente. Exemplo: 600 notas gerará um acréscimo de R$ 15,00 a mais além da mensalidade.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="isExceeded"
                type="checkbox"
                checked={isExceeded}
                onChange={event => setIsExceeded(event.target.checked)}
              />
            }
            label={<Typography color='primary'>Concordo</Typography>}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '30px 0 30px 0' }}>
          <Typography color='primary' variant='h6'>
            Cartão de Crédito
          </Typography>
          <div style={{ border: '0.5px solid #757575', margin: '10px 0 10px 0', opacity: 0.5 }} />
          <Typography color='textSecondary'>
            É necessário cadastro do cartão de crédito para utilizar esse módulo caso ultrapasse o limite gratuito de 500 notas fiscais.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="isCreditCardMandatory"
                type="checkbox"
                checked={isCreditCardMandatory}
                onChange={event => setIsCreditCardMandatory(event.target.checked)}
              />
            }
            label={<Typography color='primary'>Concordo</Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color='primary' variant='h6'>
            Verificações
          </Typography>
          <div style={{ border: '0.5px solid #757575', margin: '10px 0 10px 0', opacity: 0.5 }} />
          <Typography color='textSecondary'>
            {`Verifique diariamente as emissões de suas notas em Financeiro > Notas Fiscais (RPS). Não nos responsabilizamos por notas não emitidas ou multas geradas por atrasos nas emissões.`}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="isVerified"
                type="checkbox"
                checked={isVerified}
                onChange={event => setIsVerified(event.target.checked)}
              />
            }
            label={<Typography color='primary'>Concordo</Typography>}
          />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginTop: 30 }}>
          <Button
            color="error"
            onClick={() => history.push('/notas-fiscais')}
          >
            Não estou de acordo
          </Button>
          <Button
            loading={isTermsOfUseLoading}
            disabled={!isExceeded || !isCreditCardMandatory || !isVerified}
            color="primary"
            onClick={handleConfirmTermsOfUse}
          >
            Aceito todos os termos
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}