import * as yup from 'yup';

export const validations = yup.object().shape({
  reason: yup
    .string()
    .required('A observação é obrigatória'),
  userPassword: yup
    .string()
    .required('A senha é obrigatória'),
  entryTime: yup
    .string()
    .test(function(entryTime) {
      return entryTime?.match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/g)
        ? true
        : this.createError({
            message: 'Insira um horário válido',
            path: 'entryTime'
          });
    }),
  exitTime: yup
    .string()
    .test(function(exitTime) {
      return exitTime?.match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/g)
        ? true
        : this.createError({
            message: 'Insira um horário válido',
            path: 'exitTime'
          });
    }),
  paymentTime: yup
    .string()
    .test(function(paymentTime) {
      return paymentTime?.match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/g)
        ? true
        : this.createError({
            message: 'Insira um horário válido',
            path: 'paymentTime'
          });
    })
});

export const removeValidations = yup.object().shape({
  reason: yup
    .string()
    .required('A observação é obrigatória'),
  userPassword: yup
    .string()
    .required('A senha é obrigatória')
});

export const emailValidation = yup.object().shape({
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .required('O e-mail é obrigatório')
});