import { compose, set, over, lensProp, lensPath, includes, pick, map } from 'ramda';
import { REGISTER_FIELD_CHANGE, SELECT_CHANGE, SET_RESTRICTIONS, HANDLE_LOADING, CLEAR_RESTRICTIONS } from './constants';

const defaultState = {
  isLoading: false,
  submit: {
    startTime: '',
    endTime: '',
    vacancies: '',
    restrictionTypePrice: '',
    weekDaysPermission: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case SELECT_CHANGE: {
      let { weekDaysPermission } = state.submit;
      const id = parseInt(action.id, 10);
      const filterId = includes(id, weekDaysPermission);

      if (filterId) {
        weekDaysPermission = weekDaysPermission.filter(day => day !== id);
      } else {
        weekDaysPermission = weekDaysPermission.concat(id);
      }

      return set(
        lensPath(['submit', 'weekDaysPermission']),
        weekDaysPermission,
        state
      );
    }
    case SET_RESTRICTIONS: {
      const convertTime = time => (time === '00:00:00' ? '' : time);

      const restrictions = compose(
        over(
          lensProp('weekDaysPermission'),
          weekDays => weekDays && map(day => parseInt(day, 10), weekDays)
        ),
        over(lensProp('endTime'), convertTime),
        over(lensProp('startTime'), convertTime),
        pick([
          'startTime',
          'endTime',
          'vacancies',
          'restrictionTypePrice',
          'weekDaysPermission',
        ])
      );

      return set(lensProp('submit'), restrictions(action.data), state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_RESTRICTIONS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};