import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  justifyContentSpaceBetween: {
    justifyContent: 'space-between'
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end'
  },
	padding: {
		padding: 20
	},
	marginTop: {
		marginTop: 10
	},
	blue: {
		color: '#1E5168'
	},
	font18: {
		fontSize: 18
	},
  infoCard: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
    padding: 15,
    backgroundColor: 'rgb(241, 244, 249)',
    border: '1px solid #38a8d8',
    borderRadius: 10
  },
	infoCardText: {
		color: '#1E5168',
		fontSize: 15,
		fontWeight: 'bold'
	}
}));