import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@material-ui/core';

export default function SingleDatePicker({
  name,
  noLabel,
  label,
  disabled,
  initialDate,
  error,
  helperText,
  blockCurrentDate = false,
  blockPastDates = false,
  blockFutureDates = false,
  daysToAddAfterBlockedCurrentDate = 0,
  daysToAddAfterBlockedPastDate = 0,
  onBlockedDate,
  onDateChange,
  readOnly = false
}) {
  const [date, setDate] = useState(initialDate || null);

  const changeDate = newDate => {
    const isCurrentDate =
      moment(newDate).isSame(moment.now(), 'day');

    const isDateBefore =
      moment(newDate).diff(moment.now(), 'hours') < -24;

    const isDateAfter =
      moment(newDate).diff(moment.now(), 'hours') > 0;

    if(isCurrentDate && blockCurrentDate) {
      setDate(moment().add(daysToAddAfterBlockedCurrentDate, 'days'));
      onDateChange(moment().add(daysToAddAfterBlockedCurrentDate, 'days'));
      onBlockedDate();

      return;
    }

    if(isDateBefore && blockPastDates) {
      setDate(moment().add(daysToAddAfterBlockedPastDate, 'days'));
      onDateChange(moment().add(daysToAddAfterBlockedPastDate, 'days'));
      onBlockedDate();

      return;
    }

    if(isDateAfter && blockFutureDates) {
      setDate(moment());
      onDateChange(moment());
      onBlockedDate();

      return;
    }

    setDate(newDate);

    return moment(newDate).isValid()
      ? onDateChange((moment(newDate).format("DD/MM/YYYY")))
      : onDateChange(newDate?._i);
  }

  return(
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        inputFormat='DD/MM/YYYY'
        autoFocus={false}
        label={noLabel ? null : (label || "Data")}
        disabled={disabled}
        value={date}
        onChange={newDate => changeDate(newDate)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            error={error}
            helperText={helperText}
            name={name}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              readOnly: readOnly
            }}
            SelectProps={{
              MenuProps: {
                disableEnforceFocus: true
              }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

SingleDatePicker.propTypes = {
  name: PropTypes.string,
  noLabel: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  initialDate: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  blockPastDates: PropTypes.bool,
  blockFutureDates: PropTypes.bool,
  onBlockedDate: PropTypes.func,
  onDateChange: PropTypes.func.isRequired
};