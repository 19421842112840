import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { convertToReal, convertFormattedMoneyToNumber } from 'helpers/converters';
import { formatPhone, formatPostalCode, formatDocument, permanence } from 'helpers/formatters';
import { getImageExtensionFromBase64 } from 'helpers/common';

import { styles } from '../styles';

const formatDate = date => {
  const formattedDate = moment(date).format('DD/MM/YYYY HH:mm');
  return formattedDate;
}

const Header = ({ children, hasDarkBackground, hasUnderline }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: hasDarkBackground ? '#E2E2E2' : '#FFFFFF',
        borderBottom: hasUnderline ? '1 solid #000000' : null
      }}
    >
      {children}
    </View>
  );
}

const Footer = ({ establishmentData }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        padding: 10,
        borderTop: '1 solid #E2E2E2'
      }}
    >
      <View style={{ width: '50%' }}>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.establishmentName}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{formatPhone(establishmentData?.phone)}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.email}</Text>
      </View>
      <View style={{ width: '50%' }}>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>CNPJ: {formatDocument(establishmentData?.documentId)}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.street}, {establishmentData?.number} {establishmentData?.district}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.city} - {establishmentData?.state} | CEP: {formatPostalCode(establishmentData?.postalCode)}</Text>
      </View>
    </View>
  );
}

const Receipt = ({ exportData }) => {
  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  const {
    serviceOrderCode,
    clientName,
    clientEmail,
    phones,
    plate,
    vehicleModel,
    vehicleColor,
    entryPhotoBase64,
    entryDateTime,
    exitDateTime,
    paymentDateTime,
    services,
    typePrice,
    paymentMethodName,
    amountTotal,
    establishment,
    operationSituationName,
    financialSituationName,
    observations,
    transactionId,
    establishment: { base64Logo }
  } = exportData;

  const isVehicleInside = operationSituationName == 'No pátio';
  const isPaid = financialSituationName == 'Pago';

  const establishmentData = {
    ...establishment?.addresses[0],
    establishmentName: establishment?.establishmentName,
    documentId: establishment?.documentId,
    email: establishment?.email,
    phone: establishment?.phone
  }

  const base64LogoExtension = getImageExtensionFromBase64(base64Logo);
  const base64EntryPhotoExtension = getImageExtensionFromBase64(entryPhotoBase64 || '');

  const doesOsHaveObservations = !!observations?.observation || !!observations?.editObservation || !!observations?.cancelObservation || !!observations?.deleteObservation || !!observations?.changePriceObservation;

  return(
    <Document>
      <Page>
        {!!base64Logo && (
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
            <Image
              style={{
                objectFit: 'cover',
                height: 100
              }}
              src={`data:image/${base64LogoExtension};base64,${base64Logo}`}
            />
          </View>
        )}
        <Header hasDarkBackground>
          <Text style={styles.fontSize13}>{!!transactionId ? 'Recibo de Pagamento' : 'Recibo'}</Text>
          <Text style={styles.fontSize13}>OS: {serviceOrderCode}</Text>
        </Header>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
          <View style={{ width: '50%' }}>
            {!!clientName && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Cliente: {clientName}</Text>
            )}
            {!!phones && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Telefone: {formatPhone(phones)}</Text>
            )}
            {!!clientEmail && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>E-mail: {clientEmail}</Text>
            )}
          </View>
          <View style={{ width: '50%' }}>
            {!!entryPhotoBase64 && (
              <Image
                style={{
                  objectFit: 'cover',
                  height: 100,
                  marginBottom: 10
                }}
                src={`data:image/${base64EntryPhotoExtension};base64,${entryPhotoBase64}`}
              />
            )}
            <Text style={[styles.fontSize11, styles.marginBottom5]}>Veículo: {plate} - {vehicleModel} {vehicleColor}</Text>
            {!!entryDateTime && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Entrada: {formatDate(entryDateTime)}</Text>
            )}
            {(!!exitDateTime && !isVehicleInside) && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Saída: {formatDate(exitDateTime)}</Text>
            )}
            {((!!exitDateTime && !!entryDateTime) && !isVehicleInside) && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Permanência: {permanence([exitDateTime, entryDateTime])}</Text>
            )}
            {!!paymentDateTime && (
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Pagamento: {formatDate(paymentDateTime)}</Text>
            )}
            {doesOsHaveObservations && (
              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {!!observations?.observation && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Observação: {observations?.observation}</Text>
                )}
                {!!observations?.editObservation && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Observação de edição: {observations?.editObservation}</Text>
                )}
                {!!observations?.cancelObservation && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Observação de cancelamento: {observations?.cancelObservation}</Text>
                )}
                {!!observations?.deleteObservation && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Observação de remoção: {observations?.deleteObservation}</Text>
                )}
                {!!observations?.changePriceObservation && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Observação de edição de valor: {observations?.changePriceObservation}</Text>
                )}
              </View>
            )}
          </View>
        </View>
        {services.length > 0 && (
          <>
            <Header hasDarkBackground>
              <Text style={styles.fontSize13}>Serviços</Text>
            </Header>
            <View style={{ padding: 10 }}>
              {services.map(service => {
                return(
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>{service?.description} ({service?.quantity})</Text>
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>{service?.serviceUsage || convertToReal(service?.amount)}</Text>
                  </View>
                );
              })}
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={[styles.fontSize11, styles.marginBottom5]}>Total</Text>
                <Text style={[styles.fontSize11, styles.marginBottom5]}>
                  {convertToReal(services.reduce((serviceAcumulator, service) => serviceAcumulator + convertFormattedMoneyToNumber(service?.amount), 0))}
                </Text>
              </View>
            </View>
          </>
        )}
        {!!typePrice && (
          <>
            <Header hasDarkBackground>
              <Text style={styles.fontSize13}>Tabela de estacionamento</Text>
            </Header>
            <View style={{ padding: 10 }}>
              <Text style={[styles.fontSize11, styles.marginBottom5]}>{typePrice}</Text>
            </View>
          </>
        )}
        {!!paymentMethodName && (
          <View style={{ marginBottom: 10 }}>
            <Header hasUnderline>
              <Text style={styles.fontSize13}>Forma de pagamento</Text>
            </Header>
            <View style={{ padding: '0, 10', marginTop: 10, marginBottom: 10 }}>
              <Text style={[styles.fontSize11, styles.marginBottom5]}>{paymentMethodName}</Text>
            </View>
          </View>
        )}
        {isPaid && (
          <Header hasDarkBackground>
            <Text style={styles.fontSize13}>VALOR TOTAL</Text>
            <Text style={styles.fontSize13}>{convertToReal(amountTotal)}</Text>
          </Header>
        )}
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
          <Text style={[styles.fontSize11, styles.secondaryColor]}>{establishmentData?.city}, {currentDate}</Text>
        </View>
        <Footer establishmentData={establishmentData} />
      </Page>
    </Document>
  );
}

export default Receipt;