import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import Button from '../Button';

const NewExportButtons = ({ isExporting, handleExportation }) => {
  return (
    <Dropdown label="Exportar">
      <Button
        loading={isExporting}
        fullWidth
        color="inherit"
        onClick={() => handleExportation('xls')}
      >
        XLS
      </Button>
      <Button
        loading={isExporting}
        fullWidth
        color="inherit"
        onClick={() => handleExportation('pdf')}
      >
        PDF
      </Button>
    </Dropdown>
  );
};

NewExportButtons.propTypes = {
  handleExportation: PropTypes.func.isRequired,
};

export default NewExportButtons;
