import qs from 'qs';
import { changeBaseAPI, changeBaseNewAPI } from '../../../services';

export const createNewUser = async params => {
  const response = await changeBaseNewAPI('post', '/user/new', qs.stringify(params));
  return response.data;
}

export const updateUser = params => {
  const { userId } = params;

  delete params['recaptcha'];

  return changeBaseAPI('put', `/user/${userId}`, qs.stringify(params)).then(response => response.data);
}

export const fetchProfile = userId => {
  return changeBaseAPI('get', `/user/${userId}`).then(response => response.data);
}