import { newAPI } from 'services';

export const fetchProductsFilters = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/products/filters/all`);
  return response.data;
}

export const fetchProductsList = async (userId, establishmentId, page, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/products/list?page=${page}${qp}`);
  return response.data;
}

export const fetchProductsExport = async (userId, establishmentId, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/products/list/export${qp}`);
  return response.data;
}