import * as types from './constants';

export const setEstablishmentId = ({ establishmentId = '' }) => ({
  type: types.SET_ESTABLISHMENT_ID,
  id: establishmentId
});

export const setBusinessInfo = data => ({
  type: types.SET_BUSINESS_INFO,
  data
});

export const setEstablishments = data => ({
  type: types.SET_ESTABLISHMENTS,
  data
});

export const setEstablishmentTypes = establishmentTypes => ({
  type: types.SET_ESTABLISHMENT_TYPES,
  establishmentTypes
});

export const clearBusinessInfo = () => ({
  type: types.CLEAR_BUSINESS_INFO
});

export const clearAddress = () => ({
  type: types.CLEAR_ADDRESS
});

export const handlePostalCode = status => ({
  type: types.HANDLE_POSTAL_CODE,
  status
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const setEditingState = status => ({
  type: types.HANDLE_EDITING,
  status
});