import React from 'react';
import { Paper } from '@material-ui/core';
import Card from 'components/Card';
import SupplierRegistration from './SupplierRegistration';

export default function SupplierRegistrationPage({ supplierUuid }) {
  return(
    <Paper>
      <Card title="Preencha as informações do fornecedor">
        <SupplierRegistration
          isFromSupplierPage
          supplierUuid={supplierUuid}
        />
      </Card>
    </Paper>
  );
}