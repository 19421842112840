import * as yup from 'yup';

export const validations = yup.object().shape({
  password: yup.string().required('A senha é obrigatória'),
  passwordConfirm: yup
    .string()
    .when('password', (password, schema) =>
      password ? schema.required('Confirme a senha') : schema
    )
    .oneOf(
      [yup.ref('password'), null],
      'A senha e a confirmação devem ser iguais'
    ),
});
