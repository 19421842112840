import { toast } from 'react-toastify';
import { createServiceNotificationConfig, updateServiceNotificationConfig } from './services';

export const registerNewServiceNotificationConfig = params => {
  createServiceNotificationConfig(params).then(() => {
    toast.success('Lembrete configurado com sucesso.');
  });
}

export const upsertServiceNotificationConfig = params => {
  updateServiceNotificationConfig(params).then(() => {
    toast.success('Lembrete atualizado com sucesso.');
  });
}