import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from 'components/Card';
import FileUploader from 'components/FileUploader';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { loadEconomicActivities } from '../../../../store/thunk';
import { loadAddress } from '../../../../../BusinessInfo/store/thunk';
import { clearAddress } from '../../../../../BusinessInfo/store/actions';
import { setLogoFile, setConfigType } from '../../../../store/actions';

import { formatDocument, formatPostalCode } from 'helpers/formatters';

export default function BusinessInfo({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { isLoading, isPostalCodeLoading } = useSelector(state => state.businessInfo);

  const {
    logoFile,
    configType,
    basicInfoErrors,
    basicInfo: {
      taxSettingsOwner
    },
    tables: {
      streetType
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  useEffect(() => {
    dispatch(setConfigType(taxSettingsOwner));
  }, [taxSettingsOwner]);

  useEffect(() => {
    dispatch(loadEconomicActivities(userId));
  }, []);

  const handlePostalCode = e => {
    const { name, value } = e.target;

    formikProps.setFieldValue(name, value);

    if(value.length === 9) {
      return dispatch(loadAddress(value)).then(data => {
        const { logradouro, bairro, localidade, uf } = data;
        formikProps.setFieldValue('street', logradouro);
        formikProps.setFieldValue('district', bairro);
        formikProps.setFieldValue('city', localidade);
        formikProps.setFieldValue('state', uf);
      });
    }

    return dispatch(clearAddress());
  }

  const handleUploadFile = (e, formikNewProps) => {
    const logo = e.target.files[0];
    const { setFieldValue } = formikNewProps;

    setFieldValue('logo', logo);

    return dispatch(setLogoFile(logo));
  }

  return(
    <Card
      title="Preencha as informações básicas do negócio"
      key={formikProps?.values?.establishmentId}
    >
      <Grid item xs={12}>
        <Form>
          <Loader isLoading={isLoading}>
            <Grid container style={{ flexDirection: 'column', marginTop: 10, marginBottom: 10 }}>
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Logo do estabelecimento
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <FileUploader
                  name="logo"
                  file={logoFile}
                  errors={formikProps.errors.logo}
                  onChange={e => handleUploadFile(e, formikProps)}
                >
                  Escolha um arquivo
                </FileUploader>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography color='textSecondary' style={{ marginTop: 20, marginBottom: 20 }}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="#F5B941"
                    size="lg"
                    style={{ marginRight: 10 }}
                  />
                  Tamanho recomendado é de 160x130. Formatos suportados (JPG, PNG e JPEG)
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Informações básicas do negócio
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  autoFocus
                  name="fantasyName"
                  label="Nome do estabelecimento"
                  value={formikProps.values.fantasyName || ''}
                  error={formikProps.errors.fantasyName || basicInfoErrors?.fantasyName}
                  helperText={formikProps.errors.fantasyName || basicInfoErrors?.fantasyName}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="documentId"
                  type="document"
                  label="CNPJ"
                  placeholder="00.000.000/0000-00"
                  value={formatDocument(formikProps.values.documentId) || ''}
                  error={formikProps.errors.documentId || basicInfoErrors?.documentId}
                  helperText={formikProps.errors.documentId || basicInfoErrors?.documentId}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="phone"
                  type="phone"
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  value={formikProps.values.phone || ''}
                  error={formikProps.errors.phone || basicInfoErrors?.phone}
                  helperText={formikProps.errors.phone || basicInfoErrors?.phone}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="usuario@exemplo.com.br"
                  value={formikProps.values.email || ''}
                  error={formikProps.errors.email || basicInfoErrors?.email}
                  helperText={formikProps.errors.email || basicInfoErrors?.email}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={isSameCnpj}
                name="companyName"
                label="Razão social"
                value={formikProps.values.companyName || ''}
                error={formikProps.errors.companyName || basicInfoErrors?.companyName}
                helperText={formikProps.errors.companyName || basicInfoErrors?.companyName}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Local onde o serviço é prestado
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="postalCode"
                  type="postalCode"
                  label="CEP"
                  placeholder="00000-000"
                  value={formatPostalCode(formikProps.values.postalCode) || ''}
                  error={formikProps.errors.postalCode || basicInfoErrors?.postalCode}
                  helperText={formikProps.errors.postalCode || basicInfoErrors?.postalCode}
                  onChange={e => handlePostalCode(e)}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <Select
                  disabled={isSameCnpj}
                  name="streetType"
                  label="Tipo do local"
                  options={streetType}
                  value={+formikProps.values.streetType || ''}
                  error={formikProps.errors.streetType || basicInfoErrors?.streetType}
                  helperText={formikProps.errors.streetType || basicInfoErrors?.streetType}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="street"
                  type="text"
                  label="Endereço"
                  placeholder="Endereço"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.street || ''}
                  error={formikProps.errors.street || basicInfoErrors?.street}
                  helperText={formikProps.errors.street || basicInfoErrors?.street}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={1} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="number"
                  type="number"
                  label="Número"
                  placeholder="0"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.number || ''}
                  error={formikProps.errors.number}
                  helperText={formikProps.errors.number}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="complement"
                  type="text"
                  label="Complemento"
                  placeholder="Ex.: 3˚ andar"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.complement || ''}
                  error={formikProps.errors.complement}
                  helperText={formikProps.errors.complement}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="district"
                  type="text"
                  label="Bairro"
                  placeholder="Bairro"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.district || ''}
                  error={formikProps.errors.district || basicInfoErrors?.district}
                  helperText={formikProps.errors.district || basicInfoErrors?.district}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="city"
                  type="text"
                  label="Cidade"
                  placeholder="Cidade"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.city || ''}
                  error={formikProps.errors.city || basicInfoErrors?.city}
                  helperText={formikProps.errors.city || basicInfoErrors?.city}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  id="state"
                  name="state"
                  type="text"
                  label="UF"
                  placeholder="UF"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.state || ''}
                  error={formikProps.errors.state || basicInfoErrors?.state}
                  helperText={formikProps.errors.state || basicInfoErrors?.state}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Loader>
        </Form>
      </Grid>
    </Card>
  );
}

BusinessInfo.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      fantasyName: '',
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: ''
    },
    errors: {
      fantasyName: false,
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false
    }
  }
};

BusinessInfo.propTypes = {
  formikProps: PropTypes.shape()
};