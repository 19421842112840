import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.background.paper,
    textAlign: 'center',
  },
  buttonPlus: {
    marginTop: '5px',
    marginBottom: '15px',
    paddingTop: '20.8px',
    paddingBottom: '16.8px',
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: 'red',
  },
  arrow: {
    color: 'red',
  },
}));
