import qs from 'qs';
import { api } from '../../../services';

export const fetchConfigurations = () =>
  api.get('/reservations/settings').then(response => response.data);

export const createConfigurations = params =>
  api
    .post('/reservations/settings', qs.stringify(params))
    .then(response => response.data);

export const updateConfigurations = params =>
  api
    .put('/reservations/settings', qs.stringify(params))
    .then(response => response.data);
