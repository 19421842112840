import { toast } from 'react-toastify';
import { handleLoading, clearProfile } from '../../Profile/store/actions';
import { sendNewPassword } from './services';

export const recoverPassword = params => dispatch => {
  dispatch(handleLoading(true));

  return sendNewPassword(params)
    .then(({ data }) => {
      toast.success(data.msg);
      dispatch(clearProfile());
    })
    .finally(() => dispatch(handleLoading(false)));
};
