import React from 'react';

import {
  Drawer as DrawerComponent,
  Typography
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DrawerIcon = require('../../assets/img/icon_drawer.svg');

export default function Drawer({ open, onClose, id, title, children }) {
  return(
    <DrawerComponent
      anchor="right"
      open={open}
      onClose={onClose}
      id={id}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0 0 10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={DrawerIcon} />
          <Typography color='textSecondary' style={{ marginLeft: 5 }}>
            {title}
          </Typography>
        </div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ padding: 10, height: '100%' }}>
        {children}
      </div>
    </DrawerComponent>
  );
}