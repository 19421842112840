import {
  SET_NOTIFICATION_TAGS,
  SET_NOTIFICATION_CATEGORIES,
  SET_CURRENT_NOTIFICATION_CATEGORY
} from './constants';

const defaultState = {
  tags: [],
  categories: [],
  currentCategory: null,
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_NOTIFICATION_TAGS: {
      return {
        ...state,
        tags: action.data
      }
    }
    case SET_NOTIFICATION_CATEGORIES: {
      return {
        ...state,
        categories: action.data.content
      }
    }
    case SET_CURRENT_NOTIFICATION_CATEGORY: {
      return {
        ...state,
        currentCategory: action.data
      }
    }
    default: {
      return state
    };
  }
}