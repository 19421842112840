import { fetchActivities } from './services';
import { setActivities, handleLoading } from './actions';

export const loadActivities = ({ page, pageSize, qp = '', extraProps }) => dispatch => {
  const {
    userId,
    establishmentId,
    startDate,
    endDate
  } = extraProps;

  let url = `${userId}/histories?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}&orderBy=created_at&sort=desc${qp}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/histories?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}&orderBy=created_at&sort=desc${qp}`;
  }

  dispatch(handleLoading(true));

  return fetchActivities(url)
    .then(({ data }) => dispatch(setActivities(data.content, data.total)))
    .catch(() => dispatch(handleLoading(false)));
}