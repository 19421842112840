import { compose, over, set, lensProp, path, pick, mergeAll } from 'ramda';
import { formatPhone, formatPostalCode, formatDocument } from '../../../helpers/formatters';
import { convertToEmptyString } from '../../../helpers/converters';

import {
  SET_ESTABLISHMENT_ID,
  SET_BUSINESS_INFO,
  SET_ESTABLISHMENT_TYPES,
  CLEAR_BUSINESS_INFO,
  CLEAR_ADDRESS,
  HANDLE_POSTAL_CODE,
  HANDLE_LOADING,
  HANDLE_EDITING,
  SET_ESTABLISHMENTS
} from './constants';

const defaultState = {
  establishmentId: '',
  accessKey: null,
  isLoading: false,
  isPostalCodeLoading: false,
  establishmentTypes: [],
  establishments: [],
  submit: {
    userId: null,
    boostPackage: 0,
    debitWarning: 0,
    premiumPackage: 0,
    appPermission: 0,
    establishmentTypes: [],
    establishmentName: '',
    companyName: '',
    documentId: '',
    phone: '',
    email: '',
    vacanciesMarks: '',
    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: ''
  },
  isEditing: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ESTABLISHMENT_ID: {
      return {
        ...state,
        establishmentId: action?.id
      }
    }
    case SET_ESTABLISHMENTS: {
      return set(lensProp('establishments'), action.data, state);
    }
    case SET_BUSINESS_INFO: {
      const setAddress = compose(
        over(lensProp('postalCode'), formatPostalCode),
        path(['addresses', 0])
      );

      const establishmentTypesStringArray = action.data.establishmentTypes;

      const establishmentTypes = {
        establishmentTypes: establishmentTypesStringArray.map(Number)
      }

      const setBusinessInfo = compose(
        over(lensProp('vacanciesMarks'), convertToEmptyString),
        over(lensProp('vacanciesMarks'), convertToEmptyString),
        over(lensProp('documentId'), formatDocument),
        over(lensProp('phone'), formatPhone),
        pick([
          'userId',
          'establishmentName',
          'companyName',
          'documentId',
          'phone',
          'email',
          'vacanciesMarks',
          'boostPackage',
          'debitWarning',
          'premiumPackage',
          'appPermission'
        ])
      );

      const businessInfo = compose(
        set(lensProp('isEditing'), false),
        set(lensProp('accessKey'), action.data.accessKey),
        set(lensProp('address'), setAddress(action.data)),
        set(
          lensProp('submit'),
          mergeAll([
            setBusinessInfo(action.data),
            setAddress(action.data),
            establishmentTypes
          ])
        )
      );

      const result = businessInfo(state);

      return result;
    }
    case SET_ESTABLISHMENT_TYPES: {
      return {
        ...state,
        establishmentTypes: action?.establishmentTypes?.map(type => +type)
      }
    }
    case CLEAR_BUSINESS_INFO: {
      return {
        ...state,
        submit: defaultState.submit
      }
    }
    case CLEAR_ADDRESS: {
      return {
        ...state,
        submit: {
          ...state.submit,
          postalCode: '',
          street: '',
          number: 0,
          complement: '',
          district: '',
          city: '',
          state: ''
        },
        isPostalCodeLoading: false
      }
    }
    case HANDLE_POSTAL_CODE: {
      return set(lensProp('isPostalCodeLoading'), action.status, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_EDITING: {
      return set(lensProp('isEditing'), action.status, state);
    }
    default: {
      return state;
    }
  }
}