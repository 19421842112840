import {
  SET_COMISSIONS_CONFIG,
  CLEAR_COMISSIONS_CONFIG,
  SET_COMISSIONS_CONFIG_LOADING,
  SET_COMISSIONS_ERRORS,
  CLEAR_COMISSIONS_ERRORS
} from './constants';

const defaultState = {
  submit: {
    establishmentId: '',
    uuid: '',
    serviceId: '',
    profiles: [],
    errors: {}
  },
  isLoading: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_COMISSIONS_CONFIG: {
      return {
        ...state,
        submit: {
          ...state.submit,
          ...action.data
        }
      }
    }
    case CLEAR_COMISSIONS_CONFIG: {
      return {
        ...state,
        submit: {
          ...defaultState.submit
        }
      }
    }
    case SET_COMISSIONS_CONFIG_LOADING: {
      return {
        ...state,
        submit: {
          ...state.submit
        },
        isLoading: action.isLoading
      }
    }
    case SET_COMISSIONS_ERRORS: {
      return {
        ...state,
        submit: {
          ...state.submit,
          errors: action.errors
        }
      }
    }
    case CLEAR_COMISSIONS_ERRORS: {
      return {
        ...state,
        submit: {
          ...state.submit,
          errors: {}
        }
      }
    }
    default: {
      return state;
    }
  }
}