import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import MaterialIcon from 'material-icons-react';
import Card from 'components/Card';
import Select from 'components/Select';
import Button from 'components/Button';

export default function ProductsTypeSelection() {
  const history = useHistory();

  const productObjetiveOptions = [
    {
      value: '0',
      label: 'Produto para venda e consumo interno',
      description: 'Se o mesmo produto for para venda aos clientes e consumo interno através dos serviços prestados.',
      icon: ''
    },
    {
      value: '1',
      label: 'Produto apenas para venda',
      description: 'Produto que possui a finalidade apenas para venda.',
      icon: 'inventory'
    },
    {
      value: '2',
      label: 'Produto apenas para consumo',
      description: 'Se você vai usar este produto apenas para consumo interno nas prestações de serviço.',
      icon: 'local_car_wash'
    }
  ];

  const [productObjetive, setProductObjetive] = useState(productObjetiveOptions[0]);

  const handleProductSelection = () => {
    if(+productObjetive.value == 0) {
      return history.push('/novo-produto-consumo-e-venda');
    }

    if(+productObjetive.value == 1) {
      return history.push('/novo-produto-venda');
    }

    if(+productObjetive.value == 2) {
      return history.push('/novo-produto');
    }
  }

  return(
    <Grid container justify="center">
      <Grid xs={12} md={6}>
        <Card title="Qual o objetivo do produto?">
          <Select
            name="productObjetive"
            options={productObjetiveOptions}
            value={productObjetive.value}
            onChange={event => setProductObjetive(productObjetiveOptions[event.target.value])}
          />
          <Grid container>
            <Grid xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
              {(+productObjetive.value == 0) && (
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                  <MaterialIcon
                    icon={productObjetiveOptions[1].icon}
                    size={36}
                    color="#022A5C"
                  />
                  <Typography variant='h5' color='primary' style={{ margin: '0 20px 30px 20px' }}>+</Typography>
                  <MaterialIcon
                    icon={productObjetiveOptions[2].icon}
                    size={36}
                    color="#022A5C"
                  />
                </div>
              )}
              {(+productObjetive.value == 1) && (
                <MaterialIcon
                  icon={productObjetiveOptions[1].icon}
                  size={36}
                  color="#022A5C"
                />
              )}
              {(+productObjetive.value == 2) && (
                <MaterialIcon
                  icon={productObjetiveOptions[2].icon}
                  size={36}
                  color="#022A5C"
                />
              )}
              <Typography variant='body1' color='primary'>
                <b>{productObjetive.label}</b>
              </Typography>
              <Typography variant='body1' color='primary' style={{ margin: '20px 0 20px 0' }}>
                {productObjetive.description}
              </Typography>
              <Button
                type="button"
                color="success"
                onClick={handleProductSelection}
              >
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}