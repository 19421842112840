import * as yup from 'yup';

const requiredCondition = text => ({
  is: true,
  then: yup.string().required(text),
});

export const validations = yup.object().shape({
  isPaying: yup.bool(),
  isEmailing: yup.bool(),
  isCanceling: yup.bool(),
  paymentDateTime: yup
    .string()
    .when('isPaying', requiredCondition('Escolha a data de pagamento')),
  paymentMethod: yup
    .string()
    .when(['isPaying', 'isCanceling'], {
      is: (isPaying, isCanceling) => isPaying && !isCanceling,
      then: yup.string().required('Escolha o meio de pagamento')
    })
    .nullable(),
  amount: yup
    .string()
    .when('isPaying', requiredCondition('Digite o valor pago')),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .when('isEmailing', requiredCondition('Digite o e-mail para enviar')),
  reason: yup
    .string()
    .when('isCanceling', requiredCondition('Digite o motivo do cancelamento')),
  userPassword: yup
    .string()
    .when('isCanceling', requiredCondition('Digite a senha para cancelar')),
});