import * as types from './constants';

export const setSupplierLoading = isLoading => ({
  type: types.SET_SUPPLIER_LOADING,
  isLoading
});

export const setSupplier = data => ({
  type: types.SET_SUPPLIER,
  data
});

export const setSuppliers = data => ({
  type: types.SET_SUPPLIERS,
  data
});

export const setSupplierUuid = uuid => ({
  type: types.SET_SUPPLIER_UUID,
  uuid
});

export const setSupplierErrors = errors => ({
  type: types.SET_SUPPLIER_ERRORS,
  errors
});

export const clearSupplierErrors = () => ({
  type: types.CLEAR_SUPPLIER_ERRORS
});

export const clearSupplier = () => ({
  type: types.CLEAR_SUPPLIER
});