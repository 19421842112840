import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from '../styles';

export default function Item({ primary, children }) {
  const classes = useStyles();

  const textCondition = typeof children === 'string' || typeof children === 'number';

  if(!children || children === '0') {
    return null;
  }

  if(textCondition) {
    return (
      <ListItem className={classes.container}>
        <Typography variant="body1" color="primary" className={classes.title}>
          {primary}
        </Typography>
        <Typography variant="body2" color="primary">
          {children}
        </Typography>
      </ListItem>
    );
  }

  return(
    <ListItem className={classes.containerMargin}>
      <Grid item sm={12} xs={12}>
        <Typography variant="body1" color="primary" className={classes.title}>
          {primary}
        </Typography>
        <Grid>{children}</Grid>
      </Grid>
    </ListItem>
  );
}

Item.propTypes = {
  primary: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}