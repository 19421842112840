import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { concat } from 'ramda';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';
import ActivitiesRegistrationDetails from 'components/ActivitiesRegistrationDetails';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { convertList } from '../../../helpers/converters';
import { formatFilters } from '../../../helpers/formatters';
import { updateToLoader, setParamsToLoader } from '../../../helpers/loaders';

import { setEstablishmentId } from 'pages/BusinessInfo/store/actions';
import { loadActivities } from '../store/thunk';

import { inputs } from './resources';

const headers = [
  { title: 'Operador', field: 'user' },
  { title: 'Data da atividade', field: 'createdAt' },
  { title: 'Tipo', field: 'activityType' },
  { title: 'Origem', field: 'activitySource' },
  { title: 'Observação', field: 'description' }
];

const width = window.innerWidth;
const isMobile = width < 1024;

class ActivitiesRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activitySource: null,
      activityType: null,
      activitySourceId: null,
      activityTypeId: null,
      activitiesRegistrationDetailsData: null,
      isOpenActivitiesRegistrationDetails: false,
      activitiesRegistrationPage: 1,
      activitiesRegistrationPageSize: 10
    }
  }

  componentDidMount() {
    this.loadActivities();
  }

  componentDidUpdate(prevProps) {
    updateToLoader(prevProps, this.props, [this.loadActivities]);
  }

  setActivitiesRegistrationDetailsData = data => {
    this.setState({
      activitiesRegistrationDetailsData: data
    });
  }

  setIsOpenActivitiesRegistrationDetails = isOpen => {
    this.setState({
      isOpenActivitiesRegistrationDetails: isOpen
    });
  }

  setActivitySource = source => {
    this.setState({
      activitySource: source
    });
  }

  setActivitySourceId = id => {
    this.setState({
      activitySourceId: id
    });
  }

  setActivityType = type => {
    this.setState({
      activityType: type
    });
  }

  setActivityTypeId = id => {
    this.setState({
      activityTypeId: id
    });
  }

  setEstablishmentId = establishmentId => {
    const { setEstablishmentId } = this.props;
    setParamsToLoader(this.props, { establishmentId }, setEstablishmentId);
  }

  loadActivities = params => {
    const { loadActivities } = this.props;

    const paramsValues = {
      ...params,
      pageSize: this.state.activitiesRegistrationPageSize,
      page: this.state.activitiesRegistrationPage
    }

    setParamsToLoader(this.props, paramsValues, loadActivities, [
      'establishmentId',
      'userId',
      'startDate',
      'endDate'
    ]);
  }

  render() {
    const { isLoading, filters, list, total } = this.props;

    const validActivitiesSource = ['Fatura', 'Ordem de serviço', 'Folha de comissão', 'Folha de pagamento', 'Configuração', 'Senha de usuário', 'Ordem de venda'];
    const validActivitiesType = ['Atualização', 'Cancelamento', 'Remoção', 'Desfazer Pagamento'];

    return(
      <Paper>
        {isMobile ? (
          <FilterMobile
            filter
            hasSearchTerm={false}
            filters={filters}
            handlePageRequest={this.loadActivities}
          />
        ) : (
          <Filter
            filter
            hasSearchTerm={false}
            filters={filters}
            handlePageRequest={this.loadActivities}
          />
        )}
        <MTable
          hasSorting
          loading={isLoading}
          headers={headers}
          data={list}
          actions={[
            rowData =>
              validActivitiesType.includes(rowData.activityType) &&
              validActivitiesSource.includes(rowData.activitySource)
                ? ({
                  icon: () => (
                    <FontAwesomeIcon
                      icon={faEye}
                      color="#022A5C"
                      size="xs"
                    />
                  ),
                  tooltip: "Ver mais informações",
                  onClick: (_, rowData) => {
                    this.setActivitySource(rowData.activitySource);
                    this.setActivitySourceId(rowData.activitySourceId);
                    this.setActivityType(rowData.activityType)
                    this.setActivityTypeId(rowData.activityTypeId);
                    this.setActivitiesRegistrationDetailsData(rowData);
                    this.setIsOpenActivitiesRegistrationDetails(true);
                  }
                })
              : null
          ]}
        />
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(total / this.state.activitiesRegistrationPageSize)}
            page={this.state.activitiesRegistrationPage}
            onChange={(_, page) => {
              this.setState({
                activitiesRegistrationPage: page
              }, () => {
                this.loadActivities();
              });
            }}
          />
        </Grid>
        {this.state.isOpenActivitiesRegistrationDetails && (
          <ActivitiesRegistrationDetails
            title={`${this.state.activityType} de ${this.state.activitySource}`}
            isOpenActivitiesRegistrationDetails={this.state.isOpenActivitiesRegistrationDetails}
            setIsOpenActivitiesRegistrationDetails={this.setIsOpenActivitiesRegistrationDetails}
            data={this.state.activitiesRegistrationDetailsData}
          />
        )}
      </Paper>
    );
  }
}

ActivitiesRegistration.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.number.isRequired,
  loadActivities: PropTypes.func.isRequired,
  list: PropTypes.shape({
    activityHistoryId: PropTypes.number,
    userId: PropTypes.number,
    establishmentId: PropTypes.number,
    activityTypeId: PropTypes.number,
    activitySourceId: PropTypes.number,
    registerId: PropTypes.number,
    lastRegister: PropTypes.shape,
    description: PropTypes.string,
    observation: PropTypes.string,
    status: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string
  }).isRequired
};

const mapStateToProps = state => {
  const { list } = state.activities;

  const properties = ['user', 'activityType', 'activitySource'];
  const filters = concat(inputs, formatFilters(properties, state.filters.selects));

  return {
    ...state.activities,
    list: convertList(properties, filters, list),
    establishmentId: state.businessInfo.establishmentId,
    establishments: state.login.establishments,
    startDate: state.dateFilter.startDate,
    endDate: state.dateFilter.endDate,
    page: state.pagination.activePage,
    pageSize: state.pagination.size,
    qp: state.filters.qp,
    userId: state.profile.userId,
    filters
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ loadActivities, setEstablishmentId }, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesRegistration);