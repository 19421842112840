import qs from 'qs';
import { newAPI, changeBaseNewAPI, changeBaseAPI } from '../../../services';

export const fetchOsReceipt = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/serviceorders/${params.serviceOrderId}/print`);
  return response.data;
}

export const fetchBusinessInfo = (userId, establishmentId) => {
  const url = `/${userId}/establishment/${establishmentId}`;
  return changeBaseAPI('get', url).then(response => response.data);
}

export const fetchServiceOrder = (serviceOrderId, userId, establishmentId) => {
  const url = `/${userId}/establishment/${establishmentId}/serviceorders/${serviceOrderId}`;
  return changeBaseNewAPI('get', url).then(response => response.data);
}

export const fetchMeansPayment = (userId, establishmentId) => {
  const url = `/${userId}/establishment/${establishmentId}/paymentmethods`;
  return changeBaseAPI('get', url).then(response => response.data);
}

export const fetchMyServices = (userId, establishmentId) => {
  const url = `/${userId}/establishment/${establishmentId}/services`;
  return changeBaseAPI('get', url).then(response => response.data);
}

export const fetchServiceOrderProduct = async (userId, establishmentId, serviceOrderServiceProductId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/serviceorders/show/${serviceOrderServiceProductId}`);
  return response.data;
}

export const deleteServiceOrder = async (data, serviceOrderId, userId, establishmentId) => {
  const response = await changeBaseNewAPI('delete', `${userId}/establishment/${establishmentId}/serviceorders/${serviceOrderId}`, qs.stringify(data));
  return response.data;
}

export const updateServiceOrder = async (params, serviceOrderId, userId, establishmentId) => {
  let newParams = qs.stringify(params);

  newParams = params?.services?.length == 0
    ? newParams.concat('&services=[]')
    : newParams;

  const response = await changeBaseNewAPI('put', `/${userId}/establishment/${establishmentId}/serviceorders/${serviceOrderId}`, newParams);
  return response.data;
}

export const deliverReceipt = async (params, userId, establishmentId) => {
  const response = await newAPI.post(`/${userId}/establishment/${establishmentId}/serviceorders/receipt/send`, qs.stringify(params));
  return response.data;
}

export const deleteDiscount = async (userId, establishmentId, serviceOrderId) => {
  const response = await newAPI.delete(`/${userId}/establishment/${establishmentId}/serviceorders/${serviceOrderId}/discount/remove`);
  return response.data;
}