import { lensProp, set } from 'ramda';
import { SET_SESSION, HANDLE_LOADING } from './constants';
import { formatSessionsRegistration } from '../../../helpers/formatters';

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SESSION: {
      return {
        ...state,
        list: formatSessionsRegistration(action.data, action.establishments),
        total: action.total,
        isLoading: false,
      };
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    default:
      return state;
  }
};
