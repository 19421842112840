import * as yup from 'yup';

const isMonthlyFrequency = text => ({
  is: value => value === 1,
  then: yup.number().required(text),
});

export const chargeValidations = yup.object().shape({
  frequencyId: yup
    .number()
    .required('Escolha a frequência da cobrança.')
    .nullable()
    .transform((value: string, originalValue: string) => originalValue ? value : null)
});

export const validationNotInvoiceDate = yup.object().shape({
  invoiceDate: yup
    .string()
    .when(['clientTypeId', 'isEdit'], {
      is: (clientTypeId, isEdit) => (+clientTypeId == 5 || +clientTypeId == 4 || +clientTypeId == 2) && !isEdit,
      then: yup.string().required('Escolha a data da primeira fatura.')
    }),
  invoiceDueDay: yup
    .number()
    .nullable()
    .transform((value: string, originalValue: string) => originalValue ? value : null)
    .when('frequencyId', isMonthlyFrequency('Escolha o dia de vencimento das faturas.')),
  frequencyId: yup
    .number()
    .required('Escolha a frequência da cobrança.')
    .nullable()
    .transform((value: string, originalValue: string) => originalValue ? value : null)
});