import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import VMasker from 'vanilla-masker';

import { Paper, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import Button from 'components/Button';
import MTable from 'components/MTable';
import Payroll from 'components/Payroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen, faFileAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

import { loadComissionedFilters, loadComissionedList, loadExport } from '../store/thunk';
import { loadSinglePayroll, loadPartialPayment } from '../../ComissionedPayrolls/store/thunk';
import { clearPayroll } from 'pages/ComissionedPayrolls/store/actions';
import { loadPaymentMethods } from 'pages/PaymentMethods/store/thunk';

import { filterInputs } from './resources';

import useDidMount from 'helpers/hooks/useDidMount';

const headers = [
  { title: 'Nome', field: 'name', sortable: false },
  { title: 'Cargo', field: 'roleName', sortable: false },
  { title: 'Situação', field: 'payroll.situationId', sortable: false },
  { title: 'Vencimento', field: 'payroll.dueDate', sortable: false },
  { title: 'Valor', field: 'payroll.amount', sortable: false }
];

const exportHeaders = [
  { id: 'name', label: 'Nome' },
  { id: 'roleName', label: 'Cargo' },
  { id: 'situationId', label: 'Situação' },
  { id: 'dueDate', label: 'Vencimento' },
  { id: 'amount', label: 'Valor' }
];

export default function ComissionedList() {
  const dispatch = useDispatch();

  const history = useHistory();

  const didComponentMount = useDidMount();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishments } = useSelector(state => state.businessInfo);
  const { list, total, isLoading } = useSelector(state => state.comissionedList);
  const { employeeRoles, payrollSituations } = useSelector(state => state.comissionedList.filters);
  const { qp } = useSelector(state => state.filters);

  const establishmentUuid = establishments?.find(establishment => +establishment?.establishmentId == +establishmentId)?.uuid || '';

  const [isPayrollModalOpen, setIsPayrollModalOpen] = useState(false);

  const [comissionedPage, setComissionedPage] = useState(1);
  const comissionedPageSize = 10;
  const comissionedPageCount = Math.ceil(total / comissionedPageSize);

  const formattedProfiles = employeeRoles?.options
    ?.map(role => {
      return {
        label: role?.name,
        value: role?.id
      }
    });

  const profileFilter = {
    advanced: false,
    type: 'select',
    id: 'roleId',
    options: formattedProfiles,
    label: 'Cargo',
    placeholder: 'Cargo',
    value: ''
  }

  const statusFilter = {
    advanced: false,
    type: 'select',
    id: 'status',
    options: [
      { label: 'Ativado', value: 1 },
      { label: 'Desativado', value: 2 }
    ],
    label: 'Status',
    placeholder: 'Status',
    value: 1
  }

  const requestComissionedList = ({ page, qp }) => {
    if(userId) {
      return dispatch(loadComissionedList({ userId, establishmentId, page, limit: 10, qp }));
    }
  }

  const requestExport = () => {
    return dispatch(loadExport({ userId, establishmentId, page: comissionedPage, limit: 10, qp, payrollSituations }));
  }

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadPaymentMethods({ userId, establishmentId, page: 1, pageSize: '', qp: '' }));
    }
  }, [establishmentId]);

  useEffect(() => {
    dispatch(loadComissionedFilters({ userId }));
  }, []);

  useEffect(() => {
    if(userId && establishmentId) {
      requestComissionedList({ page: 1, qp });
    }
  }, [userId, establishmentUuid]);

  useEffect(() => {
    if(!didComponentMount) {
      requestComissionedList({ page: comissionedPage, qp });
    }
  }, [comissionedPage]);

  const openCurrentPayroll = employee => {
    dispatch(clearPayroll());

    const { uuid } = employee?.payroll;

    if(!uuid) {
      return toast.error('A folha de pagamento não foi encontrada.');
    }

    setIsPayrollModalOpen(true);
    dispatch(loadSinglePayroll(userId, establishmentId, uuid));
    dispatch(loadPartialPayment(userId, establishmentId, uuid));
  }

  return(
    <Paper>
      {isPayrollModalOpen && (
        <Payroll
          isPayrollModalOpen={isPayrollModalOpen}
          setIsPayrollModalOpen={setIsPayrollModalOpen}
          pageOrigin='/funcionarios'
        />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          alternativeExport
          functionAlternativeExport={() => requestExport()}
          exportType='comissioned'
          exportHeaders={exportHeaders}
          actionButton={
            <Button
              type="button"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#FFFFFF"
                  size="xs"
                />
              }
              onClick={() => history.push('/novo-funcionario')}
            >
              Adicionar funcionário
            </Button>
          }
          handlePageRequest={({ qp }) => {
            setComissionedPage(1);
            return requestComissionedList({ page: 1, qp });
          }}
          filters={filterInputs.concat(profileFilter, statusFilter)}
        />
      ) : (
        <Filter
          filter
          alternativeExport
          isLoadingAlternativeExport={true}
          functionAlternativeExport={(_) => requestExport()}
          exportType='comissioned'
          exportHeaders={exportHeaders}
          actionButton={
            <Button
              type="button"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#FFFFFF"
                  size="xs"
                />
              }
              onClick={() => history.push('/novo-funcionario')}
            >
              Adicionar funcionário
            </Button>
          }
          handlePageRequest={({ qp }) => {
            setComissionedPage(1);
            return requestComissionedList({ page: 1, qp });
          }}
          filters={filterInputs.concat(profileFilter, statusFilter)}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={list.map(employee => {
          return {
            ...employee,
            payroll: {
              ...employee?.payroll,
              dueDate: moment(employee?.payroll?.dueDate).format('DD/MM/YYYY'),
              amount: employee?.payroll?.amount ? `R$ ${VMasker.toMoney(employee?.payroll?.amount || '')}` : '',
              situationId: payrollSituations?.options?.find(situation => situation?.id == employee?.payroll?.situationId)?.name || ''
            }
          }
        })}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                color='#022A5C'
                size='xs'
              />
            ),
            tooltip: 'Folha de pagamento pendente',
            onClick: (_, rowData) => openCurrentPayroll(rowData)
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faFileAlt}
                color='#022A5C'
                size='xs'
              />
            ),
            tooltip: 'Lista de folhas de pagamento',
            onClick: (_, rowData) => {
              history.push({
                pathname: '/folhas-de-pagamento',
                state: {
                  data: rowData
                }
              });
            }
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color='#022A5C'
                size='xs'
              />
            ),
            tooltip: 'Editar funcionário',
            onClick: (_, rowData) => {
              history.push({
                pathname: '/editar-funcionario',
                state: {
                  data: rowData
                }
              });
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color='primary'
          variant='outlined'
          shape='rounded'
          count={comissionedPageCount}
          page={comissionedPage}
          onChange={(_, page) => setComissionedPage(page)}
        />
      </Grid>
    </Paper>
  );
}