import * as types from './constants';

export const setStorageHistoryResume = data => ({
  type: types.SET_STORAGE_HISTORY_RESUME,
  data
});

export const setStorageSellHistoryResume = data => ({
  type: types.SET_STORAGE_SELL_HISTORY_RESUME,
  data
});

export const setStorageHistoryResumeLoading = isLoading => ({
  type: types.SET_STORAGE_HISTORY_RESUME_LOADING,
  isLoading
});

export const setStorageHistoryList = data => ({
  type: types.SET_STORAGE_HISTORY_LIST,
  data
});

export const setStorageHistoryListLoading = isLoading => ({
  type: types.SET_STORAGE_HISTORY_LIST_LOADING,
  isLoading
});

export const setStorageHistoryModalLoading = isLoading => ({
  type: types.SET_STORAGE_HISTORY_MODAL_LOADING,
  isLoading
});

export const setStorageHistoryErrors = errors => ({
  type: types.SET_STORAGE_HISTORY_ERRORS,
  errors
});

export const clearStorageHistoryErrors = () => ({
  type: types.CLEAR_STORAGE_HISTORY_ERRORS
});