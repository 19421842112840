import {
  SET_SUPPLIER,
  SET_SUPPLIERS,
  SET_SUPPLIER_ERRORS,
  CLEAR_SUPPLIER_ERRORS,
  SET_SUPPLIER_UUID,
  SET_SUPPLIER_LOADING,
  CLEAR_SUPPLIER
} from './constants';

const defaultState = {
  isSupplierLoading: false,
  supplierUuid: '',
  suppliers: [],
  supplier: {
    cnpj: '',
    active: 1,
    fantasyName: '',
    corporateName: '',
    contacts: [],
    observations: '',
    cep: '',
    address: '',
    addressNumber: '',
    complement: '',
    city: '',
    district: '',
    state: ''
  },
  supplierErrors: {
    cnpj: '',
    fantasyName: '',
    corporateName: '',
    address: '',
    addressNumber: '',
    cep: '',
    city: '',
    district: '',
    state: ''
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_SUPPLIER_LOADING: {
      return {
        ...state,
        isSupplierLoading: action.isLoading
      }
    }
    case SET_SUPPLIER: {
      const { supplierInfo, active, contacts, observations, addresses } = action.data;

      const {
        cnpj,
        fantasyName,
        corporateName
      } = supplierInfo;

      const {
        cep,
        address,
        addressNumber,
        complement,
        city,
        district,
        state: uf
      } = addresses[0];

      return {
        ...state,
        supplier: {
          cnpj,
          active,
          fantasyName,
          corporateName,
          contacts,
          observations,
          cep,
          address,
          addressNumber,
          complement,
          city,
          district,
          state: uf
        }
      }
    }
    case SET_SUPPLIERS: {
      return {
        ...state,
        suppliers: action.data
      }
    }
    case SET_SUPPLIER_ERRORS: {
      return {
        ...state,
        supplierErrors: {
          cnpj: action?.errors['supplierInfo.cnpj'] ? action?.errors['supplierInfo.cnpj'][0] : null,
          fantasyName: action?.errors['supplierInfo.fantasyName'] ? action?.errors['supplierInfo.fantasyName'][0] : null,
          corporateName: action?.errors['supplierInfo.corporateName'] ? action?.errors['supplierInfo.corporateName'][0] : null,
          address: action?.errors['addresses.0.address'] ? action?.errors['addresses.0.address'][0] : null,
          addressNumber: action?.errors['addresses.0.addressNumber'] ? action?.errors['addresses.0.addressNumber'][0] : null,
          cep: action?.errors['addresses.0.cep'] ? action?.errors['addresses.0.cep'][0] : null,
          city: action?.errors['addresses.0.city'] ? action?.errors['addresses.0.city'][0] : null,
          district: action?.errors['addresses.0.district'] ? action?.errors['addresses.0.district'][0] : null,
          state: action?.errors['addresses.0.state'] ? action?.errors['addresses.0.state'][0] : null
        }
      }
    }
    case CLEAR_SUPPLIER_ERRORS: {
      return {
        ...state,
        supplierErrors: {
          ...defaultState.supplierErrors
        }
      }
    }
    case SET_SUPPLIER_UUID: {
      return {
        ...state,
        supplierUuid: action.uuid
      }
    }
    case CLEAR_SUPPLIER: {
      return {
        ...state,
        supplier: {
          ...defaultState.supplier
        }
      }
    }
    default: {
      return state;
    }
  }
}