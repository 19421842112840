import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import Select from '../Select';
import Datepicker from '../Datepicker';
import Breadcrumb from '../Breadcrumb';
import Autocomplete from '../Autocomplete';

import { setGraphicTimeRange, clearSearch } from '../Filter/store/actions';
import { setEstablishmentId, setEstablishmentTypes } from '../../pages/BusinessInfo/store/actions';
import { convertToEstablishmentName } from '../../helpers/converters';
import { timeRanges } from './resources';
import { useStyles } from './styles';
import { setBaseAPI } from '../../services';

import { loadCategories } from '../../pages/NotificationsConfig/store/thunk';
import { setCurrentNotificationCategory } from '../../pages/NotificationsConfig/store/actions';

const Subheader = ({
  name,
  path,
  previousPaths,
  showDate,
  showTimeRange,
  hideEstablishments,
  filterEstablishments,
  showEstablishmentName
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  const isDashboard = history.location.pathname == '/visao-geral';
  const isNotificationConfig = history.location.pathname === '/configuracao-de-lembrete';
  const isOperationalHistory = history.location.pathname === '/historico-operacional';
  const isClientList = history.location.pathname === '/clientes';

  const md = showDate || showTimeRange ? 4 : 8;

  const defaultEstablishmentId = useSelector(state => state.login.establishments)[1]?.establishmentId;
  const { establishmentId } = useSelector(state => state.businessInfo);

  const { userId } = useSelector(state => state.profile);
  const { establishments } = useSelector(state => state.login);
  const { graphicTimeRange } = useSelector(state => state.filters);

  const [selectCustomGraphicTimeRange, setSelectCustomGraphicTimeRange] = useState(graphicTimeRange == 'custom' ? true : false);

  const currentNotificationCategory = useSelector(state => state.notificationsConfig.currentCategory);

  const notificationsTypeList = useSelector(state => state.notificationsConfig.categories).map(notifications => {
    return {
      value: notifications.id,
      label: notifications.name
    };
  });

  const selectedNotificationType =
    notificationsTypeList?.find(notificationsType => notificationsType.value == 1)?.value;

  useEffect(() => {
    const selectedEstablishmentId = establishmentId || defaultEstablishmentId;

    const params = {
      userId,
      establishmentId: selectedEstablishmentId
    }

    if(isNotificationConfig) {
      dispatch(loadCategories(params));
    }
  }, [establishmentId, isNotificationConfig]);

  useEffect(() => {
    setSelectCustomGraphicTimeRange(graphicTimeRange == 'custom');
  }, [graphicTimeRange]);

  const handleChangeEstablishment = (e, option) => {
    const establishmentId = option.value;

    setBaseAPI(userId, establishmentId);

    dispatch(setEstablishmentTypes(option?.establishmentTypes));
    dispatch(setEstablishmentId({ establishmentId }));

    if(!isClientList) {
      dispatch(clearSearch());
    }
  }

  const handleChangeSelectedCategory = category => {
    dispatch(setCurrentNotificationCategory(category));
  }

  const handleGraphicTimeRange = event => {
    setSelectCustomGraphicTimeRange(false);
    dispatch(setGraphicTimeRange(event.target.name, event.target.value));

    if(event.target.value == 'custom') {
      setSelectCustomGraphicTimeRange(true);
    }
  }

  return (
    <Paper square elevation={2} className={classes.root}>
      <Container>
        <Grid
          container
          alignItems="center"
          justify={isNotificationConfig || selectCustomGraphicTimeRange ? "space-between" : "flex-end"}
        >
          {!selectCustomGraphicTimeRange && (
            <Grid
              item
              lg={isNotificationConfig ? 4 : md}
              md={(showDate || showTimeRange) || isNotificationConfig ? 4 : 6}
              xs={12}
              mdDown={!!showTimeRange}
              implementation="css"
              component={Hidden}
            >
              <Breadcrumb
                mainPath={{ name, path }}
                previousPaths={previousPaths}
              />
            </Grid>
          )}
          {hideEstablishments || (
            <Grid
              item
              lg={4}
              md={showDate || isNotificationConfig || selectCustomGraphicTimeRange ? 4 : 6}
              xs={12}
            >
              <Autocomplete
                value={establishmentId}
                options={filterEstablishments}
                onChange={(e, opt) => handleChangeEstablishment(e, opt)}
              />
            </Grid>
          )}
          {isNotificationConfig && (
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: 10 }}
            >
              <Select
                disabled
                options={notificationsTypeList}
                value={currentNotificationCategory || selectedNotificationType}
                onChange={event => handleChangeSelectedCategory(event.target.value)}
              />
            </Grid>
          )}
          {showDate && (
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
              style={{ paddingLeft: 5 }}
            >
              <Grid container className={classes.dateContent}>
                <Datepicker
                  blockFutureDates={isOperationalHistory}
                  onBlockedDate={() => toast.error('A data selecionada não pode ser maior do que a data atual.')}
                />
              </Grid>
            </Grid>
          )}
          {showTimeRange && (
            <>
              <Grid
                item
                mdDown
                xs={1}
                implementation="css"
                component={Hidden}
              />
              <Grid
                container
                justifyContent="flex-end"
                lg={selectCustomGraphicTimeRange ? 6 : 3}
                md={selectCustomGraphicTimeRange ? 6 : 3}
                xs={12}
              >
                {selectCustomGraphicTimeRange && (
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{ paddingRight: 2.5 }}
                  >
                    <Datepicker
                      style={{ marginBottom: 10 }}
                      blockFutureDates
                      readOnly={isDashboard}
                      onBlockedDate={() => toast.error('A data selecionada não pode ser maior do que a data atual.')}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={selectCustomGraphicTimeRange ? 3 : 12}
                >
                  <Select
                    displayEmpty
                    name="graphicTimeRange"
                    options={timeRanges}
                    value={graphicTimeRange}
                    onChange={event => handleGraphicTimeRange(event)}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {showEstablishmentName ? (
            <Grid item lg={4} md={6} xs={12}>
              <Typography color="primary">
                <span style={{ fontWeight: 500 }}>Estabelecimento:</span>{' '}
                {convertToEstablishmentName(establishmentId, establishments)}
              </Typography>
            </Grid>
          ) : (
            <Grid item lg={4} md={6} xs={12} />
          )}
        </Grid>
      </Container>
    </Paper>
  );
};

Subheader.defaultProps = {
  name: '',
  path: '',
  previousPaths: [],
  enableSelectFutureDates: false,
  showDate: false,
  showEstablishmentName: false,
  showTimeRange: false,
  hideEstablishments: false
};

Subheader.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  previousPaths: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ),
  filterEstablishments: PropTypes.arrayOf.isRequired,
  enableSelectFutureDates: PropTypes.bool,
  showDate: PropTypes.bool,
  showEstablishmentName: PropTypes.bool,
  showTimeRange: PropTypes.bool,
  hideEstablishments: PropTypes.bool
};

export default Subheader;