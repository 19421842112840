import * as yup from 'yup';

const withoutPaymentMethodId = text => ({
  is: value => value === null,
  then: yup.string().required(text),
});

export const validations = yup.object().shape({
  paymentMethodId: yup.number().nullable(),
  accountId: yup
    .number()
    .nullable()
    .required('Escolha a conta destino'),
  paymentMethodName: yup
    .string()
    .when('paymentMethodId', withoutPaymentMethodId('O nome é obrigatório')),
  primitivePaymentMethodId: yup
    .number()
    .nullable()
    .required('Escolha o tipo de pagamento'),
  deliveryTime: yup.string().nullable(),
  receivingFee: yup.string().nullable(),
  status: yup.string(),
});
