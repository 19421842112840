import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faMinusCircle,
  faLink,
  faUnlink,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

import TableItem from '../TableItem';
import Button from '../../../../components/Button';
import ModalConfirmation from '../../../../components/ModalConfirmation';

import { handleSpecialUpdate } from '../../store/actions';
import {
  changeSpecialTablePropCompulsory,
  removeSpecialTable,
} from '../../store/thunk';
import {
  convertToReal,
  translateDays,
  convertMinutesToPeriod,
  convertToHHMM,
} from '../../../../helpers/converters';

import { useStyles } from '../styles';

export default function SpecialTable({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.priceTable);
  const [isOpen, openSpecialTable] = useState(false);
  const [isRemoving, handleRemoveSpecialTable] = useState(false);
  const {
    typePrice,
    description,
    compulsory,
    tolerance,
    maximumValue,
    startTime,
    endTime,
    weekDays,
    items,
  } = data;

  const handleUpdateSpecialTable = data => {
    dispatch(handleSpecialUpdate(data));
  };

  const handleRemoveConfirmation = () => {
    const params = { typePrice, description };

    dispatch(removeSpecialTable(params));
  };

  const handleChangeSpecialTableProp = () => {
    const params = { compulsory: +!data['compulsory'] };

    dispatch(changeSpecialTablePropCompulsory(params, typePrice, description));
  };

  return (
    <Grid className={classes.specialRoot}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Button
            disableElevation
            color="transparent"
            classes={{ root: classes.title }}
            onClick={() => openSpecialTable(!isOpen)}
          >
            {description}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disableElevation
            color="transparent"
            onClick={() => handleUpdateSpecialTable(data)}
          >
            <FontAwesomeIcon icon={faPen} color="#2CA9D6" />
          </Button>
          <Button
            disableElevation
            color="transparent"
            onClick={() => handleRemoveSpecialTable(true)}
          >
            <FontAwesomeIcon icon={faMinusCircle} color="#F66D6E" />
          </Button>
          <Button
            id="compulsory"
            disableElevation
            color="transparent"
            onClick={() => handleChangeSpecialTableProp(data)}
          >
            <FontAwesomeIcon
              icon={compulsory ? faLink : faUnlink}
              color="#1E5168"
            />
          </Button>
          <Button
            disableElevation
            color="transparent"
            onClick={() => openSpecialTable(!isOpen)}
          >
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              color="#1E5168"
            />
          </Button>
        </Grid>
      </Grid>

      <ModalConfirmation
        open={isRemoving}
        isLoading={isLoading}
        onClose={() => handleRemoveSpecialTable(false)}
        handleConfirmation={handleRemoveConfirmation}
      >
        Ao remover a condição especial <strong>{description}</strong>, todas as
        informações dela serão apagadas do sistema e do aplicativo.
      </ModalConfirmation>

      <Collapse in={isOpen}>
        <Grid container>
          {weekDays.length > 0 && (
            <Grid item xs={12}>
              <TableItem
                value={`${translateDays(weekDays)} - Das ${convertToHHMM(
                  startTime
                )} até ${convertToHHMM(endTime)}`}
              />
            </Grid>
          )}

          {tolerance !== 0 && (
            <Grid item xs={12}>
              <TableItem
                label="Tolerancia inicial"
                value={convertMinutesToPeriod(tolerance)}
              />
            </Grid>
          )}

          {items.length > 0 &&
            items.map((timeRange, index) => {
              if (timeRange.since) {
                return (
                  <Grid key={index} xs={12}>
                    <TableItem
                      value={`A partir de ${convertMinutesToPeriod(
                        timeRange.since
                      )} a cada ${convertMinutesToPeriod(
                        timeRange.period
                      )} somar o valor de ${convertToReal(timeRange.price)}`}
                    />
                  </Grid>
                );
              }

              return (
                <Grid key={index} xs={12}>
                  <TableItem
                    value={`Até ${convertMinutesToPeriod(
                      timeRange.period
                    )} cobrar valor de ${convertToReal(timeRange.price)}`}
                  />
                </Grid>
              );
            })}

          {maximumValue !== '0.00' && (
            <Grid item xs={12}>
              <TableItem
                value={`Cobrar o valor máximo de ${convertToReal(
                  maximumValue
                )}`}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Grid>
  );
}

SpecialTable.defaultProps = {
  data: {
    typePrice: '',
    description: '',
    compulsory: 0,
    tolerance: 0,
    maximumValue: 0,
    startTime: '',
    endTime: '',
    weekDays: [],
    items: [],
  },
};

SpecialTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};
