import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';

export default function ModalMobile({
  isOpen,
  setIsOpenModalMobile,
  invisible,
  handleInvisible,
  handleMainTable,
  removeTable
}) {
  const { isLoading } = useSelector(state => state.priceTable.covenant);

  return(
    <Modal
      id="covenant"
      title="Configurações"
      maxWidth="sm"
      open={isOpen}
      onClose={() => setIsOpenModalMobile(false)}
    >
      <Loader isLoading={isLoading}>
        <Grid>
          <Typography variant="h6">Tornar tabela principal</Typography>
          <p style={{ fontSize: 10 }}>
            Coloque essa tabela como a primeira opção a ser escolhida na
            listagem das tabelas. Só pode ter uma tabela principal. Ativando
            ela, desativará outra.
          </p>
          <Grid>
            <Button
              type="button"
              color="primary"
              loading={isLoading}
              id="major"
              onClick={handleMainTable}
            >
              Tornar tabela principal
            </Button>
          </Grid>
        </Grid>
        <hr />
        <Grid>
          <Typography variant="h6">{invisible}</Typography>
          <p style={{ fontSize: 10 }}>
            Usado para automações que você não deseja que o operador escolha
            manualmente, e sim, o sistema.
          </p>
          <Grid>
            <Button
              id="invisible"
              type="button"
              color="primary"
              loading={isLoading}
              onClick={handleInvisible}
            >
              {invisible === 'Tabela está invisível'
                ? 'Tornar tabela visível'
                : 'Tornar tabela invisível'}
            </Button>
          </Grid>
        </Grid>
        <hr />
        <Grid>
          <Typography variant="h6">Excluir tabela</Typography>
          <p style={{ fontSize: 12 }}>
            Tem certeza que deseja excluir essa tabela ?
          </p>
          <Grid>
            <Button
              type="button"
              color="#f66c6b"
              variant="fullWidth"
              loading={isLoading}
              onClick={() => removeTable(true)}
            >
              Excluir tabela
            </Button>
          </Grid>
        </Grid>
      </Loader>
    </Modal>
  );
}

ModalMobile.defaultProps = {
  isOpen: false,
  setIsOpenModalMobile: {}
};

ModalMobile.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpenModalMobile: PropTypes.func
};