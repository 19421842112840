import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 1.5,
      borderRadius: 6
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 2.5
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 2.5
    },
    '&.MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 4
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '9.5px 4px !important'
    }
  },
  input: {
    fontWeight: 500,
    color: 'gray',
    fontSize: 12,
    lineHeight: 1.2,
    '&:first-line': {
      color: palette.primary.main,
      fontSize: 16,
      width: '100%'
    }
  },
  option: {
    '&.MuiAutocomplete-option': {
      padding: '0px !important'
    }
  },
  popupIndicator: {
    color: palette.primary.main
  }
}));