import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';

import Button from '../Button';

import { setIsClientExportationError } from '../Filter/store/actions';

import { convertToOptions } from '../../helpers/converters';
import { formatFilters } from '../../helpers/formatters';
import { convertList, convertToEstablishmentName } from '../../helpers/converters';

import {
  List,
  Receipt,
  ReceiptInvoice,
  ReceiptServiceOrderInvoice,
  FinancialResume,
  UsageResume,
  CashierDetailsResume,
  ReceiptPayrollExtract,
  Comissioned,
  ReceiptComissioned,
  OperationalExport,
  Supplier
} from './templates';

function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for(let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export default function ExportPDF({
  type,
  loading,
  color,
  children,
  disabled,
  style,
  exportType,
  fileName,
  exportHeaders,
  requestData,
  data,
  formatComponentData,
  monthDate,
  fullWidth,
  ...props
}) {
  const dispatch = useDispatch();

  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { isExporting, selects, qp } = useSelector(state => state.filters);

  const serviceCategories = useSelector(state => state.filters)
    ?.selects
    ?.find(filter => filter?.id == 'serviceCategory')
    ?.options;

  const availablePaymentMethods = convertToOptions(['paymentMethod'], selects);

  const createDocument = component => {
    return component.toBlob().then(blobProp => {
      const reader = new FileReader();

      reader.onload = () => {
        const blob = base64ToArrayBuffer(reader.result.split(',')[1]);
        const file = new Blob([blob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      };

      reader.readAsDataURL(blobProp);
    });
  }

  const handleExportation = () => {
    const wholeData = { page: '', pageSize: '', qp, exportation: true };
    let dateRange = [startDate, endDate];
    let clientsExportationData;

    const establishmentName =
      convertToEstablishmentName(establishmentId, establishments) ||
      'Todos os estabelecimentos';

    let pdfComponent;

    if(exportType === 'cashierDetailsResume') {
      pdfComponent = pdf(
        <CashierDetailsResume
          data={data}
          fileName={fileName}
        />
      );

      return createDocument(pdfComponent);
    }

    requestData(wholeData, 'pdf')
      .then(data => {
        clientsExportationData = data;

        let component;

        if(exportType === 'comissioned') {
          component = pdf(
            <Comissioned
              exportData={data}
              exportHeaders={exportHeaders}
            />
          );
        }

        if(exportType === 'receiptComissioned') {
          component = pdf(
            <ReceiptComissioned
              data={data}
            />
          );
        }

        if(exportType === 'receiptOsInvoice') {
          component = pdf(
            <ReceiptServiceOrderInvoice
              data={data}
              fileName={fileName}
            />
          );
        }

        if(exportType === 'financialResume') {
          dateRange = monthDate
            ? [data.startDate, data.endDate]
            : dateRange;

          component = pdf(
            <FinancialResume
              exportData={data}
              serviceCategories={serviceCategories}
              establishmentName={establishmentName}
              fileName={fileName}
              dateRange={dateRange}
            />
          );
        }

        if(exportType === 'usageResume') {
          dateRange = monthDate
            ? [data.startDate, data.endDate]
            : dateRange;

          component = pdf(
            <UsageResume
              exportData={data?.resume}
              establishmentName={establishmentName}
              fileName={fileName}
              dateRange={dateRange}
            />
          );
        }

        if(exportType === 'receipt') {
          component = pdf(
            <Receipt
              exportData={data.data}
              establishmentName={establishmentName}
              fileName={fileName}
            />
          );
        }

        if(exportType === 'receiptInvoice') {
          component = pdf(
            <ReceiptInvoice
              exportData={{ ...data, paymentMethods: availablePaymentMethods }}
              establishmentName={establishmentName}
              fileName={fileName}
            />
          );
        }

        if(exportType === 'receiptPayrollExtract') {
          component = pdf(
            <ReceiptPayrollExtract
              exportData={data.data}
            />
          );
        }

        if(exportType === 'extract' || exportType === 'clients') {
          const exportData = convertList(
            ['category', 'clientInvoiceSituation'],
            formatFilters(['category', 'clientInvoiceSituation'], selects),
            formatComponentData(exportType === 'extract' ? data : data.content, true)
          );

          component = pdf(
            <List
              exportType={exportType}
              exportResume={null}
              exportHeaders={exportHeaders}
              exportData={exportData}
              dateRange={dateRange}
              fileName={fileName}
              availablePaymentMethods={availablePaymentMethods}
              establishmentName={establishmentName}
            />
          );
        }

        if(exportType === 'operational') {
          const exportData = formatComponentData(data.content, true);
          const exportResume = data.resume;

          component = pdf(
            <List
              exportResume={exportResume}
              exportHeaders={exportHeaders}
              exportData={exportData}
              dateRange={dateRange}
              fileName={fileName}
              establishmentName={establishmentName}
            />
          );
        }

        if(exportType == 'operationalExport') {
          component = pdf(
            <OperationalExport
              exportData={{ ...data, startDate, endDate }}
            />
          );
        }

        if(exportType == 'supplier') {
          component = pdf(
            <Supplier
              exportData={data}
              exportHeaders={exportHeaders}
            />
          );
        }

        return createDocument(component);
      })
      .catch(() => {
        if(exportType === 'clients' && establishmentId) {
          dispatch(setIsClientExportationError(true));
          return window.location.reload();
        }

        if(exportType === 'receiptOsInvoice') {
          toast.error('Nenhuma OS foi encontrada para essa fatura.');
          return;
        }

        toast.error('Não foi possível exportar, tente novamente mais tarde');
      });
  }

  return(
    <Button
      type={type}
      fullWidth={(color === 'inherit') || fullWidth}
      disableElevation={color === 'inherit'}
      style={style}
      color={color}
      disabled={disabled}
      loading={isExporting || loading}
      onClick={() => handleExportation()}
      {...props}
    >
      {children}
    </Button>
  );
}

ExportPDF.defaultProps = {
  color: 'inherit',
  exportHeaders: [],
  loading: false,
  disabled: false,
  style: {},
  monthDate: {},
  formatComponentData: () => {}
};

ExportPDF.propTypes = {
  fileName: PropTypes.string.isRequired,
  requestData: PropTypes.func.isRequired,
  formatComponentData: PropTypes.func,
  children: PropTypes.string.isRequired,
  exportType: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  monthDate: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  exportHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string
    })
  )
};