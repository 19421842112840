import {
  fetchProductsFilters,
  fetchProductsList,
  fetchProductsExport
} from './services';

import {
  setProductListLoading,
  setProductFilters,
  setProductList
} from './actions';

import { handleExporting } from 'components/Filter/store/actions';

export const loadProductsFilters = (userId, establishmentId) => dispatch => {
  return fetchProductsFilters(userId, establishmentId)
    .then(({ data }) => dispatch(setProductFilters(data)));
}

export const loadProductsList = ({ userId, establishmentId, page, qp }) => dispatch => {
  dispatch(setProductListLoading(true));

  return fetchProductsList(userId, establishmentId, page, qp)
    .then((data) => dispatch(setProductList(data)))
    .finally(() => dispatch(setProductListLoading(false)));
}

export const loadProductsExport = ({ userId, establishmentId, qp }) => dispatch => {
  dispatch(handleExporting(true));

  return fetchProductsExport(userId, establishmentId, qp)
    .then(({ data }) => data)
    .finally(() => dispatch(handleExporting(false)));
}