import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import Header from '../Header';
import { styles } from '../styles';
import { convertToReal, convertFormattedMoneyAndNegativeMoneyToNumber } from '../../../../helpers/converters';

const Table = ({ data }) => {
  const convertToDate = str => `${str.substring(6, 8)}/${str.substring(4, 6)}/${str.substring(0, 4)}`;

  return(
    <>
      {data.length > 0 ? (
        <>
          {data.map(usageResume => (
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.focusBackground]}>
                <View style={[styles.tableColHeader, { width: '100%' }]}>
                  <Text style={[styles.tableCellHeader, { textAlign: 'center' }]}>
                    Resumo do dia {`${convertToDate(usageResume.days)} - ${convertToReal(usageResume.amount)}`}
                  </Text>
                </View>
              </View>
              {usageResume.total > 0 && (
                <View style={[styles.tableCol, { width: '100%' }]}>
                  <Text style={[styles.tableCell, { textAlign: 'center' }]}>
                    {`Quantidade (${usageResume.total}) - Total: ${convertToReal(convertFormattedMoneyAndNegativeMoneyToNumber(usageResume.amount) * usageResume.total)}`}
                  </Text>
                </View>
              )}
            </View>
          ))}
        </>
      ) : (
        <View style={styles.table}>
          <View style={[styles.tableCol, { width: '100%' }]}>
            <Text style={[styles.tableCell, { textAlign: 'center' }]}>
              Não há registros
            </Text>
          </View>
        </View>
      )}
    </>
  )
}

const UsageResume = ({
  exportData,
  dateRange,
  fileName,
  establishmentName
}) => {
  return(
    <Document>
      <Page style={styles.body}>
        <Header
          fileName={fileName}
          dateRange={dateRange}
          establishmentName={establishmentName}
        />
        <View>
          <Table data={exportData} />
        </View>
      </Page>
    </Document>
  );
}

UsageResume.propTypes = {
  exportData: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fileName: PropTypes.string.isRequired,
  establishmentName: PropTypes.string.isRequired
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired
};

export default UsageResume;