import moment from 'moment';
import 'moment/locale/pt-br';

import {
  compose,
  set,
  lensProp,
  findIndex,
  update,
  prop,
  propEq,
  concat
} from 'ramda';

import {
  SET_INITIAL_ESTABLISHMENTS,
  SET_ALL_ESTABLISHMENTS,
  SET_APP_PERMISSION_ESTABLISHMENTS,
  SET_NEW_ESTABLISHMENT,
  SET_SINGLE_ESTABLISHMENT,
  SET_UPDATED_ESTABLISHMENT,
  SET_UUID,
  SET_TERMS_OF_USE_DATE,
  HANDLE_LOADING,
  UPDATE_TOKEN,
  HANDLE_LOGOUT,
  SET_IS_TERMS_OF_USE_CHECKED
} from './constants';

moment.locale('pt-BR');

const defaultState = {
  isLoading: false,
  termsOfUseDate: null,
  isTermsOfUseChecked: false,
  token: null,
  uuid: null,
  loggedIn: false,
  tokenExpirationTime: null,
  allEstablishments: [
    {
      establishmentId: '',
      establishmentName: 'Todos os estabelecimentos',
      appPermission: 1,
      boostPackage: 0,
      addresses: [{ street: '', number: 0 }]
    }
  ],
  establishments: [
    {
      establishmentId: '',
      establishmentName: 'Todos os estabelecimentos',
      appPermission: 1,
      boostPackage: 0,
      addresses: [{ street: '', number: 0 }]
    }
  ]
}

const TOKEN_EXPIRATION_TIME = 12;

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_INITIAL_ESTABLISHMENTS: {
      let establishments = action.data;

      if (establishments.length > 1) {
        establishments = concat(defaultState.establishments, action.data);
      }

      return set(lensProp('establishments'), establishments, state);
    }
    case SET_ALL_ESTABLISHMENTS: {
      const establishments = state.allEstablishments.concat(action.data);
      return set(lensProp('allEstablishments'), establishments, state);
    }
    case SET_APP_PERMISSION_ESTABLISHMENTS: {
      const establishmentsWithAppPermission = action?.data
        ?.filter(establishment => +establishment?.appPermission == 1)
        ?.map(establishment => establishment?.establishmentId)
        ?.concat('');

      const availableEstablishments = state?.allEstablishments
        ?.filter(establishment => establishmentsWithAppPermission?.includes(establishment?.establishmentId));

      return set(lensProp('establishments'), availableEstablishments, state);
    }
    case SET_NEW_ESTABLISHMENT: {
      return {
        ...state,
        establishments: [
          ...state.establishments,
          action.data
        ],
        allEstablishments: [
          ...state.allEstablishments,
          action.data
        ]
      }
    }
    case SET_SINGLE_ESTABLISHMENT: {
      const establishments = action.data.filter(e => e.value !== '');

      return set(lensProp('establishments'), establishments, state);
    }
    case SET_UPDATED_ESTABLISHMENT: {
      let { establishments } = state;
      const id = prop('establishmentId', action.data);
      const index = findIndex(propEq('establishmentId', id), establishments);

      if (index >= 0) {
        establishments = update(index, action.data, establishments);
      } else {
        establishments.push(action.data);
      }

      return set(lensProp('establishments'), establishments, state);
    }
    case SET_UUID: {
      return set(lensProp('uuid'), action.uuid, state);
    }
    case SET_TERMS_OF_USE_DATE: {
      return set(lensProp('termsOfUseDate'), action.date, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case UPDATE_TOKEN: {
      const token = compose(
        set(lensProp('token'), action.token),
        set(lensProp('loggedIn'), true),
        set(
          lensProp('tokenExpirationTime'),
          moment()
            .add(TOKEN_EXPIRATION_TIME, 'h')
            .format('YYYY-MM-DD HH:mm:ss')
        )
      );

      return token(state);
    }
    case HANDLE_LOGOUT: {
      localStorage.clear();
      const logout = compose(
        set(lensProp('token'), null),
        set(lensProp('loggedIn'), false)
      );

      return logout(state);
    }
    case SET_IS_TERMS_OF_USE_CHECKED: {
      return set(lensProp('isTermsOfUseChecked'), action.checked, state);
    }
    default:
      return state;
  }
}