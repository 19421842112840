import { fetchTotalOccupation, fetchPaidOut, fetchCanceled, fetchAltered, fetchCashflow, fetchGraphics } from './services';
import { setOccupation, setPaidOut, setCanceled, setAltered, setCashflow, setGraphics } from './actions';

export const loadTotalOccupation = params => dispatch => {
  const { userId, establishmentId, type, startDate, endDate } = params;

  let url = `${userId}/reports/dashboard/occupation?type=${type}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/reports/dashboard/occupation?type=${type}`;
  }

  if(type == 'custom') {
    url = `${userId}/reports/dashboard/occupation?endDate=${endDate}&startDate=${startDate}`;

    if(establishmentId) {
      url = `${userId}/establishment/${establishmentId}/reports/dashboard/occupation?endDate=${endDate}&startDate=${startDate}`;
    }
  }

  return fetchTotalOccupation(url)
    .then(data => dispatch(setOccupation(data)));
}

export const loadPaidOut = params => dispatch => {
  const { userId, establishmentId, type, startDate, endDate } = params;

  let url = `${userId}/reports/dashboard/paidout?type=${type}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/reports/dashboard/paidout?type=${type}`;
  }

  if(type == 'custom') {
    url = `${userId}/reports/dashboard/paidout?endDate=${endDate}&startDate=${startDate}`;

    if(establishmentId) {
      url = `${userId}/establishment/${establishmentId}/reports/dashboard/paidout?endDate=${endDate}&startDate=${startDate}`;
    }
  }

  return fetchPaidOut(url)
    .then(data => dispatch(setPaidOut(data)));
}

export const loadCanceled = params => dispatch => {
  const { userId, establishmentId, type, startDate, endDate } = params;

  let url = `${userId}/reports/dashboard/canceled?type=${type}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/reports/dashboard/canceled?type=${type}`;
  }

  if(type == 'custom') {
    url = `${userId}/reports/dashboard/canceled?endDate=${endDate}&startDate=${startDate}`;

    if(establishmentId) {
      url = `${userId}/establishment/${establishmentId}/reports/dashboard/canceled?endDate=${endDate}&startDate=${startDate}`;
    }
  }

  return fetchCanceled(url)
    .then(data => dispatch(setCanceled(data)));
}

export const loadAltered = params => dispatch => {
  const { userId, establishmentId, type, startDate, endDate } = params;

  let url = `${userId}/reports/dashboard/altered?type=${type}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/reports/dashboard/altered?type=${type}`;
  }

  if(type == 'custom') {
    url = `${userId}/reports/dashboard/altered?endDate=${endDate}&startDate=${startDate}`;

    if(establishmentId) {
      url = `${userId}/establishment/${establishmentId}/reports/dashboard/altered?endDate=${endDate}&startDate=${startDate}`;
    }
  }

  return fetchAltered(url)
    .then(data => dispatch(setAltered(data)));
}

export const loadGraphics = params => dispatch => {
  const { type, endDate, startDate, userId, establishmentId } = params;

  let url = `${userId}/reports/dashboard/graphics?type=${type}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/reports/dashboard/graphics?type=${type}`;
  }

  if(type == 'custom') {
    url = `${userId}/reports/dashboard/graphics?endDate=${endDate}&startDate=${startDate}`;

    if(establishmentId) {
      url = `${userId}/establishment/${establishmentId}/reports/dashboard/graphics?endDate=${endDate}&startDate=${startDate}`;
    }
  }

  return fetchGraphics(url)
    .then(({ data }) => dispatch(setGraphics(data)))
}

export const loadCashflow = params => dispatch => {
  const { userId, establishmentId, type, startDate, endDate } = params;

  let url = `${userId}/reports/dashboard/cashflow?type=${type}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/reports/dashboard/cashflow?type=${type}`;
  }

  if(type == 'custom') {
    url = `${userId}/reports/dashboard/cashflow?endDate=${endDate}&startDate=${startDate}`;

    if(establishmentId) {
      url = `${userId}/establishment/${establishmentId}/reports/dashboard/cashflow?endDate=${endDate}&startDate=${startDate}`;
    }
  }

  return fetchCashflow(url)
    .then(data => dispatch(setCashflow(data)));
}