import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import VMasker from 'vanilla-masker';

import { Grid, Typography } from '@material-ui/core';
import Card from 'components/Card';
import Loader from 'components/Loader';
import WarningMessage from 'components/WarningMessage';
import CardFooter from 'components/CardFooter';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';

import { loadConfigurations, upsertConfigurations } from '../../store/thunk';

import { availableWithdrawTimes } from '../resources';
import { convertToDB } from 'helpers/converters';

export default function Cashier() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);
  const { cashReserve, withdrawal } = useSelector(state => state.operational.cashier);

  const [operationalCashReserve, setOperationalCashReserve] = useState(cashReserve);
  const [operationalWithdrawal, setOperationalWithdrawal] = useState(withdrawal);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    setOperationalCashReserve(cashReserve);
    setOperationalWithdrawal(withdrawal);
  }, [cashReserve, withdrawal]);

  const hasOperationalCashReserveChanged = (cashReserve, operationalCashReserve) => {
    return VMasker.toMoney(cashReserve) != VMasker.toMoney(operationalCashReserve);
  }

  const handleConfigurationsSave = () => {
    let params = {
      withdrawal: operationalWithdrawal,
      page: 'Caixa'
    }

    if(hasOperationalCashReserveChanged(cashReserve, operationalCashReserve)) {
      params = {
        ...params,
        cashReserve: convertToDB(operationalCashReserve)
      }
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  return(
    <Card title="Caixa">
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Grid container>
            {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
              <WarningMessage
                message={`Última alteração feita por ${settingHistoryUserName} em ${moment(settingHistoryDateTime).format('LLL')}`}
              />
            )}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                <b>Escolha o valor da reserva de caixa</b>
              </Typography>
              <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                Configure a quantidade mínima sugerida de dinheiro que o estabelecimento precisa manter no caixa.
              </Typography>
              <Input
                fullWidth
                name="cashReserve"
                type="money"
                label="Reserva de caixa"
                placeholder="R$ 0,00"
                value={operationalCashReserve || ''}
                onChange={e => setOperationalCashReserve(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                <b>Escolha a frequência do fechamento de caixa</b>
              </Typography>
              <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                Todo dia, automaticamente, o sistema fecha o caixa a meia noite, porém você pode ignorar essa automatização e fechar o caixa a qualquer momento quando fizer uma sangria.
              </Typography>
              <Select
                name="withdrawal"
                label="Selecione a frequência"
                options={availableWithdrawTimes}
                value={operationalWithdrawal?.toString() || ''}
                onChange={e => setOperationalWithdrawal(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <CardFooter>
              <Button
                color="primary"
                onClick={handleConfigurationsSave}
              >
                Salvar
              </Button>
            </CardFooter>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}