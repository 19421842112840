export const SET_INITIAL_ESTABLISHMENTS = 'AUTH/SET_INITIAL_ESTABLISHMENTS';
export const SET_ALL_ESTABLISHMENTS = 'AUTH/SET_ALL_ESTABLISHMENTS';
export const SET_APP_PERMISSION_ESTABLISHMENTS = 'AUTH/SET_APP_PERMISSION_ESTABLISHMENTS';
export const SET_NEW_ESTABLISHMENT = 'AUTH/SET_NEW_ESTABLISHMENT';
export const SET_SINGLE_ESTABLISHMENT = 'AUTH/SET_SINGLE_ESTABLISHMENT';
export const SET_UPDATED_ESTABLISHMENT = 'AUTH/SET_UPDATED_ESTABLISHMENT';
export const SET_UUID = 'AUTH/SET_UUID';
export const SET_TERMS_OF_USE_DATE = 'AUTH/SET_TERMS_OF_USE_DATE';
export const HANDLE_LOADING = 'AUTH/HANDLE_LOADING';
export const UPDATE_TOKEN = 'AUTH/UPDATE_TOKEN';
export const HANDLE_LOGOUT = 'AUTH/HANDLE_LOGOUT';
export const SET_IS_TERMS_OF_USE_CHECKED = 'RPS_INFO/SET_IS_TERMS_OF_USE_CHECKED';