import { toast } from 'react-toastify';

import {
  fetchServiceOrder,
  deleteServiceOrder,
  updateServiceOrder,
  deliverReceipt,
  fetchBusinessInfo,
  fetchMeansPayment,
  fetchMyServices,
  fetchOsReceipt,
  fetchServiceOrderProduct,
  deleteDiscount
} from './services';

import { setServiceOrder, handleLoading, clearChanges } from './actions';

import { toggleServiceOrder } from '../../../pages/OperationalHistory/store/actions';

import { loadTransaction } from '../../../pages/Transactions/store/thunk';
import { loadStorageHistoryList, loadStorageHistoryResume } from 'pages/StorageHistory/store/thunk';

export const loadServiceOrder = accountProps => async dispatch => {
  const { serviceOrderId, userId, establishmentId } = accountProps;

  if(!serviceOrderId || !userId || !establishmentId) {
    return;
  }

  try {
    dispatch(handleLoading(true));

    const { data } = await fetchServiceOrder(serviceOrderId, userId, establishmentId);
    const responseBuninessInfo = await fetchBusinessInfo(userId, establishmentId);

    const body = {
      ...data,
      buninessInfo: responseBuninessInfo.data
    }

    dispatch(setServiceOrder(body));

    return body;
  } catch (err) {
    dispatch(handleLoading(false));
  } finally {
    dispatch(handleLoading(false));
  }
}

export const upsertServiceOrder = (
  params,
  accountProps,
  startDate,
  endDate,
  extraProps
) => dispatch => {
  const { serviceOrderId, userId, establishmentId, pageOrigin } = accountProps;

  const {
    transactionsQp,
    transactionsPage,
    transactionsPageSize,
    storageHistoryPage,
    storageProductUuid
  } = extraProps;

  dispatch(handleLoading(true));

  return updateServiceOrder(params, serviceOrderId, userId, establishmentId)
    .then(({ data }) => {
      const { serviceOrderId, establishmentId } = data;

      toast.success('Ordem de serviço atualizada com sucesso!');

      dispatch(loadServiceOrder(serviceOrderId, userId, establishmentId));

      if(pageOrigin === 'transactions') {
        dispatch(
          loadTransaction({
            qp: transactionsQp,
            page: transactionsPage,
            pageSize: transactionsPageSize,
            extraProps: { userId, establishmentId, startDate, endDate }
          })
        );
      }

      if(pageOrigin == 'storageHistory') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, storageHistoryPage));
        dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
      }

      return dispatch(clearChanges());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const removeServiceOrder = (
  params,
  accountProps,
  startDate,
  endDate,
  extraProps
) => dispatch => {
  const { serviceOrderId, userId, establishmentId, pageOrigin } = accountProps;

  const {
    transactionsQp,
    transactionsPage,
    transactionsPageSize,
    storageHistoryPage,
    storageProductUuid
  } = extraProps;

  dispatch(handleLoading(true));

  return deleteServiceOrder(params, serviceOrderId, userId, establishmentId)
    .then(({ data }) => {
      toast.success(data.msg);

      dispatch(toggleServiceOrder(false));

      if(pageOrigin == 'transactions') {
        dispatch(
          loadTransaction({
            qp: transactionsQp,
            page: transactionsPage,
            pageSize: transactionsPageSize,
            extraProps: { userId, establishmentId, startDate, endDate }
          })
        );
      }

      if(pageOrigin == 'storageHistory') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, storageHistoryPage));
        dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
      }

      return dispatch(clearChanges());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadServiceOrderProduct = (userId, establishmentId, serviceOrderServiceProductId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchServiceOrderProduct(userId, establishmentId, serviceOrderServiceProductId)
    .then(({ data }) => dispatch(setServiceOrder(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const sendReceipt = (params, accountProps) => dispatch => {
  const { userId, establishmentId } = accountProps;

  dispatch(handleLoading(true));

  return deliverReceipt(params, userId, establishmentId)
    .then(() => {
      toast.success('Recibo enviado com sucesso!');
      dispatch(clearChanges());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const getMeansPayments = (userId, establishmentId) => async dispatch => {
  try {
    dispatch(handleLoading(true));
    const { data } = await fetchMeansPayment(userId, establishmentId);
    return data;
  } finally {
    dispatch(handleLoading(false));
  }
}

export const getServices = (userId, establishmentId) => async dispatch => {
  try {
    dispatch(handleLoading(true));
    const { data } = await fetchMyServices(userId, establishmentId);
    return data;
  } finally {
    dispatch(handleLoading(false));
  }
}

export const getOsReceipt = async params => {
  const response = await fetchOsReceipt(params);
  return response;
}

export const removeDiscount = (userId, establishmentId, serviceOrderId) => dispatch => {
  dispatch(handleLoading(true));

  return deleteDiscount(userId, establishmentId, serviceOrderId)
    .then(() => dispatch(loadServiceOrder({ userId, establishmentId, serviceOrderId })))
    .catch(() => toast.error('Não foi possível remover o desconto da OS.'))
    .finally(() => dispatch(handleLoading(false)));
}