import * as type from './constants';

export const setIsLoading = isLoading => ({
  type: type.SET_IS_LOADING,
  isLoading
});

export const setComissionedPayroll = data => ({
  type: type.SET_COMISSIONED_PAYROLL,
  data
});

export const clearComissionedPayroll = () => ({
  type: type.CLEAR_COMISSIONED_PAYROLL
});

export const setComissionedPayrollErrors = data => ({
  type: type.SET_COMISSIONED_PAYROLL_ERRORS,
  data
});

export const clearComissionedPayrollErrors = () => ({
  type: type.CLEAR_COMISSIONED_PAYROLL_ERRORS
});