import { formatEmptyValue } from '../../../helpers/formatters';
import { compose, merge, set, over, map, lensProp, pick, prop } from 'ramda';

import {
  REGISTER_FIELD_CHANGE,
  SET_ACCOUNTS,
  HANDLE_REGISTRATION,
  HANDLE_UPDATE,
  HANDLE_LOADING,
  CLEAR_REGISTRATION
} from './constants';

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
  registration: false,
  accountId: null,
  status: 1,
  accountName: '',
  bankName: '',
  bankAgency: '',
  bankAccount: '',
  establishments: [],
  establishmentsUuid: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      let { value } = action;

      if (action.field === 'status') {
        value = parseInt(value, 10);
      }

      return set(lensProp(action.field), value, state);
    }
    case SET_ACCOUNTS: {
      const list = map(over(lensProp('bankName'), formatEmptyValue), action.data.content);

      return compose(
        set(lensProp('total'), action.data.total),
        set(lensProp('list'), list),
        set(lensProp('isLoading'), false)
      )(state);
    }
    case HANDLE_REGISTRATION: {
      return set(lensProp('registration'), action.status, state);
    }
    case HANDLE_UPDATE: {
      const bankName = prop('bankName', action.data);

      const account = pick(
        [
          'mainAccount',
          'accountTypeId',
          'accountId',
          'accountName',
          'bankName',
          'bankAgency',
          'bankAccount',
          'status',
          'establishments',
          'establishmentsUuid'
        ],
        action.data
      );

      return compose(
        set(lensProp('registration'), true),
        set(lensProp('bankName'), bankName === '--' ? '' : bankName),
        merge(state)
      )(account);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_REGISTRATION: {
      const clear = compose(set(lensProp('list'), state.list), set(lensProp('total'), state.total));
      return clear(defaultState);
    }
    default: {
      return state;
    }
  }
}