import { toast } from 'react-toastify';
import { updateToken, setInitialEstablishments, setAllEstablishments, setUuid, handleLoading } from './actions';
import { setUserId, setUserRestrictions } from '../../Profile/store/actions';
import { setEstablishmentId, setBusinessInfo, setEstablishments, setEstablishmentTypes } from '../../BusinessInfo/store/actions';
import { setIsFromRegistration } from '../../../components/Onboarding/store/actions';
import { setTermsOfUseDate } from './actions';
import { setFilters } from '../../../components/Filter/store/actions';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { fetchLogin } from './services';
import { setToken, setBaseAPI } from '../../../services';

export const login = (params, history) => async dispatch => {
  try {
    dispatch(handleLoading(true));

    const response = await fetchLogin(params);

    if(response) {
      const { data } = response;

      const {
        filters,
        establishments,
        user: { name, userId, uuid, accessToken, nfTermsOfUse, userRestrictions, profileId }
      } = data;

      const user = {
        name,
        email: params.email
      }

      localStorage.setItem('user', JSON.stringify(user));

      dispatch(setAllEstablishments(establishments));

      const availableEstablishments = establishments;

      let haveEstablishments = false;

      if(establishments.length === 0) {
        haveEstablishments = true;

        localStorage.clear();

        history.push({
          pathname: '/cadastro-inicial',
          state: {
            data: {
              isFromLogin: true
            }
          }
        });

        const establishmentId =
          availableEstablishments.length === 1
            ? availableEstablishments[0].establishmentId
            : '';

        await setBaseAPI(userId, establishmentId);
        await setToken(accessToken);
        await dispatch(updateToken(accessToken));
        await dispatch(setTermsOfUseDate(nfTermsOfUse));
        await dispatch(setUserId(userId));
        await dispatch(setUuid(uuid));
        await dispatch(setUserRestrictions(userRestrictions, profileId));
        await dispatch(setInitialEstablishments(availableEstablishments));
        await dispatch(setEstablishmentId({ establishmentId }));
        await dispatch(setEstablishments(availableEstablishments));

        if(availableEstablishments.length === 1) {
          await dispatch(loadFilters(userId));
          await dispatch(setBusinessInfo(availableEstablishments[0]));
        } else {
          await dispatch(setFilters(filters));
        }

        return;
      }

      const establishmentId =
        availableEstablishments.length === 1
          ? availableEstablishments[0].establishmentId
          : '';

      await setBaseAPI(userId, establishmentId);
      await setToken(accessToken);
      await dispatch(updateToken(accessToken));
      await dispatch(setTermsOfUseDate(nfTermsOfUse));
      await dispatch(setUserId(userId));
      await dispatch(setUuid(uuid));
      await dispatch(setUserRestrictions(userRestrictions, profileId));
      await dispatch(setInitialEstablishments(availableEstablishments));
      await dispatch(setEstablishmentId({ establishmentId }));
      await dispatch(setEstablishments(availableEstablishments));

      if(availableEstablishments.length === 1) {
        await dispatch(loadFilters(userId));
        await dispatch(setBusinessInfo(availableEstablishments[0]));
        await dispatch(setEstablishmentTypes(availableEstablishments[0]?.establishmentTypes));
      } else {
        await dispatch(setFilters(filters));
      }

      if(availableEstablishments.length === 0 && !haveEstablishments) {
        toast.error('Acesso desativado. Por favor, entre em contato com o financeiro da Jump.');
        return;
      }

      dispatch(setIsFromRegistration(false));

      history.push('/visao-geral');
    }
  } catch (err) {
    dispatch(handleLoading(false));

    const { code, userId, accessToken } = err?.response?.data?.data || {};
    const noAccessCode = '01037';

    if(code?.toString() == noAccessCode) {
      await dispatch(updateToken(accessToken));
      await dispatch(setUserId(userId));

      return history?.push({
        pathname: '/lista/clientes',
        state: {
          data: {
            userId,
            noAccessCode: true
          }
        }
      });
    }
  } finally {
    dispatch(handleLoading(false));
  }
}