import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheck, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Drawer from '../../../../components/Drawer';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import ExportPDF from 'components/ExportPDF';
import FileUploader from 'components/FileUploader';

import { loadBusinessInfo } from 'pages/BusinessInfo/store/thunk';
import { loadCashierDetails, loadStoreCashierDetails, upsertTransaction, getWithdral, upsertCashierAttachment, removeCashierAttachment } from '../../store/thunk';
import { handleRegistration, clearCashierDetails, setOpeningAttachments, setClosingAttachments, setNewOpeningAttachments, setNewClosingAttachments } from '../../store/actions';

import { convertToReal } from '../../../../helpers/converters';
import { formatFilters } from '../../../../helpers/formatters';
import { filterValueLabel, getFileExtensionFromUrl } from '../../../../helpers/common';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

import { useStyles } from '../styles';

import { SITE_RESTRICTIONS } from '../../../../helpers/restrictions';

function formatNotFound(value) {
  return value || 'Não encontrado';
}

function Item({ title, children }) {
  const classes = useStyles();

  return(
    <Grid item className={classes.itemContainer}>
      <Typography
        variant="body1"
        color="primary"
        display="inline"
        className={classes.title}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        display="inline"
      >
        {children}
      </Typography>
    </Grid>
  );
}

export default function CashierDetails({ isOpen }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { width } = useWindowDimensions();
  const isSm = width < 960;

  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { sourceId, categorySourceId, cashierId, storeCashierId, isDetailing, submit } = useSelector(state => state.transactions);
  const { selects } = useSelector(state => state.filters);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const cashierDetails = useSelector(state => state.transactions.cashierDetails);
  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishmentName } = useSelector(state => state.businessInfo.submit);
  const userRestrictions = useSelector(state => state.profile.userRestrictions);

  const {
    openingUserName,
    openingDateTime,
    openingAccountName,
    accountDestinationName,
    openingAmount,
    sequence,
    closingUserName,
    closingDateTime,
    transactionsCount,
    closingPrintAbstract,
    closingObservation,
    totalAmount,
    transactionTypeId,
    description,
    withdralDateTime,
    withdralAmount,
    withdralUser,
    openingAttachments,
    closingAttachments
  } = useSelector(state => state.transactions.cashierDetails);

  const { expenses, receipts } = useSelector(state => state.transactions.cashierDetails.closingPrintAbstract) || [];

  const hasExpenses = expenses?.length > 0;
  const hasReceipts = receipts?.length > 0;

  const [isUpdating, setUpdate] = useState(false);
  const [initialWithdralAmount, setInitialWithdralAmount] = useState(withdralAmount);
  const [amount, handleTextChange] = useState(openingAmount);
  const [canCashierEdit, setCanCashierEdit] = useState(false);

  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);

  const [isReceiptUploadSuccess, setIsReceiptUploadSuccess] = useState(false);
  const [receiptsUploadQnt, setReceiptsUploadQnt] = useState(0);
  const [attachmentImageArray, setAttachmentImageArray] = useState([]);
  const [attachmentArrayCurrentIndex, setAttachmentArrayCurrentIndex] = useState(1);
  const [attachmentRemoveLoading, setAttachmentRemoveLoading] = useState(false);
  const [isAttachmentError, setIsAttachmentError] = useState(false);

  const filters = formatFilters(['user'], selects) || null;

  const { transactionId } = submit;

  const cashierOperationType =
    categorySourceId && categorySourceId === 6
      ? `Abertura do caixa`
      : `Fechamento do caixa`;

  const withdralUserName =
    withdralUser && filters
      ? filterValueLabel(filters[0].options, withdralUser)
      : '';

  useEffect(() => {
    if(cashierOperationType == 'Abertura do caixa') {
      setAttachmentImageArray(openingAttachments);
    }
  }, [openingAttachments, cashierOperationType]);

  useEffect(() => {
    if(cashierOperationType == 'Fechamento do caixa') {
      setAttachmentImageArray(closingAttachments);
    }
  }, [closingAttachments, cashierOperationType]);

  useEffect(() => {
    setInitialWithdralAmount(withdralAmount);
  }, [withdralAmount]);

  useEffect(() => {
    const params = {
      userId,
      establishmentId
    }

    if(userId && establishmentId) {
      dispatch(loadBusinessInfo(params));
    }
  }, [userId, establishmentId]);

  useEffect(() => {
    (cashierOperationType === 'Fechamento do caixa' && categorySourceId != 4)
      ? setCanCashierEdit(false)
      : setCanCashierEdit(true);
  }, [cashierOperationType, categorySourceId]);

  useEffect(() => {
    if(storeCashierId) {
      dispatch(loadStoreCashierDetails(userId, establishmentId, storeCashierId));
      return;
    }

    if(cashierId) {
      dispatch(loadCashierDetails(userId, establishmentId, cashierId));
      return;
    }

    if(!sourceId && transactionId) {
      dispatch(getWithdral(transactionId));
    }

    return () => dispatch(clearCashierDetails());
  }, []);

  useEffect(() => {
    if(isReceiptUploadSuccess) {
      toast.success(`${receiptsUploadQnt} comprovante(s) adicionado(s) com sucesso!`);
    }
  }, [isReceiptUploadSuccess]);

  const arrayCycle = array => {
    return [array[array?.length -1], ...array?.slice(0, -1)];
  }

  const backwardsArrayCycle = array => {
    return [...array?.slice(1, array?.length), array[0]];
  }

  const handleUploadFile = async e => {
    const column = cashierOperationType == 'Fechamento do caixa'
      ? 'closingAttachments'
      : 'openingAttachments';

    const receipts = e.target.files;

    setReceiptsUploadQnt(Array.from(receipts).length);

    for(let files = 0; files < receipts?.length; files++) {
      await dispatch(upsertCashierAttachment(userId, establishmentId, cashierId, receipts[files], column))
        .then(res => {
          if(cashierOperationType == 'Fechamento do caixa') {
            dispatch(setClosingAttachments(res?.data));
          }

          if(cashierOperationType == 'Abertura do caixa') {
            dispatch(setOpeningAttachments(res?.data));
          }

          setIsReceiptUploadSuccess(true);
        }).catch(() => setIsReceiptUploadSuccess(false));
    }

    setIsReceiptUploadSuccess(false);
  }

  const handleCloseCashierDetails = () => {
    dispatch(handleRegistration(false));
  }

  const handleUpdateCashier = () => {
    setUpdate(!isUpdating);
    handleTextChange(openingAmount);
  }

  const handleAmountChange = e => {
    handleTextChange(e.target.value);
  }

  const handleRequestUpdateCashier = () => {
    let params = { transactionId, amount, previsionDateTime: '00:00:00' };

    const extraProps = {
      userId,
      establishmentId,
      page,
      pageSize,
      startDate,
      endDate,
      openingAttachments,
      closingAttachments
    }

    if(categorySourceId == 4 && transactionTypeId == 2) {
      params = {
        ...params,
        amount: initialWithdralAmount
      }
    }

    dispatch(upsertTransaction(params, extraProps, false, true));
  }

  return(
    <Drawer
      id="categories"
      title={categorySourceId === 4 ? 'Sangria' : 'Detalhes do caixa'}
      open={isOpen}
      onClose={handleCloseCashierDetails}
    >
      {(isAttachmentModalOpen && attachmentImageArray?.length > 0) && (
        <Modal
          scroll="body"
          open={isAttachmentModalOpen}
          onClose={() => setIsAttachmentModalOpen(false)}
          title="Comprovantes"
        >
          <Grid container justifyContent="center" style={{ padding: 5 }}>
            <Grid xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                type="button"
                onClick={() => {
                  setIsAttachmentError(false);
                  setAttachmentImageArray(backwardsArrayCycle(attachmentImageArray));

                  if(attachmentArrayCurrentIndex == 1) {
                    return setAttachmentArrayCurrentIndex(attachmentImageArray?.length);
                  }

                  setAttachmentArrayCurrentIndex(attachmentArrayCurrentIndex - 1);
                }}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  color="#1E5168"
                  size="3x"
                />
              </Button>
            </Grid>
            <Grid xs={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {isAttachmentError ? (
                <Typography>
                  Pré visualização não disponível. <a href={attachmentImageArray[0]?.url} target='_blank'>Clique aqui para fazer o download do {getFileExtensionFromUrl(attachmentImageArray[0]?.url)}.</a>
                </Typography>
              ) : (
                <img
                  src={attachmentImageArray[0]?.url}
                  width={isSm ? "100%" : "50%"}
                  height="auto"
                  onError={() => setIsAttachmentError(true)}
                />
              )}
            </Grid>
            <Grid xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                type="button"
                onClick={() => {
                  setIsAttachmentError(false);
                  setAttachmentImageArray(arrayCycle(attachmentImageArray));

                  if(attachmentArrayCurrentIndex > attachmentImageArray?.length - 1) {
                    return setAttachmentArrayCurrentIndex(1);
                  }

                  setAttachmentArrayCurrentIndex(attachmentArrayCurrentIndex + 1);
                }}
              >
                <FontAwesomeIcon
                  icon={faAngleRight}
                  color="#1E5168"
                  size="3x"
                />
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end', padding: 5 }}>
              <Typography color='primary' style={{ fontSize: 18 }}>
                {attachmentArrayCurrentIndex}/{attachmentImageArray?.length}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <Grid xs={4} style={{ padding: 5 }}>
              <Button
                fullWidth
                type="button"
                onClick={() => setIsAttachmentModalOpen(false)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid xs={4} style={{ padding: 5 }}>
              <FileUploader
                fullWidth
                color="success"
                name="attachment"
                multiple={true}
                displayFileName={false}
                onChange={e => handleUploadFile(e)}
              >
                Adicionar
              </FileUploader>
            </Grid>
            <Grid xs={4} style={{ padding: 5 }}>
              <Button
                fullWidth
                type="button"
                color="error"
                disabled={userRestrictions?.includes(+SITE_RESTRICTIONS.REMOVE_TRANSACTION_ATTACHMENT_AND_CASHIER)}
                loading={attachmentRemoveLoading}
                onClick={async () => {
                  setAttachmentRemoveLoading(true);

                  dispatch(removeCashierAttachment(userId, establishmentId, cashierId, attachmentImageArray[0].url))
                    .then(() => {
                      const newAttachmentImageArray = attachmentImageArray.slice(1);

                      if(cashierOperationType == 'Abertura do caixa') {
                        dispatch(setNewOpeningAttachments(newAttachmentImageArray));
                      }

                      if(cashierOperationType == 'Fechamento do caixa') {
                        dispatch(setNewClosingAttachments(newAttachmentImageArray));
                      }

                      toast.success('Comprovante removido.');

                      setAttachmentImageArray(newAttachmentImageArray);
                      setAttachmentRemoveLoading(false);
                    })
                    .catch(() => setAttachmentRemoveLoading(false));
                }}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div>
          <Loader isLoading={isDetailing}>
            <div style={{ backgroundColor: '#FCFCFC', border: '1px solid #D7D7D7', borderRadius: 5, padding: 10 }}>
              <Grid container direction="column">
                {(+sequence > 0) && (
                  <Item title="Sequência">{sequence}</Item>
                )}
                <Item title="ID do caixa">{cashierId || storeCashierId}</Item>
              </Grid>
              {categorySourceId && categorySourceId === 6 && (
                <Grid container direction="column">
                  <Item title="Operação">{cashierOperationType}</Item>
                  <Item title="Operador">{formatNotFound(openingUserName)}</Item>
                  <Item title="Data">{formatNotFound(openingDateTime)}</Item>
                  <Item title="Conta Origem">
                    {formatNotFound(openingAccountName)}
                  </Item>
                  <Item title="Conta Destino">
                    {formatNotFound(accountDestinationName)}
                  </Item>
                  {isUpdating ? (
                    <Input
                      type="money"
                      label="Valor inicial"
                      placeholder="0"
                      value={amount}
                      onChange={e => handleAmountChange(e)}
                    />
                  ) : (
                    <Item title="Valor inicial">{formatNotFound(openingAmount)}</Item>
                  )}
                </Grid>
              )}
              {categorySourceId && categorySourceId === 5 && (
                <Grid>
                  <Grid container direction="column">
                    <Item title="Operação">{cashierOperationType}</Item>
                    <Item title="Operador">{formatNotFound(closingUserName)}</Item>
                    <Item title="Data de abertura">
                      {formatNotFound(openingDateTime)}
                    </Item>
                    <Item title="Data de fechamento">
                      {formatNotFound(closingDateTime)}
                    </Item>
                    {closingObservation && (
                      <Item title="Observação">
                        {closingObservation}
                      </Item>
                    )}
                    <Item title="Conta Origem">
                      {formatNotFound(openingAccountName)}
                    </Item>
                    <Item title="Conta Destino">
                      {formatNotFound(accountDestinationName)}
                    </Item>
                    <Item title="Quantidade de transações">
                      {formatNotFound(transactionsCount)}
                    </Item>
                  </Grid>
                  <Grid container direction="column" className={classes.container}>
                    {(closingPrintAbstract?.recurrentList && closingPrintAbstract?.recurrentList?.length > 0) && (
                      <Grid item xs={12}>
                        <Item title="Faturas pagas">
                          {closingPrintAbstract && closingPrintAbstract?.recurrentList && closingPrintAbstract?.recurrentList?.map(recurrent => (
                            <div>
                              {recurrent?.description} ({recurrent?.transactionCount}): {convertToReal(recurrent?.amount)}
                            </div>
                          ))}
                        </Item>
                      </Grid>
                    )}
                    {(closingPrintAbstract?.services && closingPrintAbstract?.services?.length > 0) && (
                      <Grid item xs={12}>
                        <Item title="Serviços">
                          {closingPrintAbstract && closingPrintAbstract?.services?.map(service => (
                            <div>
                              {service?.description} ({service?.transactionCount}): {convertToReal(service?.amount)}
                            </div>
                          ))}
                        </Item>
                      </Grid>
                    )}
                    {(closingPrintAbstract?.paymentTypes?.length > 0) && (
                      <Grid item xs={12}>
                        <Item title="Meios de pagamento">
                          {closingPrintAbstract?.paymentTypes?.map(payment => (
                            <div>
                              {payment?.description} ({payment?.transactionCount}): {convertToReal(payment?.amount)}
                            </div>
                          ))}
                        </Item>
                      </Grid>
                    )}
                    {(closingPrintAbstract?.paymentTypes && closingPrintAbstract?.rotaryList?.length > 0) && (
                      <Grid item xs={12}>
                        <Item title="Tabela de estacionamento">
                          {closingPrintAbstract?.rotaryList?.map(rotary => (
                            <div>
                              {rotary?.description} ({rotary?.transactionCount}): {convertToReal(rotary?.amount)}
                            </div>
                          ))}
                        </Item>
                      </Grid>
                    )}
                    {(cashierOperationType == 'Fechamento do caixa' && closingPrintAbstract?.discounts) && (
                      <Grid item xs={12}>
                        <Item title="Descontos">
                          <div>
                            {`${convertToReal(closingPrintAbstract?.discounts?.amount)} (${closingPrintAbstract?.discounts?.count})`}
                          </div>
                        </Item>
                      </Grid>
                    )}
                    {(closingPrintAbstract?.products && closingPrintAbstract?.products?.length > 0) && (
                      <Grid item xs={12}>
                        <Item title="Produtos">
                          {closingPrintAbstract?.products?.map(product => (
                            <div>
                              {product?.description} ({product?.count}): {convertToReal(product?.amount)}
                            </div>
                          ))}
                        </Item>
                      </Grid>
                    )}
                  </Grid>
                  {cashierOperationType === 'Fechamento do caixa' && (
                    <>
                      {hasExpenses && (
                        <Item title="Despesas avulsas">
                          {expenses?.map(expense => (
                            <div>
                              {expense.description} ({expense.transactionCount}): {convertToReal(expense.amount)}
                            </div>
                          ))}
                        </Item>
                      )}
                      {hasReceipts && (
                        <Item title="Receitas avulsas">
                          {receipts?.map(receipt => (
                            <div>
                              {receipt.description} ({receipt.transactionCount}): {convertToReal(receipt.amount)}
                            </div>
                          ))}
                        </Item>
                      )}
                    </>
                  )}
                  {closingPrintAbstract ? (
                    <Grid container direction="column" className={classes.container}>
                      <Item title="Abertura de caixa">
                        {convertToReal(closingPrintAbstract.openingAmount)}
                      </Item>
                      <Item title="Receita em dinheiro">
                        {convertToReal(closingPrintAbstract.recipe)}
                      </Item>
                      <Item title="Despesa">
                        {convertToReal(closingPrintAbstract.expense)}
                      </Item>
                      <Item title="Sangria">
                        {convertToReal(closingPrintAbstract.cashDrain)}
                      </Item>
                      <Item title="Saldo em dinheiro no caixa">{totalAmount}</Item>
                    </Grid>
                  ) : null}
                </Grid>
              )}
              {categorySourceId && categorySourceId === 4 && (
                <Grid container direction="column">
                  <Item title="Data">{formatNotFound(withdralDateTime)}</Item>
                  <Item title="Operador">{formatNotFound(withdralUserName)}</Item>
                  <Item title="Conta Destino">{formatNotFound(description)}</Item>
                  {isUpdating ? (
                    <Input
                      type="money"
                      label="Valor"
                      placeholder="0"
                      value={initialWithdralAmount}
                      onChange={event => setInitialWithdralAmount(event.target.value)}
                    />
                  ) : (
                    <Item title="Valor">
                      {formatNotFound(withdralAmount)}
                    </Item>
                  )}
                </Grid>
              )}
            </div>
          </Loader>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
          {!storeCashierId && (
            <>
              {attachmentImageArray?.length == 0 && (
                <FileUploader
                  color="tertiary"
                  variant="outlined"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      color="#757575"
                      size="xs"
                    />
                  }
                  name="attachment"
                  multiple={true}
                  displayFileName={false}
                  onChange={e => handleUploadFile(e)}
                >
                  Comprovantes
                </FileUploader>
              )}
            </>
          )}
          {attachmentImageArray?.length > 0 && (
            <Button
              type="button"
              startIcon={
                <FontAwesomeIcon
                  icon={faCheck}
                  color="#6DBB7B"
                  size="xs"
                />
              }
              onClick={() => setIsAttachmentModalOpen(true)}
            >
              Comprovantes
            </Button>
          )}
          {cashierOperationType === 'Fechamento do caixa' && (
            <ExportPDF
              style={{ width: 'fit-content' }}
              fileName='resumo_fechamento_de_caixa'
              exportType='cashierDetailsResume'
              data={{...cashierDetails, establishmentName}}
            >
              <Button type="button" color="success">
                Exportar PDF
              </Button>
            </ExportPDF>
          )}
          {transactionTypeId == 2 && (
            <div>
              {isUpdating ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                  <Button
                    type="button"
                    color="error"
                    loading={isDetailing}
                    onClick={handleUpdateCashier}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="button"
                    color="success"
                    loading={isDetailing}
                    onClick={handleRequestUpdateCashier}
                  >
                    Salvar
                  </Button>
                </div>
              ) : (
                <>
                  {!(!!storeCashierId && cashierOperationType == 'Abertura do caixa') && (
                    <>
                      {canCashierEdit && (
                        <Button
                          type="button"
                          color="success"
                          loading={isDetailing}
                          onClick={handleUpdateCashier}
                          disabled={(categorySourceId === 4 && userRestrictions?.includes(SITE_RESTRICTIONS.CASH_DRAIN))}
                        >
                          Editar
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
}

CashierDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired
}