import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  cardHeader: {
    textAlign: 'center',
    padding: spacing(1),
    backgroundColor: palette.primary.main,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  cardTitle: {
    color: 'white',
  },
  container: {
    padding: '12px 24px',
  },
}));
