import { toast } from 'react-toastify';
import {
  setUser,
  setUserId,
  handleLoading,
  clearProfile,
} from '../../Profile/store/actions';
import { fetchToken, sendNewPassword } from './services';
import { setBaseAPI } from '../../../services';

export const verifyPasswordToken = (token, email) => dispatch => {
  dispatch(handleLoading(true));

  return fetchToken(token, email)
    .then(({ data }) => {
      setBaseAPI(data.userId);
      dispatch(setUserId(data.userId));
      dispatch(setUser(data));
    })
    .finally(() => dispatch(handleLoading(false)));
};

export const resetPassword = params => dispatch => {
  dispatch(handleLoading(true));

  return sendNewPassword(params)
    .then(({ data }) => {
      dispatch(clearProfile());
      toast.success(data.msg);
    })
    .finally(() => dispatch(handleLoading(false)));
};
