import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Loader from '../Loader';

import { convertToMask } from './masks';

const Input = ({
  withoutFullWidth,
  type,
  error,
  helperText,
  disabled,
  loading,
  onChange,
  styleInput,
  maxLength,
  noAutoComplete,
  ...props
}) => {
  const [showPassword, setPasswordVisibility] = useState(false);

  const handleMaskChange = e => {
    const { value } = e.target;
    e.target.value = convertToMask(type, value);
    return onChange(e);
  }

  return(
    <TextField
      {...props}
      labelHeight={0}
      fullWidth={!withoutFullWidth}
      type={type === 'password' && !showPassword ? 'password' : 'text' || type}
      variant="outlined"
      disabled={disabled}
      error={error}
      helperText={helperText}
      onChange={e => handleMaskChange(e)}
      inputProps={{
        maxLength: type == 'timeRangePadStart' ? 6 : maxLength,
        autocomplete: "new-password",
        form: {
          autocomplete: noAutoComplete ? "off" : "new-password"
        }
      }}
      InputProps={{
        className: styleInput,
        endAdornment:
          type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setPasswordVisibility(!showPassword)}
                tabIndex="-1"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        startAdornment: loading && (
          <InputAdornment position="start">
            <Loader isLoading={loading} spinner />
          </InputAdornment>
        )
      }}
    />
  );
}

Input.defaultProps = {
  type: 'text',
  loading: false,
  error: false,
  helperText: '',
  withoutFullWidth: false,
  disabled: false
};

Input.propTypes = {
  classes: PropTypes.shape.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  withoutFullWidth: PropTypes.bool
};

export default Input;