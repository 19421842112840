import * as types from './constants';

export const textChange = (field, value) => ({
  type: types.TEXT_CHANGE,
  field,
  value,
});

export const setCashFlow = (content, resume) => ({
  type: types.SET_CASHFLOW,
  content,
  resume,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const changeType = periodType => ({
  type: types.CHANGE_PERIOD_TYPE,
  periodType,
});

export const verifyTypeAndSetCurrentYear = () => ({
  type: types.SET_CURRENT_YEAR_IN_DAY_TYPE,
});

export const goToNextPoint = () => ({
  type: types.NEXT_POINT,
});

export const goToPrevPoint = () => ({
  type: types.PREV_POINT,
});

export const setYearRange = yearRange => ({
  type: types.SET_YEAR_RANGE,
  yearRange,
});

export const handleBuildResume = status => ({
  type: types.BUILD_RESUME,
  status,
});

export const clearBuildResumeFilters = () => ({
  type: types.CLEAR_BUILD_RESUME_FILTERS,
});

export const changeDate = (startDate, endDate) => ({
  type: types.CHANGE_DATE,
  startDate,
  endDate,
});

export const clearDate = () => ({
  type: types.CLEAR_DATE,
});
