import { compose, over, set, lensProp, map, assoc } from 'ramda';

import { formatDate, formatEmptyValue } from '../../../helpers/formatters';
import { convertToReal } from '../../../helpers/converters';

import {
  SET_RPS,
  SET_RPS_INFO,
  SET_IS_NF_INFO,
  SET_SOURCE_ID,
  HANDLE_LOADING,
  HANDLE_ERROR
} from './constants';

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
  error: null,
  transactionId: null,
  sourceId: null,
  establishmentId: null,
  paymentDateTime: null,
  isNfInfo: false,
  nfInfo: {
    rpsSeries: null,
    rpsNumber: null,
    nfNumber: null,
    nfStatus: null
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_RPS: {
      const formatRps = rps => {
        const {
          rpsSeries,
          rpsNumber,
          nfStatus,
          nfNumber,
          paymentDateTime,
          amount
        } = rps;

        const formatter = compose(
          assoc('rpsSerieAndNumber', `${rpsSeries}-${rpsNumber}`),
          assoc('nfseStatusNumber', nfStatus),
          assoc('nfseNumber', nfNumber),
          assoc('nfseStatus', nfStatus),
          over(lensProp('nfNumber'), formatEmptyValue),
          over(lensProp('nfDateTime'), formatDate),
          assoc('amountView', convertToReal(amount)),
          assoc('paymentDateTimeView', formatDate(paymentDateTime))
        );

        return formatter(rps);
      }

      const list = map(formatRps, action.data);

      const rps = compose(
        set(lensProp('list'), list),
        set(lensProp('total'), action.total),
        set(lensProp('isLoading'), false)
      );

      return rps(state);
    }
    case SET_RPS_INFO: {
      const {
        rpsSeries,
        rpsNumber,
        nfNumber,
        nfStatus
      } = action.data;

      return {
        ...state,
        nfInfo: {
          rpsSeries,
          rpsNumber,
          nfNumber,
          nfStatus
        }
      }
    }
    case SET_IS_NF_INFO: {
      return {
        ...state,
        isNfInfo: action.status
      }
    }
    case SET_SOURCE_ID: {
      return {
        ...state,
        sourceId: action.sourceId
      }
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_ERROR: {
      return {
        ...state,
        error: action.error,
        transactionId: action.transactionId,
        establishmentId: action.establishmentId,
        paymentDateTime: action.paymentDateTime
      }
    }
    default:
      return state;
  }
}