import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setClientInvoices = data => ({
  type: types.SET_CLIENT_INVOICES,
  data,
});

export const setInvoiceId = id => ({
  type: types.SET_INVOICE_ID,
  id,
});

export const setInvoiceDetails = data => ({
  type: types.SET_INVOICE_DETAILS,
  data,
});

export const setAmountReceived = amount => ({
  type: types.SET_AMOUNT_RECEIVED,
  amount,
});

export const handleUpdateInvoice = status => ({
  type: types.HANDLE_UPDATE_INVOICE,
  status,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const handleDetailing = status => ({
  type: types.HANDLE_DETAILING,
  status,
});

export const handlePaying = status => ({
  type: types.HANDLE_PAYING,
  status,
});

export const handleEmailing = status => ({
  type: types.HANDLE_EMAILING,
  status,
});

export const handleCanceling = status => ({
  type: types.HANDLE_CANCELING,
  status,
});

export const handleCancelingPayment = status => ({
  type: types.HANDLE_CANCELING_PAYMENT,
  status,
});

export const clearForm = () => {
  return {
    type: types.CLEAR_FORM,
  };
};

export const onConvertAndChangeData = (field, date) => ({
  type: types.HANDLE_CHANGE_DATE,
  field,
  date,
});