import * as types from './constants';

export const setVehicleDamageList = result => ({
  type: types.SET_VEHICLE_DAMAGE_LIST,
  result
});

export const setVehicle = data => ({
  type: types.SET_VEHICLE,
  data
});

export const setIsLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  isLoading
});