export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'startTime',
    maskType: 'time',
    label: 'Hora inicial',
    placeholder: '00:00',
    value: '',
  },
  {
    advanced: false,
    type: 'input',
    id: 'endTime',
    maskType: 'time',
    label: 'Hora final',
    placeholder: '00:00',
    value: '',
  },
];
