export const REGISTER_FIELD_CHANGE = 'CLIENT_INVOICES/REGISTER_FIELD_CHANGE';
export const SET_CLIENT_INVOICES = 'CLIENT_INVOICES/SET_CLIENT_INVOICES';
export const SET_INVOICE_ID = 'CLIENT_INVOICES/SET_INVOICE_ID';
export const SET_INVOICE_DETAILS = 'CLIENT_INVOICES/SET_INVOICE_DETAILS';
export const SET_AMOUNT_RECEIVED = 'CLIENT_INVOICES/SET_AMOUNT_RECEIVED';
export const HANDLE_UPDATE_INVOICE = 'CLIENT_INVOICES/HANDLE_UPDATE_INVOICE';
export const HANDLE_LOADING = 'CLIENT_INVOICES/HANDLE_LOADING';
export const HANDLE_DETAILING = 'CLIENT_INVOICES/HANDLE_DETAILING';
export const HANDLE_PAYING = 'CLIENT_INVOICES/HANDLE_PAYING';
export const HANDLE_EMAILING = 'CLIENT_INVOICES/HANDLE_EMAILING';
export const HANDLE_CANCELING = 'CLIENT_INVOICES/HANDLE_CANCELING';
export const HANDLE_CANCELING_PAYMENT =
  'CLIENT_INVOICES/HANDLE_CANCELING_PAYMENT';
export const CLEAR_FORM = 'CLIENT_INVOICES/CLEAR_FORM';
export const HANDLE_CHANGE_DATE = 'EXTRACT/HANDLE_CHANGE_DATE';
