import { makeStyles } from '@material-ui/core/styles';
// .MuiTableRow-root.MuiTableRow-hover:hover

export const useStyles = makeStyles(() => ({
  table: {
    '& tbody>.MuiTableRow-root:hover': {
      background: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
}));
