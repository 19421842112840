import { toast } from 'react-toastify';
import { fetchConfigurations, updateConfigurations, sendFile } from './services';
import { setConfigurations, setImageUrl, setImagePath, setIsLoading } from './actions';

export const loadConfigurations = (userId, establishmentId) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchConfigurations(userId, establishmentId)
    .then(({ data }) => dispatch(setConfigurations(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const upsertConfigurations = (userId, establishmentId, params) => dispatch => {
  dispatch(setIsLoading(true));

  return updateConfigurations(userId, establishmentId, params)
    .then(({ data }) => {
      toast.success('Configurações salvas com sucesso');
      dispatch(setConfigurations(data));
    })
    .catch(() => toast.error('Houve um problema ao salvar as configurações'))
    .finally(() => dispatch(setIsLoading(false)));
}

export const uploadFile = (userId, establishmentId, params) => dispatch => {
  return sendFile(userId, establishmentId, params)
    .then(({ data }) => {
      toast.success('Logotipo escolhido com sucesso');
      dispatch(setImageUrl(data.url));
      dispatch(setImagePath(data.file));
    })
    .catch(() => toast.error('Não foi possível cadastrar o logotipo'));
}