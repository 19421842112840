import { set, lensProp } from 'ramda';
import { HANDLE_TYPE_PRICE_LINK_LOADING, SET_TYPE_PRICE_LINK } from './constants';

const defaultState = {
  isLoading: false,
  submit: {
    typePriceLink: []
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_TYPE_PRICE_LINK_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case SET_TYPE_PRICE_LINK: {
      return {
        ...state,
        submit: {
          ...state.submit,
          typePriceLink: [action.typePriceLink]
        }
      }
    }
    default: {
      return state;
    }
  }
};