import * as type from './constants';

export const registerFieldChange = (field, value) => ({
  type: type.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setServiceOrder = data => ({
  type: type.SET_SERVICE_ORDER,
  data,
});

export const setServiceOrderId = (serviceOrderId, userId, establishmentId) => ({
  type: type.SET_SERVICE_ORDER_ID,
  userId,
  establishmentId,
  id: serviceOrderId,
});

export const setUpdate = () => ({
  type: type.SET_UPDATE,
});

export const setRemove = () => ({
  type: type.SET_REMOVE,
});

export const setReceipt = () => ({
  type: type.SET_RECEIPT,
});

export const removeService = index => ({
  type: type.REMOVE_SERVICE,
  index,
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status,
});

export const clearServiceOrder = () => ({
  type: type.CLEAR_SERVICE_ORDER,
});

export const clearChanges = () => ({
  type: type.CLEAR_CHANGES,
});

export const onConvertAndChangeData = (field, date) => ({
  type: type.HANDLE_CHANGE_DATE,
  field,
  date,
});
