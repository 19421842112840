import { fetchReservations } from './services';
import { setReservations, handleLoading } from './actions';

export const loadReservations = ({
  page,
  pageSize,
  qp = '',
  extraProps,
}) => dispatch => {
  const { startDate, endDate } = extraProps;
  const url = `/reservations?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;

  dispatch(handleLoading(true));

  return fetchReservations(url)
    .then(({ data }) => dispatch(setReservations(data.content, data.total)))
    .finally(() => dispatch(handleLoading(false)));
};
