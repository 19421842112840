import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function InvoiceCancelling({ lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader
          activityData={activityData}
        />
        <Grid xs={12} style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
          <Row>
            <Typography color='primary'>
              <b>Cliente</b>
            </Typography>
            <Typography color='textSecondary'>{lastRegister?.invoice?.clientName}</Typography>
          </Row>
          <Row>
            <Typography color='primary'>
              <b>Dia do vencimento</b>
            </Typography>
            <Typography color='textSecondary'>{moment(lastRegister?.invoice?.dueDateTime).format('DD/MM/YYYY')}</Typography>
          </Row>
          <Row>
            <Typography color='primary'>
              <b>Valor</b>
            </Typography>
            <Typography color='textSecondary'>{convertToReal(lastRegister?.invoice?.amountReceived)}</Typography>
          </Row>
        </Grid>
      </Grid>
    </Grid>
  );
}

InvoiceCancelling.propTypes = {
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};