import * as types from './constants';

export const setNotification = data => ({
  type: types.SET_NOTIFICATION,
  data
});

export const setNotificationSituations = data => ({
  type: types.SET_NOTIFICATION_SITUATIONS,
  data
});

export const setCurrentNotificationFilterId = data => ({
  type: types.SET_CURRENT_NOTIFICATION_FILTER_ID,
  data
});

export const setCurrentPrevisionFilterId = data => ({
  type: types.SET_CURRENT_PREVISION_FILTER_ID,
  data
});

export const setIsNotificationListLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  isLoading
});