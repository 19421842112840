import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import { convertToBrl } from 'helpers/converters';

import { styles } from '../styles';

const Footer = ({ total, amount, totalPeriod, style }) => {
  const amountTotal =
    amount || amount === 0 ? `Total: ${convertToBrl(amount)}` : '';

  const periodTotal =
    totalPeriod && totalPeriod !== '0'
      ? ` / Período total: ${moment
        .duration(parseInt(totalPeriod, 10), 'seconds')
        .format('D [dia(s)], H [h e] m [min]')}`
      : '';

  return(
    <View style={[styles.footer, style, { display: 'flex', flexDirection: 'column' }]}>
      {!!total && (
        <Text style={styles.text}>
          {(+total > 1) && `Este relatório tem ${total} itens.`}
          {(+total == 1) && `Este relatório tem ${total} item.`}
        </Text>
      )}
      {!!amountTotal && (
        <Text style={styles.text}>
          {amountTotal}
        </Text>
      )}
      {!!periodTotal && (
        <Text style={styles.text}>
          {periodTotal}
        </Text>
      )}
    </View>
  );
}

Footer.propTypes = {
  total: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  totalPeriod: PropTypes.string.isRequired,
  style: PropTypes.shape.isRequired
};

export default Footer;