import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  breadcrumb: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 0),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3, 0),
    },
  },
  bold: {
    fontWeight: 500,
  },
  link: {
    textDecoration: 'none',
  },
}));
