import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: spacing(1)
  },
  infoCard: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
    marginBottom: 15,
    padding: 15,
    backgroundColor: '#f6ebe9',
    border: '1px solid #efd7d7',
    borderRadius: 10
  },
	infoCardText: {
		color: '#2e2d2c',
		fontSize: 15,
		fontWeight: 'bold'
	}
}));