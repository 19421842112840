import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { toast } from 'react-toastify';
import { formatFilters } from './formatters';
import { timeRanges } from '../components/Subheader/resources';

import {
  assoc,
  compose,
  map,
  over,
  lensProp,
  find,
  prop,
  propEq,
  reduce,
  head,
  replace,
  last,
  split,
  always,
  ifElse,
  isNil,
  join,
  prepend,
  append,
  length,
  test,
  identity,
  range,
  update,
  set
} from 'ramda';

export const componentToHtmlBlob = component => {
  const content = ReactDOMServer.renderToString(component);

  return window.open(
    URL.createObjectURL(
      new Blob([content], {
        type: 'text/html;charset=UTF-8'
      })
    )
  );
}

export const hoursAndMinutesToMinutes = time => {
  if(!time) {
    return 0;
  }

  const [hours, minutes] = time.split(':');
  const hoursToMinutes = +hours * 60;

  return hoursToMinutes + +minutes;
}

export const formatCurrency = amount => {
  const numberFormat = number =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(number);

  if (!amount) {
    return 'R$ 0,00';
  }

  return numberFormat(amount);
};

export const convertFormattedMoneyToNumber = formattedAmount => {
  return +formattedAmount?.replace(/[R$ ]/g, '')?.replace(/[,]/g, '.')?.replace(/[.](?=.*[.])/g, '') || 0;
}

export const convertFormattedMoneyAndNegativeMoneyToNumber = formattedAmount => {
  return +formattedAmount?.replace(/[-R$ ]/g, '')?.replace(/[,]/g, '.')?.replace(/[.](?=.*[.])/g, '') || 0;
}

export const convertToBrl = value => {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export const convertToReal = (money, withRealSign = true) => {
  let conversionNumber = money;
  if (!conversionNumber && withRealSign) {
    return 'R$ 0,00';
  }
  if (!conversionNumber && !withRealSign) {
    return '0,00';
  }
  if (typeof conversionNumber === 'number') {
    conversionNumber = conversionNumber.toString();
  }

  if (conversionNumber.indexOf('.') === -1) {
    conversionNumber = `${conversionNumber}.00`;
  }

  let formatCurrency = compose(replace('.', ','))(conversionNumber);

  if (withRealSign) {
    formatCurrency = compose(
      join(''),
      prepend('R$ '),
      replace('.', ',')
    )(conversionNumber);
  }

  if (test(/[.][1-9]$/, conversionNumber)) {
    formatCurrency = compose(join(''), append('0'))(formatCurrency);
  }

  const currencyLength = compose(length, head, split(','), last, split('R$ '));

  const currencyNegativeLength =
    formatCurrency.indexOf('-') !== -1 ? formatCurrency : 'R$ 0,00';

  if (
    currencyLength(formatCurrency) > 3 &&
    formatCurrency.indexOf('-') === -1
  ) {
    formatCurrency = formatCurrency.replace(
      /\d{3}(?=(\d{3})*,)/g,
      s => `.${s}`
    );
  }

  if (
    currencyLength(currencyNegativeLength) > 4 &&
    formatCurrency.indexOf('-') !== -1
  ) {
    formatCurrency = formatCurrency.replace(
      /\d{3}(?=(\d{3})*,)/g,
      s => `.${s}`
    );
  }

  return formatCurrency;
};

export const convertMoneyToNumber = money => {
  const currency =
    money.replace(/[A-Z]+/, '').replace(/[$]+/, '').replace(/[.]+/g, '').replace(/[,]+/g, '.');

  return +currency;
}

export const convertNumberToMoney = number => {
  return number?.toString()?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const convertToRealWithouSign = money => {
  let conversionNumber = money;
  if (!conversionNumber) {
    return '0,00';
  }
  if (typeof conversionNumber === 'number') {
    conversionNumber = conversionNumber.toString();
  }

  if (conversionNumber.indexOf('.') === -1) {
    conversionNumber = `${conversionNumber}.00`;
  }

  let formatCurrency = compose(replace('.', ','))(conversionNumber);

  if (test(/[.][1-9]$/, conversionNumber)) {
    formatCurrency = compose(join(''), append('0'))(formatCurrency);
  }

  const currencyLength = compose(length, head, split(','), last, split('R$ '));

  const currencyNegativeLength =
    formatCurrency.indexOf('-') !== -1 ? formatCurrency : 'R$ 0,00';

  if (
    currencyLength(formatCurrency) > 3 &&
    formatCurrency.indexOf('-') === -1
  ) {
    formatCurrency = formatCurrency.replace(
      /\d{3}(?=(\d{3})*,)/g,
      s => `.${s}`
    );
  }

  if (
    currencyLength(currencyNegativeLength) > 4 &&
    formatCurrency.indexOf('-') !== -1
  ) {
    formatCurrency = formatCurrency.replace(
      /\d{3}(?=(\d{3})*,)/g,
      s => `.${s}`
    );
  }

  return formatCurrency;
};

export const convertToEmptyString = string => string || '';

export const convertList = (props, filters, list) => {
  const convertName = name =>
    over(lensProp(name), id => {
      const lensOptions = compose(prop('options'), find(propEq('id', name)));

      const findOption = find(propEq('value', id));
      const optionName = lensOptions(filters)
        ? prop('label', findOption(lensOptions(filters)))
        : null;

      return optionName || '--';
    });

  const iterator = (list, name) => map(convertName(name), list);

  return reduce(iterator, list, props);
};

export const convertToOptions = (options, filters) =>
  prop('options', head(formatFilters(options, filters))) || [];

export const convertAmountToDB = amount => {
  if (!amount) {
    return 0;
  }

  const formatMoney = compose(
    replace(',', '.'),
    replace('.', ''),
    last,
    split('R$ ')
  );
  const transformValue = ifElse(isNil, always(0), formatMoney);
  const toInt = parseFloat(transformValue(amount));

  return toInt;
};

const invoiceSituation = params => {
  const { situationId, transactionId, dueDateTime } = params;
  const currentDate = moment().format('YYYY-MM-DD');

  if (situationId === 3) {
    return 'Cancelada';
  }

  if (transactionId) {
    return 'Paga';
  }

  if (moment(dueDateTime).isBefore(`${currentDate} 00:00:00`)) {
    return 'Atrasada';
  }

  return 'Pendente';
};

export const convertInvoiceParams = params => {
  const invoice = compose(
    over(lensProp('amountReceived'), convertToReal),
    over(lensProp('currentAmount'), convertToReal),
    over(lensProp('receivedAmount'), convertToReal),
    over(lensProp('amount'), convertToReal),
    over(lensProp('currentDueDateTime'), date => moment(date).format('DD/MM/YYYY')),
    over(lensProp('dueDateTime'), date => moment(date).format('DD/MM/YYYY')),
    over(lensProp('paymentDateTime'), date =>
      date === '0001-01-01 00:00:00' || !date
        ? '--'
        : moment(date).format('DD/MM/YYYY HH:mm:ss')
    ),
    over(
      lensProp('closingDateTime'), date => {
        if(+params?.opened == 0) {
          return moment(date).format('DD/MM/YYYY HH:mm:ss') == '01/01/0001 00:00:00'
            ? '--'
            : moment(date).format('DD/MM/YYYY HH:mm:ss');
        }

        return moment(date).format('DD/MM/YYYY HH:mm:ss') == '01/01/0001 00:00:00'
          ? '--'
          : moment(date).format('DD/MM/YYYY');
      }
    ),
    assoc('invoiceSituation', invoiceSituation(params)),
    assoc('currentDueDateTime', prop('dueDateTime', params)),
    assoc('receivedAmount', prop('amountReceived', params) || prop('amount', params)),
    assoc('currentAmount', prop('amount', params)),
    assoc('email', prop('clientEmail', params))
  );

  return invoice(params);
}

export const convertInvoicePrint = params => {
  const { client, establishment, invoice } = params;

  return {
    serviceOrderId: null,
    pathLogo: establishment.pathLogo,
    clientName: client.name,
    clientEmail: client.email,
    clientDocument: client.documentId,
    invoiceSituation: invoiceSituation(invoice),
    amount: convertToReal(invoice.amountReceived),
    establishmentDocument: establishment.documentId,
    establishmentAddress: establishment.addresses,
    dueDateTime: moment(invoice.dueDateTime).format('DD/MM/YYYY'),
    paymentDateTime: moment(invoice.paymentDateTime).format('DD/MM/YYYY')
  };
};

export const convertServicesToString = services => {
  const toString = s => `${s.description} - ${convertToReal(s.amount)}`;
  const formatter = ifElse(
    services => services.length > 0,
    services => map(toString, services).join(', '),
    services => identity(services)
  );

  return formatter(services);
};

export const convertToRawString = string => {
  return string ? replace(/\D/g, '', string) : '';
};

export const convertToDB = value => {
  if (!value) {
    return 0;
  }

  const formatMoney = compose(
    replace(',', '.'),
    replace('.', ''),
    last,
    split('R$ ')
  );
  const transformValue = ifElse(isNil, always(0), formatMoney);
  const toInt = parseFloat(transformValue(value));

  return toInt;
};

export const convertPercentageToDB = num => {
  let formatPercentage = num.indexOf('%') > 0 ? num.replace('%', '') : num;
  formatPercentage =
    formatPercentage.indexOf(',') > 0
      ? formatPercentage.replace(',', '.')
      : formatPercentage;

  return formatPercentage;
};

export const convertTypeToTimeRange = type => {
  const findTimeRange = find(propEq('value', type));
  return findTimeRange(timeRanges).label;
};

export const convertToPercentage = (amount, total) => {
  const percentage = amount && total ? parseInt((amount * 100) / total, 10) : 0;
  return `${percentage}%`;
};

export const convertTypeToNumberOfDays = type => {
  if (type === 'week') {
    return 7;
  }
  if (type === 'month') {
    return 30;
  }
  if (type === 'bimonthly') {
    return 60;
  }
  return 1;
};

export const convertNumberToTime = time =>
  time < 10 ? `0${time}:00` : `${time}:00`;

export const convertGraphicData = (data, type, timeRange) => {
  const formatDateTimeRanges =
    type === 'day' || type === 'yesterday'
      ? map(hours => hours.split(':')[0], timeRange)
      : timeRange;

  let graphicData = map(always(0), range(0, timeRange.length + 1));

  const insertIndexOnData = data => {
    const index = formatDateTimeRanges.findIndex(currentValue => {
      return type !== 'day' && data.days
        ? currentValue === `${data.days.substring(6, 8)}/${data.days.substring(4, 6)}`
        : currentValue === data.hours;
    });

    return assoc('index', index, data);
  }

  const dataWithAmountIndexes = map(insertIndexOnData, data);

  dataWithAmountIndexes.forEach(data => {
    const { amount, index } = data;
    const amountTotal = parseFloat(amount, 10);

    graphicData = update(index, amountTotal, graphicData);
  });

  return graphicData;
}

export const convertToOrganizeFilters = filters => {
  if (filters.length > 0) {
    if (filters.length > 8) {
      return filters;
    }

    return map(filter => set(lensProp('advanced'), false, filter), filters);
  }

  return [];
};

export const convertDateAndTimeToDB = time => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

export const convertTimeToDB = time => moment(time).format('YYYY-MM-DD');
export const convertDateToDB = time =>
  time ? moment(time, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';

export const convertToEstablishmentName = (id, list) => {
  const establishment = list.filter(
    establishment => establishment.establishmentId === id
  );

  return establishment.length > 0 ? establishment[0].establishmentName : '';
};

export const convertToHHMM = time => moment(time, 'HH:mm:ss').format('HH:mm');
export const convertToMinutes = time => moment.duration(time).asMinutes();
export const convertMinutesToPeriod = time => {
  let hours;
  let minutes;

  if (time >= 60) {
    hours = Math.floor(time / 60);
    minutes = time % 60;
  } else {
    hours = '00';
    minutes = `${time}`;
  }

  minutes = minutes < 10 ? `0${minutes}` : minutes;
  hours = hours < 10 && hours > 0 ? `0${hours}` : hours;

  if (minutes === 0) {
    minutes = '00';
  }

  return `${hours}:${minutes}`;
};

export const translateDays = days => {
  const convertedDays = [];

  for (let i = 0; i < days.length; i++) {
    const day = parseInt(days[i], 10);
    if (day === 1) {
      convertedDays[0] = 'Seg';
    }
    if (day === 2) {
      convertedDays[1] = 'Ter';
    }
    if (day === 3) {
      convertedDays[2] = 'Qua';
    }
    if (day === 4) {
      convertedDays[3] = 'Qui';
    }
    if (day === 5) {
      convertedDays[4] = 'Sex';
    }
    if (day === 6) {
      convertedDays[5] = 'Sab';
    }
    if (day === 7) {
      convertedDays[6] = 'Dom';
    }
  }

  const orderedDays = convertedDays.filter(n => n);

  const formatedDays = [
    orderedDays.slice(0, -1).join(', '),
    orderedDays.slice(-1)[0],
  ].join(convertedDays.length < 2 ? '' : ' e ');

  return formatedDays;
};

export function convertTransactionDate(date) {
  const currentTime = moment().format('HH:mm:ss');
  return `${convertTimeToDB(date)} ${currentTime}`;
}

export const convertEmptyTime = time =>
  time === '00:00:01' || time === '23:59:59' ? '' : convertToHHMM(time);

export const convertToBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    return callback(reader.result);
  };
  reader.onerror = error => {
    return toast.error(`Erro ao converter arquivo: ${error}`);
  };
};