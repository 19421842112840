import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchPayrolls = async ({ userId, establishmentId, pg, limit, employeeId, orderBy, sort }) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls?pg=${pg}&limit=${limit}&employeeId=${employeeId}&orderBy=${orderBy}&sort=${sort}`
    : `${userId}/payrolls?pg=${pg}&limit=${limit}&employeeId=${employeeId}&orderBy=${orderBy}&sort=${sort}`;

  const response = await newAPI.get(url);
  return response.data;
}

export const fetchSinglePayroll = async (userId, establishmentId, payrollUuid) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}`
    : `${userId}/payrolls/${payrollUuid}`;

  const response = await newAPI.get(url);
  return response.data;
}

export const fetchPayrollExport = async (userId, establishmentId, payrollUuid) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/extract`
    : `${userId}/payrolls/${payrollUuid}/extract`;

  const response = await newAPI.get(url);
  return response.data;
}

export const updatePayroll = async (userId, establishmentId, payrollUuid, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}`
    : `${userId}/payrolls/${payrollUuid}`;

  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}

export const requestClosePayroll = async (userId, establishmentId, payrollUuid, closingDate) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/close`
    : `${userId}/payrolls/${payrollUuid}/close`;

  const response = await newAPI.put(url, qs.stringify({ closingDate }));
  return response.data;
}

export const requestPayPayroll = async (userId, establishmentId, payrollUuid, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/pay`
    : `${userId}/payrolls/${payrollUuid}/pay`;

  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}

export const requestUndoPayPayroll = async (userId, establishmentId, payrollUuid, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/undo_pay`
    : `${userId}/payrolls/${payrollUuid}/undo_pay`;

  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}

export const requestPayPartialPayroll = async (userId, establishmentId, payrollUuid, params) => {
  const url = `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/partialpayments`;

  const response = await newAPI.post(url, qs.stringify(params));
  return response.data;
}

export const requestUndoPayPartialPayroll = async (userId, establishmentId, payrollUuid, partialPaymentUuid, params) => {
  const url = `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/partialpayments/${partialPaymentUuid}/undo_pay`;

  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}

export const fetchPartialPayment = async (userId, establishmentId, payrollUuid) => {
  const url = `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/partialpayments`;

  const response = await newAPI.get(url);
  return response.data;
}

export const fetchSinglePartialPayment = async (userId, establishmentId, payrollUuid, partialPaymentUuid) => {
  const url = `${userId}/establishment/${establishmentId}/payrolls/${payrollUuid}/partialpayments/${partialPaymentUuid}`;

  const response = await newAPI.get(url);
  return response.data;
}