import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value
});

export const setFilters = data => ({
  type: types.SET_FILTERS,
  data
});

export const setSuperlogica = data => ({
  type: types.SET_SUPERLOGICA,
  data
});

export const setGraphicTimeRange = (field, value) => ({
  type: types.SET_GRAPHIC_TIME_RANGE,
  field,
  value
});

export const handleSearch = status => ({
  type: types.HANDLE_SEARCH,
  status
});

export const handleFilterButtonClick = status => ({
  type: types.HANDLE_FILTER_BUTTON_CLICK,
  status
});

export const handleExporting = status => ({
  type: types.HANDLE_EXPORTING,
  status
});

export const clearSearch = () => ({
  type: types.CLEAR_SEARCH
});

export const toggleAdvanced = status => ({
  type: types.TOGGLE_ADVANCED,
  status
});

export const persistQueryParamsSearch = qp => ({
  type: types.HANDLE_PERSIST_SEARCH_QUERY_PARAMS,
  qp
});

export const setIsClientExportationError = status => ({
  type: types.SET_IS_CLIENT_EXPORTATION_ERROR,
  status
});