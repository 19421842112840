import {
  SET_IS_LOADING,
  SET_COMISSIONED_PAYROLL_LIST,
  CLEAR_COMISSIONED_PAYROLL_LIST
} from './constants';

const defaultState = {
  list: [],
  total: 0,
  isLoading: false
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_COMISSIONED_PAYROLL_LIST: {
      const { content, total } = action.data;

      return {
        ...state,
        list: content,
        total
      }
    }
    case CLEAR_COMISSIONED_PAYROLL_LIST: {
      return {
        ...state,
        total: 0,
        list: []
      }
    }
    default: {
      return state;
    }
  }
}