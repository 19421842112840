import {
  SET_GRAPHICS,
  SET_OCCUPATION,
  SET_PAID_OUT,
  SET_CANCELED,
  SET_ALTERED,
  SET_CASHFLOW
} from './constants';

const defaultState = {
  isLoading: false,
  graphics: {
    entry: [],
    payments: [],
    balance: []
  },
  occupation: {
    total: 0,
    vacancies: 0
  },
  cashFlow: {
    balance: 0,
    entry: 0,
    exit: 0
  },
  serviceOrders: {
    paidOut: {
      total: 0,
      totalAmount: 0
    },
    canceled: {
      total: 0,
      totalAmount: 0
    },
    altered: {
      total: 0,
      totalAmount: 0
    }
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_GRAPHICS: {
      const { entry, payments, balance } = action.data;

      return {
        ...state,
        isLoading: false,
        graphics: {
          entry,
          payments,
          balance
        }
      }
    }
    case SET_OCCUPATION: {
      const { total, vacancies } = action.data.data;

      return {
        ...state,
        occupation: {
          total,
          vacancies
        }
      }
    }
    case SET_CASHFLOW: {
      const { balance, entry, exit } = action.data.data;

      return {
        ...state,
        cashFlow: {
          balance,
          entry,
          exit
        }
      }
    }
    case SET_PAID_OUT: {
      const { total, totalAmount } = action.data.data;

      return {
        ...state,
        serviceOrders: {
          ...state.serviceOrders,
          paidOut: {
            total,
            totalAmount
          }
        }
      }
    }
    case SET_CANCELED: {
      const { total, totalAmount } = action.data.data;

      return {
        ...state,
        serviceOrders: {
          ...state.serviceOrders,
          canceled: {
            total,
            totalAmount
          }
        }
      }
    }
    case SET_ALTERED: {
      const { total, totalAmount } = action.data.data;

      return {
        ...state,
        serviceOrders: {
          ...state.serviceOrders,
          altered: {
            total,
            totalAmount
          }
        }
      }
    }
    default:
      return state;
  }
}