import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import SingleDatePicker from '../SingleDatePicker';

import { convertDateToDB } from 'helpers/converters';
import { changeDate } from '../LayoutRoute/store/actions';

export default function FilterDatePicker(props) {
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector(state => state.dateFilter);

  return(
    <Grid container>
      <Grid xs={6} style={{ paddingRight: 2.5 }}>
        <SingleDatePicker
          {...props}
          noLabel
          name="filterDatePickerStartDate"
          initialDate={startDate || null}
          onDateChange={date => dispatch(changeDate(convertDateToDB(date), endDate))}
        />
      </Grid>
      <Grid xs={6} style={{ paddingLeft: 2.5 }}>
        <SingleDatePicker
          {...props}
          noLabel
          name="filterDatePickerEndDate"
          initialDate={endDate || null}
          onDateChange={date => dispatch(changeDate(startDate, convertDateToDB(date)))}
        />
      </Grid>
    </Grid>
  );
}