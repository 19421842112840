import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { fetchCities } from '../../pages/ProvisionalReceiptConfig/store/service';
import { loadCities } from '../../pages/ProvisionalReceiptProductConfig/store/services'
import { setIsCityLoaded } from 'pages/ProvisionalReceiptConfig/store/actions';

import { useStyles } from './styles';

const AsyncSelectComponent = ({ isProvisionalReceiptProduct = false, ...props }) => {
  const dispatch = useDispatch();

  const classes = useStyles(props);

  const { label, value, onChange } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const { userId } = useSelector(state => state.profile);

  const getCities = async value => {
    if(isProvisionalReceiptProduct) {
      const { data } = await loadCities(userId);
      setOptions(data);
      return;
    }

    try {
      setLoading(true);
      const data = await fetchCities(userId, value);
      setOptions(data || []);
    } catch (err) {
      toast.error('Não foi possível carregar a lista de municípios.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(setIsCityLoaded(false));

    getCities()
      .finally(() => dispatch(setIsCityLoaded(true)));
  }, []);

  const optionValue = () => {
    if(options?.length > 0) {
      if(value) {
        return options?.filter(opt => opt.cityCode == value)[0];
      }
    }

    return {};
  }

  const getOptionLabel = option => {
    const { city, state } = option;

    if(!city) {
      return '';
    }

    return `${city} - ${state}`;
  }

  return(
    <Autocomplete
      disabled={props.disabled}
      id="cityCode"
      getOptionLabel={option => getOptionLabel(option)}
      classes={classes}
      options={options}
      loading={loading}
      value={optionValue()}
      loadingText={<p>Carregando...</p>}
      noOptionsText={<p>Sem opções</p>}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}

AsyncSelectComponent.defaultProps = {
  displayEmpty: false,
  disabled: false,
  error: false,
  helperText: '',
  value: '',
  noMargin: false,
  loading: false
};

AsyncSelectComponent.propTypes = {
  loading: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  noMargin: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number])
};

export { AsyncSelectComponent };