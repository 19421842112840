import qs from 'qs';
import { newAPI, changeBaseNewAPI } from '../../../services';

export const fetchBusinessInfo = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}`);
  return response.data;
}

export const createNewEstablishment = async params => {
  const response = await changeBaseNewAPI('post', `/${params.userId}/establishment/new`, qs.stringify(params));
  return response.data;
}

export const updateEstablishment = async params => {
  const { userId, establishmentId } = params;

  delete params["userId"];

  const response = await newAPI.put(`/${userId}/establishment/${establishmentId}`, qs.stringify(params));
  return response.data;
}

export const activePremiumPackage = async (userId, establishmentId) => {
  const response = await newAPI.post(`/${userId}/establishment/${establishmentId}/premium_package/activate`);
  return response.data;
}

export const disablePremiumPackage = async (userId, establishmentId) => {
  const response = await newAPI.post(`/${userId}/establishment/${establishmentId}/premium_package/disable`);
  return response.data;
}

export const debitWarning = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/debit_warning`, qs.stringify(params));
  return response.data;
}