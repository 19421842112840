import { set, lensProp, lensPath, concat, prop } from 'ramda';
import {
  REGISTER_FIELD_CHANGE,
  SET_FILTERS,
  SET_GRAPHIC_TIME_RANGE,
  HANDLE_SEARCH,
  HANDLE_EXPORTING,
  HANDLE_FILTER_BUTTON_CLICK,
  CLEAR_SEARCH,
  TOGGLE_ADVANCED,
  HANDLE_PERSIST_SEARCH_QUERY_PARAMS,
  SET_SUPERLOGICA,
  SET_IS_CLIENT_EXPORTATION_ERROR
} from './constants';
import { defaultSelects } from '../components/resources';
import { formatOptions } from '../../../helpers/formatters';

const defaultState = {
  selects: defaultSelects,
  graphicTimeRange: 'day',
  isSearching: false,
  isExporting: false,
  isClientExportationError: false,
  isAdvanced: true,
  isFilterButtonClicked: false,
  search: '',
  filtrate: null,
  qp: '',
  superlogica: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      if (action.field === 'search') {
        return set(lensProp(action.field), action.value, state);
      }

      return set(lensPath(['filtrate', action.field]), action.value, state);
    }
    case SET_FILTERS: {
      return set(
        lensProp('selects'),
        concat(formatOptions(action.data), prop('selects', defaultState)),
        state
      );
    }
    case SET_IS_CLIENT_EXPORTATION_ERROR: {
      return {
        ...state,
        isClientExportationError: action.status
      }
    }
    case SET_SUPERLOGICA: {
      return action.data;
    }
    case SET_GRAPHIC_TIME_RANGE: {
      return set(lensProp(action.field), action.value, state);
    }
    case HANDLE_SEARCH: {
      return set(lensProp('isSearching'), action.status, state);
    }
    case HANDLE_FILTER_BUTTON_CLICK: {
      return set(lensProp('isFilterButtonClicked'), action.status, state);
    }
    case HANDLE_EXPORTING: {
      return set(lensProp('isExporting'), action.status, state);
    }
    case CLEAR_SEARCH: {
      return set(lensProp('selects'), state.selects, defaultState);
    }
    case TOGGLE_ADVANCED: {
      return set(lensProp('isAdvanced'), action.status, state);
    }
    case HANDLE_PERSIST_SEARCH_QUERY_PARAMS: {
      return set(lensProp('qp'), action.qp, state);
    }
    default: {
      return state;
    }
  }
}