import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    paddingTop: spacing(3),
  },
}));
