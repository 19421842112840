import qs from 'qs';
import { changeBaseNewAPI } from '../../../services';

export const fetchToken = async (token, email) => {
  const response = await changeBaseNewAPI('get', `/user/password-recovery?token=${token}&email=${email}`);
  return response.data;
}

export const sendNewPassword = async params => {
  const response = await changeBaseNewAPI('post', '/user/password-recovery/reset', qs.stringify(params));
  return response.data;
}