import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export default function RestrictionsView() {
  const styles = useStyles();

  const { restrictions } = useSelector(state => state.provisionalReceiptConfig);

  return(
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 20, borderBottom: '1px solid #E4E7EA' }}>
        <Typography color="primary">
          <b>Restrições de envio de NFS-e de cada prefeitura</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>
            Obrigatoriedade no nome do cliente:{' '}
            {restrictions?.requiredClientName ? 'Sim' : 'Não'}
          </b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>
            Obrigatoriedade no CPF/CNPJ do cliente:{' '}
            {restrictions?.requiredClientDocumentId ? 'Sim' : 'Não'}
          </b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>
            Obrigatoriedade no endereço do cliente:{' '}
            {restrictions?.requiredClientAddress ? 'Sim' : 'Não'}
          </b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>
            Obrigatoriedade no cadastro de cliente:{' '}
            {restrictions?.requiredClient ? 'Sim' : 'Não'}
          </b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>
            Cancelamento NF-e precisa do cadastro de cliente:{' '}
            {restrictions?.requiredClientToCancel ? 'Sim' : 'Não'}
          </b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>
            Cancelamento de NF-e precisa do CPF/CNPJ do cliente:{' '}
            {restrictions?.requiredClientDocumentIdToCancel ? 'Sim' : 'Não'}
          </b>
        </Typography>
      </Grid>
    </Grid>
  );
}