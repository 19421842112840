import * as yup from 'yup';
import { formatDocument, formatPhone, formatPostalCode } from 'helpers/formatters';
import { phoneRegExp, documentRegExp, postalCodeRegExp } from '../../../../../helpers/regex';
import { checkedValueByType } from 'helpers/common';

export const basicInfoValidations = yup.object().shape({
  fantasyName: yup
    .string()
    .required('O nome do estabelecimento é obrigatório')
    .nullable(),
  companyName: yup
    .string()
    .required('A razão social é obrigatória')
    .nullable(),
  documentId: yup
    .string()
    .test(function(document) {
      return formatDocument(document).match(documentRegExp)
        ? true
        : this.createError({
            message: 'O CNPJ é obrigatório',
            path: 'documentId'
          });
    })
    .nullable(),
  phone: yup
    .string()
    .test(function(phone) {
      return formatPhone(phone).match(phoneRegExp)
        ? true
        : this.createError({
            message: 'O telefone é obrigatório',
            path: 'phone'
          });
    })
    .nullable(),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .required('O e-mail é obrigatório')
    .nullable(),
  postalCode: yup
    .string()
    .test(function(postalCode) {
      return formatPostalCode(postalCode).match(postalCodeRegExp)
        ? true
        : this.createError({
            message: 'O CEP é obrigatório',
            path: 'postalCode'
          });
    }),
  streetType: yup
    .string()
    .required('O tipo do local é obrigatório')
    .nullable(),
  street: yup
    .string()
    .required('O endereço é obrigatório')
    .nullable(),
  number: yup
    .number()
    .required('O número é obrigatório')
    .nullable(),
  district: yup
    .string()
    .required('O bairro é obrigatório')
    .nullable(),
  city: yup
    .string()
    .required('A cidade é obrigatória')
    .nullable(),
  state: yup
    .string()
    .required('O estado é obrigatório')
    .nullable(),
  encouraging: yup
    .number()
    .test(function(option) {
      return option == 1 || option == 2
        ? true
        : this.createError({
            message: 'Incentivador cultural é obrigatório',
            path: 'encouraging'
          });
    }),
  municipalSubscription: yup
    .string()
    .nullable()
    .test(function(value) {
      if(!!value) {
        return true;
      }

      if(!!this.parent.stateSubscription && !value) {
        return true;
      }

      return this.createError({
        message: 'A inscrição municipal é obrigatória',
        path: 'municipalSubscription'
      });
    }),
  stateSubscription: yup
    .string()
    .nullable()
    .test(function(value) {
      if(!!value) {
        return true;
      }

      if(!!this.parent.municipalSubscription && !value) {
        return true;
      }

      return this.createError({
        message: 'A inscrição estadual é obrigatória',
        path: 'stateSubscription'
      });
    }),
  simpleNational: yup
    .number()
    .test(function(option) {
      return option == 1 || option == 2
        ? true
        : this.createError({
            message: 'Selecione se sua empresa se enquadra no Simples Nacional',
            path: 'simpleNational'
          });
    }),
  issRetain: yup
    .number()
    .test(function(option) {
      return option == 1 || option == 2
        ? true
        : this.createError({
            message: 'Selecione se sua empresa possui ISS retido',
            path: 'issRetain'
          });
    }),
  aliquot: yup
    .string()
    .required('Alíquota é obrigatória')
    .nullable(),
  taxationRegime: yup
    .string()
    .nullable()
    .test(function(option) {
      return option != null && option != undefined
        ? true
        : this.createError({
            message: 'O regime de taxação é obrigatório',
            path: 'taxationRegime'
          })
    }),
  taxationRegimeSpecial: yup
    .string()
    .nullable()
    .when(['requiredSpecialTaxRegime'], {
      is: requiredSpecialTaxRegime => checkedValueByType(requiredSpecialTaxRegime, 'boolean'),
      then: yup.string().required('O regime de taxação especial é obrigatório')
    }),
  description: yup
    .string()
    .nullable()
    .when('requiredServiceDescription', {
      is: requiredServiceDescription => checkedValueByType(requiredServiceDescription, 'boolean'),
      then: yup.string().required('Descrição do serviço é obrigatória')
    }),
  serviceCode: yup
    .string()
    .nullable()
    .when('requiredMunicipalServiceCode', {
      is: requiredMunicipalServiceCode => checkedValueByType(requiredMunicipalServiceCode, 'boolean'),
      then: yup.string().required('Código do serviço é obrigatório')
    }),
  code: yup
    .string()
    .nullable()
    .when('requiredCnae', {
      is: requiredCnae => checkedValueByType(requiredCnae, 'boolean'),
      then: yup.string().required('O CNAE é obrigatório')
    }),
  serviceItem: yup
    .string()
    .nullable()
    .when('requiredServiceItem', {
      is: requiredServiceItem => checkedValueByType(requiredServiceItem, 'boolean'),
      then: yup.string().required('Item lista serviço é obrigatório')
    }),
  autenticatorUser: yup
    .string()
    .nullable()
    .when('isLogin', {
      is: isLogin => !!isLogin,
      then: yup.string().required('O usuário é obrigatório')
    }),
  autenticatorUserPassword: yup
    .string()
    .nullable()
    .when(['isLogin', 'isPassword'], {
      is: (isLogin, isPassword) => (!!isLogin && !!isPassword),
      then: yup.string().required('A senha é obrigatória')
    }),
  autenticatorToken: yup
    .string()
    .nullable()
    .when(['isPassword', 'isLogin'], {
      is: (isPassword, isLogin) => isPassword && !isLogin,
      then: yup.string().required('A chave de acesso é obrigatória')
    })
});