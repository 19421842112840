import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchServices = async (userId, establishmentId, url) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}${url}`);
  return response.data;
}

export const fetchSingleService = async (userId, establishmentId, serviceId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/services/${serviceId}`);
  return response.data;
}

export const createService = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/services/new`, qs.stringify(params));
  return response.data;
}

export const updateService = async (userId, establishmentId, serviceId, params) => {
  const formattedParams = params?.categories?.length > 0
    ? qs.stringify(params)
    : qs.stringify(params).concat('&categories[0]=');

  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/services/${serviceId}`, formattedParams);
  return response.data;
}

export const deleteServices = async (userId, establishmentId, data) => {
  const servicesPreparedies = data.data.serviceIds.map(service => `serviceIds[]=${service}`);

  const regexForReplace = /,/gi;

  const dataForm =
    servicesPreparedies.length > 1
      ? servicesPreparedies.join().replace(regexForReplace, '&')
      : servicesPreparedies[0];

  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/services`, { data: dataForm });
  return response.data;
}

export const updateVisibility = async (userId, establishmentId, serviceId, status, data) => {
  const params = {
    ...data,
    status: +status
  }

  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/services/${serviceId}`, qs.stringify(params));
  return response.data;
}

export const fetchServicesCategories = async userId => {
  const response = await newAPI.get(`${userId}/services/categories`);
  return response.data;
}