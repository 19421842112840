import {
  SET_TRANSACTION,
  SET_RESUME,
  SET_ACCOUNT_ID,
  HANDLE_LOADING,
  HANDLE_REGISTRATION,
  REGISTER_FIELD_CHANGE,
  CLEAR_REGISTRATION,
  CLEAR_CASHIER_DETAILS,
  HANDLE_CONFIRM,
  HANDLE_CHANGE_DATE,
  CLEAR_TRANSACTION,
  HANDLE_DETAILING,
  SELECT_SOURCE_ID,
  SELECT_CATEGORY_SOURCE_ID,
  SELECT_TRANSACTION_ID,
  SET_TRANSACTION_TYPE,
  SET_TRANSACTION_DETAILS,
  SET_CASHIER_DETAILS,
  SET_STORE_CASHIER_ID,
  SET_CASHIER_ID,
  SET_REMOVE,
  CLEAR_CHANGES,
  SET_ATTACHMENT,
  SET_OPENING_ATTACHMENTS,
  SET_CLOSING_ATTACHMENTS,
  SET_NEW_OPENING_ATTACHMENTS,
  SET_NEW_CLOSING_ATTACHMENTS
} from './constants';

import moment from 'moment';
import { compose, lensPath, lensProp, set, over } from 'ramda';
import { formatTransaction } from '../../../helpers/formatters';
import { convertTimeToDB, convertToReal } from '../../../helpers/converters';

const defaultState = {
  content: [],
  total: 0,
  resume: {
    confirmed: {
      inbound: 0,
      outbound: 0,
      balance: 0
    },
    pendent: {
      inbound: 0,
      outbound: 0,
      balance: 0
    }
  },
  isLoading: false,
  accountsFilters: [],
  registration: false,
  modalType: '',
  sourceId: null,
  categorySourceId: null,
  cashierId: null,
  storeCashierId: null,
  submit: {
    establishmentId: null,
    situationId: null,
    nfseStatus: null,
    transactionId: null,
    transactionTypeId: null,
    description: null,
    categoryId: null,
    transactionDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    previsionDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    accountId: null,
    amount: null,
    paymentMethodId: null,
    paymentDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    confirmationDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    userName: null,
    attachment: null
  },
  isConfirm: false,
  isDetailing: false,
  isRemoving: false,
  cashierDetails: {
    description: '',
    withdralDateTime: null,
    withdralAmount: null,
    withdralUser: null,
    transactionTypeId: null,
    cashierId: null,
    storeCashierId: null,
    openingDateTime: null,
    closingDateTime: null,
    userId: null,
    closingUserId: null,
    accountId: null,
    transactions: [],
    paymentMethodsAmounts: [],
    totalAmount: null,
    status: null,
    openingUserName: null,
    closingUserName: null,
    openingAmount: null,
    transactionsCount: null,
    closingPrintAbstract: [],
    closingObservation: null,
    sequence: 0,
    openingAttachments: [],
    closingAttachments: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TRANSACTION: {
      const { total } = action.data;

      return {
        ...state,
        content: formatTransaction(action.data),
        total,
        isLoading: false
      }
    }
    case SET_RESUME: {
      const { data } = action.data;

      return {
        ...state,
        resume: data
      }
    }
    case SET_ACCOUNT_ID: {
      return {
        ...state,
        submit: {
          ...state.submit,
          accountId: action.id,
        },
      };
    }
    case HANDLE_LOADING: {
      return {
        ...state,
        isLoading: action.status,
      };
    }
    case REGISTER_FIELD_CHANGE: {
      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case HANDLE_CHANGE_DATE: {
      const currentTime = moment().format('HH:mm:ss');
      const dateTime = `${convertTimeToDB(action.date)} ${currentTime}`;

      return set(lensPath(['submit', action.field]), dateTime, state);
    }
    case HANDLE_REGISTRATION: {
      return compose(
        set(lensProp('registration'), action.status),
        set(lensProp('modalType'), action.modalType)
      )(state);
    }
    case HANDLE_CONFIRM: {
      return set(
        lensProp('isConfirm'),
        action.status || !state.isConfirm,
        state
      );
    }
    case SET_ATTACHMENT: {
      return {
        ...state,
        submit: {
          ...state.submit,
          attachment: action.attachment
        }
      }
    }
    case SET_OPENING_ATTACHMENTS: {
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          openingAttachments: [...state.cashierDetails.openingAttachments, action.attachments]
        }
      }
    }
    case SET_CLOSING_ATTACHMENTS: {
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          closingAttachments: [...state.cashierDetails.closingAttachments, action.attachments]
        }
      }
    }
    case SET_NEW_OPENING_ATTACHMENTS: {
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          openingAttachments: action.attachments
        }
      }
    }
    case SET_NEW_CLOSING_ATTACHMENTS: {
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          closingAttachments: action.attachments
        }
      }
    }
    case CLEAR_REGISTRATION: {
      return {
        ...state,
        registration: false,
        isRemoving: false,
        isConfirm: false,
        submit: defaultState.submit,
      };
    }
    case CLEAR_CASHIER_DETAILS: {
      return {
        ...state,
        cashierDetails: defaultState.cashierDetails,
      };
    }
    case CLEAR_TRANSACTION:
      return defaultState;
    case HANDLE_DETAILING: {
      return set(lensProp('isDetailing'), action.status, state);
    }
    case SELECT_SOURCE_ID: {
      return set(lensProp('sourceId'), action.sourceId, state);
    }
    case SET_TRANSACTION_TYPE: {
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          transactionTypeId: action.transactionTypeId,
        },
      };
    }
    case SELECT_CATEGORY_SOURCE_ID: {
      return set(lensProp('categorySourceId'), action.categorySourceId, state);
    }
    case SELECT_TRANSACTION_ID: {
      return set(
        lensPath(['submit', 'transactionId']),
        action.transactionId,
        state
      );
    }
    case SET_TRANSACTION_DETAILS: {
      return compose(
        set(lensPath(['submit', 'establishmentId']), action.data.establishmentId),
        set(lensPath(['submit', 'cashierId']), action.data.cashierId),
        set(lensPath(['submit', 'userName']), action.data.userName),
        set(lensPath(['submit', 'situationId']), action.data.situationId),
        set(lensPath(['submit', 'nfseStatus']), action.data.nfseStatus),
        set(lensPath(['submit', 'attachment']), action.data.attachment),
        set(
          lensPath(['submit', 'transactionTypeId']),
          action.data.transactionTypeId
        ),
        set(lensPath(['submit', 'transactionId']), action.data.transactionId),
        set(
          lensPath(['submit', 'paymentMethodId']),
          action.data.paymentMethodId
        ),
        set(lensPath(['submit', 'description']), action.data.description),
        set(lensPath(['submit', 'categoryId']), action.data.categoryId),
        set(
          lensPath(['submit', 'previsionDateTime']),
          moment(action.data.previsionDateTime).format('YYYY-MM-DD HH:mm:ss')
        ),
        set(lensPath(['submit', 'accountId']), action.data.accountId),
        set(lensPath(['submit', 'amount']), convertToReal(action.data.amount)),
        over(lensPath(['submit', 'confirmationDateTime']), () =>
          action.data.confirmationDateTime !== '0001-01-01 00:00:00' &&
          action.data.confirmationDateTime
            ? moment(action.data.confirmationDateTime).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : defaultState.submit.confirmationDateTime
        ),
        over(lensPath(['submit', 'paymentDateTime']), () =>
          action.data.paymentDateTime !== '0001-01-01 00:00:00' &&
          action.data.paymentDateTime
            ? moment(action.data.paymentDateTime).format('YYYY-MM-DD HH:mm:ss')
            : defaultState.submit.paymentDateTime
        ),
        set(lensPath(['submit', 'sourceId']), action.data.sourceId),
        set(
          lensProp('isConfirm'),
          action.data.situationId === 2 || action.data.situationId === 3
        )
      )(state);
    }
    case SET_CASHIER_DETAILS: {
      return compose(
        set(lensPath(['cashierDetails', 'cashierId']), action.data.cashierId),
        set(lensPath(['cashierDetails', 'storeCashierId']), action.data.storeCashierId),
        set(lensPath(['cashierDetails', 'userId']), action.data.userId),
        set(lensPath(['cashierDetails', 'closingAttachments']), action.data.closingAttachments || []),
        set(lensPath(['cashierDetails', 'openingAttachments']), action.data.openingAttachments || []),
        set(
          lensPath(['cashierDetails', 'withdralDateTime']),
          moment(action.data.paymentDateTime).format('DD/MM/YYYY HH:mm')
        ),
        set(
          lensPath(['cashierDetails', 'description']),
          action.data.description || ''
        ),
        set(
          lensPath(['cashierDetails', 'withdralUser']),
          action.data.userId || ''
        ),
        set(
          lensPath(['cashierDetails', 'openingUserName']),
          action.data?.openingUserName
        ),
        set(
          lensPath(['cashierDetails', 'openingDateTime']),
          moment(action.data.openingDateTime).format('DD/MM/YYYY HH:mm')
        ),
        set(
          lensPath(['cashierDetails', 'openingAccountName']),
          action.data?.openingAccountName
        ),
        set(
          lensPath(['cashierDetails', 'accountDestinationName']),
          action.data?.closingAccountName
        ),
        set(
          lensPath(['cashierDetails', 'withdralAmount']),
          convertToReal(action.data.amount)
        ),
        set(
          lensPath(['cashierDetails', 'openingAmount']),
          !!action.data?.openingTransactionAmount ? convertToReal(action.data?.openingTransactionAmount) : ''
        ),
        set(
          lensPath(['cashierDetails', 'closingUserName']),
          action.data?.closingUserName
        ),
        set(
          lensPath(['cashierDetails', 'closingDateTime']),
          moment(action.data.closingDateTime).format('DD/MM/YYYY HH:mm')
        ),
        set(
          lensPath(['cashierDetails', 'transactionsCount']),
          action.data.transactions ? action.data.transactions.length : null
        ),
        set(
          lensPath(['cashierDetails', 'paymentMethodsAmounts']),
          action.data.paymentMethodsAmounts || null
        ),
        set(
          lensPath(['cashierDetails', 'totalAmount']),
          convertToReal(action.data.totalAmount)
        ),
        set(
          lensPath(['cashierDetails', 'closingPrintAbstract']),
          action.data.closingPrintAbstract || null
        ),
        set(
          lensPath(['cashierDetails', 'closingObservation']),
          action.data.closingObservation || null
        ),
        set(
          lensPath(['cashierDetails', 'sequence']),
          action.data.sequence || 1
        )
      )(state);
    }
    case SET_STORE_CASHIER_ID: {
      return {
        ...state,
        storeCashierId: action.id
      }
    }
    case SET_CASHIER_ID: {
      return {
        ...state,
        cashierId: action.id
      }
    }
    case SET_REMOVE: {
      return set(lensProp('isRemoving'), true, state);
    }
    case CLEAR_CHANGES: {
      return compose(
        set(lensProp('isRemoving'), false),
        set(lensPath(['submit', 'userPassword']), ''),
        set(lensPath(['submit', 'reason']), '')
      )(state);
    }
    default:
      return state;
  }
}