import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Button from '../../../components/Button';
import MTable from '../../../components/MTable';

import CostCenterRegistration from './CostCenterRegistration';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { loadFilters } from '../../../components/Filter/store/thunk';
import { loadCostCenters } from '../store/thunk';
import { handleRegistration, handleUpdate, clearRegistration } from '../store/actions';
import { formatFilters } from '../../../helpers/formatters';

import './CostCenters.scss';

const headers = [{ title: 'Nome', field: 'costCenterName', sortable: false }];
const width = window.innerWidth;
const tableRowWidth = width < 768 ? 150 : 250;

export default function CostCenters() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { costCenters, total, isLoading, registration } = useSelector(state => state.costCenters);
  const { selects, qp } = useSelector(state => state.filters);

  const filters = formatFilters(['status'], selects);

  const [costCentersPage, setCostCentersPage] = useState(1);
  const costCentersPageSize = 10;
  const costCentersPageCount = Math.ceil(total / costCentersPageSize);

  const requestLoadCostCenters = ({ page, qp }) => {
    dispatch(loadCostCenters({ userId, establishmentId, page, pageSize: costCentersPageSize, qp }));
  }

  useEffect(() => {
    if(establishmentId) {
      requestLoadCostCenters({ page: 1, qp });
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    if(establishmentId) {
      requestLoadCostCenters({ page: costCentersPage, qp });
    }
  }, [costCentersPage]);

  const handleRegister = () => {
    dispatch(clearRegistration());
    dispatch(handleRegistration(true));
  }

  const handleEdit = costCenter => {
    dispatch(handleUpdate(costCenter));
  }

  return (
    <>
      {registration && <CostCenterRegistration isOpen={registration} />}
      <Paper>
        {isMobile ? (
          <FilterMobile
            filter
            hasSearchTerm
            filters={filters}
            handlePageRequest={requestLoadCostCenters}
            actionButton={
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={handleRegister}
              >
                Adicionar Centro de Custo
              </Button>
            }
          />
        ) : (
          <Filter
            filter
            hasSearchTerm
            filters={filters}
            handlePageRequest={requestLoadCostCenters}
            actionButton={
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={handleRegister}
              >
                Adicionar Centro de Custo
              </Button>
            }
          />
        )}
        <MTable
          loading={isLoading}
          headers={headers}
          data={costCenters}
          actionColumnWidth={tableRowWidth}
        />
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={costCentersPageCount}
            page={costCentersPage}
            onChange={(_, page) => setCostCentersPage(page)}
          />
        </Grid>
      </Paper>
    </>
  );
}