import qs from 'qs';
import { newAPI } from '../../../../../services/newAPI';

export const createServiceNotificationConfig = async params => {
  const response = await newAPI.post(`/${params.userId}/establishment/${params.establishmentId}/notifications/settings/new`, qs.stringify(params.notificationShape));
  return response.data;
}

export const updateServiceNotificationConfig = async params => {
  const response = await newAPI.put(`${params.userId}/establishment/${params.establishmentId}/notifications/settings/${params.notificationSettingId}`, qs.stringify(params.notificationShape));
  return response.data;
}