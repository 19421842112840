import {
  SET_VEHICLE_DAMAGE_LIST,
  SET_VEHICLE,
  SET_IS_LOADING
} from './constants';

const defaultState = {
  list: [],
  vehicle: {},
  isLoading: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_VEHICLE_DAMAGE_LIST: {
      return {
        ...state,
        list: action.result
      }
    }
    case SET_VEHICLE: {
      return {
        ...state,
        vehicle: action.data
      }
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    default: {
      return state;
    }
  }
}