import {
  SET_STORAGE_HISTORY_RESUME,
  SET_STORAGE_SELL_HISTORY_RESUME,
  SET_STORAGE_HISTORY_RESUME_LOADING,
  SET_STORAGE_HISTORY_LIST,
  SET_STORAGE_HISTORY_LIST_LOADING,
  SET_STORAGE_HISTORY_MODAL_LOADING,
  SET_STORAGE_HISTORY_ERRORS,
  CLEAR_STORAGE_HISTORY_ERRORS
} from './constants';

const defaultState = {
  list: [],
  total: 0,
  isLoading: false,
  resume: {
    toUse: {
      previousQuantity: 0,
      entries: 0,
      exits: 0,
      currentQuantity: 0
    },
    toSell: {
      previousQuantity: 0,
      entries: 0,
      exits: 0,
      currentQuantity: 0
    }
  },
  isResumeLoading: false,
  isModalLoading: false,
  errors: {
    quantity: null
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_STORAGE_HISTORY_RESUME: {
      return {
        ...state,
        resume: {
          ...state.resume,
          toUse: action.data
        }
      }
    }
    case SET_STORAGE_SELL_HISTORY_RESUME: {
      return {
        ...state,
        resume: {
          ...state.resume,
          toSell: action.data
        }
      }
    }
    case SET_STORAGE_HISTORY_RESUME_LOADING: {
      return {
        ...state,
        isResumeLoading: action.isLoading
      }
    }
    case SET_STORAGE_HISTORY_LIST: {
      const { list, total } = action.data;

      return {
        ...state,
        list,
        total
      }
    }
    case SET_STORAGE_HISTORY_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_STORAGE_HISTORY_MODAL_LOADING: {
      return {
        ...state,
        isModalLoading: action.isLoading
      }
    }
    case SET_STORAGE_HISTORY_ERRORS: {
      return {
        ...state,
        errors: {
          quantity: action?.errors?.quantity ? action?.errors?.quantity[0] : null
        }
      }
    }
    case CLEAR_STORAGE_HISTORY_ERRORS: {
      return {
        ...state,
        errors: defaultState.errors
      }
    }
    default: {
      return state;
    }
  }
}