import * as types from './constants';

export const setProductFilters = filters => ({
  type: types.SET_PRODUCT_FILTERS,
  filters
});

export const setProductList = data => ({
  type: types.SET_PRODUCT_LIST,
  data
});

export const setProductListLoading = isLoading => ({
  type: types.SET_PRODUCT_LIST_LOADING,
  isLoading
});