import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '../Button';

import { useStyles } from './styles';

export default function FileUploader({
  id,
  file,
  onChange,
  isLoading,
  errors,
  children,
  displayFileName = true,
  multiple = false,
  fullWidth = false,
  color = 'primary',
  variant = 'contained',
  startIcon,
  disabled
}) {
  const classes = useStyles();

  const inputFile = useRef(null);

  const handleUploadFile = () => {
    inputFile.current.click();
  }

  return(
    <>
      <Button
        fullWidth={fullWidth}
        type="button"
        color={color}
        variant={variant}
        loading={isLoading}
        startIcon={startIcon}
        disabled={disabled}
        onClick={() => handleUploadFile()}
      >
        {children}
      </Button>
      {displayFileName && (
        <Typography
          variant="subtitle2"
          color="textSecondary"
          display="inline"
          className={classes.root}
        >
          {file ? file.name : 'Nenhum arquivo selecionado...'}
        </Typography>
      )}
      <input
        multiple={multiple}
        id={id}
        type="file"
        ref={inputFile}
        onChange={onChange}
        style={{ display: 'none' }}
      />
      {errors ? (
        <Typography variant="subtitle2" color="error">
          {errors}
        </Typography>
      ) : null}
    </>
  );
}

FileUploader.propTypes = {
  id: PropTypes.string.isRequired,
  file: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  errors: PropTypes.string,
  displayFileName: PropTypes.bool,
  multiple: PropTypes.bool
};

FileUploader.defaultProps = {
  isLoading: false,
  errors: '',
  file: null
};