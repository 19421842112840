import * as type from './constants';

export const setServiceOrders = (data, total) => ({
  type: type.SET_SERVICE_ORDERS,
  data,
  total
});

export const setResumeOrders = resume => ({
  type: type.SET_RESUME_SERVICE_ORDERS,
  resume
});

export const toggleServiceOrder = status => ({
  type: type.TOGGLE_SERVICE_ORDER,
  status
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status
});

export const handleResumeLoading = status => ({
  type: type.HANDLE_RESUME_LOADING,
  status
});