import { compose, pick, set, over, lensProp, lensPath } from 'ramda';
import {
  REGISTER_FIELD_CHANGE,
  SET_BASIC_INFO,
  SET_CLIENT_ID,
  SET_ADDRESS,
  CLEAR_ADDRESS,
  CLEAR_BASIC_INFORMATION,
  HANDLE_POSTAL_CODE,
  HANDLE_LOADING,
  SET_IS_EDIT
} from './constants';

const defaultState = {
  clientId: null,
  isLoading: false,
  isEdit: false,
  postalCodeState: {
    isLoading: false,
  },
  submit: {
    name: '',
    email: '',
    phones: [''],
    dateOfBirth: '',
    documentId: '',
    documentIdType: 1,
    status: 1,
    postalCode: '',
    street: '',
    streetNumber: 0,
    streetComplement: '',
    district: '',
    city: '',
    state: '',
    comments: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      const setValue = value =>
        set(lensPath(['submit', action.field]), value, state);

      if (action.field === 'documentId') {
        const documentIdType = id =>
          compose(
            set(lensPath(['submit', action.field]), action.value),
            set(lensPath(['submit', 'documentIdType']), id)
          )(state);

        if (action.value.replace(/\D/g, '').length > 11) {
          return documentIdType(2);
        }

        return documentIdType(1);
      }

      if (action.field === 'phones') {
        return setValue([action.value]);
      }

      if (action.field === 'status') {
        return setValue(parseInt(action.value, 10));
      }

      return setValue(action.value);
    }
    case SET_BASIC_INFO: {
      const basicInfo = compose(
        pick([
          'name',
          'email',
          'phones',
          'dateOfBirth',
          'documentId',
          'documentIdType',
          'status',
          'postalCode',
          'street',
          'streetNumber',
          'streetComplement',
          'district',
          'city',
          'state',
          'comments',
          'typePricesLink',
          'servicesLink',
        ])
      );

      return compose(
        set(lensProp('isLoading'), false),
        set(lensProp('submit'), basicInfo(action.data))
      )(state);
    }
    case SET_IS_EDIT: {
      return {
        ...state,
        submit: {
          ...state.submit
        },
        isEdit: action.isEdit
      }
    }
    case SET_CLIENT_ID: {
      return set(lensProp('clientId'), action.clientId, state);
    }
    case SET_ADDRESS: {
      return {
        ...state,
        postalCodeState: {
          isLoading: false,
        },
        submit: {
          ...state.submit,
          street: action.address.street,
          district: action.address.district,
          city: action.address.city,
          state: action.address.state,
        },
      };
    }
    case CLEAR_ADDRESS: {
      return {
        ...state,
        postalCodeState: defaultState.postalCodeState,
        submit: {
          ...state.submit,
          street: null,
          number: null,
          complement: null,
          district: null,
          city: null,
          state: null,
        },
      };
    }
    case HANDLE_POSTAL_CODE: {
      return {
        ...state,
        postalCodeState: {
          ...state.postalCodeState,
          isLoading: action.status,
        },
      };
    }
    case HANDLE_LOADING: {
      return {
        ...state,
        isLoading: action.status,
      };
    }
    case CLEAR_BASIC_INFORMATION: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
