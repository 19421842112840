import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
  },
  contentError: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '60px',
  },
  errorText: {
    margin: 0,
    padding: 0,
    fontSize: '2.3em',
  },
  errorTitle: {
    margin: 0,
    padding: 0,
    fontSize: '12.4em',
    fontWeight: '400',
    lineHeight: '0.8em',
    alignSelf: 'center',
  },
  errorDescription: {
    margin: 0,
    padding: 0,
    fontSize: '2.3em',
    alignSelf: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: '1.4em',
  },
  actions: {
    textAlign: 'center',
    fontSize: '1.4em',
  },
});

// Error Boundery Styles
export const styles = () => ({
  root: {
    backgroundColor: '#f1f4f9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {},
  contentError: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '60px',
  },
  errorText: {
    margin: 0,
    padding: 0,
    fontSize: '2.3em',
    color: '#1E5168',
  },
  errorTitle: {
    margin: 0,
    padding: 0,
    fontSize: '12.4em',
    fontWeight: '400',
    lineHeight: '0.8em',
    alignSelf: 'center',
    color: '#1E5168',
  },
  errorDescription: {
    margin: 0,
    padding: 0,
    fontSize: '2.3em',
    alignSelf: 'center',
    color: '#1E5168',
  },
  description: {
    textAlign: 'center',
    fontSize: '1.4em',
  },
  actions: {
    textAlign: 'center',
    fontSize: '1.4em',
  },
});
