import { api, changeBaseAPI, setAPI, setBaseAPI } from './api';
import { newAPI, changeBaseNewAPI, setNewAPI, setBaseNewAPI } from './newAPI';

const setToken = token => {
  api.defaults.headers.common['Authorization'] = token;
  newAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export { api, changeBaseAPI, setAPI, setBaseAPI }
export { newAPI, changeBaseNewAPI, setNewAPI, setBaseNewAPI }
export { setToken }