import * as type from './constants';

export const setEmployeesList = (data, total) => ({
  type: type.SET_EMPLOYEES_LIST,
  data,
  total
});

export const setEmployeeRegister = employee => ({
  type: type.SET_EMPLOYEE_REGISTER,
  employee
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status
});