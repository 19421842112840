import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, Typography, TextField, FormControlLabel, Switch } from '@material-ui/core';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import ExportPDF from 'components/ExportPDF';
import SingleDatePicker from 'components/SingleDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { convertToReal, convertDateToDB, convertFormattedMoneyToNumber } from 'helpers/converters';

import { loadComissionedFilters } from 'pages/ComissionedList/store/thunk';
import { loadComissionedPayrollListSettings } from 'pages/ComissionedPayrollList/store/thunk';
import { loadTransaction, loadResume } from 'pages/Transactions/store/thunk';

import {
  updatePayrollSheet,
  closePayrollSheet,
  loadPayrollSheets,
  loadPayrollSheetExtract,
  payPayrollSheet,
  undoPayPayrollSheet
} from 'pages/ComissionedPayrollSheet/store/thunk';

export default function PayrollSheet({ isPayrollSheetModalOpen, setIsPayrollSheetModalOpen, pageOrigin }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishments } = useSelector(state => state.businessInfo);
  const { submit, isSinglePayrollSheetLoading } = useSelector(state => state.comissionedPayrollSheet);
  const commissionedUuid = useSelector(state => state.comissionedPayrollSheet.commissionedUuid);
  const { qp } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);

  const commissionProfiles = useSelector(state => state.comissionedList.filters?.commissionProfiles)?.options?.map(profile => {
    return {
      label: profile?.name,
      value: profile?.id
    }
  });

  const establishmentUuid = establishments?.find(establishment => establishment?.establishmentId == establishmentId)?.uuid;

  const isPayrollSheetClosed = !!submit?.uuid;
  const isPayrollSheetPaid = !!submit?.transactionId;

  const [isClosingPayrollSheet, setIsClosingPayrollSheet] = useState(isPayrollSheetClosed);
  const [confirmClosingPayrollSheet, setConfirmClosingPayrollSheet] = useState(false);
  const [confirmPayPayrollSheet, setConfirmPayPayrollSheet] = useState(isPayrollSheetClosed);
  const [confirmUndoPayPayrollSheet, setConfirmUndoPayPayrollSheet] = useState(false);

  const [payrollSheetData, setPayrollSheetData] = useState({
    closingDate: null,
    dueDate: null,
    name: null,
    userName: null,
    profileId: null,
    amount: null,
    paidAmount: null,
    partialPayment: null,
    paymentDateTime: null,
    registerDateTime: null,
    situationId: null,
    transactionId: null,
    transaction: {},
    uuid: null
  });

  const [payrollSheetUndoPaymentData, setPayrollSheetUndoPaymentData] = useState({
    reason: null,
    userPassword: null
  });

  useEffect(() => {
    setPayrollSheetData({
      ...submit,
      paidAmount: convertToReal(convertFormattedMoneyToNumber(submit.amount)),
      paymentDateTime: submit?.paymentDateTime ? submit?.paymentDateTime : moment(),
      closingDate: submit?.closingDate ? submit?.closingDate : moment()
    });
  }, [submit]);

  useEffect(() => {
    setConfirmPayPayrollSheet(isPayrollSheetClosed);
  }, [isPayrollSheetClosed]);

  useEffect(() => {
    if(userId) {
      dispatch(loadComissionedFilters({ userId, establishmentId }));
    }
  }, [userId]);

  const loadTransactionsAndResume = () => {
    dispatch(
      loadTransaction({
        qp,
        page: page || 1,
        pageSize: pageSize || 10,
        extraProps: { userId, establishmentId, startDate, endDate }
      })
    );

    dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp }));
  }

  const handleClosePayrollSheet = () => {
    const params = {
      closingDate: moment(payrollSheetData.closingDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      commissionedUuid,
      establishmentUuid
    }

    setConfirmClosingPayrollSheet(false);

    dispatch(closePayrollSheet(userId, establishmentId, params, qp))
      .finally(() => setIsPayrollSheetModalOpen(false));
  }

  const handleLoadPayrollSheetExtract = () => {
    return dispatch(loadPayrollSheetExtract(userId, establishmentId, submit.uuid));
  }

  const handlePayrollSheetPayment = () => {
    const paymentDate = moment(payrollSheetData.paymentDateTime, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const paymentTime = moment().format('HH:mm:ss');

    const params = {
      paymentDateTime: `${paymentDate} ${paymentTime}`,
      closingDate: moment(payrollSheetData.closingDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      paidAmount: convertFormattedMoneyToNumber(payrollSheetData.paidAmount)
    }

    dispatch(payPayrollSheet(userId, establishmentId, submit?.uuid, params))
      .then(() => {
        dispatch(loadPayrollSheets(userId, establishmentId, commissionedUuid));
        dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        setIsPayrollSheetModalOpen(false);
      });
  }

  const handleUndoPayrollSheetPayment = () => {
    dispatch(undoPayPayrollSheet(userId, establishmentId, submit?.uuid, payrollSheetUndoPaymentData))
      .then(() => {
        dispatch(loadPayrollSheets(userId, establishmentId, commissionedUuid));
        dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page: 1, limit: 10, qp: '' }));

        if(pageOrigin == 'transactions') {
          loadTransactionsAndResume();
        }

        setIsPayrollSheetModalOpen(false);
      });
  }

  const handleUpdatePayrollSheet = () => {
    const params = {
      dueDate: moment(payrollSheetData?.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }

    dispatch(updatePayrollSheet(userId, establishmentId, submit?.uuid, params))
      .then(() => {
        dispatch(loadPayrollSheets(userId, establishmentId, commissionedUuid));
        dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page: 1, limit: 10, qp: '' }));
        setIsPayrollSheetModalOpen(false);
      });
  }

  return(
    <Modal
      id='payrollSheet'
      title='Folha de comissão'
      scroll='body'
      maxWidth='md'
      open={isPayrollSheetModalOpen}
      onClose={() => setIsPayrollSheetModalOpen(false)}
    >
      {confirmClosingPayrollSheet && (
        <Modal
          id='payrollSheet'
          title='Folha de comissão'
          scroll='body'
          maxWidth='md'
          open={confirmClosingPayrollSheet}
          onClose={() => setConfirmClosingPayrollSheet(false)}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Deseja gerar a folha de comissão?
              </Typography>
              <Typography variant='body1'>
                Esta ação não pode ser desfeita.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 10 }}>
              <Button
                type='button'
                color='primary'
                onClick={() => setConfirmClosingPayrollSheet(false)}
              >
                Cancelar
              </Button>
              <Button
                type='button'
                color='success'
                onClick={handleClosePayrollSheet}
              >
                Sim
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
      <Loader isLoading={isSinglePayrollSheetLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={isPayrollSheetClosed ? 4 : 6} spacing={2}>
            <Input
              disabled
              name='name'
              label='Funcionário'
              value={payrollSheetData.name}
            />
          </Grid>
          {isPayrollSheetClosed && (
            <Grid item xs={12} md={isPayrollSheetClosed ? 4 : 6} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={isPayrollSheetPaid || isPayrollSheetClosed}
                  autoFocus={false}
                  label='Data de fechamento'
                  inputFormat='DD/MM/YYYY'
                  value={payrollSheetData.closingDate}
                  onChange={date => setPayrollSheetData({ ...payrollSheetData, closingDate: date })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      name='closingDate'
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps
                      }}
                      SelectProps={{
                        MenuProps: {
                          disableEnforceFocus: true
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {isPayrollSheetClosed && (
            <Grid item xs={12} md={4} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={isPayrollSheetPaid}
                  autoFocus={false}
                  label='Data de vencimento'
                  inputFormat='DD/MM/YYYY'
                  value={payrollSheetData.dueDate}
                  onChange={date => setPayrollSheetData({ ...payrollSheetData, dueDate: date })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      name='dueDate'
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps
                      }}
                      SelectProps={{
                        MenuProps: {
                          disableEnforceFocus: true
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          <Grid item xs={12} md={6} spacing={2}>
            <Select
              disabled
              name='profile'
              label='Perfil'
              value={payrollSheetData.profileId}
              options={commissionProfiles}
            />
          </Grid>
          {isPayrollSheetClosed && (
            <Grid item xs={12} md={6} spacing={2}>
              <Input
                disabled
                name='amount'
                label='Valor'
                type='money'
                value={`R$ ${payrollSheetData.amount || '0.00'}`}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>
        {!isPayrollSheetClosed && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                id='closePayrollSheet'
                name='closePayrollSheet'
                label='Gerar folha de comissão'
                control={
                  <Switch
                    color='primary'
                    onChange={() => setIsClosingPayrollSheet(!isClosingPayrollSheet)}
                    checked={isClosingPayrollSheet}
                  />
                }
              />
            </Grid>
            {(isClosingPayrollSheet || isPayrollSheetClosed) && (
              <>
                <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <SingleDatePicker
                    name='closingDate'
                    label='Data de fechamento'
                    initialDate={convertDateToDB(payrollSheetData.closingDate) || ''}
                    onDateChange={date => setPayrollSheetData({ ...payrollSheetData, closingDate: date })}
                  />
                </Grid>
                <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ marginRight: 10 }}
                    type='button'
                    color='primary'
                    onClick={() => setConfirmClosingPayrollSheet(true)}
                  >
                    Confirmar
                  </Button>
                  <Button
                    type='button'
                    color='error'
                    variant='outlined'
                    onClick={() => setIsClosingPayrollSheet(false)}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {(isPayrollSheetPaid || isPayrollSheetClosed) && (
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '15px 0px' }}>
            <Grid>
              {payrollSheetData.userName && (
                <Typography>Gerado por {payrollSheetData.userName} dia {moment(payrollSheetData?.registerDateTime?.split(' ')[0]).format('DD/MM/YYYY')} as {payrollSheetData?.registerDateTime?.split(' ')[1]}</Typography>
              )}
              {isPayrollSheetPaid && (
                <Typography>Pago por {payrollSheetData.transaction?.userName} dia {moment(payrollSheetData.transaction?.paymentDateTime?.split(' ')[0]).format('DD/MM/YYYY')} as {payrollSheetData.transaction?.paymentDateTime?.split(' ')[1]}</Typography>
              )}
            </Grid>
          </Grid>
        )}
        {confirmPayPayrollSheet && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={isPayrollSheetPaid}
                  autoFocus={false}
                  label='Data de pagamento'
                  inputFormat='DD/MM/YYYY'
                  value={payrollSheetData.paymentDateTime}
                  onChange={date => setPayrollSheetData({ ...payrollSheetData, paymentDateTime: date })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      name='paymentDateTime'
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps
                      }}
                      SelectProps={{
                        MenuProps: {
                          disableEnforceFocus: true
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} spacing={2}>
              <Input
                disabled={isPayrollSheetPaid}
                name='paidAmount'
                label='Valor'
                type='money'
                value={convertToReal(convertFormattedMoneyToNumber(payrollSheetData.paidAmount))}
                onChange={event => setPayrollSheetData({ ...payrollSheetData, paidAmount: event.target.value })}
              />
            </Grid>
          </Grid>
        )}
        {confirmUndoPayPayrollSheet && (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input
                name='reason'
                label='Motivo do cancelamento'
                type='text'
                value={payrollSheetUndoPaymentData.reason}
                onChange={event => setPayrollSheetUndoPaymentData({ ...payrollSheetUndoPaymentData, reason: event.target.value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                name='userPassword'
                label='Confirme sua senha'
                type='password'
                value={payrollSheetUndoPaymentData.userPassword}
                onChange={event => setPayrollSheetUndoPaymentData({ ...payrollSheetUndoPaymentData, userPassword: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ marginRight: 10 }}
                type='button'
                color='error'
                variant='outlined'
                onClick={() => setConfirmUndoPayPayrollSheet(false)}
              >
                Cancelar
              </Button>
              <Button
                type='button'
                color='primary'
                onClick={handleUndoPayrollSheetPayment}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        )}
        {!confirmUndoPayPayrollSheet && (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
              {isPayrollSheetClosed && (
                <ExportPDF
                  type='button'
                  color='tertiary'
                  variant='outlined'
                  exportType='receiptComissioned'
                  requestData={handleLoadPayrollSheetExtract}
                >
                  Extrato
                </ExportPDF>
              )}
              {(isPayrollSheetClosed && !isPayrollSheetPaid) && (
                <Button
                  type='button'
                  color='success'
                  onClick={handleUpdatePayrollSheet}
                >
                  Salvar
                </Button>
              )}
              {(confirmPayPayrollSheet && !isPayrollSheetPaid) && (
                <Button
                  type='button'
                  color='primary'
                  onClick={handlePayrollSheetPayment}
                >
                  Pagar Comissionamento
                </Button>
              )}
              {isPayrollSheetPaid && (
                <Button
                  type='button'
                  color='error'
                  variant='outlined'
                  onClick={() => setConfirmUndoPayPayrollSheet(true)}
                >
                  Desfazer Pagamento
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Loader>
    </Modal>
  );
}