export const nameInput = {
  advanced: false,
  type: 'input',
  id: 'clientName',
  label: 'Nome',
  value: ''
}

export const documentInput = {
  advanced: true,
  type: 'input',
  maskType: 'document',
  placeholder: '000.000.000-00',
  id: 'document',
  label: 'CPF/CNPJ',
  value: ''
}

export const orderInput = {
  advanced: false,
  type: 'input',
  id: 'orderCode',
  label: 'N° Pedido',
  value: ''
}

export const productInput = {
  advanced: false,
  type: 'input',
  id: 'orderItemName',
  label: 'Nome do Produto',
  value: ''
}