import * as type from './constants';

export const setGraphics = data => ({
  type: type.SET_GRAPHICS,
  data
});

export const setOccupation = data => ({
  type: type.SET_OCCUPATION,
  data
});

export const setCashflow = data => ({
  type: type.SET_CASHFLOW,
  data
});

export const setPaidOut = data => ({
  type: type.SET_PAID_OUT,
  data
});

export const setCanceled = data => ({
  type: type.SET_CANCELED,
  data
});

export const setAltered = data => ({
  type: type.SET_ALTERED,
  data
});