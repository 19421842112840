import * as yup from 'yup';
import { phoneRegExp } from '../../../helpers/regex';

const withoutUserId = text => ({
  is: value => value === null,
  then: yup.string().required(text)
});

const agreeWithTerms = text => ({
  is: value => value === null,
  then: yup.bool().oneOf([true], text)
});

export const validations = yup.object().shape({
  userId: yup
    .number()
    .nullable(),
  name: yup
    .string()
    .when('userId', withoutUserId('O nome é obrigatório')),
  email: yup
    .string()
    .transform(function(value) {
      return value.trim();
    })
    .email('O e-mail precisa ser válido')
    .when('userId', withoutUserId('O e-mail é obrigatório')),
  emailConfirm: yup
    .string()
    .when(['email', 'userId'], (email, userId, schema) =>
      !userId && email ? schema.required('Confirme o email') : schema
    )
    .test({
      message: 'O email e a confirmação devem ser iguais',
      test: function(value) {
        if(this.parent.isProfile) {
          return true;
        }

        return value?.trim()?.toLowerCase() == this.parent.email?.trim()?.toLowerCase();
      }
    }),
  phone: yup
    .string()
    .required('O telefone é obrigatório')
    .test(function (value) {
      if(!phoneRegExp?.test(value)) {
        return this.createError({
          message: 'O telefone precisa ser válido',
          path: 'phone'
        });
      }

      const isPhoneInvalid =
        value?.includes('(55) 1') ||
        value?.includes('(55) 2') ||
        value?.includes('(55) 3') ||
        value?.includes('(55) 4') ||
        value?.includes('(55) 5') ||
        value?.includes('(55) 6') ||
        value?.includes('(55) 7') ||
        value?.includes('(55) 8');

      if(isPhoneInvalid) {
        return this.createError({
          message: 'Por favor, escreva seu telefone no formato DDD + número de celular',
          path: 'phone'
        });
      }

      return true;
    }),
  password: yup
    .string()
    .when('userId', withoutUserId('A senha é obrigatória')),
  passwordConfirm: yup
    .string()
    .when('password', (password, schema) =>
      password ? schema.required('Confirme a senha') : schema
    )
    .oneOf(
      [yup.ref('password'), null],
      'A senha e a confirmação devem ser iguais'
    ),
  termsOfUse: yup
    .boolean()
    .when('userId', agreeWithTerms('Aceite os termos de uso para avançar')),
  recaptcha: yup
    .boolean()
    .when('userId', agreeWithTerms('Você precisa verificar o reCAPTCHA'))
});