import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { convertToBrl } from 'helpers/converters';
import { formatPermanence, formatVehicle } from 'helpers/formatters';
import { getImageExtensionFromBase64 } from 'helpers/common';

import { styles } from '../styles';

const TableColumnsHeaderItems = ['Nº', 'Placa', 'Modelo', 'Data Entrada', 'Data Saída', 'Permanência', 'Serviços', 'Observação', 'Valor'];
const TableColumnsNumber = TableColumnsHeaderItems.length;
const TableColumnsWidth = 100 / TableColumnsNumber;

const ReceiptServiceOrderInvoice = ({ data }) => {
  const {
    amount,
    closingAmount,
    closingDateTime,
    dueDateTime,
    paymentDateTime,
    establishmentName,
    base64Logo,
    clientName,
    serviceOrders
  } = data;

  const base64Extension = getImageExtensionFromBase64(base64Logo);

  return(
    <Document>
      <Page style={{ padding: 10 }}>
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -11 }}>
          <View style={[styles.table, { display: 'flex', flexDirection: 'column', width: '30%' }]}>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize13, { padding: 5 }]}>Extrato da Fatura</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Cliente: {clientName}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Fornecedor: {establishmentName}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Valor inicial da fatura: {convertToBrl(+amount || 0)}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Vencimento: {moment(dueDateTime).format('DD/MM/YYYY')}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Fechamento: {moment(closingDateTime).format('DD/MM/YYYY')}</Text>
            </View>
            {paymentDateTime != '0001-01-01 00:00:00' && (
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Pagamento: {moment(paymentDateTime).format('DD/MM/YYYY HH:mm:ss')}</Text>
              </View>
            )}
          </View>
          <View style={[styles.table, styles.tableCol, { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '70%' }]}>
            {!!base64Logo && (
              <Image
                style={{
                  objectFit: 'contain',
                  height: 100
                }}
                src={`data:image/${base64Extension};base64,${base64Logo}`}
              />
            )}
          </View>
        </View>
        <View style={[styles.table, { display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -11 }]}>
          <View style={{ display: 'flex', flexDirection: 'row', width: `${(100 / TableColumnsNumber) * 6}%` }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Total de OS</Text>
              </View>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>{serviceOrders?.length || 0}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Total de Faturamento</Text>
              </View>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>{convertToBrl(+closingAmount || 0)}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', justifyContent: 'center', width: `${(100 / TableColumnsNumber) * 3}%` }]}>
            <Text style={[styles.fontSize8, styles.tableCell]}>Total: {convertToBrl((parseFloat(amount) + parseFloat(closingAmount)) || 0)}</Text>
          </View>
        </View>
        {serviceOrders && (
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={[styles.table, styles.noMargin, { display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }]}>
              {TableColumnsHeaderItems.map(tableHeader => {
                return(
                  <View style={[styles.tableColHeader, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCellHeader, styles.fontSize8]}>{tableHeader}</Text>
                  </View>
                );
              })}
            </View>
            {serviceOrders.map(serviceOrder => {
              return(
                <View style={[styles.table, styles.noMargin, styles.noBorderTop]}>
                  <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{serviceOrder.serviceOrderCode}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{serviceOrder.plate}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{formatVehicle(serviceOrder.vehicle)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{moment(serviceOrder.entryDateTime).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{moment(serviceOrder.exitDateTime).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{formatPermanence([serviceOrder.exitDateTime, serviceOrder.entryDateTime])}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      {serviceOrder?.services?.map(service => {
                        return(
                          <Text style={[styles.tableCell, styles.fontSize8, { marginTop: 5 }]}>
                            {service.description} - {convertToBrl(+service.amount)}
                          </Text>
                        );
                      })}
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{serviceOrder.observations.observation}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>
                        {convertToBrl(parseFloat(serviceOrder.amount) + parseFloat(serviceOrder.amountServices) - parseFloat(serviceOrder?.discountAmount))}
                      </Text>
                    </View>
                  </View>
                </View>
              )
            })}
          </View>
        )}
      </Page>
    </Document>
  );
}

export default ReceiptServiceOrderInvoice;