export const SET_PRODUCT = 'PRODUCTS_REGISTRATION/SET_PRODUCT';
export const SET_PRODUCT_ID = 'PRODUCTS_REGISTRATION/SET_PRODUCT_ID';
export const SET_PRODUCT_UUID = 'PRODUCTS_REGISTRATION/SET_PRODUCT_UUID';
export const SET_SUPPLIER = 'PRODUCTS_REGISTRATION/SET_SUPPLIER';
export const SET_SUPPLIERS = 'PRODUCTS_REGISTRATION/SET_SUPPLIERS';
export const SET_SUPPLIER_UUID = 'PRODUCTS_REGISTRATION/SET_SUPPLIER_UUID';
export const SET_SUPPLIER_ERRORS = 'PRODUCTS_REGISTRATION/SET_SUPPLIER_ERRORS';
export const CLEAR_SUPPLIER_ERRORS = 'PRODUCTS_REGISTRATION/CLEAR_SUPPLIER_ERRORS';
export const SET_PRODUCT_BASIC = 'PRODUCTS_REGISTRATION/SET_PRODUCT_BASIC';
export const SET_PRODUCT_STORAGE = 'PRODUCTS_REGISTRATION/SET_PRODUCT_STORAGE';
export const SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE = 'PRODUCTS_REGISTRATION/SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE';
export const SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS = 'PRODUCTS_REGISTRATION/SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS';
export const CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS = 'PRODUCTS_REGISTRATION/CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS';
export const SET_PRODUCT_LOADING = 'PRODUCTS_REGISTRATION/SET_PRODUCT_LOADING';
export const SET_SUPPLIER_LOADING = 'PRODUCTS_REGISTRATION/SET_SUPPLIER_LOADING';
export const CLEAR_PRODUCT = 'PRODUCTS_REGISTRATION/CLEAR_PRODUCT';
export const CLEAR_SUPPLIER = 'PRODUCTS_REGISTRATION/CLEAR_SUPPLIER';