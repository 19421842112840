import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchPaymentMethods = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const createPaymentMethod = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/paymentmethods/new`, qs.stringify(params));
  return response.data;
}

export const updatePaymentMethod = async (userId, establishmentId, params) => {
  const { paymentMethodId } = params;

  delete params['paymentMethodId'];

  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/paymentmethods/${paymentMethodId}`, qs.stringify(params));
  return response.data;
}

export const deletePaymentMethod = async (userId, establishmentId, paymentmethodId) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/paymentmethods/${paymentmethodId}`);
  return response.data;
}

export const fetchPrimitivePayment = async (userId, establishmentId, primitivePaymentMethodId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/primitivepaymentmethods/${primitivePaymentMethodId}`);
  return response.data;
}

export const fetchSinglePaymentMethod = async (userId, establishmentId, paymentMethodId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/paymentmethods/${paymentMethodId}`);
  return response.data;
}