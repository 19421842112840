import storage from 'redux-persist/lib/storage';
import allReducers from './reducer';
import { HANDLE_LOGOUT } from '../pages/Login/store/constants';

export const rootReducer = (state, action) => {
  let appState = state;
  if (action.type === HANDLE_LOGOUT) {
    localStorage.removeItem('persist:business');
    storage.removeItem('persist:business');
    appState = undefined;
  }
  return allReducers(appState, action);
};
