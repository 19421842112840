import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  iconOutlined: {
    color: palette.primary.main
  },
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 !important'
    },
    '& .MuiInputBase-root > span': {
      margin: '0px 0px 0px 10px !important',
      fontSize: '17px !important'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 2
  }
}));