import * as types from './constants';

export const setIsLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  isLoading
});

export const setSupplierList = data => ({
  type: types.SET_SUPPLIER_LIST,
  data
});