import * as yup from 'yup';

export const configRpsValidations = yup.object().shape({
  rpsSeries: yup
    .string()
    .required('A série é obrigatória')
    .nullable(),
  rpsNumber: yup
    .string()
    .required('A numeração é obrigatória')
    .nullable()
});