import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import { formatDate } from '../../../../helpers/formatters';

import { styles } from '../styles';

const Header = ({ dateRange, fileName, establishmentName }) => {
  const page = fileName.split('_').join(' ');

  return (
    <View style={styles.headerRow}>
      <Text style={styles.title}>{page}</Text>
      <Text style={styles.label}>
        Estabelecimento: <Text style={styles.text}>{establishmentName}</Text>
      </Text>
      <Text style={styles.label}>
        Período:{' '}
        <Text style={styles.text}>
          {formatDate(dateRange[0])} - {formatDate(dateRange[1])}{' '}
        </Text>
      </Text>
    </View>
  );
};

Header.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fileName: PropTypes.string.isRequired,
  establishmentName: PropTypes.string.isRequired,
};

export default Header;
