import {
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_PRODUCT_UUID,
  SET_PRODUCT_BASIC,
  SET_PRODUCT_STORAGE,
  SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE,
  SET_PRODUCT_CONSUMPTION,
  CLEAR_PRODUCT_CONSUMPTION,
  SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS,
  CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS,
  SET_PRODUCT_CONSUMPTION_RESPONSE_ERRORS,
  CLEAR_PRODUCT_CONSUMPTION_RESPONSE_ERRORS,
  SET_PRODUCT_LOADING,
  CLEAR_PRODUCT,
  SET_MEASUREMENT_UNIT_GROUP_ID,
  SET_IS_CONSUMPTION_MODIFIED
} from './constants';

const defaultState = {
  isLoading: false,
  isConsumptionModified: false,
  productId: '',
  productUuid: '',
  measurementUnitGroupId: '',
  basic: {
    name: '',
    visible: '1',
    storageId: '',
    productBrandId: '',
    productTypeId: '',
    areasOfUse: [],
    measurementUnitId: '',
    netContent: ''
  },
  storage: {
    quantityToUse: '',
    minQuantityToUse: ''
  },
  basicAndStorageResponse: {
    storageProductUuid: '',
    productUuid: '',
    productTypeUuid: '',
    productTypeDescription: '',
    productName: '',
    measurementUnitUuid: '',
    measurementUnitName: '',
    netContent: ''
  },
  consumption: {
    consumptionByService: [
      {
        serviceId: '',
        measurementUnitIdConsumptionService: '',
        toBeUsedContent: ''
      }
    ]
  },
  basicAndStorageResponseErrors: {
    name: null,
    productTypeId: null,
    productBrandId: null,
    areasOfUse: null,
    storageId: null,
    quantityToUse: null,
    minQuantityToUse: null,
    measurementUnitId: null
  },
  consumptionResponseErrors: {
    serviceId: null,
    measurementUnitId: null,
    toBeUsedContent: null
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_PRODUCT: {
      const {
        productId,
        storageProductUuid,
        name,
        visible,
        storageId,
        productBrandId,
        productTypeId,
        areasOfUse,
        measurementUnitId,
        netContent,
        quantityToUse,
        minQuantityToUse
      } = action.data;

      return {
        ...state,
        productId,
        basic: {
          name,
          visible,
          storageId,
          productBrandId,
          productTypeId,
          areasOfUse,
          measurementUnitId,
          netContent
        },
        storage: {
          quantityToUse,
          minQuantityToUse
        },
        basicAndStorageResponse: {
          ...state.basicAndStorageResponse,
          storageProductUuid
        }
      }
    }
    case SET_PRODUCT_ID: {
      return {
        ...state,
        productId: action.id
      }
    }
    case SET_PRODUCT_UUID: {
      return {
        ...state,
        productUuid: action.uuid
      }
    }
    case SET_PRODUCT_BASIC: {
      return {
        ...state,
        basic: {
          ...action.data
        }
      }
    }
    case SET_PRODUCT_STORAGE: {
      return {
        ...state,
        storage: {
          ...action.data
        }
      }
    }
    case SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE: {
      const { uuid, storageProductUuid } = action.data;

      return {
        ...state,
        basicAndStorageResponse: {
          ...action.data,
          storageProductUuid: uuid || storageProductUuid
        }
      }
    }
    case SET_PRODUCT_CONSUMPTION: {
      const formattedProductConsumption = action.data?.map(product => {
        return {
          ...product,
          measurementUnitIdConsumptionService: +product?.measurementUnitId
        }
      });

      return {
        ...state,
        consumption: {
          consumptionByService: [
            ...formattedProductConsumption,
            {
              serviceId: '',
              measurementUnitIdConsumptionService: '',
              toBeUsedContent: ''
            }
          ]
        }
      }
    }
    case CLEAR_PRODUCT_CONSUMPTION: {
      return {
        ...state,
        consumption: {
          consumptionByService: [
            {
              serviceId: '',
              measurementUnitIdConsumptionService: '',
              toBeUsedContent: ''
            }
          ]
        }
      }
    }
    case SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS: {
      return {
        ...state,
        basicAndStorageResponseErrors: {
          name: action?.data?.name ? action?.data?.name[0] : null,
          productTypeId: action?.data?.productTypeId ? action?.data?.productTypeId[0] : null,
          productBrandId: action?.data?.productBrandId ? action?.data?.productBrandId[0] : null,
          areasOfUse: action?.data?.areasOfUse ? action?.data?.areasOfUse[0] : null,
          storageId: action?.data?.storageId ? action?.data?.storageId[0] : null,
          quantityToUse: action?.data?.quantityToUse ? action?.data?.quantityToUse[0] : null,
          minQuantityToUse: action?.data?.minQuantityToUse ? action?.data?.minQuantityToUse[0] : null,
          measurementUnitId: action?.data?.measurementUnitId ? action?.data?.measurementUnitId[0] : null
        }
      }
    }
    case CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS: {
      return {
        ...state,
        basicAndStorageResponseErrors: defaultState.basicAndStorageResponseErrors
      }
    }
    case SET_PRODUCT_CONSUMPTION_RESPONSE_ERRORS: {
      return {
        ...state,
        consumptionResponseErrors: {
          serviceId: action?.data?.serviceId ? action?.data?.serviceId[0] : null,
          measurementUnitId: action?.data?.measurementUnitId ? action?.data?.measurementUnitId[0] : null,
          toBeUsedContent: action?.data?.toBeUsedContent ? action?.data?.toBeUsedContent[0] : null
        }
      }
    }
    case CLEAR_PRODUCT_CONSUMPTION_RESPONSE_ERRORS: {
      return {
        ...state,
        consumptionResponseErrors: defaultState.consumptionResponseErrors
      }
    }
    case SET_PRODUCT_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case CLEAR_PRODUCT: {
      return defaultState;
    }
    case SET_MEASUREMENT_UNIT_GROUP_ID: {
      return {
        ...state,
        measurementUnitGroupId: action.id
      }
    }
    case SET_IS_CONSUMPTION_MODIFIED: {
      return {
        ...state,
        isConsumptionModified: action.flag
      }
    }
    default: {
      return state;
    }
  }
}