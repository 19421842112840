import { toast } from 'react-toastify';

import {
  setPayrollSheetListIsLoading,
  setIsSinglePayrollSheetLoading,
  setPayrollSheetList,
  setPayrollSheet
} from './actions';

import {
  fetchPayrollSheets,
  fetchSinglePayrollSheet,
  requestUpdatePayrollSheet,
  requestClosePayrollSheet,
  requestPayrollSheetExtract,
  requestPayPayrollSheet,
  requestUndoPayPayrollSheet
} from './services';

import { loadComissionedPayrollListSettings } from '../../ComissionedPayrollList/store/thunk';

export const loadPayrollSheets = (userId, establishmentId, uuid) => dispatch => {
  dispatch(setPayrollSheetListIsLoading(true));

  return fetchPayrollSheets(userId, establishmentId, uuid)
    .then(({ data }) => dispatch(setPayrollSheetList(data?.content)))
    .finally(() => dispatch(setPayrollSheetListIsLoading(false)));
}

export const loadSinglePayrollSheet = (userId, establishmentId, uuid) => dispatch => {
  dispatch(setIsSinglePayrollSheetLoading(true));

  return fetchSinglePayrollSheet(userId, establishmentId, uuid)
    .then(({ data }) => dispatch(setPayrollSheet(data)))
    .catch(() => toast.error('Houve um problema ao carregar a folha de comissão.'))
    .finally(() => dispatch(setIsSinglePayrollSheetLoading(false)));
}

export const updatePayrollSheet = (userId, establishmentId, uuid, params) => dispatch => {
  dispatch(setIsSinglePayrollSheetLoading(true));

  return requestUpdatePayrollSheet(userId, establishmentId, uuid, params)
    .then(() => toast.success('Folha de comissão atualizada com sucesso.'))
    .catch(() => toast.error('Houve um problema ao atualizar a folha de comissão.'))
    .finally(() => dispatch(setIsSinglePayrollSheetLoading(false)));
}

export const closePayrollSheet = (userId, establishmentId, params, qp) => dispatch => {
  dispatch(setIsSinglePayrollSheetLoading(true));

  return requestClosePayrollSheet(userId, establishmentId, params)
    .then(() => {
      dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page: 1, limit: 10, qp }));
      toast.success('Folha gerada com sucesso.');
    })
    .finally(() => dispatch(setIsSinglePayrollSheetLoading(false)));
}

export const loadPayrollSheetExtract = (userId, establishmentId, uuid) => dispatch => {
  dispatch(setIsSinglePayrollSheetLoading(true));

  return requestPayrollSheetExtract(userId, establishmentId, uuid)
    .then(({ data }) => data)
    .finally(() => dispatch(setIsSinglePayrollSheetLoading(false)));
}

export const payPayrollSheet = (userId, establishmentId, uuid, params) => dispatch => {
  dispatch(setIsSinglePayrollSheetLoading(true));

  return requestPayPayrollSheet(userId, establishmentId, uuid, params)
    .then(() => toast.success('Folha de comissão paga com sucesso.'))
    .finally(() => dispatch(setIsSinglePayrollSheetLoading(false)));
}

export const undoPayPayrollSheet = (userId, establishmentId, uuid, params) => dispatch => {
  dispatch(setIsSinglePayrollSheetLoading(true));

  return requestUndoPayPayrollSheet(userId, establishmentId, uuid, params)
  .then(() => toast.success('Pagamento da folha de comissão desfeito com sucesso.'))
    .finally(() => dispatch(setIsSinglePayrollSheetLoading(false)));
}