import axios from 'axios';
import qs from 'qs';
import { newAPI } from '../../../services';

const url = process.env.REACT_APP_NEW_BASE_API;

const superLogicaAPI = axios.create({
  baseURL: url,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export const fetchFilters = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchUsersSituation = async (userId, token) => {
  newAPI.defaults.headers.common['Authorization'] = token;

  const response = await superLogicaAPI.get(`${userId}/users/establishments/situation`);
  return response.data;
}

export const removeSuperLogicaDebitWarning = async (mainUserId, mainUserToken) => {
  const response = await newAPI.get(
    `${mainUserId}/deactivate/debit-warning`,
    {
      headers: {
        Authorization: mainUserToken
      }
    }
  );

  return response.data;
}

export const fetchSuperLogicaInvoices = async (isUserAdmin, userId, establishmentId) => {
  const url = isUserAdmin
    ? `${userId}/establishment/${establishmentId}/superlogica/situation`
    : `${userId}/superlogica/invoices`;

  const response = await newAPI.get(url);
  return response.data;
}

export const generateToken = async (userId, token, body) => {
  newAPI.defaults.headers.common['Authorization'] = token;

  const response = await newAPI.post(`${userId}/superlogica/token`, qs.stringify(body));
  return response.data;
}