import { groupWith, prop } from 'ramda';

import {
  SET_IS_SINGLE_PAYROLL_SHEET_LOADING,
  SET_PAYROLL_SHEET_EMPLOYEE_UUID,
  SET_COMMISSIONED_UUID,
  SET_PAYROLL_SHEET,
  CLEAR_PAYROLL_SHEET,
  SET_PAYROLL_SHEET_LIST,
  SET_PAYROLL_SHEET_LIST_IS_LOADING
} from './constants';

const defaultState = {
  submit: {
    closingDate: null,
    dueDate: null,
    name: null,
    userName: null,
    profileId: null,
    amount: null,
    paidAmount: null,
    partialPayment: null,
    paymentDateTime: null,
    registerDateTime: null,
    situationId: null,
    transactionId: null,
    transaction: {},
    uuid: null
  },
  payrollSheetEmployeeUuid: null,
  commissionedUuid: null,
  isSinglePayrollSheetLoading: false,
  isLoading: false,
  list: []
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_IS_SINGLE_PAYROLL_SHEET_LOADING: {
      return {
        ...state,
        isSinglePayrollSheetLoading: action.isLoading
      }
    }
    case SET_PAYROLL_SHEET_EMPLOYEE_UUID: {
      return {
        ...state,
        payrollSheetEmployeeUuid: action.uuid
      }
    }
    case SET_COMMISSIONED_UUID: {
      return {
        ...state,
        commissionedUuid: action.uuid
      }
    }
    case SET_PAYROLL_SHEET: {
      return {
        ...state,
        submit: {
          ...action.payroll
        }
      }
    }
    case CLEAR_PAYROLL_SHEET: {
      return {
        ...state,
        submit: defaultState.submit
      }
    }
    case SET_PAYROLL_SHEET_LIST: {
      const payrollByYears = groupWith((payroll, nextPayroll) => {
        const startDate = prop('dueDate', payroll).split('-')[0];
        const nextDate = prop('dueDate', nextPayroll).split('-')[0];
        return startDate === nextDate;
      });

      const formattedPayrolls = payrollByYears(action.data);

      return {
        ...state,
        list: formattedPayrolls
      }
    }
    case SET_PAYROLL_SHEET_LIST_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    default: {
      return state;
    }
  }
}