import { makeStyles } from '@material-ui/core/styles';

const cashFlowMdContainerHeight = 450;
const cashFlowContainerHeight = 650;

export const useStyles = makeStyles(({ palette, spacing }) => ({
  grid: {
    marginBottom: spacing(2),
  },
  container: {
    padding: spacing(2),
    height: '110px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cashFlowContainer: {
    padding: spacing(2),
    height: `${cashFlowContainerHeight}px`
  },
  cashFlowMdContainer: {
    padding: spacing(2),
    height: `${cashFlowMdContainerHeight}px`
  },
  footer: {
    color: palette.primary.main,
    backgroundColor: '#FFFFFF',
    padding: spacing(1),
    marginTop: 10,
    borderRadius: 5,
    borderBottom: `5px solid ${palette.primary.main}`,
    height: '35px'
  },
  progress: {
    height: 16,
    borderRadius: 5,
    marginTop: spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  },
  error: {
    backgroundColor: palette.error.main
  },
  success: {
    backgroundColor: palette.success.main
  }
}));