export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'plate',
    label: 'Placa',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'serviceOrderCode',
    label: 'Código da OS',
    value: ''
  }
];