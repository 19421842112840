import { newAPI } from '../../../services';

export const fetchTags = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/notifications/tags`);
  return response.data;
}

export const fetchCategories = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/notifications/categories`);
  return response.data.data;
}

export const fetchServiceConfig = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/notifications/settings/view_by_service?notificationCategoryId=${params.notificationCategoryId}&serviceId=${params.serviceId}`);
  return response?.data?.data;
}