import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { compose, over, set, lensProp, map, assoc, prop } from 'ramda';
import { SET_RESERVATIONS, HANDLE_LOADING } from './constants';
import { formatDateTime } from '../../../helpers/formatters';
import { convertToReal } from '../../../helpers/converters';

momentDurationFormatSetup(moment); // Start the moment duration plugin

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_RESERVATIONS: {
      const formatReservations = reservation => {
        const formatter = compose(
          assoc('reservationType', prop('reservationTypeId', reservation)),
          assoc('reservationSituation', prop('situationId', reservation)),
          assoc('user', prop('userId', reservation)),
          over(lensProp('phones'), phones => {
            const phone = JSON.parse(phones)
            return phone.length > 0 ? phone[0] : '--'
          }
          ),
          over(lensProp('amount'), convertToReal),
          over(lensProp('startDateTime'), formatDateTime),
          over(lensProp('endDateTime'), formatDateTime)
        );

        return formatter(reservation);
      };
      const list = map(formatReservations, action.data);
      const reservations = compose(
        set(lensProp('list'), list),
        set(lensProp('total'), action.total),
        set(lensProp('isLoading'), false)
      );

      return reservations(state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    default:
      return state;
  }
};
