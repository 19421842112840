import { compose, set, over, lensProp, pick } from 'ramda';
import { formatPhone } from '../../../helpers/formatters';

import {
  SET_EMPLOYEE_ID,
  SET_EMPLOYEE,
  SET_RESTRICTIONS,
  SET_EMPTY_EMPLOYEE,
  HANDLE_LOADING,
  HANDLE_RESTRICTIONS_LOADING
} from './constants';

const defaultState = {
  employeeId: null,
  mainUser: 0,
  selectOptions: {},
  isRestrictionsLoading: false,
  isLoading: false,
  restrictionsTypes: [],
  restrictionsApp: [],
  restrictionsUser: [],
  submit: {
    name: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    profileId: null,
    status: 1,
    establishments: [],
    availableEstablishments: [],
    userRestrictions: [],
    appRestrictions: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_ID: {
      return set(lensProp('employeeId'), action.employeeId, state);
    }
    case SET_RESTRICTIONS: {
      const { app, site } = action.data;

      return {
        ...state,
        restrictionsApp: app,
        restrictionsUser: site
      }
    }
    case SET_EMPTY_EMPLOYEE: {
      return defaultState;
    }
    case SET_EMPLOYEE: {
      const { userId, mainUser } = action.data;

      const formatEmployee = compose(
        over(lensProp('phone'), formatPhone),
        pick([
          'name',
          'email',
          'phone',
          'profileId',
          'establishments',
          'availableEstablishments',
          'userRestrictions',
          'appRestrictions',
          'status'
        ])
      );

      const employee = compose(
        set(lensProp('employeeId'), userId),
        set(lensProp('mainUser'), mainUser),
        set(lensProp('submit'), formatEmployee(action.data)),
        set(lensProp('isLoading'), false)
      );

      return employee(state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_RESTRICTIONS_LOADING: {
      return set(lensProp('isRestrictionsLoading'), action.status, state);
    }
    default:
      return state;
  }
}