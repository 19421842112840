import { assoc } from 'ramda';
import { toast } from 'react-toastify';

import {
  fetchSingleService,
  createService,
  updateService,
  deleteServices,
  fetchServices,
  updateVisibility,
  fetchServicesCategories
} from './services';

import {
  setServices,
  handleServiceUpdate,
  handleLoading,
  resetServicesSelected,
  setServicesCategories,
  clearForm
} from './actions';

export const loadServices = params => dispatch => {
  const { userId, establishmentId, page, pageSize, qp } = params;
  const url = `/services?page=${page}&limit=${pageSize}${qp}`;

  dispatch(handleLoading(true));
  dispatch(clearForm());

  return fetchServices(userId, establishmentId, url)
    .then(({ data }) => {
      dispatch(resetServicesSelected());
      dispatch(setServices(data.content, data.total));
      return data;
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadSingleService = (userId, establishmentId, serviceId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchSingleService(userId, establishmentId, serviceId)
    .then(({ data }) => {
      const service = assoc('isUpdating', true, data);
      dispatch(handleServiceUpdate(service));
    })
    .catch(() => toast.error('Não foi possível carregar o serviço.'))
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertService = (userId, establishmentId, serviceId, params) => dispatch => {
  dispatch(handleLoading(true));

  if(!serviceId) {
    return createService(userId, establishmentId, params)
      .then(() => toast.success('Serviço cadastrado com sucesso!'))
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateService(userId, establishmentId, serviceId, params)
    .then(() => toast.success('Serviço atualizado com sucesso!'))
    .finally(() => dispatch(handleLoading(false)));
}

export const removeServices = (userId, establishmentId, serviceIds) => dispatch => {
  const data = { data: { serviceIds } };

  dispatch(handleLoading(true));

  return deleteServices(userId, establishmentId, data)
    .then(() => {
      toast.success('Serviço(s) removido(s) com sucesso!');
      dispatch(resetServicesSelected());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const changeVisibility = (userId, establishmentId, serviceId, status, data) => dispatch => {
  dispatch(handleLoading(true));

  return updateVisibility(userId, establishmentId, serviceId, status, data)
    .then(() => toast.success('Serviço atualizado com sucesso!'))
    .finally(() => dispatch(handleLoading(false)));
}

export const loadServicesCategories = userId => dispatch => {
  dispatch(handleLoading(true));

  return fetchServicesCategories(userId)
    .then(res => dispatch(setServicesCategories(res.data)))
    .finally(() => dispatch(handleLoading(false)));
}