import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';

export default function Storage({ formikProps }) {
  const history = useHistory();

  const isEdit = history.location.pathname == '/editar-produto-venda';

  const { values, errors, handleChange } = formikProps;

  const { basicAndStorageResponseErrors, isLoading } = useSelector(state => state.productsSellRegistration);
  const { storages } = useSelector(state => state.products.filters);

  const formattedStorages = storages?.map(storage => {
    return {
      value: storage?.id,
      label: storage?.name
    }
  });

  return(
    <Card title="Cadastre as informações de quantidade do estoque">
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Select
                  disabled
                  name="storageId"
                  label="Estoque"
                  options={formattedStorages}
                  value={values?.storageId}
                  error={errors?.storageId || !!basicAndStorageResponseErrors?.storageId}
                  helperText={errors?.storageId || basicAndStorageResponseErrors?.storageId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  disabled={isEdit}
                  name="quantityToSell"
                  type="number"
                  label="Estoque Atual"
                  value={values?.quantityToSell}
                  onChange={handleChange}
                  error={errors?.quantityToSell || !!basicAndStorageResponseErrors?.quantityToSell}
                  helperText={errors?.quantityToSell || basicAndStorageResponseErrors?.quantityToSell}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="minQuantityToSell"
                  type="number"
                  label="Estoque Mínimo"
                  value={values?.minQuantityToSell}
                  onChange={handleChange}
                  error={errors?.minQuantityToSell || !!basicAndStorageResponseErrors?.minQuantityToSell}
                  helperText={errors?.minQuantityToSell || basicAndStorageResponseErrors?.minQuantityToSell}
                />
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}