import * as types from './constants';

export const setNotificationTags = data => ({
	type: types.SET_NOTIFICATION_TAGS,
	data
});

export const setNotificationCategories = data => ({
  type: types.SET_NOTIFICATION_CATEGORIES,
  data
});

export const setCurrentNotificationCategory = data => ({
  type: types.SET_CURRENT_NOTIFICATION_CATEGORY,
  data
});