import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { useStyles } from './styles';

export default function CardFooter({ children }) {
  const classes = useStyles();
  return (
    <Grid item>
      <Divider className={classes.divider} />
      <Grid container justify="flex-end">
        {children}
      </Grid>
    </Grid>
  );
}

CardFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
