import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value
});

export const setProvisionalReceiptInfo = data => ({
  type: types.SET_RPS_INFO,
  data
});

export const setProvisionalReceiptErrors = errors => ({
  type: types.SET_RPS_ERRORS,
  errors
});

export const clearProvisionalReceiptErrors = () => ({
  type: types.CLEAR_RPS_ERRORS
});

export const setServices = data => ({
  type: types.SET_SERVICES,
  data
});

export const setEconomicActivities = data => ({
  type: types.SET_ECONOMIC_ACTIVIES,
  data
});

export const setCertificateFile = file => ({
  type: types.SET_CERTIFICATE_FILE,
  file
});

export const setLogoFile = file => ({
  type: types.SET_LOGO_FILE,
  file
});

export const clearProvisionalReceiptInfo = () => ({
  type: types.CLEAR_RPS_INFO
});

export const clearCertificate = () => ({
  type: types.CLEAR_CERTIFICATE
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const handleCertificateLoading = status => ({
  type: types.HANDLE_CERTIFICATE_LOADING,
  status
});

export const handleRestrictionsLoading = status => ({
  type: types.HANDLE_RESTRICTIONS_LOADING,
  status
});

export const setDataPageAction = data => ({
  type: types.SET_DATA_PAGE,
  data
});

export const setCityCode = code => ({
  type: types.SET_CITY_CODE,
  code
});

export const setIsCityLoaded = isCityLoaded => ({
  type: types.SET_IS_CITY_LOADED,
  isCityLoaded
});

export const setConfigType = config => ({
  type: types.SET_CONFIG_TYPE,
  config
});

export const setEstablishmentsWithConfig = establishments => ({
  type: types.SET_ESTABLISHMENTS_WITH_CONFIG,
  establishments
});

export const setSelectedEstablishmentsWithConfig = id => ({
  type: types.SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG,
  id
});

export const setLastRpsSuccess = rps => ({
  type: types.SET_LAST_RPS_SUCCESS,
  rps
});

export const isRpsConfig = isConfig => ({
  type: types.IS_RPS_CONFIG,
  isConfig
});

export const isSendNfseLoading = isLoading => ({
  type: types.SET_IS_SEND_NFSE_LOADING,
  isLoading
});

export const setBasicInfo = data => ({
  type: types.SET_BASIC_INFO,
  data
});

export const setTables = data => ({
  type: types.SET_TABLES,
  data
});

export const setAuthentication = data => ({
  type: types.SET_AUTHENTICATION,
  data
});

export const setCertificate = data => ({
  type: types.SET_CERTIFICATE,
  data
});

export const setLogin = data => ({
  type: types.SET_LOGIN,
  data
});

export const setToken = data => ({
  type: types.SET_TOKEN,
  data
});

export const setRpsConfig = data => ({
  type: types.SET_RPS_CONFIG,
  data
});

export const setRpsFilter = data => ({
  type: types.SET_RPS_FILTER,
  data
});

export const setIsTermsOfUseLoading = isLoading => ({
  type: types.SET_IS_TERMS_OF_USE_LOADING,
  isLoading
});