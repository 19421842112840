import { over, lensProp } from 'ramda';
import { toast } from 'react-toastify';
import { formatToOnlyNumbers } from '../../../helpers/formatters';
import {
  createNewEmployee,
  updateEmployee,
  fetchEmployee,
  fetchRestrictions,
} from './services';
import {
  setEmployeeId,
  setEmployee,
  setEmptyEmployee,
  setRestrictions,
  handleLoading,
  handleRestrictionsLoading,
} from './actions';

export const loadEmployee = (userId, employeeId) => dispatch => {
  if(!userId) {
    return dispatch(setEmptyEmployee());
  }

  dispatch(handleLoading(true));

  return fetchEmployee(userId, employeeId)
    .then(({ data }) => {
      dispatch(setEmployee(data));
      dispatch(loadRestrictions(userId));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadRestrictions = userId => dispatch => {
  dispatch(handleRestrictionsLoading(true));

  return fetchRestrictions(userId)
    .then(response => dispatch(setRestrictions(response.data)))
    .finally(() => dispatch(handleRestrictionsLoading(false)));
}

export const upsertEmployee = (
  userRegistration,
  userId,
  params
) => dispatch => {
  const formatParams = over(lensProp('phone'), formatToOnlyNumbers, params);

  formatParams.appRestrictions = formatParams.appRestrictions?.length > 0
    ? formatParams.appRestrictions
    : '[]';

  formatParams.userRestrictions = formatParams.userRestrictions?.length > 0
    ? formatParams.userRestrictions
    : '[]';

  dispatch(handleLoading(true));

  if(!userId) {
    return createNewEmployee(userRegistration, formatParams)
      .then(({ data }) => {
        toast.success('Usuário criado com sucesso.');
        dispatch(setEmployeeId(data.userId));
      })
      .finally(() => dispatch(handleLoading(false)));
  }
  return updateEmployee(userRegistration, userId, formatParams)
    .then(({ data }) => {
      toast.success('Usuário atualizado com sucesso.');
      dispatch(setEmployeeId(data.userId));
    })
    .finally(() => dispatch(handleLoading(false)));
}