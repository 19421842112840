import * as types from './constants';

export const textChange = (field, value) => ({
  type: types.TEXT_CHANGE,
  field,
  value,
});

export const setConfigurations = data => ({
  type: types.SET_CONFIGURATIONS,
  data,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const clearConfigurations = () => ({
  type: types.CLEAR_CONFIGURATIONS,
});
