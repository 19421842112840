import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Modal from 'components/Modal';
import Button from 'components/Button';

const useConfirm = (title, message) => {
  const [promise, setPromise] = useState(null);

  const confirm = () => new Promise((resolve, _) => {
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  }

  const handleConfirm = () => {
    return(
      promise?.resolve(true),
      handleClose()
    );
  }

  const handleCancel = () => {
    return(
      promise?.resolve(false),
      handleClose()
    );
  }

  const ConfirmationDialog = () => (
    <Modal
      title={title}
      open={promise != null}
      onClose={handleCancel}
    >
      <Grid container style={{ marginBottom: 10 }}>
        <Typography>
          {message}
        </Typography>
      </Grid>
      <Grid container style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
        <Button
          color='error'
          onClick={handleCancel}
        >
          Não
        </Button>
        <Button
          color='success'
          onClick={handleConfirm}
        >
          Sim
        </Button>
      </Grid>
    </Modal>
  );

  return [ConfirmationDialog, confirm];
}

export default useConfirm;