import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';

import { convertToBrl } from 'helpers/converters';
import { getImageExtensionFromBase64 } from 'helpers/common';
import { formatVehicle } from 'helpers/formatters';

import { styles } from '../styles';

const TableColumnsHeaderItems = ['OS', 'Placa', 'Modelo/Cor', 'Data Entrada', 'Data Saída', 'Serviços a Comissionar', 'Valor', 'Valor da Comissão'];
const TableColumnsNumber = TableColumnsHeaderItems.length;
const TableColumnsWidth = 100 / TableColumnsNumber;

const ReceiptComissioned = ({ data }) => {
  const {
    name,
    amount,
    paidAmount,
    dueDate,
    closingDate,
    paymentDateTime,
    commissions,
    situationId
  } = data?.content;

  const isPayrollSheetOpen = +situationId == 1;

  const { base64Logo } = data?.establishment;
  const base64LogoExtension = getImageExtensionFromBase64(base64Logo);

  const commissionsList = commissions?.map(commission => {
    return {
      os: commission?.serviceOrder?.serviceOrderCode || '-',
      entryDateTime: commission?.serviceOrder?.entryDateTime || '-',
      exitDateTime: commission?.serviceOrder?.exitDateTime || '-',
      plate: commission?.vehicle?.plate || '-',
      model: formatVehicle(commission?.vehicle?.vehicleModel, commission?.vehicle?.vehicleColor),
      services: `${commission?.service?.quantity} - ${commission?.service?.description}`,
      commissionAmount: commission?.amount,
      amount: commission?.serviceOrderServiceAmount
    };
  });

  return(
    <Document>
      <Page style={{ padding: 10 }}>
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -21 }}>
          <View style={[styles.table, { display: 'flex', flexDirection: 'column', width: '30%' }]}>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize13, { padding: 5 }]}>Extrato da Comissão</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Comissionado: {name}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Valor: {isPayrollSheetOpen ? convertToBrl(+amount || 0) : convertToBrl(+paidAmount || 0)}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Vencimento: {moment(dueDate).format('DD/MM/YYYY')}</Text>
            </View>
            <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
              <Text style={[styles.fontSize8, styles.tableCell]}>Fechamento: {moment(closingDate).format('DD/MM/YYYY')}</Text>
            </View>
            {(!!paymentDateTime && paymentDateTime != '0001-01-01 00:00:00') && (
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '100%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Pagamento: {moment(paymentDateTime).format('DD/MM/YYYY HH:mm:ss')}</Text>
              </View>
            )}
          </View>
          <View style={[styles.table, styles.tableCol, { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '70%' }]}>
            {!!base64Logo && (
              <Image
                style={{
                  objectFit: 'contain',
                  height: 100
                }}
                src={`data:image/${base64LogoExtension};base64,${base64Logo}`}
              />
            )}
          </View>
        </View>
        {commissions?.length > 0 && (
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={[styles.table, styles.noMargin, { display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }]}>
              {TableColumnsHeaderItems.map(tableHeader => {
                return(
                  <View style={[styles.tableColHeader, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCellHeader, styles.fontSize8]}>{tableHeader}</Text>
                  </View>
                );
              })}
            </View>
            {commissionsList?.map(commission => (
              <View style={[styles.table, styles.noMargin, styles.noBorderTop]}>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{commission.os}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{commission.plate}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{commission.model}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{moment(commission.entryDateTime).format('DD/MM/YYYY HH:mm:ss')}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{moment(commission.exitDateTime).format('DD/MM/YYYY HH:mm:ss')}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{commission.services}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{convertToBrl(+commission.amount || 0)}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: `${TableColumnsWidth}%` }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{convertToBrl(+commission.commissionAmount || 0)}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );
}

export default ReceiptComissioned;