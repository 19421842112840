export const SET_IS_LOADING = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_IS_LOADING';
export const SET_CONFIG_TYPE = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_CONFIG_TYPE';
export const SET_SELECTED_ESTABLISHMENT_CONFIG = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_SELECTED_ESTABLISHMENT_CONFIG';
export const SET_DOES_CONFIG_EXISTS = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_DOES_CONFIG_EXISTS';
export const SET_PROVISIONAL_RECEIPT_PRODUCT = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_PROVISIONAL_RECEIPT_PRODUCT';
export const SET_CERTIFICATE = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_CERTIFICATE';
export const SET_CERTIFICATE_FILE = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_CERTIFICATE_FILE';
export const SET_BASIC_INFO = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_BASIC_INFO';
export const SET_BASIC_INFO_ERRORS = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_BASIC_INFO_ERRORS';
export const CLEAR_BASIC_INFO_ERRORS = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/CLEAR_BASIC_INFO_ERRORS';
export const SET_FILTERS = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_FILTERS';
export const SET_TABLES = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_TABLES';
export const SET_TAX_SETTINGS_ESTABLISHMENTS = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/SET_TAX_SETTINGS_ESTABLISHMENTS';
export const CLEAR_PROVISIONAL_RECEIPT_PRODUCT = 'PROVISIONAL_RECEIPT_PRODUCT_CONFIG/CLEAR__PROVISIONAL_RECEIPT_PRODUCT';