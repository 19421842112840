import {
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_PRODUCT_UUID,
  SET_SUPPLIER,
  SET_SUPPLIERS,
  SET_SUPPLIER_ERRORS,
  CLEAR_SUPPLIER_ERRORS,
  SET_SUPPLIER_UUID,
  SET_PRODUCT_BASIC,
  SET_PRODUCT_STORAGE,
  SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE,
  SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS,
  CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS,
  SET_PRODUCT_LOADING,
  SET_SUPPLIER_LOADING,
  CLEAR_PRODUCT,
  CLEAR_SUPPLIER
} from './constants';

const defaultState = {
  isLoading: false,
  isSupplierLoading: false,
  productId: '',
  productUuid: '',
  supplierUuid: '',
  basic: {
    name: '',
    visible: '1',
    supplierId: '',
    productBrandId: '',
    productTypeId: '',
    unitCost: '',
    salePrice: ''
  },
  storage: {
    storageId: '',
    quantityToSell: '',
    minQuantityToSell: ''
  },
  basicAndStorageResponse: {
    storageProductUuid: '',
    productUuid: '',
    productTypeUuid: '',
    productTypeDescription: '',
    productName: '',
    measurementUnitUuid: '',
    measurementUnitName: '',
    netContent: ''
  },
  identifiers: {
    internalCode: '',
    barCode: '',
    gtinEan: ''
  },
  basicAndStorageResponseErrors: {
    name: null,
    productTypeId: null,
    productBrandId: null,
    storageId: null,
    quantityToUseSell: null,
    minQuantityToSell: null
  },
  supplier: {
    cnpj: '',
    active: 1,
    fantasyName: '',
    corporateName: '',
    contacts: [],
    observations: '',
    cep: '',
    address: '',
    addressNumber: '',
    complement: '',
    city: '',
    district: '',
    state: ''
  },
  supplierErrors: {
    cnpj: '',
    fantasyName: '',
    corporateName: '',
    address: '',
    addressNumber: '',
    cep: '',
    city: '',
    district: '',
    state: ''
  },
  suppliers: []
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_PRODUCT: {
      const {
        storageProductUuid,
        name,
        visible,
        supplierId,
        productBrandId,
        productTypeId,
        unitCost,
        salePrice,
        storageId,
        quantityToSell,
        minQuantityToSell,
        internalCode,
        barCode,
        gtinEan
      } = action.data;

      return {
        ...state,
        productUuid: storageProductUuid,
        basic: {
          name,
          visible,
          supplierId,
          productBrandId,
          productTypeId,
          unitCost,
          salePrice
        },
        storage: {
          storageId,
          quantityToSell,
          minQuantityToSell
        },
        identifiers: {
          internalCode,
          barCode,
          gtinEan
        },
        basicAndStorageResponse: {
          ...state.basicAndStorageResponse,
          storageProductUuid
        }
      }
    }
    case SET_PRODUCT_ID: {
      return {
        ...state,
        productId: action.id
      }
    }
    case SET_PRODUCT_UUID: {
      return {
        ...state,
        productUuid: action.uuid
      }
    }
    case SET_SUPPLIER: {
      const { supplierInfo, active, contacts, observations, addresses } = action.data;

      const {
        cnpj,
        fantasyName,
        corporateName
      } = supplierInfo;

      const {
        cep,
        address,
        addressNumber,
        complement,
        city,
        district,
        state: uf
      } = addresses[0];

      return {
        ...state,
        supplier: {
          cnpj,
          active,
          fantasyName,
          corporateName,
          contacts,
          observations,
          cep,
          address,
          addressNumber,
          complement,
          city,
          district,
          state: uf
        }
      }
    }
    case SET_SUPPLIERS: {
      return {
        ...state,
        suppliers: action?.data?.data
      }
    }
    case SET_SUPPLIER_ERRORS: {
      return {
        ...state,
        supplierErrors: {
          cnpj: action?.errors['supplierInfo.cnpj'] ? action?.errors['supplierInfo.cnpj'][0] : null,
          fantasyName: action?.errors['supplierInfo.fantasyName'] ? action?.errors['supplierInfo.fantasyName'][0] : null,
          corporateName: action?.errors['supplierInfo.corporateName'] ? action?.errors['supplierInfo.corporateName'][0] : null,
          address: action?.errors['addresses.0.address'] ? action?.errors['addresses.0.address'][0] : null,
          addressNumber: action?.errors['addresses.0.addressNumber'] ? action?.errors['addresses.0.addressNumber'][0] : null,
          cep: action?.errors['addresses.0.cep'] ? action?.errors['addresses.0.cep'][0] : null,
          city: action?.errors['addresses.0.city'] ? action?.errors['addresses.0.city'][0] : null,
          district: action?.errors['addresses.0.district'] ? action?.errors['addresses.0.district'][0] : null,
          state: action?.errors['addresses.0.state'] ? action?.errors['addresses.0.state'][0] : null
        }
      }
    }
    case CLEAR_SUPPLIER_ERRORS: {
      return {
        ...state,
        supplierErrors: {
          ...defaultState.supplierErrors
        }
      }
    }
    case SET_SUPPLIER_UUID: {
      return {
        ...state,
        supplierUuid: action.uuid
      }
    }
    case SET_PRODUCT_BASIC: {
      return {
        ...state,
        basic: {
          ...state.basic,
          ...action.data
        }
      }
    }
    case SET_PRODUCT_STORAGE: {
      return {
        ...state,
        storage: {
          ...action.data
        }
      }
    }
    case SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE: {
      const { uuid, storageProductUuid } = action.data;

      return {
        ...state,
        basicAndStorageResponse: {
          ...action.data,
          storageProductUuid: uuid || storageProductUuid
        }
      }
    }
    case SET_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS: {
      return {
        ...state,
        basicAndStorageResponseErrors: {
          name: action?.data?.name ? action?.data?.name[0] : null,
          productTypeId: action?.data?.productTypeId ? action?.data?.productTypeId[0] : null,
          productBrandId: action?.data?.productBrandId ? action?.data?.productBrandId[0] : null,
          areasOfUse: action?.data?.areasOfUse ? action?.data?.areasOfUse[0] : null,
          storageId: action?.data?.storageId ? action?.data?.storageId[0] : null,
          quantityToUse: action?.data?.quantityToUse ? action?.data?.quantityToUse[0] : null,
          minQuantityToUse: action?.data?.minQuantityToUse ? action?.data?.minQuantityToUse[0] : null,
          measurementUnitId: action?.data?.measurementUnitId ? action?.data?.measurementUnitId[0] : null
        }
      }
    }
    case CLEAR_PRODUCT_BASIC_AND_STORAGE_RESPONSE_ERRORS: {
      return {
        ...state,
        basicAndStorageResponseErrors: defaultState.basicAndStorageResponseErrors
      }
    }
    case SET_PRODUCT_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_SUPPLIER_LOADING: {
      return {
        ...state,
        isSupplierLoading: action.isLoading
      }
    }
    case CLEAR_PRODUCT: {
      return defaultState;
    }
    case CLEAR_SUPPLIER: {
      return {
        ...state,
        supplier: {
          ...defaultState.supplier
        }
      }
    }
    default: {
      return state;
    }
  }
}