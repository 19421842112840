import * as yup from 'yup';

export const basicValidations = yup.object().shape({
  name: yup
    .string()
    .required('O nome do produto é obrigatório')
    .nullable(),
  measurementUnitId: yup
    .string()
    .required('A unidade de medida é obrigatória')
    .nullable(),
  netContent: yup
    .string()
    .required('A quantidade de unidade de medida é obrigatória')
    .nullable()
});