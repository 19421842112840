import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../components/Button';

import { goToNextPoint, goToPrevPoint } from '../../store/actions';

const PeriodRange = ({ type, year, month, goToNextPoint, goToPrevPoint }) => {
  const increasePoint = () => {
    goToNextPoint();
  };

  const decreasePoint = () => {
    goToPrevPoint();
  };

  const dateElement = () => {
    const formatMonth = month - 1;
    const formatMonthName = moment()
      .month(formatMonth)
      .format('MMMM');

    return type === 'day' ? `${formatMonthName} - ${year}` : year;
  };

  return (
    <>
      <ButtonGroup variant="contained">
        <Button color="primary" onClick={decreasePoint}>
          <FontAwesomeIcon icon={faChevronLeft} color="white" />
        </Button>

        <Button color="default" variant="outline">
          {dateElement()}
        </Button>
        <Button color="primary" onClick={increasePoint}>
          <FontAwesomeIcon icon={faChevronRight} color="white" />
        </Button>
      </ButtonGroup>
    </>
  );
};

PeriodRange.propTypes = {
  type: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  goToNextPoint: PropTypes.func.isRequired,
  goToPrevPoint: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  type: state.cashFlow.type,
  year: state.cashFlow.year,
  month: state.cashFlow.month,
  monthOperationalRange: state.cashFlow.monthOperationalRange,
  yearRangeBase: state.cashFlow.yearRange[0],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToNextPoint,
      goToPrevPoint,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PeriodRange);
