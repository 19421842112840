import * as types from './constants';

export const setSellOrdersList = data => ({
  type: types.SET_SELL_ORDERS_LIST,
  data
});

export const setSellOrdersResume = data => ({
  type: types.SET_SELL_ORDERS_RESUME,
  data
});

export const setSellOrder = data => ({
  type: types.SET_SELL_ORDER,
  data
});

export const clearSellOrder = () => ({
  type: types.CLEAR_SELL_ORDER
});

export const setIsLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  isLoading
});

export const setIsModalLoading = isLoading => ({
  type: types.SET_SELL_ORDER_MODAL_IS_LOADING,
  isLoading
});