import { compose, set, lensProp } from 'ramda';
import { formatPhone } from '../../../helpers/formatters';

import {
  SET_USER,
  SET_USER_ID,
  SET_RECAPTCHA,
  CLEAR_PASSWORD,
  CLEAR_BASIC_INFORMATION,
  HANDLE_LOADING,
  SET_USER_RESTRICTIONS,
} from './constants';

const defaultState = {
  userId: null,
  isLoading: false,
  userRestrictions: [],
  isMobile: false,
  submit: {
    profileId: 2,
    name: '',
    email: '',
    emailConfirm: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    termsOfUse: false,
    recaptcha: false
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER: {
      const { name, email, phone } = action.data;
      const user = compose(
        set(lensProp('name'), name),
        set(lensProp('email'), email),
        set(lensProp('phone'), formatPhone(phone))
      );

      return {
        ...state,
        submit: user(state.submit),
        isLoading: false
      }
    }
    case SET_USER_ID: {
      return {
        ...state,
        userId: action.userId
      }
    }
    case SET_RECAPTCHA: {
      return {
        ...state,
        submit: {
          ...state.submit,
          recaptcha: action.token
        }
      }
    }
    case CLEAR_PASSWORD: {
      return {
        ...state,
        submit: {
          ...state.submit,
          password: '',
          passwordConfirm: ''
        }
      }
    }
    case HANDLE_LOADING: {
      return {
        ...state,
        isLoading: action.status
      }
    }
    case SET_USER_RESTRICTIONS: {
      return {
        ...state,
        userRestrictions: action.userRestrictions,
        submit: {
          ...state.submit,
          profileId: action.profileId
        }
      }
    }
    case CLEAR_BASIC_INFORMATION: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}