import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

const Validator = ({
  initialValues,
  validations,
  onSubmit,
  children,
  ...props
}) => (
  <Formik
    {...props}
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validations}
    onSubmit={(values, actions) => onSubmit(values, actions)}
  >
    {children}
  </Formik>
);

Validator.propTypes = {
  initialValues: PropTypes.shape.isRequired,
  validations: PropTypes.shape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default Validator;
