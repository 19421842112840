import React from 'react';

export const IconClient = ({ active = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <rect
            width="30"
            height="30"
            fill={active ? 'red' : '#fff'}
            opacity="0"
          />
          <path
            d="M8.836,2.461,6.541.164A.562.562,0,0,0,6.143,0H6V3H9V2.857A.561.561,0,0,0,8.836,2.461ZM5.25,3.188V0H.563A.561.561,0,0,0,0,.563V11.437A.561.561,0,0,0,.563,12H8.438A.561.561,0,0,0,9,11.437V3.75H5.813A.564.564,0,0,1,5.25,3.188ZM1.5,1.688A.187.187,0,0,1,1.688,1.5H3.563a.187.187,0,0,1,.188.188v.375a.187.187,0,0,1-.187.188H1.688A.187.187,0,0,1,1.5,2.063Zm0,1.875V3.188A.187.187,0,0,1,1.688,3H3.563a.187.187,0,0,1,.188.188v.375a.187.187,0,0,1-.187.188H1.688A.187.187,0,0,1,1.5,3.563ZM4.875,9.747v.565a.187.187,0,0,1-.188.188H4.313a.187.187,0,0,1-.187-.188V9.743a1.342,1.342,0,0,1-.735-.266.188.188,0,0,1-.013-.285l.275-.263a.192.192,0,0,1,.237-.017A.564.564,0,0,0,4.19,9h.659a.3.3,0,0,0,.277-.309.306.306,0,0,0-.206-.3L3.865,8.076a1.061,1.061,0,0,1-.74-1.017A1.044,1.044,0,0,1,4.125,6V5.437a.187.187,0,0,1,.188-.187h.375a.187.187,0,0,1,.188.187v.569a1.34,1.34,0,0,1,.735.266.188.188,0,0,1,.013.285l-.275.263a.192.192,0,0,1-.237.017.562.562,0,0,0-.3-.087H4.151a.3.3,0,0,0-.277.309.306.306,0,0,0,.206.3l1.055.316a1.061,1.061,0,0,1,.74,1.017,1.044,1.044,0,0,1-1,1.056Z"
            transform="translate(8 6)"
            fill={active ? 'red' : '#fff'}
          />
        </g>
      </g>
    </svg>
  );
};

export const IconSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect data-name="Retângulo 2036" width="24" height="24" fill="none" />
      <path
        id="search-solid"
        d="M19.728,17.294,15.833,13.4a.937.937,0,0,0-.664-.273h-.637a8.122,8.122,0,1,0-1.406,1.406v.637a.937.937,0,0,0,.273.664l3.895,3.895a.934.934,0,0,0,1.324,0l1.106-1.106A.942.942,0,0,0,19.728,17.294Zm-11.6-4.168a5,5,0,1,1,5-5A5,5,0,0,1,8.126,13.126Z"
        transform="translate(2 2)"
        fill="#fff"
      />
    </svg>
  );
};
