import * as yup from 'yup';
import { documentRegExp, phoneRegExp, postalCodeRegExp } from 'helpers/regex';

export const basicInfoValidations = yup.object().shape({
  companyName: yup
    .string()
    .required('A razão social é obrigatória')
    .nullable(),
  fantasyName: yup
    .string()
    .required('O nome fantasia é obrigatório')
    .nullable(),
  documentId: yup
    .string()
    .required('O CNPJ é obrigatório')
    .matches(documentRegExp, {
      message: 'O CNPJ precisa ser válido',
      excludeEmptyString: true,
    })
    .nullable(),
  phone: yup
    .string()
    .required('O telefone é obrigatório')
    .matches(phoneRegExp, {
      message: 'O telefone precisa ser válido',
      excludeEmptyString: true,
    })
    .nullable(),
  email: yup
    .string()
    .required('O e-mail é obrigatório')
    .email('O e-mail precisa ser válido')
    .nullable(),
  municipalSubscription: yup
    .string()
    .nullable()
    .required('A inscrição municipal é obrigatória'),
  stateSubscription: yup
    .string()
    .nullable()
    .required('A inscrição estadual é obrigatória'),
  streetType: yup
    .string()
    .required('O tipo do local é obrigatório')
    .nullable(),
  postalCode: yup
    .string()
    .required('O CEP é obrigatório')
    .matches(postalCodeRegExp, {
      message: 'O CEP precisa ser válido',
      excludeEmptyString: true,
    })
    .nullable(),
  street: yup
    .string()
    .required('O endereço é obrigatório')
    .nullable(),
  district: yup
    .string()
    .required('O bairro é obrigatório')
    .nullable(),
  city: yup
    .string()
    .required('A cidade é obrigatória')
    .nullable(),
  state: yup
    .string()
    .required('O estado (UF) é obrigatório')
    .nullable(),
  cityCode: yup
    .string()
    .required('O município é obrigatório')
    .nullable(),
  simpleNational: yup
    .string()
    .required('Uma opção para simples nacional é obrigatória')
    .nullable(),
  taxationRegime: yup
    .string()
    .required('O regime tributário é obrigatório')
    .nullable(),
  taxationRegimeSpecial: yup
    .string()
    .required('O regime tributário especial é obrigatório')
    .nullable(),
  taxIncentive: yup
    .string()
    .required('Uma opção para incentivo tributário é obrigatória')
    .nullable(),
  encouraging: yup
    .string()
    .required('Uma opção para incentivador cultural é obrigatória')
    .nullable()
});