import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { formatPhone, formatPostalCode, formatDocument } from 'helpers/formatters';
import { convertToReal } from 'helpers/converters';
import { getImageExtensionFromBase64 } from 'helpers/common';

import { styles } from '../styles';

const Header = ({ children, hasDarkBackground, hasUnderline }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: hasDarkBackground ? '#E2E2E2' : '#FFFFFF',
        borderBottom: hasUnderline ? '1 solid #000000' : null
      }}
    >
      {children}
    </View>
  );
}

const Footer = ({ establishmentData }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        padding: 10,
        borderTop: '1 solid #E2E2E2'
      }}
    >
      <View style={{ width: '50%' }}>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.establishmentName}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{formatPhone(establishmentData?.phone)}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.email}</Text>
      </View>
      <View style={{ width: '50%' }}>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>CNPJ: {formatDocument(establishmentData?.documentId)}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.street}, {establishmentData?.number} {establishmentData?.district}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.city} - {establishmentData?.state} | CEP: {formatPostalCode(establishmentData?.postalCode)}</Text>
      </View>
    </View>
  );
}

const ReceiptInvoice = ({ exportData }) => {
  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  const {
    name,
    email,
    phone,
    documentId,
    vacancies,
    vehicles
  } = exportData.client;

  const establishmentData = {
    ...exportData.establishment,
    ...exportData.establishment.addresses[0]
  }

  const { city } = exportData.establishment.addresses[0];

  const {
    dueDateTime,
    paymentDateTime,
    paymentMethodId,
    amountReceived,
    servicesContract
  } = exportData.invoice;

  const paymentMethodName =
    exportData?.paymentMethods?.find(paymentMethod => paymentMethod.value == paymentMethodId)?.label;

  const base64Extension = getImageExtensionFromBase64(establishmentData?.base64Logo);

  return(
    <Document>
      <Page>
        {!!establishmentData?.base64Logo && (
          <Image
            style={{
              objectFit: 'contain',
              height: 100,
              padding: 10
            }}
            src={`data:image/${base64Extension};base64,${establishmentData?.base64Logo}`}
          />
        )}
        <View>
          <Header hasDarkBackground>
            <Text style={styles.fontSize13}>Recibo de Pagamento</Text>
          </Header>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
            <View style={{ width: '50%' }}>
              {!!name && (
                <Text style={[styles.fontSize11, styles.marginBottom5]}>Cliente: {name}</Text>
              )}
              {!!email && (
                <Text style={[styles.fontSize11, styles.marginBottom5]}>E-mail: {email}</Text>
              )}
              {!!phone && (
                <Text style={[styles.fontSize11, styles.marginBottom5]}>Telefone: {phone}</Text>
              )}
              {!!documentId && (
                <Text style={[styles.fontSize11, styles.marginBottom5]}>CPF/CNPJ: {formatDocument(documentId)}</Text>
              )}
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Vagas: {vacancies}</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Vencimento: {moment(dueDateTime).format('DD/MM/YYYY')}</Text>
              {paymentDateTime != '0001-01-01 00:00:00' && (
                <Text style={[styles.fontSize11, styles.marginBottom5, { marginBottom: 20 }]}>Pagamento: {moment(paymentDateTime).format('DD/MM/YYYY HH:mm:ss')}</Text>
              )}
              {vehicles.map(vehicle => {
                return <Text style={[styles.fontSize11, styles.marginBottom5]}>Veículo: {vehicle.plate} {vehicle.vehicleModel} {vehicle.vehicleColor}</Text>
              })}
            </View>
          </View>
          {servicesContract?.length > 0 && (
            <>
              <Header hasUnderline>
                <Text style={styles.fontSize13}>Serviços</Text>
              </Header>
              <View style={{ padding: 10 }}>
                {servicesContract.map(services => {
                  return <Text style={[styles.fontSize11, styles.marginBottom5]}>{services?.count} {services?.description}</Text>
                })}
              </View>
            </>
          )}
          {paymentMethodName && (
            <>
              <Header hasUnderline>
                <Text style={styles.fontSize13}>Forma de Pagamento</Text>
              </Header>
              <View style={{ padding: 10 }}>
                <Text style={[styles.fontSize11, styles.marginBottom5]}>{paymentMethodName}</Text>
              </View>
            </>
          )}
          <Header hasDarkBackground>
            <Text style={styles.fontSize13}>VALOR TOTAL</Text>
            <Text style={styles.fontSize13}>{convertToReal(amountReceived)}</Text>
          </Header>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
            <Text style={[styles.fontSize11, styles.secondaryColor]}>{city}, {currentDate}</Text>
          </View>
          <Footer
            establishmentData={establishmentData}
          />
        </View>
      </Page>
    </Document>
  );
}

export default ReceiptInvoice;