import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '../../../../../components/Card';
import Loader from '../../../../../components/Loader';
import Input from '../../../../../components/Input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { loadConfigRps } from 'pages/ProvisionalReceiptConfig/store/thunk';

export default function ConfigRps({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.businessInfo);
  const { configType } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigRps(userId, establishmentId));
    }
  }, [establishmentId]);

  return(
    <Card title="Configuração de geração de RPS">
      <Grid item xs={12}>
        <Form>
          <Loader isLoading={isLoading}>
            <Grid container>
              <Grid item xs={12}>
                <Typography color='textSecondary' style={{ margin: '10px 0 10px 0' }}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="#F5B941"
                    size="lg"
                    style={{ marginRight: 10 }}
                  />
                  Atenção!
                  <ul style={{ margin: 0 }}>
                    <li>Sua prefeitura exige a configuração de série única.</li>
                    <li>O RPS será gerado online e não será impresso no ticket.</li>
                    <li>Verifique qual a Série Única da sua Prefeitura.</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="rpsSeries"
                  type="text"
                  label="Série"
                  placeholder="Série"
                  maxLength={5}
                  value={formikProps.values.rpsSeries || ''}
                  error={formikProps.errors.rpsSeries}
                  helperText={formikProps.errors.rpsSeries}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="rpsNumber"
                  type="text"
                  label="Numeração de RPS a partir de"
                  placeholder="Numeração de RPS a partir de"
                  value={formikProps.values.rpsNumber || ''}
                  error={formikProps.errors.rpsNumber}
                  helperText={formikProps.errors.rpsNumber}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Loader>
        </Form>
      </Grid>
    </Card>
  );
}

ConfigRps.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: ''
    },
    errors: {
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false
    }
  }
};

ConfigRps.propTypes = {
  formikProps: PropTypes.shape()
};