import * as yup from 'yup';

const requiredCondition = text => ({
  is: true,
  then: yup.string().required(text)
});

export const validations = yup.object().shape({
  isPaying: yup.bool(),
  isEmailing: yup.bool(),
  isCanceling: yup.bool(),
  isCancelingPayment: yup.bool(),
  opened: yup.number(),
  paymentDateTime: yup
    .string()
    .when('isPaying', requiredCondition('Escolha a data de pagamento')),
  paymentMethod: yup
    .string()
    .when(['isPaying', 'opened'], (isPaying, opened, schema, node) => {
      const isClientPaying = !isPaying && opened == 0;
      const hasDueDateTimeChanged = node.parent.currentDueDateTime != node.parent.dueDateTime;
      const paymentMethod = node.parent.paymentMethod;

      if(isClientPaying) {
        if(!paymentMethod) {
          if(hasDueDateTimeChanged) return;
          return yup.string().required('Escolha o meio de pagamento');
        }
      }
    })
    .nullable(),
  amount: yup
    .string()
    .when('isPaying', requiredCondition('Digite o valor pago')),
  reason: yup
    .string()
    .when('isCancelingPayment', requiredCondition('Digite o motivo do cancelamento')),
  userPassword: yup
    .string()
    .when('isCancelingPayment', requiredCondition('Digite a senha para cancelar'))
});