export const SET_TRANSACTION = 'TRANSACTIONS/SET_TRANSACTION';
export const SET_RESUME = 'TRANSACTIONS/SET_RESUME';
export const SET_ACCOUNT_ID = 'TRANSACTIONS/SET_ACCOUNT_ID';
export const HANDLE_LOADING = 'TRANSACTIONS/HANDLE_LOADING';
export const HANDLE_REGISTRATION = 'TRANSACTIONS/HANDLE_REGISTRATION';
export const REGISTER_FIELD_CHANGE = 'TRANSACTIONS/REGISTER_FIELD_CHANGE';
export const CLEAR_REGISTRATION = 'TRANSACTIONS/CLEAR_REGISTRATION';
export const CLEAR_CASHIER_DETAILS = 'TRANSACTIONS/CLEAR_CASHIER_DETAILS';
export const HANDLE_CONFIRM = 'TRANSACTIONS/HANDLE_CONFIRM';
export const HANDLE_CHANGE_DATE = 'TRANSACTIONS/HANDLE_CHANGE_DATE';
export const CLEAR_TRANSACTION = 'TRANSACTIONS/CLEAR_TRANSACTION';
export const SELECT_SOURCE_ID = 'TRANSACTIONS/SELECT_SOURCE_ID';
export const SELECT_CATEGORY_SOURCE_ID = 'TRANSACTIONS/SELECT_CATEGORY_SOURCE_ID';
export const SELECT_TRANSACTION_ID = 'TRANSACTIONS/SELECT_TRANSACTION_ID';
export const SET_TRANSACTION_TYPE = 'TRANSACTIONS/SET_TRANSACTION_TYPE';
export const SET_TRANSACTION_DETAILS = 'TRANSACTIONS/SET_TRANSACTION_DETAILS';
export const SET_CASHIER_DETAILS = 'TRANSACTIONS/SET_CASHIER_DETAILS';
export const SET_STORE_CASHIER_ID = 'TRANSACTIONS/SET_STORE_CASHIER_ID';
export const SET_CASHIER_ID = 'TRANSACTIONS/SET_CASHIER_ID';
export const SET_WITHDRAL = 'TRANSACTIONS/SET_WITHDRAL';
export const HANDLE_DETAILING = 'TRANSACTIONS/HANDLE_DETAILING';
export const SET_REMOVE = 'TRANSACTIONS/SET_REMOVE';
export const CLEAR_CHANGES = 'TRANSACTIONS/CLEAR_CHANGES';
export const SET_ATTACHMENT = 'TRANSACTIONS/SET_ATTACHMENT';
export const SET_OPENING_ATTACHMENTS = 'TRANSACTIONS/SET_OPENING_ATTACHMENTS';
export const SET_CLOSING_ATTACHMENTS = 'TRANSACTIONS/SET_CLOSING_ATTACHMENTS';
export const SET_NEW_OPENING_ATTACHMENTS = 'TRANSACTIONS/SET_NEW_OPENING_ATTACHMENTS';
export const SET_NEW_CLOSING_ATTACHMENTS = 'TRANSACTIONS/SET_NEW_CLOSING_ATTACHMENTS';