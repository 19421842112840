import { newAPI } from '../../../services';

export const fetchCashFlow = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchFinancialResume = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchUsageResume = async url => {
  const response = await newAPI.get(url);
  return response.data;
}