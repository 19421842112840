import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, Grid, Typography } from '@material-ui/core';
import Button from 'components/Button';
import LoaderLabel from 'components/LoaderLabel';

import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { relativePercentage } from '../../../helpers/common';

import { setIsFinalOnboardingOpen } from '../store/actions';

export default function FinalOnboarding() {
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  const isSm = width < 960;

  const { isFinalOnboardingOpen } = useSelector(state => state.onboarding);

  const COUNTDOWN_INITIAL_VALUE = 15;
  const [countdown, setCountdown] = useState(COUNTDOWN_INITIAL_VALUE);

  const isJumpPark =
    window.location.hostname == 'localhost' ||
    window.location.hostname == 'teste-admin.jumppark.com.br' ||
    window.location.hostname == 'admin.jumppark.com.br';

  useEffect(() => {
    if(countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  }, [countdown]);

  return(
    <Dialog
      fullWidth
      open={isFinalOnboardingOpen}
      maxWidth='md'
      scroll='body'
    >
      <Grid container style={{ padding: 15 }}>
        <Grid
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography
            variant='h5'
            color='primary'
            style={{ padding: '10px 0px 15px 0px' }}
          >
            É necessário assistir este vídeo para prosseguir
          </Typography>
        </Grid>
        <Grid xs={12}>
          <iframe
            width='100%'
            height={(window.innerHeight / 2)}
            src={
              isJumpPark
                ? 'https://www.youtube.com/embed/D27qjumgNA8?autoplay=1&mute=0'
                : 'https://www.youtube.com/embed/XMs_VXfFok0?autoplay=1&mute=0'
            }
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
          />
        </Grid>
        <Grid container style={{ marginTop: 15 }}>
          <Grid xs={12}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                return isJumpPark
                  ? window.open('https://play.google.com/store/apps/details?id=br.com.jumppark.gestor&hl=pt_BR&gl=US&pli=1', '_blank').focus()
                  : window.open('https://play.google.com/store/apps/details?id=br.com.jumppark.gestor.jumpcar.esteticaautomotiva&hl=pt_BR&gl=US', '_blank').focus();
              }}
            >
              Baixar o App e iniciar a operação
            </Button>
          </Grid>
          <Grid xs={12}>
            <Grid container style={{ marginTop: 15 }}>
              <Grid
                xs={12}
                md={6}
                style={{ paddingRight: isSm ? 0 : 5 }}
              >
                <Button
                  style={{ width: '100%' }}
                  color='#e2b440'
                  onClick={() => {
                    return isJumpPark
                      ? window.open('https://jumppark.com.br/maquina-estacionamento/', '_blank').focus()
                      : window.open('https://jumpcar.com.br/maquinas-lava-rapido-estetica-automotiva/', '_blank').focus();
                  }}
                >
                  Sobre equipamentos
                </Button>
              </Grid>
              <Grid
                xs={12}
                md={6}
                style={{ paddingLeft: isSm ? 0 : 5, marginTop: isSm ? 15 : 0 }}
              >
                <Button
                  style={{ width: '100%' }}
                  color='success'
                  onClick={() => window.open('https://api.whatsapp.com/send?phone=5521999966453&text=Ol%C3%A1%20Jump!%20Estou%20me%20cadastrando%20no%20site%20e%20queria%20tirar%20algumas%20d%C3%BAvidas.%20Pode%20me%20ajudar?', '_blank').focus()}
                >
                  Fale com o suporte
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 15 }}>
          <Grid xs={6}>
            <Grid container justify='flex-start'>
              {(countdown > 0) && (
                <LoaderLabel value={relativePercentage(countdown, COUNTDOWN_INITIAL_VALUE)}>
                  {countdown}
                </LoaderLabel>
              )}
            </Grid>
          </Grid>
          <Grid xs={6}>
            <Grid container justify='flex-end'>
              <Button
                color="success"
                disabled={countdown > 0}
                onClick={() => dispatch(setIsFinalOnboardingOpen(false))}
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}