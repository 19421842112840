import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';

import { formatFilters } from 'helpers/formatters';
import { formatPermanence } from 'helpers/formatters';
import { convertFormattedMoneyToNumber, convertToReal } from 'helpers/converters';

import { Row, Column, getUserNameById, getContractNameById, getPaymentMethodNameById } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { loadFilters } from 'components/Filter/store/thunk';

export default function ServiceOrderUpdate({ serviceOrder, lastRegister, activityData }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);

  const { selects } = useSelector(state => state.filters);

  const userList = formatFilters(['user'], selects)[0]?.options;
  const contractNameList = formatFilters(['contract'], selects)[0]?.options;
  const paymentMethods = formatFilters(['paymentMethod'], selects)[0]?.options;

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
  }, []);

  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader activityData={activityData} />
        <Grid container style={{ marginTop: 10 }}>
          <Grid xs={12} style={{ padding: 5 }}>
            <Typography color='primary' style={{ marginBottom: 5, borderBottom: '1px solid #E4E7EA' }}>
              <b>OS Anterior</b>
            </Typography>
            <Grid style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
              <Row>
                <Typography color='primary'>
                  <b>Código da OS</b>
                </Typography>
                <Typography color='textSecondary'>{lastRegister?.serviceOrder?.serviceOrderCode}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Cartão</b>
                </Typography>
                <Typography color='textSecondary'>{lastRegister?.serviceOrder?.cardCode}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Operador da entrada</b>
                </Typography>
                <Typography color='textSecondary'>{getUserNameById(userList, lastRegister?.serviceOrder?.userId)}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Operador da saída</b>
                </Typography>
                <Typography color='textSecondary'>{getUserNameById(userList, lastRegister?.serviceOrder?.userOutputId)}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Entrada</b>
                </Typography>
                <Typography color='textSecondary'>{moment(lastRegister?.serviceOrder?.entryDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Saída</b>
                </Typography>
                <Typography color='textSecondary'>{moment(lastRegister?.serviceOrder?.exitDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Placa</b>
                </Typography>
                <Typography color='textSecondary'>{lastRegister?.serviceOrder?.plate}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Veículo</b>
                </Typography>
                <Typography color='textSecondary'>{lastRegister?.serviceOrder?.vehicle?.vehicleModel} {lastRegister?.serviceOrder?.vehicle?.vehicleColor}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Cobrança</b>
                </Typography>
                <Typography color='textSecondary'>{lastRegister?.serviceOrder?.typePrice}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Tipo de contrato</b>
                </Typography>
                <Typography color='textSecondary'>{getContractNameById(contractNameList, lastRegister?.serviceOrder?.contractId)}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Meio de pagamento</b>
                </Typography>
                <Typography color='textSecondary'>{getPaymentMethodNameById(paymentMethods, lastRegister?.transaction?.paymentMethodId)}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Período</b>
                </Typography>
                <Typography color='textSecondary'>{formatPermanence([lastRegister?.serviceOrder?.exitDateTime, lastRegister?.serviceOrder?.entryDateTime])}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Valor</b>
                </Typography>
                <Typography color='textSecondary'>{convertToReal(parseFloat(lastRegister?.serviceOrder?.amount) + parseFloat(lastRegister?.serviceOrder?.amountServices))}</Typography>
              </Row>
              {Array.isArray(lastRegister?.serviceOrder?.services) && lastRegister?.serviceOrder?.services?.length > 0 && (
                <Column>
                  <Typography color='primary' style={{ marginBottom: 5 }}>
                    <b>Serviços</b>
                  </Typography>
                  {lastRegister?.serviceOrder?.services?.map(service => (
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography color='textSecondary'>{service?.quantity}</Typography>
                      <Typography color='textSecondary' style={{ margin: '0px 20px 0px 20px' }}>{service?.description}</Typography>
                      <Typography color='textSecondary'>{convertToReal(convertFormattedMoneyToNumber(service?.amount))}</Typography>
                    </Grid>
                  ))}
                </Column>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} style={{ padding: 5, marginBottom: 5 }}>
            <Typography color='primary' style={{ marginTop: 10, marginBottom: 5, borderBottom: '1px solid #E4E7EA' }}>
              <b>OS Atual</b>
            </Typography>
            <Grid style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
              <Row>
                <Typography color='primary'>
                  <b>Código da OS</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.serviceOrderCode}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Cartão</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.cardCode}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Operador da entrada</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.entryUserName}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Operador da saída</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.exitUserName}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Entrada</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.entryDateTime}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Saída</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.exitDateTime}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Placa</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.plate}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Veículo</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.vehicleModel} {serviceOrder?.vehicleColor}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Cobrança</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.typePrice}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Tipo de contrato</b>
                </Typography>
                <Typography color='textSecondary'>{getContractNameById(contractNameList, serviceOrder?.contractId)}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Meio de pagamento</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.paymentMethodName || '--'}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Período</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.permanence}</Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Valor</b>
                </Typography>
                <Typography color='textSecondary'>{serviceOrder?.amountTotal}</Typography>
              </Row>
              {Array.isArray(serviceOrder?.services) && serviceOrder?.services?.length > 0 && (
                <Column>
                  <Typography color='primary' style={{ marginBottom: 5 }}>
                    <b>Serviços</b>
                  </Typography>
                  {serviceOrder?.services?.map(service => (
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography color='textSecondary'>{service?.quantity}</Typography>
                      <Typography color='textSecondary' style={{ margin: '0px 20px 0px 20px' }}>{service?.description}</Typography>
                      <Typography color='textSecondary'>{convertToReal(convertFormattedMoneyToNumber(service?.amount))}</Typography>
                    </Grid>
                  ))}
                </Column>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ServiceOrderUpdate.propTypes = {
  serviceOrder: PropTypes.object.isRequired,
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};