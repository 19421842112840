export const SET_SERVICES = 'SERVICES/SET_SERVICES';
export const CLEAR_SERVICES_SELECTED = 'SERVICES/CLEAR_SERVICES_SELECTED';
export const SELECT_SERVICES = 'SERVICES/SELECT_SERVICES';
export const SELECT_SINGLE_SERVICE = 'SERVICES/SELECT_SINGLE_SERVICE';
export const HANDLE_SERVICE_UPDATE = 'SERVICES/HANDLE_SERVICE_UPDATE';
export const LOADING_INFORMATIONS = 'SERVICES/LOADING_INFORMATIONS';
export const CLEAR_FORM = 'SERVICES/CLEAR_FORM';
export const CLEAR_SERVICES = 'SERVICES/CLEAR_SERVICES';
export const RESET_SERVICES_SELECTED = 'SERVICES/RESET_SERVICES_SELECTED';
export const TOGGLE_TO_REMOVE_SERVICE = 'SERVICES/TOGGLE_TO_REMOVE_SERVICE';
export const SET_SERVICES_CATEGORIES = 'SERVICES/SET_SERVICES_CATEGORIES';