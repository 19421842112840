import * as types from './constants';

export const setUser = data => ({
  type: types.SET_USER,
  data
});

export const setUserId = userId => ({
  type: types.SET_USER_ID,
  userId
});

export const setRecaptcha = token => ({
  type: types.SET_RECAPTCHA,
  token
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const clearPassword = () => ({
  type: types.CLEAR_PASSWORD
});

export const clearProfile = () => ({
  type: types.CLEAR_BASIC_INFORMATION
});

export const setUserRestrictions = (userRestrictions, profileId) => ({
  type: types.SET_USER_RESTRICTIONS,
  userRestrictions,
  profileId
});