import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import ServiceNotificationConfig from './ServiceNotificationConfig';

export default function NotificationsConfig() {
  const defaultOption = 1;
  const currentNotificationCategory = useSelector(state => state.notificationsConfig.currentCategory) || defaultOption;

  return(
    <Container>
      {currentNotificationCategory === 1 && <ServiceNotificationConfig />}
    </Container>
  );
}