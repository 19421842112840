import * as types from './constants';

export const handleTypePricesLink = status => ({
  type: types.HANDLE_TYPE_PRICE_LINK_LOADING,
  status,
});

export const setTypePricesLink = typePriceLink => ({
  type: types.SET_TYPE_PRICE_LINK,
  typePriceLink
});