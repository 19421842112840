import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { formatPhone, formatPostalCode, formatDocument } from 'helpers/formatters';
import { convertToReal } from 'helpers/converters';
import { getImageExtensionFromBase64 } from 'helpers/common';

import { styles } from '../styles';

const Header = ({ children, hasDarkBackground, hasUnderline }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: hasDarkBackground ? '#E2E2E2' : '#FFFFFF',
        borderBottom: hasUnderline ? '1 solid #000000' : null
      }}
    >
      {children}
    </View>
  );
}

const Footer = ({ establishmentData }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        padding: 10,
        borderTop: '1 solid #E2E2E2'
      }}
    >
      <View style={{ width: '50%' }}>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.establishmentName}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{formatPhone(establishmentData?.phone)}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.email}</Text>
      </View>
      <View style={{ width: '50%' }}>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>CNPJ: {formatDocument(establishmentData?.documentId)}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.street}, {establishmentData?.number} {establishmentData?.district}</Text>
        <Text style={[styles.fontSize11, styles.marginBottom5, styles.secondaryColor]}>{establishmentData?.city} - {establishmentData?.state} | CEP: {formatPostalCode(establishmentData?.postalCode)}</Text>
      </View>
    </View>
  );
}

const ReceiptPayrollExtract = ({ exportData }) => {
  const currentDate = moment(new Date()).format('DD/MM/YYYY');

  const {
    name,
    amount,
    dueDate,
    paymentDateTime,
    partialPayments
  } = exportData?.content;

  const establishmentData = {
    ...exportData.establishment,
    ...exportData.establishment.addresses[0]
  }

  const { city } = exportData.establishment.addresses[0];
  const { base64Logo } = exportData?.establishment;

  const base64LogoExtension = getImageExtensionFromBase64(base64Logo);

  return(
    <Document>
      <Page>
        {!!base64Logo && (
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
            <Image
              style={{
                objectFit: 'contain',
                height: 100,
                padding: 10
              }}
              src={`data:image/${base64LogoExtension};base64,${base64Logo}`}
            />
          </View>
        )}
        <View>
          <Header hasDarkBackground>
            <Text style={styles.fontSize13}>Recibo de Pagamento</Text>
          </Header>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
            <View style={{ width: '50%' }}>
              {!!name && (
                <Text style={[styles.fontSize11, styles.marginBottom5]}>Funcionário: {name}</Text>
              )}
            </View>
            <View style={{ width: '50%' }}>
              <Text style={[styles.fontSize11, styles.marginBottom5]}>Vencimento: {moment(dueDate).format('DD/MM/YYYY')}</Text>
              {(!!paymentDateTime && paymentDateTime != '0001-01-01 00:00:00') && (
                <Text style={[styles.fontSize11, styles.marginBottom5, { marginBottom: 20 }]}>Pagamento: {moment(paymentDateTime).format('DD/MM/YYYY')}</Text>
              )}
            </View>
          </View>
          {(partialPayments?.length > 0) && (
            <>
              <Header hasDarkBackground>
                <Text style={styles.fontSize13}>Pagamentos Parciais</Text>
              </Header>
              <View style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                {partialPayments?.map(payment => (
                  <Text style={styles.fontSize11}>
                    Pago {convertToReal(payment?.amount)} em {moment(payment?.paymentDateTime).format('DD/MM/YYYY')}
                  </Text>
                ))}
              </View>
            </>
          )}
          <Header hasDarkBackground>
            <Text style={styles.fontSize13}>VALOR TOTAL</Text>
            <Text style={styles.fontSize13}>{convertToReal(amount)}</Text>
          </Header>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
            <Text style={[styles.fontSize11, styles.secondaryColor]}>{city}, {currentDate}</Text>
          </View>
          <Footer establishmentData={establishmentData} />
        </View>
      </Page>
    </Document>
  );
}

export default ReceiptPayrollExtract;