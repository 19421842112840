import { toast } from 'react-toastify';
import { setAccounts, clearRegistration, openUpdateModal, openRegistrationModal, handleLoading } from './actions';
import { fetchAccount, fetchAccounts, createAccount, updateAccount } from './services';

export const loadAccount = params => dispatch => {
  const url = params?.establishmentId
    ? `${params.userId}/establishment/${params.establishmentId}/accounts/${params.accountId}`
    : `${params.userId}/accounts/${params.accountId}`;

  return fetchAccount(url)
    .then(({ data }) => dispatch(openUpdateModal(data)))
    .catch(() => toast.error('Não foi possível carregar as informações da conta.'));
}

export const loadAccounts = ({ page, pageSize, qp = '', userId, establishmentId }) => dispatch => {
  dispatch(handleLoading(true));

  let url = `${userId}/establishment/${establishmentId}/accounts?&pg=${page}&limit=${pageSize}${qp}`;

  if(!establishmentId) {
    url = `${userId}/accounts?&pg=${page}&limit=${pageSize}${qp}`;
  }

  return fetchAccounts(url)
    .then(({ data }) => dispatch(setAccounts(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertAccount = (params, properties) => dispatch => {
  const { page, pageSize } = properties;
  const { userId, establishmentId } = params;

  dispatch(handleLoading(true));

  if(!params.accountId) {
    return createAccount(params)
      .then(() => {
        toast.success('Conta cadastrada com sucesso.');

        dispatch(openRegistrationModal(false));
        dispatch(clearRegistration());
        dispatch(loadAccounts({ page, pageSize, userId, establishmentId }));
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  if(+params?.mainAccount == 1) {
    delete params['establishments'];
  }

  if(!params?.establishmentId) {
    delete params['establishmentId'];
  }

  const url = params?.establishmentId
    ? `${params.userId}/establishment/${params.establishmentId}/accounts/${params.accountId}`
    : `${params.userId}/accounts/${params.accountId}`;

  return updateAccount(url, params)
    .then(() => {
      toast.success('Conta atualizada com sucesso.');

      dispatch(openRegistrationModal(false));
      dispatch(clearRegistration());
      dispatch(loadAccounts({ page, pageSize, userId, establishmentId }));
    })
    .finally(() => dispatch(handleLoading(false)));
}