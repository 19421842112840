import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Formik, Form } from 'formik';
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Card from 'components/Card';
import Loader from 'components/Loader';
import CardFooter from 'components/CardFooter';
import Button from 'components/Button';
import WarningMessage from 'components/WarningMessage';

import { loadConfigurations, upsertConfigurations } from '../../store/thunk';

import { checkedValueByType } from 'helpers/common';

export default function VehicleEntry() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);
  const submit = useSelector(state => state.operational.vehicleEntry);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  const handleSubmit = values => {
    const params = {
      requiredPlateOrPhoto: checkedValueByType(values?.requiredPlateOrPhoto, 'number'),
      requiredVehiclePlate: checkedValueByType(values?.requiredVehiclePlate, 'number'),
      requiredVehicleModel: checkedValueByType(values?.requiredVehicleModel, 'number'),
      requiredVehicleColor: checkedValueByType(values?.requiredVehicleColor, 'number'),
      requiredVehicleDamage: checkedValueByType(values?.requiredVehicleDamage, 'number'),
      requiredEntryPhoto: checkedValueByType(values?.requiredEntryPhoto, 'number'),
      page: 'Entrada de Veículo'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  const initialValues = {
    requiredPlateOrPhoto: checkedValueByType(submit?.requiredPlateOrPhoto, 'boolean'),
    requiredVehiclePlate: checkedValueByType(submit?.requiredVehiclePlate, 'boolean'),
    requiredVehicleModel: checkedValueByType(submit?.requiredVehicleModel, 'boolean'),
    requiredVehicleColor: checkedValueByType(submit?.requiredVehicleColor, 'boolean'),
    requiredVehicleDamage: checkedValueByType(submit?.requiredVehicleDamage, 'boolean'),
    requiredEntryPhoto: checkedValueByType(submit?.requiredEntryPhoto, 'boolean')
  }

  return(
    <Card title="Entrada de Veículo">
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ ...formikProps }) => {
              return(
                <Form>
                  <Grid container>
                    {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
                      <WarningMessage
                        message={`Última alteração feita por ${settingHistoryUserName} em ${moment(settingHistoryDateTime).format('LLL')}`}
                      />
                    )}
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Obrigatoriedades para dar entrada em um veículo</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 0 20px 0' }}>
                      <Typography color="textSecondary">Escolha as opções que deseja obrigar o preenchimento para permitir dar entrada em um veículo</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiredPlateOrPhoto"
                            type="checkbox"
                            value={formikProps?.values?.requiredPlateOrPhoto || ''}
                            checked={formikProps?.values?.requiredPlateOrPhoto || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="primary">Digitação ou foto da placa do veículo</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiredVehiclePlate"
                            type="checkbox"
                            value={formikProps?.values?.requiredVehiclePlate || ''}
                            checked={formikProps?.values?.requiredVehiclePlate || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="primary">Digitação da <b>placa</b> do veículo</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiredEntryPhoto"
                            type="checkbox"
                            value={formikProps?.values?.requiredEntryPhoto || ''}
                            checked={formikProps?.values?.requiredEntryPhoto || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="primary">Registro de <b>foto da placa</b> do veículo</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiredVehicleModel"
                            type="checkbox"
                            value={formikProps?.values?.requiredVehicleModel || ''}
                            checked={formikProps?.values?.requiredVehicleModel || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="primary">Digitação do <b>modelo</b> do veículo</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiredVehicleColor"
                            type="checkbox"
                            value={formikProps?.values?.requiredVehicleColor || ''}
                            checked={formikProps?.values?.requiredVehicleColor || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="primary">Digitação da <b>cor</b> do veículo</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiredVehicleDamage"
                            type="checkbox"
                            value={formikProps?.values?.requiredVehicleDamage || ''}
                            checked={formikProps?.values?.requiredVehicleDamage || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="primary">Registro de <b>avaria</b> do veículo (Checklist)</Typography>
                        }
                      />
                    </Grid>
                    <Grid xs={12}>
                      <CardFooter>
                        <Button color="primary" type="submit">
                          Salvar
                        </Button>
                      </CardFooter>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Loader>
      </Grid>
    </Card>
  );
}