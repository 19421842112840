import { newAPI } from '../../../services';

export const fetchTotalOccupation = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchPaidOut = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchCanceled = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchAltered = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchGraphics = async url => {
  const response = await newAPI.get(url, { timeout: 60000 });
  return response.data;
}

export const fetchCashflow = async url => {
  const response = await newAPI.get(url);
  return response.data;
}