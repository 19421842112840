import { toast } from 'react-toastify';

import {
  fetchSellOrdersList,
  fetchSellOrdersResume,
  fetchSellOrdersExport,
  fetchSellOrder,
  deleteSellOrder
} from './services';

import {
  setIsLoading,
  setIsModalLoading,
  setSellOrdersResume,
  setSellOrdersList,
  setSellOrder,
  clearSellOrder
} from './actions';

import { loadStorageHistoryList } from 'pages/StorageHistory/store/thunk';
import { loadStorageHistoryResume } from 'pages/StorageHistory/store/thunk';

import { handleExporting } from 'components/Filter/store/actions';

export const loadSellOrdersList = (userId, establishmentId, extraProps) => dispatch => {
  dispatch(setIsLoading(true));

  const { page, limit, startDate, endDate, qp } = extraProps;

  return fetchSellOrdersList(userId, establishmentId, page, limit, startDate, endDate, qp)
    .then(({ data }) => dispatch(setSellOrdersList(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadSellOrdersResume = (userId, establishmentId, extraProps) => dispatch => {
  dispatch(setIsLoading(true));

  const { startDate, endDate, qp } = extraProps;

  return fetchSellOrdersResume(userId, establishmentId, startDate, endDate, qp)
    .then(({ data }) => dispatch(setSellOrdersResume(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadSellOrdersExport = (userId, establishmentId, extraProps) => dispatch => {
  dispatch(handleExporting(true));

  const { startDate, endDate, qp } = extraProps;

  return fetchSellOrdersExport(userId, establishmentId, startDate, endDate, qp)
    .then(({ data }) => data)
    .finally(() => dispatch(handleExporting(false)));
}

export const loadSellOrder = (userId, establishmentId, orderId) => dispatch => {
  dispatch(clearSellOrder());
  dispatch(setIsModalLoading(true));

  return fetchSellOrder(userId, establishmentId, orderId)
    .then(({ data }) => dispatch(setSellOrder(data)))
    .finally(() => dispatch(setIsModalLoading(false)));
}

export const removeSellOrder = (userId, establishmentId, orderId, params, extraProps, pageOrigin) => dispatch => {
  dispatch(setIsModalLoading(true));

  const { storageProductUuid, page } = extraProps;

  return deleteSellOrder(userId, establishmentId, orderId, params)
    .then(() => {
      if(pageOrigin == 'storageHistory') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, page));
        dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
      }

      toast.success('Ordem de venda removida com sucesso.');

      return Promise.resolve();
    })
    .finally(() => dispatch(setIsModalLoading(false)));
}