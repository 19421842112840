import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';

import { convertToReal } from 'helpers/converters';

export default function PayrollSheetUndoPayment({ lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12} style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
        <Row>
          <Typography color='primary'>
            <b>Data de pagamento</b>
          </Typography>
          <Typography color='textSecondary'>{moment(lastRegister?.paymentDateTime).format('DD/MM/YYYY HH:mm:ss')}</Typography>
        </Row>
        <Row>
          <Typography color='primary'>
            <b>Motivo</b>
          </Typography>
          <Typography color='textSecondary'>{lastRegister?.description || activityData?.description}</Typography>
        </Row>
        <Row>
          <Typography color='primary'>
            <b>Valor</b>
          </Typography>
          <Typography color='textSecondary'>{convertToReal(lastRegister?.amount)}</Typography>
        </Row>
      </Grid>
    </Grid>
  );
}

PayrollSheetUndoPayment.propTypes = {
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};