import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import { handleLogout } from '../../pages/Login/store/actions';

import { styles } from '../../pages/PageNotFound/components/styles';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  logout = () => {
    const { handleLogout } = this.props;
    handleLogout();
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    const { classes } = this.props;

    if (hasError) {
      return (
        <Box className={classes.root} pt={4} pb={4}>
          <Container maxWidth="lg" className={classes.wrapper}>
            <Paper elevation={1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Box
                    component="section"
                    pt={4}
                    pb={4}
                    className={classes.contentError}
                  >
                    <Box component="div" className={classes.errorBlock}>
                      <Typography variant="h2" className={classes.errorText}>
                        Ocorreu um erro
                      </Typography>
                      <Typography variant="h1" className={classes.errorTitle}>
                        Ops!
                      </Typography>
                      <br />
                      <br />
                      <Typography
                        variant="h2"
                        className={classes.errorDescription}
                      >
                        Desculpe o transtorno
                      </Typography>
                    </Box>
                    <Box component="div" className={classes.description}>
                      Houve um problema e nossa equipe já foi notificada.
                      Resolveremos em breve.
                    </Box>
                    <Box component="div" className={classes.actions}>
                      <a href="/" onClick={this.logout}>
                        Clique aqui para entrar novamente no sistema
                      </a>{' '}
                      ou{' '}
                      <a href="https://jumppark.com.br/">acesse o nosso site</a>
                      .
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  handleLogout: PropTypes.func.isRequired,
  classes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapDispatchToProps = {
  handleLogout,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ErrorBoundary));
