import { fetchCashFlow, fetchFinancialResume, fetchUsageResume } from './services';
import { setCashFlow, handleLoading } from './actions';

export const loadCashFlow = (userId, establishmentId, type, month, year) => dispatch => {
  let url = `${userId}/establishment/${establishmentId}/financial/cashflow?&type=${type}&year=${year}`;

  if(type === 'day') {
    url = url + `&month=${month}`;
  }

  dispatch(handleLoading(true));

  return fetchCashFlow(url)
    .then(({ data }) => {
      dispatch(setCashFlow(data.content, data.total));
      dispatch(handleLoading(false));
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const loadFinancialResume = (
  userId,
  establishmentId,
  startDate,
  endDate,
  startTime,
  endTime
) => dispatch => {
  const url =  `${userId}/establishment/${establishmentId}/reports/financial?startDate=${startDate}&endDate=${endDate}&startTime=${startTime}&endTime=${endTime}`;

  dispatch(handleLoading(true));

  return fetchFinancialResume(url)
    .then(({ data }) => {
      dispatch(handleLoading(false));

      return {
        ...data,
        startDate,
        endDate
      }
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const loadUsageResume = (
  userId,
  establishmentId,
  startDate,
  endDate,
  startTime,
  endTime
) => dispatch => {
  const url = `${userId}/establishment/${establishmentId}/reports/usage?startDate=${startDate}&endDate=${endDate}&startTime=${startTime}&endTime=${endTime}`;

  dispatch(handleLoading(true));

  return fetchUsageResume(url)
    .then(({ data }) => {
      dispatch(handleLoading(false));

      return {
        resume: data,
        startDate,
        endDate
      }
    })
    .catch(() => dispatch(handleLoading(false)));
}