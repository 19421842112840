export const REGISTER_FIELD_CHANGE = 'CATEGORIES/REGISTER_FIELD_CHANGE';
export const TEXT_CHANGE = 'CATEGORIES/TEXT_CHANGE';
export const SET_CATEGORIES = 'CATEGORIES/SET_CATEGORIES';
export const HANDLE_SEARCH = 'CATEGORIES/HANDLE_SEARCH';
export const HANDLE_PAGINATION = 'CATEGORIES/HANDLE_PAGINATION';
export const LOADING_INFORMATIONS = 'CATEGORIES/LOADING_INFORMATIONS';
export const CLEAR_CATEGORIES = 'CATEGORIES/CLEAR_CATEGORIES';
export const HANDLE_REGISTRATION = 'CATEGORIES/HANDLE_REGISTRATION';
export const HANDLE_UPDATE = 'CATEGORIES/HANDLE_UPDATE';
export const CLEAR_REGISTRATION = 'CATEGORIES/CLEAR_REGISTRATION';
