import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export default function LoaderLabel(props) {
  return(
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress variant='determinate' {...props} size={50} />
      <Box
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography color='primary'>
          {props.children}
        </Typography>
      </Box>
    </Box>
  );
}