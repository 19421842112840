import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { find, propEq, concat } from 'ramda';

import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';
import Pagination from '../../../components/Pagination/components';
import ServiceOrder from '../../../components/ServiceOrder/components';

import { loadReservations } from '../store/thunk';
import { toggleServiceOrder } from '../../OperationalHistory/store/actions';
import { setServiceOrderId } from '../../../components/ServiceOrder/store/actions';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { formatFilters } from '../../../helpers/formatters';
import { convertList } from '../../../helpers/converters';
import { inputs } from './resources';

const headers = [
  { title: 'Placa', field: 'plate', sortable: false },
  { title: 'Nome', field: 'clientName', sortable: false },
  { title: 'Telefone', field: 'phones', sortable: false },
  { title: 'Data de entrada', field: 'startDateTime', sortable: false },
  { title: 'Data de saída', field: 'endDateTime', sortable: false },
  { title: 'Situação', field: 'reservationSituation', sortable: false },
  { title: 'Valor', field: 'amount', sortable: false },
];

const width = window.innerWidth;
const tableWidth = width < 768 ? 120 : 100;

export default function Reservations({ ...props }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { selects, qp } = useSelector(state => state.filters);
  const { modal } = useSelector(state => state.operationalHistory);
  const { list, isLoading, total } = useSelector(state => state.reservations);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);

  const filters = concat(
    formatFilters(
      ['reservationType', 'paymentMethod', 'reservationSituation'],
      selects
    ),
    inputs
  );

  const convertedList = convertList(
    ['reservationSituation', 'reservationType'],
    filters,
    list
  );

  const handleLoadReservations = ({ page, qp }) => {
    const extraProps = { startDate, endDate };
    dispatch(loadReservations({ page, pageSize, qp, extraProps }));
  }

  useEffect(() => {
    if (startDate !== 'Invalid date' && endDate !== 'Invalid date') {
      dispatch(loadFilters(userId, establishmentId));
      handleLoadReservations({ page, qp });
    }
  }, [userId, establishmentId, startDate, endDate]);

  const openServiceOrder = (newEstablishmentId, serviceOrderId) => {
    dispatch(setServiceOrderId(serviceOrderId, userId, newEstablishmentId));
    dispatch(toggleServiceOrder(true));
  }

  const width = window.innerWidth;
  const isMobile = width < 1024;

  return (
    <Paper>
      {isMobile ? (
        <FilterMobile
          filter
          hasSearchTerm
          linkButtonText="Configurações de reserva"
          hasLinkButton="/configuracoes-de-reserva"
          filters={filters}
          handlePageRequest={handleLoadReservations}
        />
      ) : (
        <Filter
          filter
          hasSearchTerm
          linkButtonText="Configurações de reserva"
          hasLinkButton="/configuracoes-de-reserva"
          filters={filters}
          handlePageRequest={handleLoadReservations}
        />
      )}

      <MTable
        loading={isLoading}
        headers={headers}
        data={convertedList}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faEye}
                color="#2CA9D6"
                size="xs"
                style={{ opacity: !rowData.serviceOrderId ? 0.5 : 1 }}
              />
            ),
            tooltip: 'Visualizar reserva',
            onClick: (event, rowData) =>
              openServiceOrder(rowData.establishmentId, rowData.serviceOrderId),
            disabled: !rowData.serviceOrderId,
          }),
        ]}
        actionColumnWidth={tableWidth}
      />
      <Pagination
        pageSize={10}
        totalItems={total}
        onSelect={handleLoadReservations}
      />
      {modal && (
        <ServiceOrder
          modal={modal}
          paymentMethods={find(propEq('id', 'paymentMethod'), filters)}
          {...props}
        />
      )}
    </Paper>
  );
}