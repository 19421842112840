import * as yup from 'yup';

export const basicValidations = yup.object().shape({
  name: yup
    .string()
    .required('O nome do produto é obrigatório')
    .nullable(),
  unitCost: yup
    .string()
    .required('O valor do custo unitário é obrigatório')
    .nullable(),
  salePrice: yup
    .string()
    .required('O valor de venda é obrigatório')
    .nullable()
});