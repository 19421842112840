import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Card from 'components/Card';
import Button from 'components/Button';

export default function ProvisionalReceiptTypeSelection() {
  const history = useHistory();

  return(
    <Card title='Selecione qual será o fluxo de nota fiscal'>
      <Grid container>
        <Grid xs={12} style={{ padding: 5 }}>
          <Button
            fullWidth
            type='button'
            color='success'
            onClick={() => history.push('/configuracoes-de-notas-fiscais')}
          >
            Nota fiscal de serviço (NFS-e)
          </Button>
        </Grid>
        {/* <Grid xs={12} md={6} style={{ padding: 5 }}>
          <Button
            fullWidth
            type='button'
            color='success'
            onClick={() => history.push('/configuracoes-de-notas-fiscais-de-produto')}
          >
            Nota fiscal de produto (NFe)
          </Button>
        </Grid> */}
      </Grid>
    </Card>
  );
}