import {
  SET_SERVICES,
  CLEAR_SERVICES_SELECTED,
  SELECT_SERVICES,
  SELECT_SINGLE_SERVICE,
  HANDLE_SERVICE_UPDATE,
  LOADING_INFORMATIONS,
  CLEAR_FORM,
  CLEAR_SERVICES,
  RESET_SERVICES_SELECTED,
  TOGGLE_TO_REMOVE_SERVICE,
  SET_SERVICES_CATEGORIES
} from './constants';

import { convertToReal } from '../../../helpers/converters';
import { compose, set, lensProp, lensPath } from 'ramda';

const defaultState = {
  list: [],
  total: 0,
  serviceId: null,
  serviceIds: [],
  isRemoving: false,
  isUpdating: false,
  isLoading: false,
  servicesCategories: [],
  submit: {
    description: '',
    amount: '',
    status: 1,
    categories: [],
    detailing: ''
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SERVICES: {
      const list = action.data?.map(service => {
        return {
          ...service,
          amount: convertToReal(service?.amount),
          categories: !!service?.categories
            ? JSON.parse(service?.categories)?.map(categoryId => +categoryId)
            : []
        }
      });

      const services = compose(
        set(lensProp('total'), action.total),
        set(lensProp('list'), list),
        set(lensProp('isLoading'), false),
        set(lensProp('isRemoving'), false)
      );

      return services(state);
    }
    case CLEAR_SERVICES_SELECTED: {
      return set(lensProp('serviceIds'), [], state);
    }
    case SELECT_SERVICES: {
      let { serviceIds } = state;
      if (action.value) {
        serviceIds = serviceIds.concat(action.serviceId);
      } else {
        serviceIds = serviceIds.filter(value => value !== action.serviceId);
      }

      return set(lensProp('serviceIds'), serviceIds, state);
    }
    case SELECT_SINGLE_SERVICE: {
      return set(lensProp('serviceIds'), [action.serviceId], state);
    }
    case HANDLE_SERVICE_UPDATE: {
      const { isUpdating, serviceId, description, detailing, amount, categories, status } = action.service;

      return {
        ...state,
        submit: {
          amount: convertToReal(amount),
          categories: JSON.parse(categories)?.map(category => +category),
          description,
          detailing,
          status
        },
        serviceId,
        isUpdating
      }
    }
    case LOADING_INFORMATIONS: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case RESET_SERVICES_SELECTED: {
      return set(lensProp('serviceIds'), [], state);
    }
    case CLEAR_FORM: {
      const clear = compose(
        set(lensPath(['submit', 'description']), ''),
        set(lensPath(['submit', 'amount']), ''),
        set(lensPath(['submit', 'categories']), []),
        set(lensPath(['submit', 'detailing']), ''),
        set(lensProp('isUpdating'), false),
        set(lensProp('serviceId'), null)
      );

      return clear(state);
    }
    case TOGGLE_TO_REMOVE_SERVICE: {
      return set(lensProp('isRemoving'), action.status, state);
    }
    case SET_SERVICES_CATEGORIES: {
      const formattedServicesCategories = action?.categories?.map(category => {
        return {
          value: category?.id,
          label: category?.name
        }
      });

      return set(lensProp('servicesCategories'), formattedServicesCategories, state);
    }
    case CLEAR_SERVICES:
      return defaultState;
    default: {
      return state;
    }
  }
}