import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const Modal = ({
  id,
  open,
  onClose,
  title,
  establishmentName,
  modalActions,
  maxWidth,
  children,
  scroll,
  overlayStyle,
  header = true
}) => {
  const classes = useStyles();

  return(
    <Dialog
      disableEnforceFocus={true}
      fullWidth
      aria-labelledby={id}
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      scroll={scroll}
      classes={{ paper: classes.scrollBody, scrollBody: classes.scrollBody }}
      PaperProps={{
        style: { boxShadow: 'none' }
      }}
      BackdropProps={{
        style: overlayStyle || {}
      }}
    >
      {header && (
        <MuiDialogTitle disableTypography id={id} className={classes.root}>
          <Typography variant="h6" color="primary">
            {title} {establishmentName && <small>({establishmentName})</small>}
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
      )}
      <MuiDialogContent
        dividers
        style={{
          overflowY: 'initial'
        }}
      >
        {children}
      </MuiDialogContent>
      {modalActions && <MuiDialogActions>{modalActions}</MuiDialogActions>}
    </Dialog>
  );
}

Modal.defaultProps = {
  establishmentName: '',
  maxWidth: 'md',
  scroll: 'paper'
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  establishmentName: PropTypes.string,
  modalActions: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  children: PropTypes.string.isRequired,
  scroll: PropTypes.string
};

export default Modal;