import React from 'react';
import { Grid } from '@material-ui/core';

function Row({ children, justifyContentStart }) {
  return(
    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: justifyContentStart ? 'flex-start' : 'space-between', width: '100%', color: '#1e5168' }}>
      {children}
    </Grid>
  );
}

function Column({ children }) {
  return(
    <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%', color: '#1e5168', padding: 8 }}>
      {children}
    </Grid>
  );
}

const getUserNameById = (userList, userId) => {
  const user = userList?.find(user => user.value == userId);
  return user?.label;
}

const getContractNameById = (contractNameList, contractId) => {
  const contract = contractNameList?.find(contract => contract.value == contractId);
  return contract?.label;
}

const getPaymentMethodNameById = (paymentMethods, paymentMethodId) => {
  const paymentMethod = paymentMethods?.find(paymentMethod => paymentMethod.value == paymentMethodId);
  return paymentMethod?.label || '--';
}

export { Row, Column, getUserNameById, getContractNameById, getPaymentMethodNameById }