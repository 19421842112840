import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { convertToReal } from 'helpers/converters';
import { styles } from '../styles';

const Title = ({ children }) => {
  return(
    <View style={[styles.title, styles.fontSize20, styles.defaultColor]}>
      <Text>{children}</Text>
    </View>
  );
}

const TitleItem = ({ children, title }) => {
  return(
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Text style={[styles.tableCellHeader, styles.defaultColor, styles.fontSize13, styles.noMargin]}>{title}: </Text>
      <Text style={[styles.subtitle, styles.defaultColor, styles.fontSize13, styles.noMargin]}>{children}</Text>
    </View>
  );
}

const Item = ({ children, title}) => {
  return(
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Text style={[styles.tableCellHeader, styles.defaultColor, styles.noMargin]}>{title}: </Text>
      <Text style={[styles.subtitle, styles.defaultColor, styles.noMargin]}>{children}</Text>
    </View>
  );
}

const CashierDetailsResume = ({ data }) => {
  const {
    establishmentName,
    closingUserName,
    closingDateTime,
    openingAccountName,
    accountDestinationName,
    closingPrintAbstract,
    closingObservation,
    totalAmount
  } = data;

  return(
    <Document>
      <Page>
        <View style={{ padding: 10 }}>
          <Title>Fechamento do caixa</Title>
          <Text style={[styles.title, styles.fontSize15, styles.defaultColor]}>{establishmentName}</Text>
        </View>
        <View style={{ padding: 10 }}>
          <Item title='Operador'>{closingUserName}</Item>
          <Item title='Data'>{closingDateTime}</Item>
          <Item title='Conta Origem'>{openingAccountName}</Item>
          <Item title='Conta Destino'>{accountDestinationName}</Item>
          {closingObservation && (
            <Item title='Observação'>{closingObservation}</Item>
          )}
        </View>
        {closingPrintAbstract?.paymentTypes?.length > 0 && (
          <View style={{ padding: 10 }}>
            <Text style={[styles.title, styles.defaultColor]}>Meios de Pagamento</Text>
            {closingPrintAbstract?.paymentTypes?.map(payment => {
              return <Text style={[styles.subtitle, styles.defaultColor]}>{payment?.description} ({payment?.transactionCount}): {convertToReal(payment?.amount)}</Text>
            })}
          </View>
        )}
        {closingPrintAbstract?.rotaryList?.length > 0 && (
          <View style={{ padding: 10 }}>
            <Text style={[styles.title, styles.defaultColor]}>Estacionamento</Text>
            {closingPrintAbstract?.rotaryList?.map(rotary => {
              return <Text style={[styles.subtitle, styles.defaultColor]}>{rotary?.description} ({rotary?.transactionCount}): {convertToReal(rotary?.amount)}</Text>
            })}
          </View>
        )}
        {closingPrintAbstract?.services?.length > 0 && (
          <View style={{ padding: 10 }}>
            <Text style={[styles.title, styles.defaultColor]}>Serviços</Text>
            {closingPrintAbstract?.services?.map(service => {
              return <Text style={[styles.subtitle, styles.defaultColor]}>{service?.description} ({service?.transactionCount}): {convertToReal(service?.amount)}</Text>
            })}
          </View>
        )}
        {closingPrintAbstract?.recurrentList?.length > 0 && (
          <View style={{ padding: 10 }}>
            <Text style={[styles.title, styles.defaultColor]}>Faturas</Text>
            {closingPrintAbstract?.recurrentList?.map(recurrent => {
              return <Text style={[styles.subtitle, styles.defaultColor]}>{recurrent?.description} ({recurrent?.transactionCount}): {convertToReal(recurrent?.amount)}</Text>
            })}
          </View>
        )}
        {closingPrintAbstract?.expenses?.length > 0 && (
          <View style={{ padding: 10 }}>
            <Text style={[styles.title, styles.defaultColor]}>Despesas Avulsas</Text>
            {closingPrintAbstract?.expenses?.map(expense => {
              return <Text style={[styles.subtitle, styles.defaultColor]}>{expense?.description} ({expense?.transactionCount}): {convertToReal(expense?.amount)}</Text>
            })}
          </View>
        )}
        {closingPrintAbstract?.receipts?.length > 0 && (
          <View style={{ padding: 10 }}>
            <Text style={[styles.title, styles.defaultColor]}>Receitas Avulsas</Text>
            {closingPrintAbstract?.receipts?.map(receipt => {
              return <Text style={[styles.subtitle, styles.defaultColor]}>{receipt?.description} ({receipt?.transactionCount}): {convertToReal(receipt?.amount)}</Text>
            })}
          </View>
        )}
        <View style={{ padding: 10 }}>
          <TitleItem title='Abertura de caixa'>{convertToReal(closingPrintAbstract?.openingAmount)}</TitleItem>
          <TitleItem title='Receita em dinheiro'>{convertToReal(closingPrintAbstract?.recipe)}</TitleItem>
          <TitleItem title='Despesas'>{convertToReal(closingPrintAbstract?.expense)}</TitleItem>
          <TitleItem title='Sangria'>{convertToReal(closingPrintAbstract?.cashDrain)}</TitleItem>
          <TitleItem title='Saldo em dinheiro em caixa'>{totalAmount}</TitleItem>
        </View>
      </Page>
    </Document>
  );
}

export default CashierDetailsResume;