import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchEmployee = async (userId, employeeId) => {
  const response = await newAPI.get(`${userId}/users/${employeeId}`);
  return response.data;
}

export const createNewEmployee = async (employeeId, params) => {
  const response = await newAPI.post(`/${employeeId}/users/new`, qs.stringify(params));
  return response.data;
}

export const updateEmployee = async (employeeId, userId, params) => {
  delete params?.availableEstablishments;

  const response = await newAPI.put(`${employeeId}/users/${userId}`, qs.stringify(params));
  return response.data;
}

export const fetchRestrictions = async userId => {
  const response = await newAPI.get(`/${userId}/tables/allrestrictions`);
  return response.data;
}