import React from 'react';
import moment from 'moment';
import qs from 'qs';

import {
  formatPhone,
  formatDocument,
  formatPostalCode,
  formatVehicle
} from 'helpers/formatters';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

import '../../../../components/ExportPDF/templates/fonts/Roboto-Regular.ttf';

export default function VehicleDamageExport({ data }) {
  const { width } = useWindowDimensions();
  const isSm = width < 769;

  const {
    content,
    qp,
    establishmentId,
    establishment,
    establishment: { addresses }
  } = data;

  const vehicle = data?.vehicle[0] || {};
  const establishmentAddresses = addresses?.length > 0 ? addresses[0] : {};

  const lineQnt = 2;

  const contentArray = content.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index / lineQnt)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  const Footer = ({ establishmentData }) => {
    return(
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
          padding: 10,
          borderTop: '1px solid #E2E2E2'
        }}
      >
        <div style={{ width: '50%' }}>
          {!!establishmentData?.establishmentName && (
            <p style={{ color: '#505050' }}>{establishmentData?.establishmentName}</p>
          )}
          {!!establishmentData?.phone && (
            <p style={{ color: '#505050' }}>{formatPhone(establishmentData?.phone)}</p>
          )}
          {!!establishmentData?.email && (
            <p style={{ color: '#505050' }}>{establishmentData?.email}</p>
          )}
        </div>
        <div style={{ width: '50%' }}>
          {!!establishmentData?.documentId && (
            <p style={{ color: '#505050' }}>CNPJ: {formatDocument(establishmentData?.documentId)}</p>
          )}
          {!!establishmentData?.street && (
            <p style={{ color: '#505050' }}>{establishmentData?.street}, {establishmentData?.number} {establishmentData?.district}</p>
          )}
          {!!establishmentData?.city && (
            <p style={{ color: '#505050' }}>{establishmentData?.city} - {establishmentData?.state} | CEP: {formatPostalCode(establishmentData?.postalCode)}</p>
          )}
        </div>
      </div>
    );
  }

  return(
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Roboto, sans-serif' }}>
      <h1 style={{ color: '#505050' }}>Histórico de Avarias</h1>
      {(establishmentId && !!qs.parse(qp)?.plate && Object.keys(vehicle || {}).length > 0) && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <h4 style={{ color: '#505050' }}>Placa: {vehicle?.plate}</h4>
          <h4 style={{ color: '#505050' }}>Modelo: {vehicle?.vehicleModel}</h4>
          <h4 style={{ color: '#505050' }}>Cor: {vehicle?.vehicleColor}</h4>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', marginTop: 15 }}>
        {contentArray?.map(content => {
          return(
            <div style={{ display: 'flex', flexDirection: isSm ? 'column' : 'row' }}>
              {content?.map(item => {
                return(
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: isSm ? '100%' : '50%', marginTop: 20 }}>
                    {!!item?.photoUrl ? (
                      <div style={{ width: '50%' }}>
                        <img
                          src={item?.photoUrl}
                          style={{
                            height: 'auto',
                            width: '100%'
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%', height: '100%', border: 'solid 1px #505050' }}>
                        <h3 style={{ color: '#505050' }}>Sem Foto</h3>
                      </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '5px 0 0 10px' }}>
                      {!!item?.plate && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Placa: {item?.plate}
                        </p>
                      )}
                      {!!item?.vehicleModel && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Veículo: {formatVehicle({ vehicleColor: item?.vehicleColor, vehicleModel: item?.vehicleModel })}
                        </p>
                      )}
                      {!!item?.partName && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Área: {item?.partName}
                        </p>
                      )}
                      {!!item?.categoryName && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Parte: {item?.categoryName}
                        </p>
                      )}
                      {!!item?.typeName && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Tipo: {item?.typeName}
                        </p>
                      )}
                      {!!item?.observation && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Observação: {item?.observation}
                        </p>
                      )}
                      {!!item?.registerDateTime && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Data de Criação: {moment(item?.registerDateTime).format('DD/MM/YYYY')}
                        </p>
                      )}
                      {!!item?.registerDateTime && (
                        <p style={{ margin: 0, color: '#505050' }}>
                          Horário: {moment(item?.registerDateTime).format('HH:mm:ss')}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {(typeof establishment == 'object' && !Array.isArray(establishment)) && (
        <Footer establishmentData={{ ...establishment, ...establishmentAddresses }} />
      )}
    </div>
  );
}