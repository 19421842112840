import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';
import Loader from '../../../../../components/Loader';
import Card from '../../../../../components/Card';
import FileUploader from '../../../../../components/FileUploader';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import CEP from '../../../../../components/CEP';
import AsyncSelect from '../../../../../components/AsyncSelect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { uploadLogo } from '../../../store/thunk';

export default function BasicInfo({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);

  const {
    tables: {
      streetType,
      taxationRegime,
      taxationRegimeSpecial
    },
    basicInfoErrors,
    configType,
    isLoading
  } = useSelector(state => state.provisionalReceiptProductConfig);

  const isSameEstablishmentConfig = +configType == 0;

  const handleUploadLogo = event => {
    const logo = event.target.files[0];
    dispatch(uploadLogo(userId, establishmentId, { logo }));
    formikProps.setFieldValue('logoFile', logo);
  }

  return(
    <Card title="Preencha as informações básicas do negócio" style={{ marginBottom: 50 }}>
      <Grid item xs={12}>
        <Loader isLoading={isLoading}>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6" style={{ marginBottom: 10 }}>Logo do estabelecimento</Typography>
              <FileUploader
                name="logoFile"
                file={formikProps.values.logoFile}
                errors={formikProps.errors.logoFile}
                onChange={event => handleUploadLogo(event)}
              >
                Escolha um arquivo
              </FileUploader>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography color='textSecondary' style={{ marginTop: 20, marginBottom: 20 }}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color="#F5B941"
                  size="lg"
                  style={{ marginRight: 10 }}
                />
                Tamanho recomendado é de 160x130. Formatos suportados (JPG, PNG e JPEG)
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">Informações básicas do negócio</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="companyName"
                type="text"
                label="Razão Social"
                value={formikProps.values.companyName || ''}
                error={formikProps.errors.companyName || !!basicInfoErrors?.companyName}
                helperText={formikProps.errors.companyName || basicInfoErrors?.companyName}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="fantasyName"
                type="text"
                label="Nome Fantasia"
                value={formikProps.values.fantasyName || ''}
                error={formikProps.errors.fantasyName || !!basicInfoErrors?.fantasyName}
                helperText={formikProps.errors.fantasyName || basicInfoErrors?.fantasyName}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="documentId"
                type="document"
                label="CNPJ"
                value={formikProps.values.documentId || ''}
                error={formikProps.errors.documentId || !!basicInfoErrors?.documentId}
                helperText={formikProps.errors.documentId || basicInfoErrors?.documentId}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="phone"
                type="phone"
                label="Telefone"
                value={formikProps.values.phone || ''}
                error={formikProps.errors.phone || !!basicInfoErrors?.phone}
                helperText={formikProps.errors.phone || basicInfoErrors?.phone}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="email"
                type="email"
                label="E-mail"
                value={formikProps.values.email || ''}
                error={formikProps.errors.email || !!basicInfoErrors?.email}
                helperText={formikProps.errors.email || basicInfoErrors?.email}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    disabled={isSameEstablishmentConfig}
                    name="stateSubscription"
                    label="Inscrição Estadual"
                    type="text"
                    value={formikProps.values.stateSubscription || ''}
                    error={formikProps.errors.stateSubscription || !!basicInfoErrors?.stateSubscription}
                    helperText={formikProps.errors.stateSubscription || basicInfoErrors?.stateSubscription}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    disabled={isSameEstablishmentConfig}
                    name="municipalSubscription"
                    label="Inscrição Municipal"
                    type="text"
                    value={formikProps.values.municipalSubscription || ''}
                    error={formikProps.errors.municipalSubscription || !!basicInfoErrors?.municipalSubscription}
                    helperText={formikProps.errors.municipalSubscription || basicInfoErrors?.municipalSubscription}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">Local onde o produto é vendido</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Select
                    disabled={isSameEstablishmentConfig}
                    name="streetType"
                    label="Tipo do local"
                    options={streetType}
                    value={+formikProps.values.streetType || ''}
                    error={formikProps.errors.streetType || !!basicInfoErrors?.streetType}
                    helperText={formikProps.errors.streetType || basicInfoErrors?.streetType}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <CEP
                disabled={isSameEstablishmentConfig}
                name="postalCode"
                type="postalCode"
                label="CEP"
                value={formikProps.values.postalCode || ''}
                error={formikProps.errors.postalCode || !!basicInfoErrors?.postalCode}
                helperText={formikProps.errors.postalCode || basicInfoErrors?.postalCode}
                onChange={postalCode => {
                  if(!postalCode.erro) {
                    const { cep, logradouro, complemento, bairro, localidade, uf } = postalCode;

                    formikProps.setFieldValue('postalCode', cep);
                    formikProps.setFieldValue('street', logradouro);
                    formikProps.setFieldValue('complement', complemento);
                    formikProps.setFieldValue('district', bairro);
                    formikProps.setFieldValue('city', localidade);
                    formikProps.setFieldValue('state', uf);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="street"
                type="text"
                label="Endereço"
                value={formikProps.values.street || ''}
                error={formikProps.errors.street || !!basicInfoErrors?.street}
                helperText={formikProps.errors.street || basicInfoErrors?.street}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="number"
                type="text"
                label="Número"
                value={formikProps.values.number || ''}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="complement"
                type="text"
                label="Complemento"
                value={formikProps.values.complement || ''}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="city"
                type="text"
                label="Cidade"
                value={formikProps.values.city || ''}
                error={formikProps.errors.city || !!basicInfoErrors?.city}
                helperText={formikProps.errors.city || basicInfoErrors?.city}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="district"
                type="text"
                label="Bairro"
                value={formikProps.values.district || ''}
                error={formikProps.errors.district || !!basicInfoErrors?.district}
                helperText={formikProps.errors.district || basicInfoErrors?.district}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="state"
                type="text"
                label="UF"
                value={formikProps.values.state || ''}
                error={formikProps.errors.state || !!basicInfoErrors?.state}
                helperText={formikProps.errors.state || basicInfoErrors?.state}
                onChange={formikProps.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">Informações tributárias</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <AsyncSelect
                disabled
                isProvisionalReceiptProduct
                name="cityCode"
                label="Município"
                value={formikProps.values.cityCode}
                error={formikProps.errors.cityCode || !!basicInfoErrors?.cityCode}
                helperText={formikProps.errors.cityCode || basicInfoErrors?.cityCode}
                onChange={(_, option) => {
                  if(option && option.cityCode) {
                    return formikProps.setFieldValue('cityCode', option.cityCode);
                  }

                  return formikProps.setFieldValue('cityCode', '');
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                disabled={isSameEstablishmentConfig}
                name="simpleNational"
                label="Simples nacional"
                options={[
                  { label: 'Sim', value: '1' },
                  { label: 'Não', value: '0' }
                ]}
                value={formikProps.values.simpleNational?.toString()}
                error={formikProps.errors.simpleNational || !!basicInfoErrors?.simpleNational}
                helperText={formikProps.errors.simpleNational || basicInfoErrors?.simpleNational}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                disabled={isSameEstablishmentConfig}
                name="taxationRegime"
                label="Regime tributário"
                options={taxationRegime}
                value={+formikProps.values.taxationRegime || ''}
                error={formikProps.errors.taxationRegime || !!basicInfoErrors?.taxationRegime}
                helperText={formikProps.errors.taxationRegime || basicInfoErrors?.taxationRegime}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                disabled={isSameEstablishmentConfig}
                name="taxationRegimeSpecial"
                label="Regime tributário especial"
                options={taxationRegimeSpecial}
                value={+formikProps.values.taxationRegimeSpecial || ''}
                error={formikProps.errors.taxationRegimeSpecial}
                helperText={formikProps.errors.taxationRegimeSpecial}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                disabled={isSameEstablishmentConfig}
                name="taxIncentive"
                label="Incentivo tributário"
                options={[
                  { label: 'Sim', value: '1' },
                  { label: 'Não', value: '0' }
                ]}
                value={formikProps.values.taxIncentive?.toString()}
                error={formikProps.errors.taxIncentive || !!basicInfoErrors?.taxIncentive}
                helperText={formikProps.errors.taxIncentive || basicInfoErrors?.taxIncentive}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                disabled={isSameEstablishmentConfig}
                name="encouraging"
                label="Incentivador cultural"
                options={[
                  { label: 'Sim', value: '1' },
                  { label: 'Não', value: '0' }
                ]}
                value={formikProps.values.encouraging?.toString()}
                error={formikProps.errors.encouraging || !!basicInfoErrors?.encouraging}
                helperText={formikProps.errors.encouraging || basicInfoErrors?.encouraging}
                onChange={formikProps.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">Geração de NFC-e</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="nfceTokenCsc"
                type="text"
                label="Token CSI"
                value={formikProps.values.nfceTokenCsc || ''}
                error={formikProps.errors.nfceTokenCsc || !!basicInfoErrors?.nfceTokenCsc}
                helperText={formikProps.errors.nfceTokenCsc || basicInfoErrors?.nfceTokenCsc}
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                disabled={isSameEstablishmentConfig}
                name="nfceIdTokenCsc"
                type="text"
                label="ID Token"
                value={formikProps.values.nfceIdTokenCsc || ''}
                error={formikProps.errors.nfceIdTokenCsc || !!basicInfoErrors?.nfceIdTokenCsc}
                helperText={formikProps.errors.nfceIdTokenCsc || basicInfoErrors?.nfceIdTokenCsc}
                onChange={formikProps.handleChange}
              />
            </Grid>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}