import { toast } from 'react-toastify';
import moment from 'moment';

import {
  fetchSingleTransaction,
  fetchTransaction,
  fetchResume,
  createTransaction,
  updateTransaction,
  updateSituation,
  fetchCashierDetails,
  fetchStoreCashierDetails,
  deleteTransaction,
  fetchWithdral,
  createAttachment,
  deleteAttachment,
  createCashierAttachment,
  deleteCashierAttachment
} from './services';

import {
  setTransaction,
  setResume,
  handleLoading,
  handleRegistration,
  clearTransaction,
  setCashierDetails,
  handleDetailing,
  setAttachment
} from './actions';

import { handleExporting } from '../../../components/Filter/store/actions';
import { convertFormattedMoneyAndNegativeMoneyToNumber } from '../../../helpers/converters';

export const loadSingleTransaction = (userId, establishmentId, transactionId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchSingleTransaction(userId, establishmentId, transactionId)
    .then(({ data }) => data)
    .catch(() => dispatch(handleLoading(false)))
    .finally(() => dispatch(handleLoading(false)));
}

export const loadTransaction = ({
  qp = '',
  page,
  pageSize,
  extraProps
}) => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId, startDate, endDate, exportation } = extraProps;

  const url = `${userId}/establishment/${establishmentId}/financial/extract?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;

  if (exportation) {
    dispatch(handleExporting(true));

    return fetchTransaction(url)
      .then(({ data }) => {
        dispatch(handleExporting(false));
        dispatch(handleLoading(false));
        return data;
      })
      .catch(() => {
        dispatch(handleExporting(false));
        dispatch(handleLoading(false));
      });
  }

  return fetchTransaction(url)
    .then(({ data }) => {
      dispatch(setTransaction(data));
      dispatch(handleLoading(false));
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const loadResume = (userId, establishmentId, extraProps) => dispatch => {
  const { startDate, endDate, qp } = extraProps;

  const url = `${userId}/establishment/${establishmentId}/financial/resume?startDate=${startDate}&endDate=${endDate}${qp}`;

  return fetchResume(url)
    .then(data => dispatch(setResume(data)));
}

export const upsertTransaction = (
  params,
  extraProps,
  isChangingConfirmation = false,
  isChangingAmount = false
) => dispatch => {
  dispatch(handleLoading(true));

  const {
    isConfirm,
    transactionId,
    transactionTypeId,
    description,
    categoryId,
    accountId,
    amount,
    previsionDateTime,
    paymentDateTime,
    confirmationDateTime,
    paymentMethodId,
    attachment
  } = params;

  const amountConverted = convertFormattedMoneyAndNegativeMoneyToNumber(amount);
  let situationId = isConfirm ? 2 : 1;

  if (transactionTypeId === 2 && isConfirm) {
    situationId = 3;
  }

  const transactionDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

  const formatPrevisionDateTime =
    previsionDateTime.split(':').length > 1
      ? previsionDateTime
      : `${previsionDateTime} 00:00:01`;

  let paramsConverted;

  if (isChangingConfirmation) {
    paramsConverted = {
      transactionId,
      confirmationDateTime
    }
  } else if (isChangingAmount) {
    paramsConverted = {
      transactionId,
      amount: amountConverted
    }
  } else {
    paramsConverted = {
      transactionId,
      transactionDateTime,
      description,
      categoryId,
      accountId,
      situationId,
      paymentMethodId,
      attachment,
      amount: amountConverted,
      previsionDateTime: formatPrevisionDateTime,
      paymentDateTime: isConfirm ? paymentDateTime : null,
      confirmationDateTime: transactionTypeId === 2 && isConfirm ? confirmationDateTime : null
    }
  }

  const { userId, establishmentId } = extraProps;

  if (!paramsConverted.transactionId) {
    return createTransaction(userId, establishmentId, paramsConverted)
      .then(() => {
        toast.success('Transação cadastrada com sucesso.');
        dispatch(handleRegistration(false, 'receipt'));
        dispatch(clearTransaction());
        dispatch(
          loadTransaction({
            page: extraProps.page,
            pageSize: extraProps.pageSize,
            extraProps: {
              userId,
              establishmentId,
              ...extraProps
            }
          })
        );
      })
      .catch(() => dispatch(handleLoading(false)));
  }

  return updateTransaction(userId, establishmentId, paramsConverted, paramsConverted.transactionId)
    .then(() => {
      toast.success('Transação atualizada com sucesso.');
      dispatch(handleRegistration(false, 'receipt'));
      dispatch(clearTransaction());
      dispatch(
        loadTransaction({
          page: extraProps.page,
          pageSize: extraProps.pageSize,
          extraProps: {
            userId,
            establishmentId,
            ...extraProps
          }
        })
      );
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const changeStatus = (
  transactionId,
  situationId,
  extraProps
) => dispatch => {
  const params = {
    situationId: situationId === 2 ? 3 : 2
  }

  const { userId, establishmentId } = extraProps;

  return updateSituation(userId, establishmentId, transactionId, params)
    .then(() => {
      dispatch(
        loadTransaction({
          page: extraProps.page,
          pageSize: extraProps.pageSize,
          extraProps: {
            userId,
            establishmentId,
            ...extraProps
          }
        })
      );
      toast.success('Transação atualizada com sucesso!');
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const loadCashierDetails = (userId, establishmentId, sourceId) => dispatch => {
  dispatch(handleDetailing(true));

  return fetchCashierDetails(userId, establishmentId, sourceId)
    .then(({ data }) => {
      dispatch(setCashierDetails(data));
      return data;
    })
    .finally(() => dispatch(handleDetailing(false)));
}

export const loadStoreCashierDetails = (userId, establishmentId, storeCashierId) => dispatch => {
  dispatch(handleDetailing(true));

  return fetchStoreCashierDetails(userId, establishmentId, storeCashierId)
    .then(({ data }) => {
      dispatch(setCashierDetails(data));
      return data;
    })
    .finally(() => dispatch(handleDetailing(false)));
}

export const removeTransaction = (params, extraProps) => dispatch => {
  const { transactionId } = params;

  const { userId, establishmentId } = extraProps;

  dispatch(handleLoading(true));

  return deleteTransaction(userId, establishmentId, transactionId, params)
    .then(() => {
      toast.success('Transação removida com sucesso.');
      dispatch(handleRegistration(false, 'receipt'));
      dispatch(clearTransaction());
      dispatch(
        loadTransaction({
          page: extraProps.page,
          pageSize: extraProps.pageSize,
          extraProps: {
            userId,
            establishmentId,
            ...extraProps
          }
        })
      );
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const getWithdral = transactionId => dispatch => {
  dispatch(handleDetailing(true));

  return fetchWithdral(transactionId)
    .then(({ data }) => {
      dispatch(setCashierDetails(data));
    })
    .finally(() => dispatch(handleDetailing(false)));
}

export const upsertAttachment = (userId, establishmentId, file) => dispatch => {
  return createAttachment(userId, establishmentId, file)
    .then(res => dispatch(setAttachment(res.data)));
}

export const removeAttachment = (userId, establishmentId, file) => dispatch => {
  return deleteAttachment(userId, establishmentId, file);
}

export const upsertCashierAttachment = (userId, establishmentId, cashierId, file, column) => dispatch => {
  return createCashierAttachment(userId, establishmentId, cashierId, file, column);
}

export const removeCashierAttachment = (userId, establishmentId, cashierId, file) => dispatch => {
  return deleteCashierAttachment(userId, establishmentId, cashierId, file)
    .then(res => res);
}