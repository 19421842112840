import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setAutomations = data => ({
  type: types.SET_AUTOMATIONS,
  data,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const clearAutomations = () => ({
  type: types.CLEAR_AUTOMATIONS,
});
