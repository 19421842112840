import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export default function PageNotFound() {
  const classes = useStyles();

  return (
    <Container className={classes.wrapper}>
      <Box component="section" className={classes.contentError}>
        <Box component="div" className={classes.errorBlock}>
          <Typography variant="h2" className={classes.errorText}>
            Ocorreu um erro
          </Typography>
          <Typography variant="h1" className={classes.errorTitle}>
            404
          </Typography>
          <Typography variant="h2" className={classes.errorDescription}>
            Página não encontrada
          </Typography>
        </Box>
        <Box component="div" className={classes.description}>
          A página que tentou acessar não foi encontrada ou não existe.
        </Box>
        <Box component="div" className={classes.actions}>
          Volte a <a href="/">página inicial</a> ou{' '}
          <a href="https://jumppark.com.br/">acesse o nosso site</a>.
        </Box>
      </Box>
    </Container>
  );
}
