import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginBottom: spacing(3),
  },
  subtitle: {
    color: palette.success.main,
    marginTop: 16,
  },
}));
