import { toast } from 'react-toastify';
import {
  fetchConfigurations,
  createConfigurations,
  updateConfigurations,
} from './service';
import {
  setConfigurations,
  clearConfigurations,
  handleLoading,
} from './actions';

export const loadConfigurations = () => dispatch => {
  dispatch(handleLoading(true));

  return fetchConfigurations()
    .then(({ data }) => dispatch(setConfigurations(data)))
    .catch(error => {
      const { code } = error.response.data.data;
      return code === '21001' && dispatch(clearConfigurations());
    })
    .finally(() => dispatch(handleLoading(false)));
};

export const upsertConfigurations = (params, hasConfiguration) => dispatch => {
  dispatch(handleLoading(true));

  if (!hasConfiguration) {
    return createConfigurations(params)
      .then(({ data }) => {
        toast.success('Configurações criadas com sucesso');
        dispatch(setConfigurations(data));
      })
      .catch(() => dispatch(handleLoading(false)));
  }
  return updateConfigurations(params)
    .then(({ data }) => {
      toast.success('Configurações atualizadas com sucesso');
      dispatch(setConfigurations(data));
    })
    .finally(() => dispatch(handleLoading(false)));
};
