import { toast } from 'react-toastify';
import { setClientInvoices, setInvoiceDetails, handleLoading, handleDetailing, clearForm } from './actions';
import { loadTransaction } from '../../Transactions/store/thunk';
import { requestLoadClients } from '../../ClientList/store/thunk';

import {
  fetchClientInvoiceServiceOrders,
  fetchReceiptInvoicePrint,
  fetchClientInvoices,
  fetchInvoiceDetails,
  fetchInvoiceServiceContractDetails,
  requestRemoveServiceContract,
  updateInvoice,
  requestToPayInvoice,
  requestUndoPayment,
  requestToSendEmail,
  requestToRestoreInvoice,
  requestToCancelInvoice
} from './services';

export const loadClientInvoices = (userId, establishmentId, clientId) => dispatch => {
  const url = `${userId}/establishment/${establishmentId}/clients/${clientId}/invoices`;

  dispatch(handleLoading(true));

  return fetchClientInvoices(url)
    .then(({ data }) => dispatch(setClientInvoices(data)))
    .catch(() => dispatch(handleLoading(false)));
}

export const loadInvoiceDetails = (userId, establishmentId, clientId, invoiceDateTime) => async dispatch => {
  const params = {
    userId,
    establishmentId,
    clientId,
    invoiceDateTime
  }

  dispatch(handleDetailing(true));

  try {
    const { data } = await fetchInvoiceDetails(params);
    dispatch(setInvoiceDetails(data));
    return data;
  } catch {
    return dispatch(handleDetailing(false));
  }
}

export const loadReceiptInvoicePrint = params => dispatch => {
  return fetchReceiptInvoicePrint(params).then(({ data }) => {
    return data;
  });
}

export const loadPrintDetails = (userId, establishmentId, clientId, invoiceDateTime) => dispatch => {
  const params = {
    userId,
    establishmentId,
    clientId,
    invoiceDateTime
  }

  dispatch(handleDetailing(true));

  return fetchInvoiceDetails(params)
    .then(({ data }) => {
      return data;
    })
    .finally(() => dispatch(handleDetailing(false)));
}

export const loadReceiptServiceOrderInvoicePos = params => {
  return fetchClientInvoiceServiceOrders(params).then(( {data} ) => {
    return data;
  });
}

export const loadInvoiceServiceContractDetails = (userId, establishmentId, clientId, invoiceDateTime) => dispatch => {
  dispatch(handleDetailing(true));

  return fetchInvoiceServiceContractDetails(userId, establishmentId, clientId, invoiceDateTime)
    .then(({ data }) => dispatch(setInvoiceDetails(data)))
    .finally(() => dispatch(handleDetailing(false)));
}

export const removeServiceContract = (userId, establishmentId, serviceContractId, params, setIsOpenInvoiceServiceContract) => dispatch => {
  dispatch(handleDetailing(true));

  const { qp, page, pageSize, startDate, endDate } = params;

  return requestRemoveServiceContract(userId, establishmentId, serviceContractId)
    .then(() => {
      dispatch(loadTransaction({
        qp,
        page,
        pageSize,
        extraProps: { userId, establishmentId, startDate, endDate }
      }));

      setIsOpenInvoiceServiceContract(false);

      toast.success('Pacote de serviço removido com sucesso.');
    })
    .finally(() => dispatch(handleDetailing(false)));
}

export const upsertInvoice = (params, properties) => dispatch => {
  const {
    userId,
    establishmentId,
    clientId,
    invoiceDateTime,
    page,
    pageSize,
    pageOrigin,
    qp
  } = properties

  dispatch(handleDetailing(true));

  return updateInvoice(userId, establishmentId, clientId, invoiceDateTime, params)
    .then(() => {
      toast.success('Fatura atualizada com sucesso');

      dispatch(loadClientInvoices(userId, establishmentId, clientId));
      dispatch(loadInvoiceDetails(userId, establishmentId, clientId, invoiceDateTime));

      if(pageOrigin === 'clients') {
        dispatch(
          requestLoadClients({
            page,
            pageSize,
            userId,
            establishmentId,
            qp,
            extraProps: { type: null }
          })
        );
      }
    })
    .catch(() => dispatch(handleDetailing(false)));
}

export const payInvoice = (params, properties) => dispatch => {
  const {
    userId,
    establishmentId,
    clientId,
    invoiceDateTime,
    page,
    pageSize,
    pageOrigin,
    qp
  } = properties;

  dispatch(handleDetailing(true));

  return requestToPayInvoice(userId, establishmentId, clientId, invoiceDateTime, params)
    .then(({ data }) => {
      toast.success(data.msg);

      dispatch(loadInvoiceDetails(userId, establishmentId, clientId, invoiceDateTime));
      dispatch(loadClientInvoices(userId, establishmentId, clientId));

      if(pageOrigin == 'clients' || pageOrigin == 'invoices') {
        dispatch(
          requestLoadClients({
            page,
            pageSize,
            userId,
            establishmentId,
            qp,
            extraProps: { type: null }
          })
        );
      }
    })
    .catch(() => dispatch(handleDetailing(false)));
}

export const undoPayment = (params, properties) => dispatch => {
  const {
    userId,
    establishmentId,
    clientId,
    invoiceDateTime,
    page,
    pageSize,
    startDate,
    endDate,
    pageOrigin,
    qp
  } = properties;

  dispatch(handleDetailing(true));

  return requestUndoPayment(userId, establishmentId, clientId, invoiceDateTime, params)
    .then(({ data }) => {
      if(pageOrigin === 'transactions') {
        dispatch(loadTransaction({
          qp,
          page,
          pageSize,
          extraProps: { userId, establishmentId, startDate, endDate }
        }));
      }

      toast.success(data.msg);

      dispatch(loadInvoiceDetails(userId, establishmentId, clientId, invoiceDateTime));
      dispatch(loadClientInvoices(userId, establishmentId, clientId));
    })
    .catch(() => dispatch(handleDetailing(false)));
}

export const emailInvoice = (userId, establishmentId, clientId, invoiceDateTime, params) => dispatch => {
  dispatch(handleDetailing(true));

  return requestToSendEmail(userId, establishmentId, clientId, invoiceDateTime, params)
    .then(({ data }) => {
      toast.success(data.msg);
      dispatch(handleDetailing(false));
      dispatch(clearForm());
    })
    .catch(() => dispatch(handleDetailing(false)));
}

export const changeInvoiceSituation = (
  userId,
  establishmentId,
  clientId,
  invoiceDateTime,
  params,
  properties
) => dispatch => {
  const { situationId } = params;
  const { page, pageSize, startDate, endDate, pageOrigin, qp } = properties;

  dispatch(handleDetailing(true));

  if(situationId === 3) {
    return requestToRestoreInvoice(userId, establishmentId, clientId, invoiceDateTime, params)
      .then(({ data }) => {
        toast.success(data.msg);
        dispatch(loadClientInvoices(userId, establishmentId, clientId));

        if(pageOrigin === 'transactions') {
          dispatch(loadTransaction({
            qp,
            page,
            pageSize,
            extraProps: { userId, establishmentId, startDate, endDate }
          }));
        }

        if(pageOrigin === 'clients') {
          dispatch(
            requestLoadClients({
              userId,
              establishmentId,
              page,
              pageSize,
              qp: '',
              extraProps: { type: null }
            })
          );
        }
      })
      .catch(() => dispatch(handleDetailing(false)));
  }

  return requestToCancelInvoice(userId, establishmentId, clientId, invoiceDateTime, params)
    .then(({ data }) => {
      toast.success(data.msg);

      dispatch(loadClientInvoices(userId, establishmentId, clientId));

      if(pageOrigin === 'transactions') {
        dispatch(loadTransaction({
          qp,
          page,
          pageSize,
          extraProps: { userId, establishmentId, startDate, endDate }
        }));
      }

      if(pageOrigin === 'clients') {
        dispatch(
          requestLoadClients({
            userId,
            establishmentId,
            page,
            pageSize,
            qp: '',
            extraProps: { type: null }
          })
        );
      }
    })
    .catch(() => dispatch(handleDetailing(false)));
}