import { fetchClients, fetchClientExporation } from './services';
import { setClients, handleLoading } from './actions';
import { handleExporting } from '../../../components/Filter/store/actions';

export const requestLoadClients = ({
  userId,
  establishmentId,
  page,
  pageSize,
  qp = ''
}) => dispatch => {
  let url = `${userId}/clients?&pg=${page}&limit=${pageSize}&orderBy=name&sort=asc${qp}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/clients?&pg=${page}&limit=${pageSize}&orderBy=name&sort=asc${qp}`;
  }

  dispatch(handleLoading(true));

  return fetchClients(url)
    .then(({ data }) => {
      dispatch(setClients(data.content, data.total));
      dispatch(handleLoading(false));
    })
    .catch(err => console.log('err', err))
    .finally(() => dispatch(handleLoading(false)));
}

export const requestClientExportation = (userId, establishmentId, qp) => dispatch => {
  dispatch(handleExporting(true));

  let url = `${userId}/clients/export?orderBy=name&sort=asc${qp}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/clients/export?orderBy=name&sort=asc${qp}`;
  }

  return fetchClientExporation(url)
    .then(({ data }) => {
      dispatch(handleExporting(false));
      return data;
    });
}