import { compose, merge, set, lensProp, pick, map, assoc, prop } from 'ramda';
import {
  REGISTER_FIELD_CHANGE,
  SET_CATEGORIES,
  LOADING_INFORMATIONS,
  HANDLE_REGISTRATION,
  HANDLE_UPDATE,
  CLEAR_REGISTRATION,
} from './constants';

const defaultState = {
  list: [],
  total: 0,
  isLoading: false,
  registration: false,
  categoryId: null,
  categoryName: '',
  transactionTypeId: null,
  costCenterId: null,
  status: 1,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      let { value } = action;

      if (action.field === 'status') {
        value = parseInt(value, 10);
      }

      return set(lensProp(action.field), value, state);
    }
    case SET_CATEGORIES: {
      const formatActivities = activity => {
        const formatter = compose(
          assoc('transactionType', prop('transactionTypeId', activity)),
          assoc('costCenter', prop('costCenterId', activity))
        );

        return formatter(activity);
      };
      const categories = map(formatActivities, action.data);

      const categoriesState = compose(
        set(lensProp('list'), categories),
        set(lensProp('total'), action.total),
        set(lensProp('registration'), false),
        set(lensProp('isLoading'), false)
      );

      return categoriesState(state);
    }
    case HANDLE_REGISTRATION: {
      return set(lensProp('registration'), action.status, state);
    }
    case HANDLE_UPDATE: {
      const account = pick(
        [
          'establishmentId',
          'categoryId',
          'categoryName',
          'transactionTypeId',
          'costCenterId',
          'mainCategory',
          'status',
        ],
        action.data
      );
      return compose(
        set(lensProp('registration'), true),
        merge(state)
      )(account);
    }
    case CLEAR_REGISTRATION: {
      const clear = compose(
        set(lensProp('list'), state.list),
        set(lensProp('total'), state.total)
      );

      return clear(defaultState);
    }
    case LOADING_INFORMATIONS:
      return {
        ...state,
        isLoading: action.status,
      };
    default:
      return state;
  }
};
