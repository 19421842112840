export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'plate',
    label: 'Placa',
    placeholder: 'Placa',
    value: '',
  },
];
