import { toast } from 'react-toastify';

import {
  fetchTablesFromEstablishment,
  fetchTables,
  updateComponent,
  requestRemoveTable,
  createTable,
  updateTable,
  createSpecial,
  updateSpecial,
  requestToRemoveSpecialTable,
  fetchCovenant,
  createCovenant,
  updateCovenant,
  updateCompulsary
} from './service';

import {
  handleLoading,
  setTables,
  clearPriceTable,
  setCovenant,
  clearCovenant,
  handleCovenant
} from './actions';

export const loadTablesFromEstablishment = establishmentId => dispatch => {
  dispatch(handleLoading(true));

  return fetchTablesFromEstablishment(establishmentId)
    .then(({ data }) => dispatch(setTables(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const loadTables = () => dispatch => {
  dispatch(handleLoading(true));

  return fetchTables()
    .then(({ data }) => dispatch(setTables(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const changeTableProp = (params, typePrice) => dispatch => {
  dispatch(handleLoading(true));

  return updateComponent(params, typePrice)
    .then(() => {
      toast.success('Tabela atualizada com sucesso');
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const removeTable = typePrice => dispatch => {
  dispatch(handleLoading(true));

  return requestRemoveTable(typePrice)
    .then(({ data }) => {
      toast.success(data.msg);
      dispatch(clearPriceTable());
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertTable = ({ updateType, ...params }) => dispatch => {
  dispatch(handleLoading(true));

  const { items, typePrice, originalTypePrice } = params;
  const formattedItems = items.filter(item => item?.period != 0 || item?.since != 0);

  params.items = formattedItems;

  delete params['originalTypePrice'];

  if(!updateType) {
    return createTable(params)
      .then(() => {
        toast.success('Tabela cadastrada com sucesso');
        dispatch(clearPriceTable());
        dispatch(loadTables());
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateTable(params, typePrice, originalTypePrice)
    .then(() => {
      toast.success('Tabela editada com sucesso');
      dispatch(clearPriceTable());
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertSpecial = ({ linkedTable, updateType, ...params }) => dispatch => {
  dispatch(handleLoading(true));

  if(!updateType) {
    return createSpecial(params, linkedTable)
      .then(() => {
        toast.success('Condição especial cadastrada com sucesso');
        dispatch(clearPriceTable());
        dispatch(loadTables());
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateSpecial(params, linkedTable, updateType)
    .then(() => {
      toast.success('Condição especial editada com sucesso');
      dispatch(clearPriceTable());
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const changeSpecialTableProp = (params, typePrice, description) => dispatch => {
  dispatch(handleLoading(true));

  return updateSpecial(params, typePrice, description)
    .then(() => {
      toast.success('Componente atualizado com sucesso');
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const changeSpecialTablePropCompulsory = (params, typePrice, description) => dispatch => {
  dispatch(handleLoading(true));

  return updateCompulsary(params, typePrice, description)
    .then(() => {
      toast.success('Componente atualizado com sucesso');
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const removeSpecialTable = params => dispatch => {
  dispatch(handleLoading(true));

  return requestToRemoveSpecialTable(params)
    .then(({ data }) => {
      toast.success(data.msg);
      dispatch(clearPriceTable());
      dispatch(loadTables());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadCovenant = typePrice => dispatch => {
  dispatch(handleLoading(true));

  return fetchCovenant(typePrice)
    .then(({ data }) => {
      return dispatch(setCovenant(data));
    })
    .catch(error => {
      if (error.response.data.data.code === '19001') {
        dispatch(clearCovenant());
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertCovenant = params => dispatch => {
  dispatch(handleLoading(true));

  const requestSuccess = (data, text) => {
    toast.success(text);
    dispatch(setCovenant(data));
    dispatch(loadTables());
    dispatch(handleCovenant(false));
  }

  if(!params.accessCode) {
    return createCovenant(params)
      .then(({ data }) => requestSuccess(data, 'Convênio criado com sucesso'))
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateCovenant(params)
    .then((data) => requestSuccess(data, 'Convênio atualizado com sucesso'))
    .finally(() => dispatch(handleLoading(false)));
}