import {
  SET_IS_LOADING,
  SET_CONFIG_TYPE,
  SET_SELECTED_ESTABLISHMENT_CONFIG,
  SET_DOES_CONFIG_EXISTS,
  SET_PROVISIONAL_RECEIPT_PRODUCT,
  SET_CERTIFICATE,
  SET_CERTIFICATE_FILE,
  SET_BASIC_INFO,
  SET_BASIC_INFO_ERRORS,
  CLEAR_BASIC_INFO_ERRORS,
  SET_FILTERS,
  SET_TABLES,
  SET_TAX_SETTINGS_ESTABLISHMENTS,
  CLEAR_PROVISIONAL_RECEIPT_PRODUCT
} from './constants';

import { checkedValueByType } from 'helpers/common';

const defaultState = {
  isLoading: false,
  configType: '1',
  certificateFile: null,
  certificateFileFromAPI: null,
  doesConfigExists: false,
  selectedEstablishmentConfig: '',
  taxSettingsEstablishments: [],
  tables: {
    streetType: [],
    taxationRegime: [],
    taxationRegimeSpecial: []
  },
  certificate: {
    certificateFile: '',
    certificateId: '',
    certificateName: '',
    certificateExpirationDate: '',
    certificatePassword: ''
  },
  basicInfo: {
    certificateId: '',
    uuid: '',
    companyName: '',
    fantasyName: '',
    documentId: '',
    municipalSubscription: '',
    stateSubscription: '',
    phone: '',
    email: '',
    streetType: '',
    postalCode: '',
    street: '',
    number: '',
    complement: '',
    city: '',
    district: '',
    state: '',
    cityCode: '',
    simpleNational: '',
    taxationRegime: '',
    taxationRegimeSpecial: '',
    taxIncentive: '',
    encouraging: '',
    logoFile: '',
    nfceTokenCsc: '',
    nfceIdTokenCsc: ''
  },
  basicInfoErrors: {
    companyName: '',
    fantasyName: '',
    documentId: '',
    municipalSubscription: '',
    stateSubscription: '',
    phone: '',
    email: '',
    streetType: '',
    postalCode: '',
    street: '',
    city: '',
    district: '',
    state: '',
    cityCode: '',
    simpleNational: '',
    taxationRegime: '',
    taxIncentive: '',
    encouraging: '',
    nfceTokenCsc: '',
    nfceIdTokenCsc: ''
  },
  filter: {
    orders: false,
    selectedTransactions: false,
    paymentMethods: [],
    nfeActive: 0
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_CONFIG_TYPE: {
      return {
        ...state,
        configType: action.config
      }
    }
    case SET_SELECTED_ESTABLISHMENT_CONFIG: {
      return {
        ...state,
        selectedEstablishmentConfig: action.establishment
      }
    }
    case SET_DOES_CONFIG_EXISTS: {
      return {
        ...state,
        doesConfigExists: action.config
      }
    }
    case SET_PROVISIONAL_RECEIPT_PRODUCT: {
      const {
        certificateFile,
        certificateId,
        certificateName,
        certificateExpirationDate,
        certificatePassword,
        uuid,
        taxSettingOwner,
        companyName,
        fantasyName,
        documentId,
        phone,
        email,
        stateSubscription,
        municipalSubscription,
        streetType,
        postalCode,
        street,
        number,
        complement,
        city,
        district,
        state: uf,
        cityCode,
        simpleNational,
        taxationRegime,
        taxationRegimeSpecial,
        taxIncentive,
        encouraging,
        nfceTokenCsc,
        nfceIdTokenCsc
      } = action.data;

      return {
        ...state,
        certificateFileFromAPI: certificateFile,
        configType: taxSettingOwner,
        certificate: {
          certificateFile,
          certificateId,
          certificateName,
          certificateExpirationDate,
          certificatePassword
        },
        basicInfo: {
          certificateId,
          uuid,
          companyName,
          fantasyName,
          documentId,
          phone,
          email,
          stateSubscription,
          municipalSubscription,
          streetType,
          postalCode,
          street,
          number,
          complement,
          city,
          district,
          state: uf,
          cityCode,
          simpleNational,
          taxationRegime,
          taxationRegimeSpecial,
          taxIncentive,
          encouraging,
          nfceTokenCsc,
          nfceIdTokenCsc
        }
      }
    }
    case SET_CERTIFICATE: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          ...action.data
        }
      }
    }
    case SET_CERTIFICATE_FILE: {
      return {
        ...state,
        certificateFile: action?.data
      }
    }
    case SET_BASIC_INFO: {
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          ...action.data
        }
      }
    }
    case SET_BASIC_INFO_ERRORS: {
      return {
        ...state,
        basicInfoErrors: {
          companyName: action?.errors?.companyName ? action?.errors?.companyName[0] : null,
          fantasyName: action?.errors?.fantasyName ? action?.errors?.fantasyName[0] : null,
          documentId: action?.errors?.documentId ? action?.errors?.documentId[0] : null,
          municipalSubscription: action?.errors?.municipalSubscription ? action?.errors?.municipalSubscription[0] : null,
          stateSubscription: action?.errors?.stateSubscription ? action?.errors?.stateSubscription[0] : null,
          phone: action?.errors?.phone ? action?.errors?.phone[0] : null,
          email: action?.errors?.email ? action?.errors?.email[0] : null,
          streetType: action?.errors?.streetType ? action?.errors?.streetType[0] : null,
          postalCode: action?.errors?.postalCode ? action?.errors?.postalCode[0] : null,
          street: action?.errors?.street ? action?.errors?.street[0] : null,
          city: action?.errors?.city ? action?.errors?.city[0] : null,
          district: action?.errors?.district ? action?.errors?.district[0] : null,
          state: action?.errors?.state ? action?.errors?.state[0] : null,
          cityCode: action?.errors?.cityCode ? action?.errors?.cityCode[0] : null,
          simpleNational: action?.errors?.simpleNational ? action?.errors?.simpleNational[0] : null,
          taxationRegime: action?.errors?.taxationRegime ? action?.errors?.taxationRegime[0] : null,
          taxIncentive: action?.errors?.taxIncentive ? action?.errors?.taxIncentive[0] : null,
          encouraging: action?.errors?.encouraging ? action?.errors?.encouraging[0] : null,
          nfceTokenCsc: action?.errors?.nfceTokenCsc ? action?.errors?.nfceTokenCsc[0] : null,
          nfceIdTokenCsc: action?.errors?.nfceIdTokenCsc ? action?.errors?.nfceIdTokenCsc[0] : null
        }
      }
    }
    case CLEAR_BASIC_INFO_ERRORS: {
      return {
        ...state,
        basicInfoErrors: defaultState.basicInfoErrors
      }
    }
    case SET_FILTERS: {
      const {
        nfeActive,
        automaticGenerateNfe
      } = action.data;

      if(!automaticGenerateNfe) {
        return {
          ...state
        }
      }

      const {
        orders,
        selectedTransactions,
        paymentMethods
      } = automaticGenerateNfe;

      return {
        ...state,
        filter: {
          ...state.filter,
          nfeActive,
          orders: checkedValueByType(orders, 'boolean'),
          selectedTransactions: checkedValueByType(selectedTransactions, 'boolean'),
          paymentMethods
        }
      }
    }
    case SET_TABLES: {
      const {
        streetType,
        taxationRegime,
        taxationRegimeSpecial
      } = action.data;

      const formattedStreetType = streetType?.map(type => {
        return {
          value: type?.id,
          label: type?.name
        }
      });

      const formattedTaxationRegime = taxationRegime?.map(type => {
        return {
          value: type?.id,
          label: type?.name
        }
      });

      const formattedTaxationRegimeSpecial = taxationRegimeSpecial?.map(type => {
        return {
          value: type?.id,
          label: type?.name
        }
      });

      return {
        ...state,
        tables: {
          streetType: formattedStreetType,
          taxationRegime: formattedTaxationRegime,
          taxationRegimeSpecial: formattedTaxationRegimeSpecial
        }
      }
    }
    case SET_TAX_SETTINGS_ESTABLISHMENTS: {
      return {
        ...state,
        taxSettingsEstablishments: action?.data
      }
    }
    case CLEAR_PROVISIONAL_RECEIPT_PRODUCT: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}