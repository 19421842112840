import * as yup from 'yup';
import { documentRegExp, phoneRegExp } from 'helpers/regex';

export const validations = yup.object().shape({
  cnpj: yup
    .string()
    .trim()
    .matches(documentRegExp, 'O CNPJ precisa ser válido')
    .required('O CNPJ é obrigatório')
    .nullable(),
  fantasyName: yup
    .string()
    .required('O nome fantasia é obrigatório')
    .nullable(),
  corporateName: yup
    .string()
    .required('A razão social é obrigatória')
    .nullable(),
  contacts: yup
    .array(
      yup.object({
        name: yup
          .string()
          .required('O nome é obrigatório')
          .nullable(),
        email: yup
          .string()
          .email('O e-mail precisa ser válido')
          .required('O e-mail é obrigatório')
          .nullable(),
        phone: yup
          .string()
          .trim()
          .matches(phoneRegExp, 'O telefone precisa ser válido')
          .required('O telefone é obrigatório')
          .nullable()
      })
    ),
  cep: yup
    .string()
    .required('O CEP é obrigatório')
    .nullable(),
  address: yup
    .string()
    .required('O endereço é obrigatório')
    .nullable(),
  addressNumber: yup
    .string()
    .required('O número é obrigatório')
    .nullable(),
  city: yup
    .string()
    .required('A cidade é obrigatória')
    .nullable(),
  district: yup
    .string()
    .required('O bairro é obrigatório')
    .nullable(),
  state: yup
    .string()
    .required('O estado é obrigatório')
    .nullable()
});

export const contactValidations = yup.object().shape({
  name: yup
    .string()
    .required('O nome é obrigatório')
    .nullable(),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .required('O e-mail é obrigatório')
    .nullable(),
  phone: yup
    .string()
    .trim()
    .matches(phoneRegExp, 'O telefone precisa ser válido')
    .required('O telefone é obrigatório')
    .nullable()
});