import {
  SET_NOTIFICATION,
  SET_NOTIFICATION_SITUATIONS,
  SET_CURRENT_NOTIFICATION_FILTER_ID,
  SET_CURRENT_PREVISION_FILTER_ID,
  SET_IS_LOADING
} from './constants';

const defaultState = {
  content: [],
  situations: [],
  currentNotificationFilterId: null,
  currentPrevisionFilterId: null,
  total: null,
  isLoading: false
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_NOTIFICATION: {
      return {
				...state,
        total: action.data.total,
				content: action.data.content
      }
    }
    case SET_NOTIFICATION_SITUATIONS: {
      return {
        ...state,
        situations: action.data.content
      }
    }
    case SET_CURRENT_NOTIFICATION_FILTER_ID: {
      return {
        ...state,
        currentNotificationFilterId: action.data
      }
    }
    case SET_CURRENT_PREVISION_FILTER_ID: {
      return {
        ...state,
        currentPrevisionFilterId: action.data
      }
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
		default: {
      return state;
    }
  }
}