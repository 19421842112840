import * as type from './constants';

export const changeDate = (startDate, endDate) => ({
  type: type.CHANGE_DATE,
  startDate,
  endDate,
});

export const clearDate = () => ({
  type: type.CLEAR_DATE,
});
