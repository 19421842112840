import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Pagination as Pages } from '@material-ui/lab';

import { formatQuery } from '../../../helpers/formatters';

import { setNumberOfPages, setActivePage } from '../store/actions';

export default function Pagination({ totalItems, onSelect }) {
  const dispatch = useDispatch();
  const pages = useSelector(state => state.pagination.pages);
  const activePage = useSelector(state => state.pagination.activePage);
  const sizeStored = useSelector(state => state.pagination.size);
  const search = useSelector(state => state.filters.search);
  const filtrate = useSelector(state => state.filters.filtrate);

  const width = window.innerWidth;
  const isMobile = width < 1024;
  let params = {};
  if (isMobile)
    params = {
      defaultPage: 6,
      siblingCount: 0,
      boundaryCount: 1,
    };

  const loadPage = params => {
    const { page, size } = params;
    const realSize = size || sizeStored;
    const qp = formatQuery(search, filtrate);

    dispatch(setActivePage(page));
    onSelect({ page, pageSize: realSize, qp });
  };

  useEffect(() => {
    dispatch(setNumberOfPages(totalItems));
  }, [totalItems]);

  const handleSelectPage = page => {
    if (page === '...' || page === 0 || page === activePage) {
      return;
    }
    loadPage({ page });
  };

  return (
    <Container>
      <Box py={2}>
        <Grid container spacing={3} direction="row-reverse">
          <Grid item>
            <Pages
              {...params}
              color="primary"
              variant="outlined"
              shape="rounded"
              page={activePage}
              count={pages}
              onChange={(event, page) => handleSelectPage(page)}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};
