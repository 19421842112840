import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import VMasker from 'vanilla-masker';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Container from '@material-ui/core/Container';
import Button from '../../../components/Button';
import { Formik, Form } from 'formik';

import TermsOfUseModal from 'pages/ProvisionalReceiptConfig/components/TermsOfUseModal';

import { Certificate, BasicInfo, Filter } from './steps';

import { certificateValidations } from './steps/Certificate/validations';
import { basicInfoValidations } from './steps/BasicInfo/validations';

import { setBasicInfo, setCertificate } from '../store/actions';

import {
  loadProvisionalReceiptProductConfig,
  loadProvisionalReceiptProductTables,
  uploadCertificate,
  upsertNfe,
  setAutomaticSendFilters
} from '../store/thunk';

import { loadFilters } from 'components/Filter/store/thunk';

import { checkedValueByType } from 'helpers/common';

import { useStyles } from './styles';

export default function ProvisionalReceiptProductConfig() {
  const dispatch = useDispatch();

  const history = useHistory();

  const classes = useStyles();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { termsOfUseDate, isTermsOfUseChecked } = useSelector(state => state.login);

  const {
    isLoading,
    doesConfigExists,
    configType,
    basicInfo,
    certificate,
    certificateFile,
    certificateFileFromAPI,
    filter
  } = useSelector(state => state.provisionalReceiptProductConfig);

  const [isTermsOfUseModalOpen, setIsTermsOfUseModalOpen] = useState(!isTermsOfUseChecked && !termsOfUseDate);

  const [currentIndex, setCurrentIndex] = useState(0);

  const configurations = [
    {
      index: 0,
      id: 'certificate',
      title: 'Certificado Digital',
      component: Certificate,
      validations: certificateValidations
    },
    {
      index: 1,
      id: 'basic',
      title: 'Informações básicas',
      component: BasicInfo,
      validations: basicInfoValidations
    },
    {
      index: 2,
      id: 'filter',
      title: 'Filtro de envio',
      component: Filter,
      validations: null
    }
  ];

  const currentConfiguration = configurations.find(config => config.index == currentIndex) || {};

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadProvisionalReceiptProductTables(userId));
      dispatch(loadProvisionalReceiptProductConfig(userId, establishmentId));
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  const handlePreviousStep = () => {
    if(currentIndex == 0) {
      return history.push('/notas-fiscais');
    }

    setCurrentIndex(currentIndex - 1);
  }

  const handleNextStep = () => {
    if(currentIndex == configurations[configurations.length - 1].index) {
      return;
    }

    setCurrentIndex(currentIndex + 1);
  }

  const handleSubmit = params => {
    if(currentConfiguration.id == 'certificate') {
      if(!basicInfo?.cityCode) {
        return toast.error('Escolha um município para continuar.');
      }

      if(params?.certificateName && !params?.certificateFile) {
        return handleNextStep();
      }

      if(!certificateFileFromAPI) {
        if(!params?.certificateFile) {
          return toast.error('Escolha um certificado para continuar.');
        }
      }

      if(params?.certificateFile) {
        if(!params?.password && !params?.passwordVerify) {
          return handleNextStep();
        }

        const values = {
          certificateFile: params?.certificateFile,
          autenticatorPassword: params?.password,
          autenticatorPasswordConfirm: params?.passwordVerify
        }

        dispatch(uploadCertificate(userId, establishmentId, values))
          .then(data => {
            dispatch(setCertificate(data));
            handleNextStep();
          });
      }
    }

    if(currentConfiguration.id == 'basic') {
      if(+configType == 0) {
        return handleNextStep();
      }

      const basicInfoParams = {
        ...params,
        ...(basicInfo?.certificateId != certificate?.certificateId) && certificate,
        taxSettingsUuid: basicInfo?.uuid || ''
      }

      basicInfoParams['documentId'] = VMasker.toNumber(params?.documentId);
      basicInfoParams['postalCode'] = VMasker.toNumber(params?.postalCode);

      delete basicInfoParams['certificateId'];
      delete basicInfoParams['taxSettingsUuid'];
      delete basicInfoParams['uuid'];

      dispatch(setBasicInfo(params));

      dispatch(upsertNfe(userId, establishmentId, basicInfoParams, doesConfigExists))
        .then(() => handleNextStep());
    }

    if(currentConfiguration.id == 'filter') {
      const {
        nfeActive,
        orders,
        selectedTransactions,
        paymentMethods
      } = params;

      const filterParams = {
        nfeActive,
        taxSettingsUuid: basicInfo?.uuid,
        automaticGenerateNfe: JSON.stringify({
          orders: checkedValueByType(orders, 'number'),
          selectedTransactions: checkedValueByType(selectedTransactions, 'number'),
          paymentMethods
        })
      }

      dispatch(setAutomaticSendFilters(userId, establishmentId, filterParams))
        .then(() => history.push('/notas-fiscais'));
    }
  }

  const getInitialValues = () => {
    if(currentConfiguration.id == 'certificate') {
      return {
        ...certificate,
        certificateFile,
        certificate: '',
        password: '',
        passwordVerify: ''
      }
    }

    if(currentConfiguration.id == 'basic') {
      return basicInfo;
    }

    if(currentConfiguration.id == 'filter') {
      return filter;
    }

    return {}
  }

  return(
    <Grid>
      {isTermsOfUseModalOpen && (
        <TermsOfUseModal
          isOpen={isTermsOfUseModalOpen}
          setIsOpen={setIsTermsOfUseModalOpen}
        />
      )}
      <Stepper
        alternativeLabel
        activeStep={currentIndex}
        style={{ background: 'transparent', padding: 0, paddingBottom: 24 }}
      >
        {configurations.map((nav, index) => (
          <Step key={nav.id}>
            <StepButton
              completed={currentIndex > index}
              onClick={() => setCurrentIndex(index)}
            >
              {nav.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Grid>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={currentConfiguration.validations}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => {
            return(
              <Form>
                {(currentIndex == currentConfiguration.index) && (
                  <currentConfiguration.component formikProps={formikProps} />
                )}
                <Grid className={classes.footer}>
                  <Container>
                    <Grid container justify="space-between">
                      <Button
                        loading={isLoading}
                        color="secondary"
                        type="button"
                        onClick={handlePreviousStep}
                      >
                        Voltar
                      </Button>
                      <Button
                        loading={isLoading}
                        color="success"
                        type="submit"
                      >
                        Avançar
                      </Button>
                    </Grid>
                  </Container>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}