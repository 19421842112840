import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export default function Card({ children, title, ...props }) {
  const classes = useStyles();

  return (
    <Paper {...props}>
      <Grid container justify="center" className={classes.cardHeader}>
        <Typography variant="h6" className={classes.cardTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid container className={classes.container}>
        {children}
      </Grid>
    </Paper>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
