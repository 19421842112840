import { toast } from 'react-toastify';

import {
  fetchProvisionalReceiptProductConfig,
  fetchProvisionalReceiptProductEstablishments,
  fetchProvisionalReceiptProductTables,
  requestUploadCertificate,
  requestUploadLogo,
  requestCreateNfe,
  requestUpdateNfe,
  fetchFilters,
  requestSetFilters,
  requestAttachProvisionalReceiptProduct,
  requestDetachProvisionalReceiptProduct
} from './services';

import {
  setIsLoading,
  setDoesConfigExists,
  setBasicInfo,
  setBasicInfoErrors,
  clearBasicInfoErrors,
  setProvisionalReceiptProduct,
  setCertificate,
  setFilters,
  setTables,
  setTaxSettingsEstablishments,
  clearProvisionalReceiptProduct
} from './actions';

export const loadProvisionalReceiptProductConfig = (userId, establishmentId, isTaxSettings = false) => dispatch => {
  if(!isTaxSettings) {
    dispatch(clearProvisionalReceiptProduct());
  }

  dispatch(setIsLoading(true));

  return fetchProvisionalReceiptProductConfig(userId, establishmentId)
    .then(({ data }) => {
      dispatch(setDoesConfigExists(true));

      if(isTaxSettings) {
        return dispatch(setBasicInfo(data));
      }

      dispatch(setProvisionalReceiptProduct(data))
    })
    .catch(() => {
      toast.warn('Estabelecimento sem configuração de nota.');
      dispatch(setDoesConfigExists(false));
    })
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadProvisionalReceiptProductEstablishments = userId => dispatch => {
  return fetchProvisionalReceiptProductEstablishments(userId)
    .then(({ data }) => dispatch(setTaxSettingsEstablishments(data)));
}

export const loadProvisionalReceiptProductTables = userId => dispatch => {
  return fetchProvisionalReceiptProductTables(userId)
    .then(({ data }) => dispatch(setTables(data)));
}

export const uploadLogo = (userId, establishmentId, params) => dispatch => {
  return requestUploadLogo(userId, establishmentId, params)
    .then(({ data }) => {
      toast.success('Logo adicionada com sucesso.');
      dispatch(setCertificate(data));
      return Promise.resolve(data);
    })
    .catch(() => {
      toast.error('Houve um erro ao adicionar a logo.');
      return Promise.reject();
    });
}

export const uploadCertificate = (userId, establishmentId, params) => dispatch => {
  dispatch(setIsLoading(true));

  return requestUploadCertificate(userId, establishmentId, params)
    .then(({ data }) => {
      toast.success('Certificado adicionado com sucesso.');
      dispatch(setCertificate(data));
      return Promise.resolve(data);
    })
    .catch(() => {
      toast.error('Houve um erro ao adicionar o certificado.');
      return Promise.reject();
    })
    .finally(() => dispatch(setIsLoading(false)));
}

export const upsertNfe = (userId, establishmentId, params, doesConfigExists) => dispatch => {
  dispatch(setIsLoading(true));
  dispatch(clearBasicInfoErrors());

  if(doesConfigExists) {
    return requestUpdateNfe(userId, establishmentId, params)
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch(setBasicInfoErrors(error?.errors));
        return Promise.reject();
      })
      .finally(() => dispatch(setIsLoading(false)));
  }

  return requestCreateNfe(userId, establishmentId, params)
    .then(({ data }) => {
      dispatch(setProvisionalReceiptProduct(data));
      return Promise.resolve(data);
    })
    .catch(error => {
      dispatch(setBasicInfoErrors(error?.errors));
      return Promise.reject();
    })
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadFilters = (userId, establishmentId) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchFilters(userId, establishmentId)
    .then(({ data }) => dispatch(setFilters(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const setAutomaticSendFilters = (userId, establishmentId, params) => dispatch => {
  dispatch(setIsLoading(true));

  return requestSetFilters(userId, establishmentId, params)
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .finally(() => dispatch(setIsLoading(false)));
}

export const attachProvisionalReceiptProduct = (userId, establishmentId, taxSettingsUuid) => dispatch => {
  return requestAttachProvisionalReceiptProduct(userId, establishmentId, taxSettingsUuid);
}

export const detachProvisionalReceiptProduct = (userId, establishmentId) => dispatch => {
  return requestDetachProvisionalReceiptProduct(userId, establishmentId);
}