import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Input from 'components/Input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

export default function Identifiers({ formikProps }) {
  const { isLoading } = useSelector(state => state.productsUseAndSellRegistration);

  const { values, errors, handleChange } = formikProps;

  return(
    <Card title="Preencha os códigos de identificadores e classificação do produto">
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Input
                  name="internalCode"
                  type="text"
                  label="Código do Produto (SKU)"
                  value={values?.internalCode}
                  error={errors?.internalCode}
                  helperText={errors?.internalCode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Input
                  name="barCode"
                  type="text"
                  label="Código de Barra Interno"
                  value={values?.barCode}
                  error={errors?.barCode}
                  helperText={errors?.barCode}
                  onChange={handleChange}
                />
                <FontAwesomeIcon
                  size="3x"
                  color="#1E5168"
                  icon={faBarcode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="gtinEan"
                  type="text"
                  label="Código GTIN/EAN"
                  value={values?.gtinEan}
                  error={errors?.gtinEan}
                  helperText={errors?.gtinEan}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}