import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({ isLoading, spinner, children }) => {
  if (isLoading) {
    if (spinner) {
      return <CircularProgress size={30} />;
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={1}>
        <CircularProgress size={30} />
      </Box>
    );
  }

  return children || null;
};

export default Loader;
