export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'amountStart',
    label: 'Valor',
    placeholder: 'R$ 0,00',
    value: '',
  },
  {
    advanced: false,
    type: 'input',
    id: 'clientName',
    label: 'Nome',
    placeholder: 'Ex.: Gustavo',
    value: '',
  },
  {
    advanced: false,
    type: 'input',
    id: 'plate',
    label: 'Placa',
    placeholder: 'AAA0000',
    value: '',
  },
];
