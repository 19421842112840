import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles(({ spacing }) => ({
  container: {
    justifyContent: 'space-between',
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0
  },
  containerMargin: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0
  },
  card: {
    border: 'solid',
    borderWidth: 1,
    borderColor: grey[400],
    borderRadius: 5,
    marginBottom: spacing(2)
  },
  list: {
    marginTop: 8,
    marginBottom: 8
  },
  title: {
    marginRight: spacing(1),
    fontWeight: 500
  }
}));