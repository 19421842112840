import { toast } from 'react-toastify';
import { setPaymentMethods, handleLoading, handleUpdate, clearRegistration } from './actions';
import { fetchPaymentMethods, createPaymentMethod, updatePaymentMethod, deletePaymentMethod, fetchPrimitivePayment, fetchSinglePaymentMethod } from './services';

export const loadPaymentMethods = ({ userId, establishmentId, page, pageSize, qp = '' }) => dispatch => {
  const query = qp === '' ? '&status=1' : qp;

  let url = `${userId}/establishment/${establishmentId}/paymentmethods?pg=${page}&limit=${pageSize}${query}`;

  if(!page || !pageSize) {
    url = `${userId}/establishment/${establishmentId}/paymentmethods?${query}`;
  }

  dispatch(handleLoading(true));

  return fetchPaymentMethods(url)
    .then(({ data }) => {
      dispatch(setPaymentMethods(data.content, data.total));
      dispatch(handleLoading(false));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadSinglePaymentMethod = (userId, establishmentId, paymentMethodId) => dispatch => {
  fetchSinglePaymentMethod(userId, establishmentId, paymentMethodId).then(res => {
    dispatch(handleUpdate(res.data));
  });
}

export const upsertPaymentMethod = (userId, establishmentId, params, properties) => dispatch => {
  const { paymentMethodId } = params;
  const { page, pageSize, qp } = properties;
  dispatch(handleLoading(true));

  if (!paymentMethodId) {
    return createPaymentMethod(userId, establishmentId, params)
      .then(() => {
        toast.success('Meio de pagamento cadastrado com sucesso.');
        dispatch(clearRegistration());
        dispatch(loadPaymentMethods({ userId, establishmentId, page, pageSize, qp }));
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updatePaymentMethod(userId, establishmentId, params)
    .then(() => {
      toast.success('Meio de pagamento atualizado com sucesso.');
      dispatch(clearRegistration());
      dispatch(loadPaymentMethods({ userId, establishmentId, page, pageSize, qp }));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const removePaymentMethod = (userId, establishmentId, accountId) => dispatch => {
  dispatch(handleLoading(true));

  return deletePaymentMethod(userId, establishmentId, accountId)
    .then(() => {
      toast.success('Meio de pagamento removido com sucesso.');
      dispatch(loadPaymentMethods());
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const getPrimitivePayment = (
  userId,
  establishmentId,
  primitivePaymentMethodId
) => dispatch => {
  dispatch(handleLoading(true));

  return fetchPrimitivePayment(userId, establishmentId, primitivePaymentMethodId)
    .then(({ data }) => {
      return data.form;
    })
    .finally(() => dispatch(handleLoading(false)));
}