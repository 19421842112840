import * as type from './constants';

export const registerFieldChange = (field, value) => ({
  type: type.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setCostCenter = data => ({
  type: type.SET_COST_CENTER,
  data,
});

export const handleUpdate = data => ({
  type: type.HANDLE_UPDATE,
  data,
});

export const handleRegistration = status => ({
  type: type.HANDLE_REGISTRATION,
  status,
});

export const clearRegistration = () => ({
  type: type.CLEAR_REGISTRATION,
});

export const handleSearch = status => ({
  type: type.HANDLE_SEARCH,
  status,
});

export const handlePagination = (page, pageSize) => ({
  type: type.HANDLE_PAGINATION,
  page,
  pageSize,
});

export const handleLoading = status => ({
  type: type.LOADING_INFORMATIONS,
  status,
});

export const clearCostCenters = () => ({
  type: type.CLEAR_COST_CENTERS,
});
