import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value
});

export const changeDate = date => ({
  type: types.CHANGE_DATE,
  date
});

export const clearFrequency = id => ({
  type: types.CLEAR_FREQUENCY,
  id
});

export const setCharges = data => ({
  type: types.SET_CHARGES,
  data
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const clearCharges = () => ({
  type: types.CLEAR_CHARGES
});