import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setVehicles = data => ({
  type: types.SET_VEHICLES,
  data,
});

export const setCards = data => ({
  type: types.SET_CARDS,
  data,
});

export const handleCardLoading = status => ({
  type: types.HANDLE_CARD_LOADING,
  status,
});

export const handleVehicleLoading = status => ({
  type: types.HANDLE_VEHICLE_LOADING,
  status,
});

export const toggleToRemoveVehicle = (status, plate) => ({
  type: types.TOGGLE_TO_REMOVE_VEHICLE,
  status,
  plate,
});

export const toggleToRemoveCard = (status, cardCode) => ({
  type: types.TOGGLE_TO_REMOVE_CARD,
  status,
  cardCode,
});

export const clearVehicleRegister = () => ({
  type: types.CLEAR_VEHICLE_REGISTER,
});

export const clearCardRegister = () => ({
  type: types.CLEAR_CARD_REGISTER,
});
