import * as yup from 'yup';

export const storageMovementValidations = yup.object().shape({
  quantity: yup
    .string()
    .when(['isStorageEmpty'], {
      is: isStorageEmpty => !isStorageEmpty,
      then: yup
        .string()
        .required('A quantidade do produto é obrigatória')
        .nullable()
    })
});