import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchPayrollSheets = async (userId, establishmentId, uuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/commissionsheets?commissionedUuid=${uuid}`);
  return response.data;
}

export const fetchSinglePayrollSheet = async (userId, establishmentId, uuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/commissionsheets/${uuid}`);
  return response.data;
}

export const requestUpdatePayrollSheet = async (userId, establishmentId, uuid, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/commissionsheets/${uuid}`, qs.stringify(params));
  return response.data;
}

export const requestClosePayrollSheet = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/commissionsheets`, qs.stringify(params));
  return response.data;
}

export const requestPayrollSheetExtract = async (userId, establishmentId, uuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/commissionsheets/${uuid}/extract`);
  return response.data;
}

export const requestPayPayrollSheet = async (userId, establishmentId, uuid, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/commissionsheets/${uuid}/pay`, qs.stringify(params));
  return response.data;
}

export const requestUndoPayPayrollSheet = async (userId, establishmentId, uuid, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/commissionsheets/${uuid}/undo_pay`, qs.stringify(params));
  return response.data;
}