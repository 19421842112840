import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  delayDaysQntText: {
    display: 'flex',
    alignItems: 'baseline',
    color: '#1E5168'
  },
  delayDaysQntTextField: {
    width: 90,
    paddingLeft: 15,
    paddingRight: 15
  },
  blue: {
    color: '#1E5168'
  },
  red: {
    color: '#E53935'
  },
  green: {
    color: '#43A047'
  },
  yellow: {
    color: '#FFC830'
  }
}));