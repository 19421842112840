import { SET_ACTIVITIES, HANDLE_LOADING } from './constants';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { compose, over, set, lensProp, map, assoc, prop } from 'ramda';
import { formatDateTime, formatEmptyValue } from '../../../helpers/formatters';

momentDurationFormatSetup(moment);

const defaultState = {
  list: [],
  total: null,
  isLoading: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVITIES: {
      const formatActivities = activity => {
        const formatter = compose(
          assoc('user', prop('userId', activity)),
          assoc('activitySource', prop('activitySourceId', activity)),
          assoc('activityType', prop('activityTypeId', activity)),
          over(lensProp('description'), formatEmptyValue),
          over(lensProp('createdAt'), formatDateTime)
        );

        return formatter(activity);
      }

      const list = map(formatActivities, action.data);

      const activities = compose(
        set(lensProp('list'), list),
        set(lensProp('total'), action.total),
        set(lensProp('isLoading'), false)
      );

      return activities(state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    default:
      return state;
  }
}