import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { concat } from 'ramda';

import { Paper, Grid, Typography, IconButton } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Button from '../../../components/Button';
import MTable from '../../../components/MTable';
import ServiceOrder from '../../../components/ServiceOrder/components';
import Invoice from '../../../components/Invoice';
import InvoiceServiceContract from '../../../components/InvoiceServiceContract';
import Receipt from '../../Transactions/components/Receipt';
import Payment from '../../Transactions/components/Payment';
import ProvisionalReceiptError from './ProvisionalReceiptError';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

import { loadProvisionalReceipt, loadDownloadNfse } from '../store/thunk';
import { handleError } from '../store/actions';
import { formatFilters } from '../../../helpers/formatters';
import { convertList } from '../../../helpers/converters';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { loadSingleTransaction } from 'pages/Transactions/store/thunk';
import { setServiceOrderId } from '../../../components/ServiceOrder/store/actions';
import { toggleServiceOrder } from '../../OperationalHistory/store/actions';
import { setClientId } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setEstablishmentId } from '../../BusinessInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../../ClientInvoices/store/actions';
import { handleRegistration, setTransactionDetails } from '../../Transactions/store/actions';
import { setSourceId } from '../store/actions';

import { setBaseAPI } from '../../../services';

import { inputs } from './resources';

const headers = [
  { title: 'Número da nota', field: 'nfseNumber', sortable: false },
  { title: 'Série e N˚(RPS)', field: 'rpsSerieAndNumber', sortable: false },
  { title: 'Emissão', field: 'nfDateTime', sortable: false },
  { title: 'Competência', field: 'paymentDateTimeView', sortable: false },
  { title: 'Status', field: 'nfseStatus', sortable: true, defaultSort: 'desc' },
  { title: 'Valor', field: 'amountView', sortable: false }
];

const width = window.innerWidth;
const tableWidth = width < 768 ? 160 : 100;

export default function ProvisionalReceipt() {
  const dispatch = useDispatch();

  const history = useHistory();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { modal } = useSelector(state => state.operationalHistory);
  const { isUpdatingInvoice } = useSelector(state => state.clientInvoices);
  const { registration, submit: { transactionTypeId } } = useSelector(state => state.transactions);
  const { selects, qp, isSearching, isFilterButtonClicked } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { list, total, isLoading, error } = useSelector(state => state.provisionalReceipt);

  const filters = concat(inputs, formatFilters(['nfseStatus'], selects));
  const convertedList = convertList(['nfseStatus'], filters, list);

  const [isOpenInvoiceServiceContract, setIsOpenInvoiceServiceContract] = useState(false);
  const [calculatedInvoiceDateTime, setCalculatedInvoiceDateTime] = useState('');

  const [provisionalReceiptPage, setProvisionalReceiptPage] = useState(1);
  const provisionalReceiptPageSize = 10;
  const provisionalReceiptPageCount = Math.ceil(total / provisionalReceiptPageSize);

  const handleProvisionalReceiptList = ({ page, qp }) => {
    const extraProps = {
      startDate,
      endDate
    }

    if(establishmentId) {
      dispatch(loadProvisionalReceipt({ userId, establishmentId, page, pageSize: provisionalReceiptPageSize, qp, extraProps }));
    }
  }

  useEffect(() => {
    dispatch(handleError({ error: null, transactionId: null }));
  }, []);

  useEffect(() => {
    if(startDate !== 'Invalid date' && endDate !== 'Invalid date') {
      handleProvisionalReceiptList({ page: 1, qp });
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId, startDate, endDate]);

  useEffect(() => {
    handleProvisionalReceiptList({ page: provisionalReceiptPage, qp });
  }, [provisionalReceiptPage]);

  useEffect(() => {
    setProvisionalReceiptPage(1);
  }, [establishmentId]);

  useEffect(() => {
    if(isSearching) {
      setProvisionalReceiptPage(1);
    }
  }, [isSearching]);

  useEffect(() => {
    if(isFilterButtonClicked) {
      setProvisionalReceiptPage(1);
    }
  }, [isFilterButtonClicked]);

  const getClientId = sourceId => {
    if(sourceId) {
      return sourceId?.slice(0, sourceId?.length - 14);
    }

    return '';
  }

  const getInvoiceDateTime = sourceId => {
    if(sourceId) {
      return sourceId?.slice(sourceId?.length - 14, sourceId?.length);
    }

    return '';
  }

  const handleTransactionRegistration = async (status, modalType) => {
    dispatch(handleRegistration(status, modalType));
  }

  const selectTransactionType = async (categorySourceId, clientId, sourceId, establishmentId) => {
    dispatch(setSourceId(sourceId));

    if(categorySourceId == 1) {
      await dispatch(setServiceOrderId(sourceId, userId, establishmentId));
      await dispatch(toggleServiceOrder(true));
    }

    if(categorySourceId == 2) {
      await dispatch(setClientId(clientId));
      await dispatch(setEstablishmentId({ establishmentId }));
      await dispatch(setInvoiceId(getInvoiceDateTime(sourceId)));
      await setBaseAPI(userId, establishmentId);
      await dispatch(handleUpdateInvoice(true));
    }

    if(categorySourceId == 3) {
      const singleTransactionData = await dispatch(loadSingleTransaction(userId, establishmentId, sourceId));

      if(singleTransactionData) {
        const { transactionTypeId } = singleTransactionData;

        await handleTransactionRegistration(true, +transactionTypeId).then(async () => {
          await dispatch(setTransactionDetails({ ...singleTransactionData }));
        });
      }
    }

    if(categorySourceId == 8) {
      await dispatch(setClientId(clientId));
      setCalculatedInvoiceDateTime(getInvoiceDateTime(sourceId));
      setIsOpenInvoiceServiceContract(true);
    }
  }

  useEffect(() => {
    if(!(headers.filter(header => header.field === 'actions').length > 0)) {
      headers.push({
        title: 'Ações',
        field: 'actions',
        render: rowData => {
          const {
            nfseStatusNumber,
            categorySourceId,
            sourceId,
            nfStatus,
            establishmentUuid
          } = rowData;

          const establishmentId = establishments
            ?.find(establishment => establishment?.uuid == establishmentUuid)
            ?.establishmentId;

          const clientId = getClientId(sourceId);

          return(
            <>
              {(+nfseStatusNumber == 4) && (
                <IconButton
                  size="medium"
                  onClick={() => {
                    dispatch(
                      handleError({
                        error: rowData && rowData.nfResult,
                        paymentDateTime: rowData?.paymentDateTime,
                        transactionId: sourceId,
                        establishmentId: establishmentId
                      })
                    )
                  }}
                >
                  <FontAwesomeIcon
                    size="xs"
                    color="#F66D6E"
                    icon={faExclamationCircle}
                  />
                </IconButton>
              )}
              <IconButton
                size="medium"
                onClick={() => selectTransactionType(categorySourceId, clientId, sourceId, establishmentId)}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#022A5C"
                  size="xs"
                />
              </IconButton>
              {(+nfStatus == 2) && (
                <IconButton
                  size="medium"
                  onClick={() => dispatch(loadDownloadNfse(userId, establishmentId, sourceId))}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    color="#2CA9D6"
                    size="xs"
                  />
                </IconButton>
              )}
            </>
          );
        }
      });
    }
  }, []);

  return(
    <>
      <Grid container style={{ backgroundColor: '#fCD40A', border: '1px solid #38A8D8', borderRadius: 10, padding: 15, marginBottom: 10 }}>
        <Grid xs={12}>
          <Typography color='primary'>
            <b>
              A migração de emissor de nota fiscal foi realizada no sistema da Jump! Agora todas as notas ficais geradas até o dia 04/11 só poderão ser visualizadas no seu portal da prefeitura!
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Paper>
        {modal && (
          <ServiceOrder modal={modal} />
        )}
        {(registration && +transactionTypeId == 1) && (
          <Receipt isOpen={registration} />
        )}
        {(registration && +transactionTypeId == 2) && (
          <Payment isOpen={registration} />
        )}
        {isUpdatingInvoice && (
          <Invoice
            isOpen={isUpdatingInvoice}
            establishmentId={establishmentId}
          />
        )}
        {isOpenInvoiceServiceContract && (
          <InvoiceServiceContract
            isOpenInvoiceServiceContract={isOpenInvoiceServiceContract}
            setIsOpenInvoiceServiceContract={setIsOpenInvoiceServiceContract}
            calculatedInvoiceDateTime={calculatedInvoiceDateTime}
            isInvoicePaid={true}
          />
        )}
        <ProvisionalReceiptError
          open={!!error}
          error={error}
          page={provisionalReceiptPage}
          pageSize={provisionalReceiptPageSize}
          qp={qp}
          extraProps={{ startDate, endDate }}
        />
        {isMobile ? (
          <FilterMobile
            filter
            hasSearchTerm
            actionButton={
              <Button
                type="button"
                color="primary"
                onClick={() => history.push('/configuracoes-de-notas-fiscais/informacoes-basicas')}
              >
                Configurações das notas fiscais
              </Button>
            }
            filters={filters}
            handlePageRequest={handleProvisionalReceiptList}
          />
        ) : (
          <Filter
            filter
            hasSearchTerm
            actionButton={
              <Button
                type="button"
                color="primary"
                onClick={() => history.push('/configuracoes-de-notas-fiscais/informacoes-basicas')}
              >
                Configurações das notas fiscais
              </Button>
            }
            filters={filters}
            handlePageRequest={handleProvisionalReceiptList}
          />
        )}
        <MTable
          loading={isLoading}
          headers={headers}
          data={convertedList}
          actionColumnWidth={tableWidth}
        />
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={provisionalReceiptPageCount}
            page={provisionalReceiptPage}
            onChange={(_, page) => setProvisionalReceiptPage(page)}
          />
        </Grid>
      </Paper>
    </>
  );
}