import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.background.paper,
    textAlign: 'center',
  },
  containerButtons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  markLabel: {
    marginBottom: theme.spacing(1),
  },
}));
