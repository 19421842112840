import {
  compose,
  merge,
  map,
  set,
  over,
  assoc,
  lensProp,
  pick,
  prop,
} from 'ramda';
import {
  REGISTER_FIELD_CHANGE,
  SET_PAYMENT_METHODS,
  HANDLE_REGISTRATION,
  HANDLE_UPDATE,
  HANDLE_LOADING,
  CLEAR_REGISTRATION,
} from './constants';

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
  registration: false,
  status: 1,
  paymentMethodId: null,
  accountId: null,
  primitivePaymentMethodId: null,
  paymentMethodName: '',
  receivingDays: '',
  receivingFee: '',
  form: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      let { value } = action;

      if (action.field === 'status') {
        value = parseInt(value, 10);
      }

      return set(lensProp(action.field), value, state);
    }
    case SET_PAYMENT_METHODS: {
      const formatPaymentMethods = paymentMethod => {
        const { receivingFee, receivingDays } = paymentMethod;
        const formatter = compose(
          assoc('convertedReceivingFee', `${receivingFee}%`),
          assoc('convertedReceivingDays', `${receivingDays} dia(s)`)
        );

        return formatter(paymentMethod);
      };
      const list = map(formatPaymentMethods, action.data);

      return compose(
        set(lensProp('total'), action.total),
        set(lensProp('list'), list),
        set(lensProp('registration'), false),
        set(lensProp('isLoading'), false)
      )(state);
    }
    case HANDLE_REGISTRATION: {
      return set(lensProp('registration'), action.status, state);
    }
    case HANDLE_UPDATE: {
      const paymentMethodId = prop('establishmentPaymentMethodId', action.data);
      const account = compose(
        over(lensProp('receivingFee'), fee => (fee === '0.00' ? '' : fee)),
        over(lensProp('receivingDays'), days => days || ''),
        pick([
          'accountId',
          'paymentMethodName',
          'primitivePaymentMethodId',
          'receivingDays',
          'receivingFee',
          'status',
          'form',
        ])
      );
      return compose(
        set(lensProp('registration'), true),
        set(lensProp('paymentMethodId'), paymentMethodId),
        merge(state)
      )(account(action.data));
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_REGISTRATION: {
      const clear = compose(
        set(lensProp('list'), state.list),
        set(lensProp('total'), state.total)
      );

      return clear(defaultState);
    }
    default: {
      return state;
    }
  }
};
