import { newAPI } from 'services';
import qs from 'qs';

export const fetchProvisionalReceiptProductConfig = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nfe/tax_settings`);
  return response.data;
}

export const fetchProvisionalReceiptProductEstablishments = async userId => {
  const response = await newAPI.get(`${userId}/nfe/tax_settings`);
  return response.data;
}

export const fetchProvisionalReceiptProductTables = async userId => {
  const response = await newAPI.get(`${userId}/nfe/tax_settings/auxiliary_tables`);
  return response.data;
}

export const loadCities = async userId => {
  const response = await newAPI.get(`${userId}/nfe/cities`);
  return response;
}

export const requestUploadLogo = async (userId, establishmentId, params) => {
  const form = new FormData();

  form.append('logoFile', params.logo);

  const response = await newAPI({
    method: 'post',
    url: `${userId}/establishment/${establishmentId}/nfe/tax_settings/logo`,
    data: form,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data;
}

export const requestUploadCertificate = async (userId, establishmentId, params) => {
  const form = new FormData();

  form.append('file', params.certificateFile);
  form.append('password', params.autenticatorPassword);

  const response = await newAPI({
    method: 'post',
    url: `${userId}/establishment/${establishmentId}/nfe/tax_settings/certificate`,
    data: form,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data;
}

export const requestCreateNfe = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/nfe/tax_settings`, qs.stringify(params));
  return response.data;
}

export const requestUpdateNfe = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nfe/tax_settings`, qs.stringify(params));
  return response.data;
}

export const fetchFilters = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nfe/automatic_send`);
  return response.data;
}

export const requestSetFilters = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nfe/automatic_send`, qs.stringify(params));
  return response.data;
}

export const requestAttachProvisionalReceiptProduct = async (userId, establishmentId, taxSettingUuid) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nfe/attach/${taxSettingUuid}`);
  return response.data;
}

export const requestDetachProvisionalReceiptProduct = async (userId, establishmentId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nfe/detach`);
  return response.data;
}