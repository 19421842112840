import VMasker from 'vanilla-masker';
import { formatPhone, formatPostalCode, formatDocument } from '../../helpers/formatters';
import { isTimeRangeFilledByZeros } from 'pages/PriceTable/components/Form/validations';
import { splitStringInHalf } from 'helpers/common';

export const convertToMask = (type, value) => {
  switch(type) {
    case 'money': {
      return VMasker.toMoney(value);
    }
    case 'postalCode': {
      return formatPostalCode(value);
    }
    case 'date': {
      return VMasker.toPattern(value, '99/99/9999');
    }
    case 'plate': {
      return VMasker.toPattern(value, 'AAASS99');
    }
    case 'percentage': {
      const numbersAndComma = /^[0-9,-]*$/g;
      if(numbersAndComma.test(value)) {
        return value;
      }
      return value.slice(0, -1);
    }
    case 'typePrice': {
      const hasDash = /^[/]*$/g;
      if(!hasDash.test(value)) {
        return value;
      }
      return value.slice(0, -1);
    }
    case 'time': {
      let time = value;
      const firstNumber = time[0];
      const lastNumber = time[time.length - 1];
      const removeLastNumber = time.slice(0, -1);

      if(time.length === 1 && time > 2) {
        time = removeLastNumber;
      } else if(time.length === 2) {
        if (lastNumber > 3 && firstNumber >= 2) {
          time = removeLastNumber;
        }
      } else if(time.length === 3 && lastNumber > 5) {
        time = removeLastNumber;
      }

      return VMasker.toPattern(time, '99:99');
    }
    case 'timeRange': {
      let timeRangePattern = '99:99';
      const timeRange = value.replace(/[^0-9.]+/g, '');
      if(timeRange.length > 4) {
        timeRangePattern = '999:99';
      }
      return VMasker.toPattern(value, timeRangePattern);
    }
    case 'phone': {
      return formatPhone(value);
    }
    case 'document': {
      return formatDocument(value);
    }
    case 'number': {
      return VMasker.toNumber(value);
    }
    case 'decimal': {
      const formattedDecimal = VMasker.toMoney(value, {
        precision: 2,
        separator: '.',
        delimiter: '%'
      });
  
      return formattedDecimal.replaceAll('%', '');
    }
    case 'timeRangePadStart': {
      const onlyNumbers = value.replace(/\D/g, '');
      const noLeadingZerosValue = parseInt(onlyNumbers, 10);

      const padding = Number.isNaN(noLeadingZerosValue)
        ? '0000'
        : noLeadingZerosValue.toString().padStart(4, '0');

      const [hh, mm] = splitStringInHalf(padding);
      const time = `${hh}:${mm}`;

      const isTimeInvalid = time.length == 5 && isTimeRangeFilledByZeros(time);

      if(isTimeInvalid) {
        return ' ';
      }

      return time;
    }
    default: {
      return value;
    }
  }
}