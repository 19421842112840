import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    backgroundColor: 'white',
    paddingBottom: spacing(2)
  },
  paperSuccess: {
    textAlign: 'center',
    padding: spacing(4),
    paddingTop: spacing(2),
    color: palette.success.main,
    border: '1px solid #E4E7EA',
    borderBottom: `5px solid ${palette.success.main}`,
    borderRadius: 5
  },
  paperError: {
    textAlign: 'center',
    padding: spacing(4),
    paddingTop: spacing(2),
    color: palette.error.main,
    border: '1px solid #E4E7EA',
    borderBottom: `5px solid ${palette.error.main}`,
    borderRadius: 5
  },
  paperPrimary: {
    textAlign: 'center',
    padding: spacing(4),
    paddingTop: spacing(2),
    color: palette.primary.main,
    border: '1px solid #E4E7EA',
    borderBottom: `5px solid #757575`,
    borderRadius: 5
  }
}));