import qs from 'qs';
import { api } from '../../../services';

export const fetchTables = () =>
  api
    .get('/pricesitems')
    .then(response => response.data);

export const fetchTablesFromEstablishment = async establishmentId => {
  const response = await api.get(`/establishment/${establishmentId}/pricesitems`);
  return response.data;
}

export const updateComponent = (params, typePrice) =>
  api
    .put(`/prices/${typePrice}`, qs.stringify(params))
    .then(response => response.data);

export const requestRemoveTable = typePrice =>
  api
    .delete(`/prices/${typePrice}`)
    .then(response => response.data);

export const createTable = params =>
  api
    .post('/pricesitems/new', qs.stringify(params))
    .then(response => response.data);

export const updateTable = async (params, typePrice, originalTypePrice) => {
  const formattedParams = {
    ...params,
    typePriceId: originalTypePrice,
    newTypePrice: typePrice
  }

  delete formattedParams['typePrice'];

  const response = await api.put(`/pricesitems`, qs.stringify(formattedParams));
  return response.data;
}

export const removePriceTableService = typePrice =>
  api
    .delete(`/prices/${typePrice}`)
    .then(response => response.data);

export const createSpecial = (params, typePrice) =>
  api
    .post(`/prices/${typePrice}/special-items/new`, qs.stringify(params))
    .then(response => response.data);

export const updateSpecial = (params, typePrice, description) =>
  api
    .put(`/prices/${typePrice}/special-items/${description}`, qs.stringify(params))
    .then(response => response.data);

export const updateCompulsary = (params, typePrice, description) =>
  api
    .put(`/prices/${typePrice}/special/${description}`, qs.stringify(params))
    .then(response => response.data);

export const requestToRemoveSpecialTable = params => {
  const { typePrice, description } = params;

  return api
    .delete(`/prices/${typePrice}/special/${description}`)
    .then(response => response.data);
}

export const fetchCovenant = typePrice =>
  api
    .get(`/covenants/${typePrice}`)
    .then(response => response.data);

export const createCovenant = params =>
  api
    .post('/covenants/new', qs.stringify(params))
    .then(response => response.data);

export const updateCovenant = async params => {
  const code = params.originalAccessCode;

  delete params['originalAccessCode'];

  const response = await api.put(`/covenants/${code}`, qs.stringify(params));
  return response.data;
}