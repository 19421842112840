import { compose, map, prop, assoc, over, set, lensProp } from 'ramda';
import { formatPhone } from '../../../helpers/formatters';
import { SET_EMPLOYEES_LIST, SET_EMPLOYEE_REGISTER, HANDLE_LOADING } from './constants';

const defaultState = {
  isLoading: false,
  total: 0,
  list: [],
  employeeRegister: {
    name: null
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EMPLOYEES_LIST: {
      const formatEmployees = item => {
        const status = prop('status', item) === 0 ? 'Inativo' : 'Ativo';
        const employeeId = item.userId;
        const employee = compose(
          over(lensProp('phone'), phone => (phone ? formatPhone(phone) : '--')),
          assoc('employeeId', employeeId),
          assoc('status', status)
        );

        return employee(item);
      }

      const employees = map(formatEmployees, action.data);

      return {
        ...state,
        list: employees,
        total: action.total,
        isLoading: false
      }
    }
    case SET_EMPLOYEE_REGISTER: {
      const { name } = action?.employee;

      return {
        ...state,
        employeeRegister: {
          name
        }
      }
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    default:
      return state;
  }
}