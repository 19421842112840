import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '../../../../components/Button';

import { changeType } from '../../store/actions';

const PeriodType = ({ active, changeType }) => {
  const onChangeType = e => {
    changeType(e.currentTarget.value);
  };

  return (
    <ButtonGroup variant="contained">
      <Button
        id="period-type"
        variant={active !== 'day' && 'outline'}
        color={active !== 'day' ? 'default' : 'primary'}
        onClick={onChangeType}
        value="day"
      >
        Diário
      </Button>
      <Button
        id="period-type"
        variant={active !== 'month' && 'outline'}
        color={active !== 'month' ? 'default' : 'primary'}
        onClick={onChangeType}
        value="month"
      >
        Mensal
      </Button>
    </ButtonGroup>
  );
};

PeriodType.propTypes = {
  active: PropTypes.bool.isRequired,
  changeType: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeType }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(PeriodType);
