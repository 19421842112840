import * as yup from 'yup';

export const validations = yup.object().shape({
  accountId: yup.number().nullable(),
  accountName: yup
    .string()
    .required('O nome é obrigatório')
    .nullable(),
  bankName: yup.string().nullable(),
  bankAgency: yup.string().nullable(),
  bankAccount: yup.string().nullable(),
  status: yup.bool(),
  establishmentsUuid: yup
    .array()
    .required('Escolha o(s) estabelecimento(s) para vincular a conta'),
});