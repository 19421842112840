import { toast } from 'react-toastify';

import {
  fetchComissioned,
  createComissioned,
  updateComissioned
} from './services';

import {
  setIsLoading,
  setComissioned,
  setComissionedErrors,
  clearComissionedErrors,
  clearComissioned
} from './actions';

export const loadComissioned = (userId, establishmentId, uuid) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchComissioned(userId, establishmentId, uuid)
    .then(res => dispatch(setComissioned(res.data)))
    .catch(() => dispatch(clearComissioned()))
    .finally(() => dispatch(setIsLoading(false)));
}

export const upsertComissioned = (userId, establishmentId, uuid, params, history) => dispatch => {
  dispatch(setIsLoading(true));

  if(uuid) {
    return updateComissioned(userId, establishmentId, uuid, params)
      .then(() => {
        toast.success('Funcionário atualizado com sucesso.');
        return history.push('/funcionarios');
      })
      .catch(error => {
        if(!error) {
          dispatch(clearComissionedErrors());
          return toast.error('Houve um erro ao atualizar o funcionário.');
        }

        dispatch(setComissionedErrors(error.errors));
      })
      .finally(() => dispatch(setIsLoading(false)));
  }

  return createComissioned(userId, establishmentId, params)
    .then(() => {
      toast.success('Funcionário cadastrado com sucesso.');
      return history.push('/funcionarios');
    })
    .catch(error => {
      if(!error) {
        dispatch(clearComissionedErrors());
        return toast.error('Houve um erro ao cadastrar o funcionário.');
      }

      dispatch(setComissionedErrors(error.errors));
    })
    .finally(() => dispatch(setIsLoading(false)));
}