import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchComissionedPayroll = async (userId, establishmentId, uuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/commissionedsettings/${uuid}`);
  return response.data;
}

export const createComissionedPayroll = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/commissionedsettings`, qs.stringify(params));
  return response.data;
}

export const updateComissionedPayroll = async (userId, establishmentId, uuid, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/commissionedsettings/${uuid}`, qs.stringify(params));
  return response.data;
}