export const availablePrints = [
  {
    label: 'Entrada por Qr Code e impressão opcional na saída',
    value: 1
  },
  {
    label: 'Impressão obrigatória na entrada e na saída',
    value: 2
  },
  {
    label: 'Impressão obrigatória na entrada e opcional na saída',
    value: 3
  },
  {
    label: 'Sem impressão na entrada e opcional na saída',
    value: 4
  },
  {
    label: 'Recibo de entrada e recibo de saída por WhatsApp',
    value: 5
  }
];