import { set, lensProp, divide } from 'ramda';
import {
  SET_NUMBER_OF_PAGES,
  SET_PAGE_SIZE,
  SET_ACTIVE_PAGE,
  CLEAR_PAGINATION,
} from './constants';

const defaultState = {
  pages: 0,
  activePage: 1,
  size: 10,
  maxPaginationNumbers: 5,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_NUMBER_OF_PAGES: {
      const count = divide(action.total, state.size);
      let pages = parseInt(count, 10);
      pages += pages !== count ? 1 : 0;

      return set(lensProp('pages'), pages, state);
    }
    case SET_PAGE_SIZE: {
      return set(lensProp('size'), action.size, state);
    }
    case SET_ACTIVE_PAGE: {
      return set(lensProp('activePage'), action.page, state);
    }
    case CLEAR_PAGINATION: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
