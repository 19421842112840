import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { map, values } from 'ramda';
import moment from 'moment';

import { styles } from '../styles';

export default function Comissioned({ exportHeaders, exportData }) {
  const tableWidth = 100 / exportHeaders.length;
  const payrollSituations = exportData?.payrollSituations?.options;

  const formattedExportData = exportData?.data?.map(data => {
    return {
      name: data?.name,
      roleName: data?.roleName,
      situationId: payrollSituations?.find(situation => situation?.id == data?.payroll?.situationId)?.name || '',
      dueDate: moment(data?.payroll?.dueDate).format('DD/MM/YYYY'),
      amount: data?.payroll?.amount ? `R$ ${data?.payroll?.amount}` : ''
    }
  });

  const items = map(values, formattedExportData);

  return(
    <Document>
      <Page>
        <View style={{ padding: 10 }}>
          <View style={[styles.table, { marginBottom: 0 }]}>
            <View style={styles.tableRow}>
              {exportHeaders?.map(header => (
                <View style={[styles.tableColHeader, { width: `${tableWidth}%` }]}>
                  <Text style={styles.tableCellHeader}>{header.label}</Text>
                </View>
              ))}
            </View>
            {items?.map(item => (
              <View style={styles.tableRow}>
                {item.map(cell => (
                  <View style={[styles.tableCol, { width: `${tableWidth}%` }]}>
                    <Text style={styles.tableCell}>{cell}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}