import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value
});

export const setAccounts = data => ({
  type: types.SET_ACCOUNTS,
  data
});

export const openUpdateModal = data => ({
  type: types.HANDLE_UPDATE,
  data
});

export const openRegistrationModal = status => ({
  type: types.HANDLE_REGISTRATION,
  status
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const clearRegistration = () => ({
  type: types.CLEAR_REGISTRATION
});