import { setAutomations, handleLoading } from './actions';
import { fetchClient } from '../../BasicInfo/store/services';

export const loadAutomations = params => dispatch => {
  dispatch(handleLoading(true));

  return fetchClient(params)
    .then(({ data }) => {
      dispatch(setAutomations(data));
      dispatch(handleLoading(false));
      return data;
    })
    .catch(() => dispatch(handleLoading(false)));
};