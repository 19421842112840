import { newAPI } from 'services';
import qs from 'qs';

export const fetchSellOrdersList = async (userId, establishmentId, page, limit, startDate, endDate, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/store/orders?pg=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}${qp}`);
  return response.data;
}

export const fetchSellOrdersResume = async (userId, establishmentId, startDate, endDate, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/store/orders/resume?startDate=${startDate}&endDate=${endDate}${qp}`);
  return response.data;
}

export const fetchSellOrdersExport = async (userId, establishmentId, startDate, endDate, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/store/orders/export?startDate=${startDate}&endDate=${endDate}${qp}`);
  return response.data;
}

export const fetchSellOrder = async (userId, establishmentId, orderId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/store/orders/${orderId}`);
  return response.data;
}

export const deleteSellOrder = async (userId, establishmentId, orderId, params) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/store/orders/${orderId}`, { data: qs.stringify(params) });
  return response.data;
}