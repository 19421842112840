import qs from 'qs';
import { newAPI, changeBaseNewAPI } from '../../../../../../services';

export const fetchClient = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}`);
  return response.data;
}

export const createClient = async params => {
  const response = await changeBaseNewAPI('post', `/${params.userId}/establishment/${params.establishmentId}/clients/new`, qs.stringify(params));
  return response.data;
}

export const updateClient = async params => {
  let formatParams = qs.stringify(params);

  if (params.weekDaysPermission) {
    if (params.weekDaysPermission.length === 0) {
      formatParams += '&weekDaysPermission=[]';
    }
  }

  if (params.services) {
    if (params.services.length === 0) {
      formatParams += '&services=[]';
    }
  }

  if (params.typePrices) {
    if (params.typePrices.length === 0) {
      formatParams += '&typePrices=[]';
    }
  }

  const response = await changeBaseNewAPI('put', `/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}`, formatParams);
  return response.data;
};