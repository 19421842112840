import { fetchComissionedPayrollListSettings } from './services';

import {
  setIsLoading,
  setComissionedPayrollList,
  clearComissionedPayrollList
} from './actions';

export const loadComissionedPayrollListSettings = ({ userId, establishmentId, page, limit, qp }) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchComissionedPayrollListSettings(userId, establishmentId, page, limit, qp)
    .then(({ data }) => dispatch(setComissionedPayrollList(data)))
    .catch(() => dispatch(clearComissionedPayrollList()))
    .finally(() => dispatch(setIsLoading(false)));
}