export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'clientName',
    label: 'Nome do cliente',
    placeholder: 'Ex.: Gustavo',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'card',
    label: 'Número do cartão',
    placeholder: '9999-9999-9999-9999',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'serviceOrderPlate',
    label: 'Placa/ Código OS',
    placeholder: 'Placa/ Código OS',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'startTime',
    maskType: 'time',
    label: 'Hora inicial',
    placeholder: '00:00',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'endTime',
    maskType: 'time',
    label: 'Hora final',
    placeholder: '00:00',
    value: ''
  },
  {
    advanced: true,
    type: 'input',
    id: 'rpsNumber',
    label: 'N˚ RPS',
    placeholder: '0',
    value: ''
  },
  {
    advanced: true,
    type: 'input',
    id: 'nfseNumber',
    label: 'N˚ NFS-e',
    placeholder: '0',
    value: ''
  },
  {
    advanced: true,
    type: 'input',
    id: 'clientDocument',
    maskType: 'document',
    label: 'CPF / CNPJ',
    placeholder: '000.000.000-00',
    value: ''
  },
  {
    advanced: false,
    type: 'select',
    id: 'discount',
    label: 'Desconto',
    value: 0,
    options: [
      { label: 'Desativado', value: '0' },
      { label: 'Ativado', value: '1' }
    ]
  }
];