import * as yup from 'yup';

const withoutCostCenterId = text => ({
  is: value => value === null,
  then: yup.string().required(text),
});

export const validations = yup.object().shape({
  costCenterId: yup.number().nullable(),
  costCenterName: yup
    .string()
    .when('costCenterId', withoutCostCenterId('O nome é obrigatório')),
  status: yup.string(),
});
