import React from 'react';

import { Typography } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function WarningMessage({ message }) {
  return(
    <div>
      <Typography color='textSecondary' style={{ margin: '10px 0 10px 0' }}>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          color="#F5B941"
          size="lg"
          style={{ marginRight: 10 }}
        />
        {message}
      </Typography>
    </div>
  );
}