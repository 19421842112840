import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiDialogActions from '@material-ui/core/DialogActions';

import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import SingleDatePicker from '../../../../components/SingleDatePicker';

import { upsertTransaction } from '../../store/thunk';
import { handleRegistration, clearRegistration } from '../../store/actions';
import { convertDateToDB } from '../../../../helpers/converters';
import { dateConfirmationValidation } from '../validations';

export default function DateConfirmation({ isOpen }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { submit, isConfirm, isLoading } = useSelector(state => state.transactions);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);

  useEffect(() => {
    return () => dispatch(clearRegistration());
  }, []);

  const handleCloseDateConfirmation = () => {
    dispatch(handleRegistration(false));
  }

  const handleSaveDateConfirmation = (values, actions) => {
    const { resetForm } = actions;

    const extraProps = {
      userId,
      establishmentId,
      page,
      pageSize,
      startDate,
      endDate
    }

    const params = {
      isConfirm,
      ...values,
      confirmationDateTime: moment(values.confirmationDateTime).format('YYYY-MM-DD HH:mm:ss')
    }

    return dispatch(upsertTransaction(params, extraProps, true)).then(() => {
      resetForm();
    });
  }

  return(
    <Formik
      enableReinitialize
      initialValues={submit}
      validationSchema={dateConfirmationValidation}
      onSubmit={handleSaveDateConfirmation}
    >
      {({ errors, values, setFieldValue }) => (
        <Modal
          id="date-confirmation"
          title="Alterar conciliação"
          scroll="body"
          open={isOpen}
          onClose={handleCloseDateConfirmation}
        >
          <Form>
            <Loader isLoading={isLoading}>
              <Grid container alignItems="flex-end">
                <Typography variant="body1" color="primary">
                  Data de confirmação da conciliação
                </Typography>
                <SingleDatePicker
                  name="confirmationDateTime"
                  initialDate={values.confirmationDateTime}
                  onDateChange={date =>setFieldValue('confirmationDateTime', convertDateToDB(date))}
                />
              </Grid>
            </Loader>
            <MuiDialogActions>
              <Button fullWidth color="success" loading={isLoading}>
                Salvar
              </Button>
            </MuiDialogActions>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

DateConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired
};