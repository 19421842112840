import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { numericFormatter } from 'react-number-format';
import { formatDocument, formatPhone } from 'helpers/formatters';

import { Formik, Form } from 'formik';
import { Grid, Card, List, Typography } from '@material-ui/core';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Button from 'components/Button';
import Item from './Item';

import { removeSellOrder } from 'pages/SellOrders/store/thunk';
import { loadTransaction, loadResume } from 'pages/Transactions/store/thunk';
import { loadSellOrdersList, loadSellOrdersResume } from 'pages/SellOrders/store/thunk';

import { convertToBrl } from 'helpers/converters';

import { sellOrdersValidations } from './validations';

import { useStyles } from './styles';

export default function SellOrder({ isSellOrderModalOpen, setIsSellOrderModalOpen, sellOrdersPageSize, sellOrdersPage, storageProductUuid, pageOrigin }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isModalLoading, order } = useSelector(state => state.sellOrders);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp } = useSelector(state => state.filters);

  const [isRemoving, setIsRemoving] = useState(false);

  const loadSellOrdersListAndResume = () => {
    const listProps = {
      startDate,
      endDate,
      qp,
      page: sellOrdersPage,
      limit: sellOrdersPageSize
    }

    const resumeProps = {
      startDate,
      endDate,
      qp
    }

    dispatch(loadSellOrdersList(userId, establishmentId, listProps));
    dispatch(loadSellOrdersResume(userId, establishmentId, resumeProps));
  }

  const loadTransactionsAndResume = () => {
    dispatch(
      loadTransaction({
        qp,
        page: page || 1,
        pageSize: pageSize || 10,
        extraProps: { userId, establishmentId, startDate, endDate }
      })
    );

    dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp }));
  }

  const handleRemoveSellOrder = params => {
    const extraProps = {
      startDate,
      endDate,
      qp,
      storageProductUuid,
      limit: sellOrdersPageSize,
      page: sellOrdersPage
    }

    dispatch(removeSellOrder(userId, establishmentId, order?.orderId, params, extraProps, pageOrigin))
      .then(() => {
        if(pageOrigin == 'transactions') {
          return loadTransactionsAndResume();
        }

        loadSellOrdersListAndResume();

        setIsSellOrderModalOpen(false);
      });
  }

  return(
    <Modal
      id='sellOrder'
      title='Informações do pedido'
      scroll='body'
      maxWidth='md'
      open={isSellOrderModalOpen}
      onClose={() => setIsSellOrderModalOpen(false)}
    >
      <Loader isLoading={isModalLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card elevation={0} className={classes.card}>
              <List disablePadding className={classes.list}>
                <Item primary="N° Pedido:">{order?.orderCode}</Item>
                <Item primary="Registro do Pedido:">{order?.userName}</Item>
                {!!order?.registrationDateTime && (
                  <Item primary="Registro:">{moment(order?.registrationDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</Item>
                )}
                <Item primary="Venda do Pedido:">{order?.transactionUserName}</Item>
                {!!order?.paymentDateTime && (
                  <Item primary="Venda:">{moment(order?.paymentDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</Item>
                )}
                <Item primary="Meio de Pagamento:">{order?.paymentMethodName}</Item>
                {!!order?.discount && (
                  <Item primary="Desconto:">
                    {+order?.discount?.type?.typeId == 1 ? `${numericFormatter(order?.discount?.amount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} %` : convertToBrl(parseFloat(order?.discount?.amount))}
                  </Item>
                )}
                <Item primary="Valor:">
                  {`R$ ${numericFormatter(order?.productsAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`}
                </Item>
              </List>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            {(!!order?.client?.documentId || !!order?.client?.email || !!order?.client?.phones) && (
              <Card elevation={0} className={classes.card}>
                <List disablePadding className={classes.list}>
                  <Item primary="Cliente:">{order?.client?.name}</Item>
                  {!!order?.client?.documentId && (
                    <Item primary="CPF/CNPJ:">{formatDocument(order?.client?.documentId)}</Item>
                  )}
                  <Item primary="E-mail:">{order?.client?.email}</Item>
                  {!!order?.client?.phones && (
                    <Item primary="Telefone:">{formatPhone(order?.client?.phones[0])}</Item>
                  )}
                </List>
              </Card>
            )}
            {(order?.orderItems?.length > 0) && (
              <Card elevation={0} className={classes.card}>
                <List disablePadding className={classes.list}>
                  <Typography color="primary" variant="h6" style={{ marginLeft: 15 }}>Produtos</Typography>
                  {order?.orderItems?.map(item => {
                    return <Item primary={`${item?.quantity} ${item?.name}`}>{`R$ ${numericFormatter(item?.amount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`}</Item>
                  })}
                </List>
              </Card>
            )}
            <Card elevation={0} className={classes.card}>
              <List disablePadding className={classes.list}>
                <Item primary="Valor Total:">{`R$ ${numericFormatter(order?.totalAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`}</Item>
              </List>
            </Card>
          </Grid>
        </Grid>
        {isRemoving && (
          <Formik
            enableReinitialize
            initialValues={{ reason: '', userPassword: '' }}
            validationSchema={sellOrdersValidations}
            validateOnChange={false}
            onSubmit={handleRemoveSellOrder}
          >
            {({ ...formikProps }) => {
              return(
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Input
                        type="text"
                        name="reason"
                        label="Observação"
                        value={formikProps.values.reason}
                        error={formikProps.errors.reason}
                        helperText={formikProps.errors.reason}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        noAutoComplete
                        type="password"
                        name="userPassword"
                        label="Senha"
                        value={formikProps.values.userPassword}
                        error={formikProps.errors.userPassword}
                        helperText={formikProps.errors.userPassword}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                      <Button
                        type="submit"
                        color="primary"
                        loading={isModalLoading}
                      >
                        Confirmar
                      </Button>
                      <Button
                        type="button"
                        color="error"
                        loading={isModalLoading}
                        onClick={() => setIsRemoving(false)}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isRemoving && (
              <Button
                type="button"
                color="error"
                loading={isModalLoading}
                onClick={() => setIsRemoving(true)}
              >
                Remover
              </Button>
            )}
          </Grid>
        </Grid>
      </Loader>
    </Modal>
  );
}