import { set, lensProp, lensPath, pick } from 'ramda';

import {
  REGISTER_FIELD_CHANGE,
  SET_AUTOMATIONS,
  CLEAR_AUTOMATIONS,
  HANDLE_LOADING,
} from './constants';

const defaultState = {
  isLoading: false,
  submit: {
    typePrices: [],
    services: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case SET_AUTOMATIONS: {
      return set(
        lensProp('submit'),
        pick(['typePrices', 'services'], action.data),
        state
      );
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_AUTOMATIONS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
