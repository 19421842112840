import qs from 'qs';
import { newAPI } from 'services';

export const fetchProduct = async (userId, establishmentId, storageProductUuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/products/${storageProductUuid}`);
  return response.data;
}

export const createProduct = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/storage/products/basic-information`, qs.stringify(params));
  return response.data;
}

export const updateProduct = async (userId, establishmentId, storageProductUuid, params) => {
  const response = await newAPI.patch(`${userId}/establishment/${establishmentId}/storage/products/basic-information/${storageProductUuid}`, qs.stringify(params));
  return response.data;
}

export const updateProductIdentifiers = async (userId, establishmentId, storageProductUuid, params) => {
  const response = await newAPI.patch(`${userId}/establishment/${establishmentId}/storage/products/identifiers/${storageProductUuid}`, qs.stringify(params));
  return response.data;
}

export const fetchSupplier = async (userId, establishmentId, supplierUuid) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/suppliers/${supplierUuid}`);
  return response.data;
}

export const fetchSupplierList = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/suppliers`);
  return response.data;
}

export const createSupplier = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/storage/suppliers`, qs.stringify(params));
  return response.data;
}

export const updateSupplier = async (userId, establishmentId, supplierUuid, params) => {
  const response = await newAPI.patch(`${userId}/establishment/${establishmentId}/storage/suppliers/${supplierUuid}`, qs.stringify(params));
  return response.data;
}