import { toast } from 'react-toastify';
import { compose, over, lensProp, omit } from 'ramda';
import { fetchProfile, createNewUser, updateUser } from './service';
import { setUserId, setUser, handleLoading, setUserRestrictions } from './actions';
import { updateToken, setUuid } from '../../Login/store/actions';
import { setBaseAPI, setToken } from '../../../services';
import { formatToOnlyNumbers } from '../../../helpers/formatters';

export const loadProfile = userId => dispatch => {
  dispatch(handleLoading(true));

  return fetchProfile(userId)
    .then(({ data }) => {
      const { userRestrictions, profileId } = data;

      dispatch(setUser(data));
      dispatch(setUserRestrictions(userRestrictions, profileId));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertUser = params => dispatch => {
  const formatParams = compose(
    over(lensProp('phone'), formatToOnlyNumbers),
    omit(['establishmentId', 'termsOfUse'])
  );

  dispatch(handleLoading(true));

  if (!formatParams(params).userId) {
    delete params['userId'];

    return createNewUser(formatParams(params))
      .then(({ data }) => {
        const { userId, accessToken, uuid } = data;

        setToken(accessToken);
        setBaseAPI(userId);

        dispatch(setUuid(uuid));
        dispatch(updateToken(accessToken));
        dispatch(setUserId(userId));

        toast.success('Usuário criado com sucesso!');
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateUser(formatParams(params))
    .then(({ data }) => {
      toast.success('Usuário atualizado com sucesso!');
      dispatch(setUser(data));
    })
    .finally(() => dispatch(handleLoading(false)));
}