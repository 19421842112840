import {
  REGISTER_FIELD_CHANGE,
  SET_CLIENT_INVOICES,
  SET_INVOICE_ID,
  SET_INVOICE_DETAILS,
  SET_AMOUNT_RECEIVED,
  HANDLE_UPDATE_INVOICE,
  HANDLE_LOADING,
  HANDLE_DETAILING,
  HANDLE_PAYING,
  HANDLE_EMAILING,
  HANDLE_CANCELING,
  HANDLE_CANCELING_PAYMENT,
  CLEAR_FORM,
  HANDLE_CHANGE_DATE,
} from './constants';

import moment from 'moment';
import { compose, map, set, over, lensPath, lensProp, groupWith, prop, assoc } from 'ramda';
import { convertInvoiceParams, convertTimeToDB } from '../../../helpers/converters';

const defaultState = {
  list: [],
  isLoading: false,
  isDetailing: false,
  isPaying: false,
  isEmailing: false,
  isCanceling: false,
  isCancelingPayment: false,
  isUpdatingInvoice: false,
  invoiceDateTime: '',
  invoiceClientName: '',
  clientTypeId: null,
  submit: {
    currentDueDateTime: '',
    dueDateTime: '',
    paymentMethod: '',
    paymentDateTime: '',
    amount: '',
    currentAmount: '',
    email: '',
    userPassword: '',
    reason: '',
    amountReceived: '',
    expirationDate: '',
    servicesContract: [],
    servicesUsage: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case SET_CLIENT_INVOICES: {
      const { data } = action;
      const { client, invoices } = data;

      const invoicesByYears = groupWith((invoice, nextInvoice) => {
        const startDate = prop('dueDateTime', invoice).split('/')[2];
        const nextDate = prop('dueDateTime', nextInvoice).split('/')[2];
        return startDate === nextDate;
      });

      const formatInvoices = map(convertInvoiceParams, invoices);
      const formattedInvoices = invoicesByYears(formatInvoices);

      return compose(
        set(lensProp('invoiceClientName'), client?.clientName),
        set(lensProp('clientTypeId'), client?.clientTypeId),
        set(lensProp('list'), formattedInvoices)
      )(defaultState);
    }
    case SET_INVOICE_ID: {
      return set(lensProp('invoiceDateTime'), action.id, state);
    }
    case SET_AMOUNT_RECEIVED: {
      return set(lensPath(['submit', 'amountReceived']), action.amount, state);
    }
    case SET_INVOICE_DETAILS: {
      const { data: { paymentMethodId } } = action;
      const paymentMethod = paymentMethodId || null;

      const formatPaymentToCurrentDay = compose(
        assoc('paymentMethod', paymentMethod),
        assoc('paymentDateTime', action.data.paymentDateTime),
        assoc('expirationDate', action?.data?.serviceContract?.expirationDate || ''),
        assoc('servicesContract', action?.data?.serviceContract?.servicesContract || []),
        assoc('servicesUsage', action?.data?.serviceContract?.servicesUsage || []),
        assoc('email', !!action?.data?.clientEmail ? action?.data?.clientEmail : ''),
        over(lensProp('dueDateTime'), date => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
        over(lensProp('currentDueDateTime'), date => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      );

      const invoiceDetails = compose(
        set(lensProp('isCanceling'), false),
        set(lensProp('isEmailing'), false),
        set(lensProp('isPaying'), false),
        set(lensProp('isDetailing'), false),
        set(
          lensProp('submit'),
          formatPaymentToCurrentDay(convertInvoiceParams(action.data))
        )
      );

      return invoiceDetails(state);
    }
    case HANDLE_UPDATE_INVOICE: {
      return set(lensProp('isUpdatingInvoice'), action.status, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_DETAILING: {
      return set(lensProp('isDetailing'), action.status, state);
    }
    case HANDLE_PAYING: {
      return set(lensProp('isPaying'), action.status || !state.isPaying, state);
    }
    case HANDLE_EMAILING: {
      const handleEmailing = compose(
        set(lensProp('isEmailing'), action.status || !state.isEmailing)
      );

      return handleEmailing(state);
    }
    case HANDLE_CANCELING: {
      const handleCanceling = compose(
        set(lensProp('isCanceling'), action.status || !state.isCanceling),
        set(lensPath(['submit', 'reason']), ''),
        set(lensPath(['submit', 'userPassword']), '')
      );

      return handleCanceling(state);
    }
    case HANDLE_CANCELING_PAYMENT: {
      const handleCancelingPayment = compose(
        set(lensProp('isCancelingPayment'), action.status || !state.isCancelingPayment),
        set(lensPath(['submit', 'reason']), ''),
        set(lensPath(['submit', 'userPassword']), '')
      );

      return handleCancelingPayment(state);
    }
    case CLEAR_FORM: {
      const clear = compose(
        set(lensProp('isUpdatingInvoice'), false),
        set(lensProp('submit'), defaultState.submit)
      );

      return clear(state);
    }
    case HANDLE_CHANGE_DATE: {
      return set(lensPath(['submit', action.field]), convertTimeToDB(action.date), state);
    }
    default: {
      return state;
    }
  }
}