export const REGISTER_FIELD_CHANGE = 'PRICE_TABLE/REGISTER_FIELD_CHANGE';
export const SET_TABLES = 'PRICE_TABLE/SET_TABLES';
export const SET_COVENANT = 'PRICE_TABLE/SET_COVENANT';
export const HANDLE_LOADING = 'PRICE_TABLE/HANDLE_LOADING';
export const HANDLE_COVENANT = 'TABLE/HANDLE_COVENANT';
export const HANDLE_UPDATE = 'PRICE_TABLE/HANDLE_UPDATE';
export const OPEN_SPECIAL_FORM = 'PRICE_TABLE/OPEN_SPECIAL_FORM';
export const HANDLE_SPECIAL_UPDATE = 'PRICE_TABLE/HANDLE_SPECIAL_UPDATE';
export const ADD_TIME_RANGE = 'PRICE_TABLE/ADD_TIME_RANGE';
export const REMOVE_TIME_RANGE = 'PRICE_TABLE/REMOVE_TIME_RANGE';
export const ADD_SPECIFIC_TIME_RANGE = 'PRICE_TABLE/ADD_SPECIFIC_TIME_RANGE';
export const REMOVE_SPECIFIC_TIME_RANGE = 'PRICE_TABLE/REMOVE_SPECIFIC_TIME_RANGE';
export const CLEAR_COVENANT = 'PRICE_TABLE/CLEAR_COVENANT';
export const CLEAR_PRICE_TABLE = 'PRICE_TABLE/CLEAR_PRICE_TABLE';