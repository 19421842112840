import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBox-root': {
      backgroundColor: '#FFFFFF'
    }
  },
  wrapper: {
    position: 'relative'
  },
  slogan: {
    position: 'absolute',
    height: 270,
    width: 400,
    backgroundColor: '#022A5C',
    color: '#FFFFFF',
    padding: 20,
    zIndex: 1000,
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  sloganMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    width: 350,
    backgroundColor: '#022A5C',
    color: '#FFFFFF',
    margin: '125px 0 125px 0'
  },
  sloganVerticalAdornment: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    width: 1,
    height: 300,
    bottom: 85,
    left: -30
  },
  sloganHorizontalAdornment: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    width: 220,
    height: 1,
    bottom: 105,
    right: 50
  },
  halfVh: {
    minHeight: '50vh'
  },
  fullVh: {
    minHeight: '100vh'
  },
  containerBackground: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  containerBackgroundJumpParkGradient: {
    height: '100%',
    width: '100%',
    backgroundImage: 'linear-gradient(rgba(2, 42, 92, 0.55), rgba(2, 42, 92, 0.28))'
  },
  containerBackgroundJumpCarGradient: {
    height: '100%',
    width: '100%',
    backgroundImage: 'linear-gradient(rgba(19, 188, 75, 0.25), rgba(19, 188, 75, 0))'
  },
  containerSecondary: {
    backgroundColor: '#EDEFF2',
    position: 'relative'
  },
  containerLogin: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: 200,
    marginTop: 35,
    marginBottom: 35
  },
  containerForm: {
    height: 'fit-content',
    textAlign: 'center',
    marginBottom: 50
  },
  containerFormMobile: {
    backgroundColor: '#FFFFFF',
    height: 'fit-content',
    width: '100%',
    textAlign: 'center',
    borderRadius: 24,
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)'
  },
  containerFormAndSloganMobile: {
    position: 'absolute',
    top: '-200px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  containerFloatingComponent: {
    position: 'absolute',
    bottom: 100,
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
}));