import * as types from './constants';

export const setTables = data => ({
  type: types.SET_TABLES,
  data,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const handleCovenant = (status, typePrice, hasCovenant) => ({
  type: types.HANDLE_COVENANT,
  status,
  typePrice,
  hasCovenant
});

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const addTimeRange = () => ({
  type: types.ADD_TIME_RANGE,
});

export const removeTimeRange = id => ({
  type: types.REMOVE_TIME_RANGE,
  id,
});

export const addSpecificTimeRange = () => ({
  type: types.ADD_SPECIFIC_TIME_RANGE,
});

export const removeSpecificTimeRange = id => ({
  type: types.REMOVE_SPECIFIC_TIME_RANGE,
  id,
});

export const handleUpdate = data => ({
  type: types.HANDLE_UPDATE,
  data,
});

export const openSpecialForm = (checked, linkedTable) => ({
  type: types.OPEN_SPECIAL_FORM,
  checked,
  linkedTable,
});

export const handleSpecialUpdate = data => ({
  type: types.HANDLE_SPECIAL_UPDATE,
  data,
});

export const setCovenant = data => ({
  type: types.SET_COVENANT,
  data,
});

export const clearCovenant = () => ({
  type: types.CLEAR_COVENANT,
});

export const clearPriceTable = () => ({
  type: types.CLEAR_PRICE_TABLE,
});