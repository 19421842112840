import qs from 'qs';
import { api, newAPI } from '../../../services';

export const fetchSingleTransaction = async (userId, establishmentId, transactionId) => {
  const response = await newAPI.get(`/${userId}/establishment/${establishmentId}/transactions/${transactionId}`);
  return response.data;
}

export const fetchTransaction = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchResume = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const createTransaction = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/transactions/new`, qs.stringify(params));
  return response.data;
}

export const updateTransaction = async (userId, establishmentId, params, transactionId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/transactions/${transactionId}`, qs.stringify(params));
  return response.data;
}

export const updateSituation = async (userId, establishmentId, transactionId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/transactions/${transactionId}/confirm`, qs.stringify(params));
  return response.data;
}

export const fetchCashierDetails = async (userId, establishmentId, sourceId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/cashiers/${sourceId}`);
  return response.data;
}

export const fetchStoreCashierDetails = async (userId, establishmentId, storeCashierId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/store/cashiers/${storeCashierId}`);
  return response.data;
}

export const fetchWithdral = transactionId =>
  api.get(`/transactions/${transactionId}`).then(response => response.data);

export const deleteTransaction = async (userId, establishmentId, transactionId, params) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/transactions/${transactionId}`, { data: qs.stringify(params) });
  return response.data;
}

export const createAttachment = async (userId, establishmentId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/transactions/attachment`, formData);
  return response.data;
}

export const deleteAttachment = async (userId, establishmentId, file) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/transactions/attachment`, { data: qs.stringify({ file }) });
  return response.data;
}

export const createCashierAttachment = async (userId, establishmentId, cashierId, file, column) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('column', column);

  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/cashiers/${cashierId}/attachment`, formData);
  return response.data;
}

export const deleteCashierAttachment = async (userId, establishmentId, cashierId, file) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/cashiers/${cashierId}/attachment`, { data: qs.stringify({ file }) });
  return response.data;
}