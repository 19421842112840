import moment from 'moment';
import { CHANGE_DATE, CLEAR_DATE } from './constants';

const defaultState = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_DATE: {
      const convertTimeToBD = time => moment(time).format('YYYY-MM-DD');
      return {
        ...state,
        startDate: convertTimeToBD(action.startDate),
        endDate: convertTimeToBD(action.endDate),
      };
    }
    case CLEAR_DATE:
      return defaultState;
    default:
      return state;
  }
};
