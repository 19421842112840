import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginBottom: spacing(3),
  },
  footer: {
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
    padding: '15px 0',
    borderTop: '1px solid #ddd',
  },
}));
