export const availableIssChargeabilities = [
  { value: 1, label: 'Exigível' },
  { value: 2, label: 'Nao incidência' },
  { value: 3, label: 'Isenção' },
  { value: 4, label: 'Exportação' },
  { value: 5, label: 'Imunidade' },
  { value: 6, label: 'Exigibilidade Suspensa por Decisão Judicial' },
  { value: 7, label: 'Exigibilidade Suspensa por Processo Administrativo' }
];

export const availableTaxTypes = [
  { value: '0', label: 'Não definido' },
  { value: '1', label: 'Isento de ISS' },
  { value: '2', label: 'Imune' },
  { value: '3', label: 'Não Incidência no Município' },
  { value: '4', label: 'Não Tributável' },
  { value: '5', label: 'Retido' },
  { value: '6', label: 'Tributável Dentro do Município' },
  { value: '7', label: 'Tributável Fora do Município' },
  { value: '8', label: 'Tributável Dentro do Município pelo tomador' }
];