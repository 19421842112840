import { compose, map, over, lensProp, set, assoc } from 'ramda';
import moment from 'moment';
import {
  TEXT_CHANGE,
  SET_CASHFLOW,
  HANDLE_LOADING,
  CHANGE_PERIOD_TYPE,
  SET_CURRENT_YEAR_IN_DAY_TYPE,
  NEXT_POINT,
  PREV_POINT,
  SET_YEAR_RANGE,
  BUILD_RESUME,
  CLEAR_BUILD_RESUME_FILTERS,
  CHANGE_DATE,
  CLEAR_DATE,
} from './constants';
import { formatDate, formatDateMonthName } from '../../../helpers/formatters';
import { convertToReal } from '../../../helpers/converters';

const increase = (point, range, withLimit = true) => {
  let newPoint = parseInt(point, 10);
  const conditional = withLimit ? newPoint !== range[range.length - 1] : true;
  if (conditional) {
    newPoint += 1;
  }
  return newPoint;
};

const decrease = point => {
  let newPoint = parseInt(point, 10);
  newPoint -= 1;
  return newPoint;
};

const increaseMonth = (year, point, range, withLimit = true) => {
  let newYear = parseInt(year, 10);
  let newPoint = parseInt(point, 10);
  const conditional = withLimit ? newPoint !== range[range.length - 1] : true;
  if (conditional) {
    newPoint += 1;
  } else {
    newYear += 1;
    newPoint = 1;
  }

  return {
    year: newYear,
    month: newPoint,
  };
};

const decreaseMonth = (year, point, range) => {
  let newYear = parseInt(year, 10);
  let newPoint = parseInt(point, 10);
  if (newPoint !== range[0]) {
    newPoint -= 1;
  } else {
    newYear -= 1;
    newPoint = 12;
  }

  return {
    year: newYear,
    month: newPoint,
  };
};

const defaultState = {
  content: [],
  resume: {
    receipts: 0,
    payments: 0,
    balance: 0,
  },
  isLoading: false,
  type: 'day',
  month: moment(new Date()).format('MM'),
  monthRange: [1, 12],
  year: moment(new Date()).format('YYYY'),
  yearRange: [2017, 2020],
  buildingResume: false,
  resumeTypeId: null,
  resumeMonthId: null,
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  startTime: '',
  endTime: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TEXT_CHANGE: {
      const { value } = action;

      return set(lensProp(action.field), value, state);
    }
    case SET_CASHFLOW: {
      const { content, resume } = action;

      const formatter = item =>
        compose(
          assoc('monthId', item.month),
          over(lensProp('day'), formatDate),
          over(lensProp('month'), formatDateMonthName),
          over(lensProp('previousBalance'), convertToReal),
          over(lensProp('amountReceipt'), convertToReal),
          over(lensProp('amountPayment'), convertToReal),
          over(lensProp('amountBalance'), convertToReal),
          over(lensProp('balance'), convertToReal)
        )(item);
      const cashflow = map(formatter, content);

      return {
        ...state,
        content: cashflow,
        resume,
        isLoading: false,
      };
    }
    case HANDLE_LOADING: {
      return {
        ...state,
        isLoading: action.status,
      };
    }
    case CHANGE_PERIOD_TYPE: {
      return {
        ...state,
        type: action.periodType,
      };
    }
    case SET_CURRENT_YEAR_IN_DAY_TYPE: {
      const { year, type } = state;

      const dateElement =
        type === 'day' ? { year: moment(new Date()).format('YYYY') } : { year };

      return {
        ...state,
        ...dateElement,
      };
    }
    case SET_YEAR_RANGE: {
      return {
        ...state,
        yearRange: action.yearRange,
      };
    }
    case NEXT_POINT: {
      const { year, month, type, monthRange, yearRange } = state;

      const dateElement =
        type === 'day'
          ? increaseMonth(year, month, monthRange)
          : { year: increase(year, yearRange, false) };

      return {
        ...state,
        ...dateElement,
      };
    }
    case PREV_POINT: {
      const { year, month, type, monthRange, yearRange } = state;

      const dateElement =
        type === 'day'
          ? decreaseMonth(year, month, monthRange)
          : { year: decrease(year, yearRange) };

      return {
        ...state,
        ...dateElement,
      };
    }
    case BUILD_RESUME: {
      return set(lensProp('buildingResume'), action.status, state);
    }
    case CLEAR_BUILD_RESUME_FILTERS: {
      return compose(
        set(lensProp('registration'), false),
        set(lensProp('resumeTypeId'), null),
        set(lensProp('resumeMonthId'), null),
        set(lensProp('startDate'), moment().format('YYYY-MM-DD')),
        set(lensProp('endDate'), moment().format('YYYY-MM-DD'))
      )(state);
    }
    case CHANGE_DATE: {
      const convertTimeToBD = time => moment(time).format('YYYY-MM-DD');
      return {
        ...state,
        startDate: convertTimeToBD(action.startDate),
        endDate: convertTimeToBD(action.endDate),
      };
    }
    case CLEAR_DATE:
      return defaultState;
    default:
      return state;
  }
};
