import {
  compose,
  over,
  lensProp,
  set,
  split,
  test,
  when,
  remove,
  lensPath,
  omit
} from 'ramda';

import {
  SET_TABLES,
  HANDLE_LOADING,
  HANDLE_COVENANT,
  REGISTER_FIELD_CHANGE,
  ADD_TIME_RANGE,
  REMOVE_TIME_RANGE,
  ADD_SPECIFIC_TIME_RANGE,
  REMOVE_SPECIFIC_TIME_RANGE,
  HANDLE_UPDATE,
  OPEN_SPECIAL_FORM,
  HANDLE_SPECIAL_UPDATE,
  SET_COVENANT,
  CLEAR_COVENANT,
  CLEAR_PRICE_TABLE
} from './constants';

import {
  convertToReal,
  convertEmptyTime,
  convertToEmptyString,
  convertMinutesToPeriod,
  hoursAndMinutesToMinutes
} from '../../../helpers/converters';

import { updateItems } from '../../../helpers/formatters';

const defaultState = {
  tables: [],
  isLoading: false,
  isCovenant: false,
  isModalMobile: false,
  isUpdating: false,
  isSpecial: [],
  updateType: '',
  linkedTable: '',
  covenant: {
    typePrice: '',
    accessCode: 0,
    activated: 0,
    toleranceActivation: 0,
    toleranceAfterActivation: 0,
    hasCovenant: false
  },
  submit: {
    typePrice: '',
    description: '',
    tolerance: '',
    major: 0,
    invisible: 0,
    compulsory: 0,
    items: [],
    maximumPeriod: '',
    maximumValue: '',
    weekDays: [],
    startTime: '',
    endTime: ''
  },
  timeRanges: [{ since: '', period: '', price: '' }],
  specificTimeRanges: [{ since: '', period: '', price: '' }]
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TABLES: {
      return {
        ...state,
        tables: action.data,
      };
    }
    case HANDLE_LOADING: {
      return {
        ...state,
        isLoading: action.status,
      };
    }
    case HANDLE_COVENANT: {
      return {
        ...state,
        isCovenant: action.status,
        covenant: {
          ...state.covenant,
          typePrice: action.typePrice,
          hasCovenant: action.hasCovenant
        },
      };
    }
    case REGISTER_FIELD_CHANGE: {
      const toInt = when(test(/\d/), Number.parseInt);
      const pathArray = split('.', action.field).map(toInt);
      const lens = lensPath(pathArray);

      return set(lens, action.value, state);
    }
    case ADD_TIME_RANGE: {
      const timeRangeWithItemId = state.timeRanges?.filter(timeRange => timeRange?.itemId);
      const timeRangeWithoutItemId = state.timeRanges?.filter(timeRange => !timeRange?.itemId)?.reverse();

      let timeRangesArray = [...timeRangeWithoutItemId];

      if(timeRangeWithItemId?.length > 0) {
        timeRangesArray = [
          ...timeRangeWithItemId,
          ...timeRangeWithoutItemId
        ]
      }

      timeRangesArray = timeRangesArray
        .map(timeRange => {
          return {
            ...timeRange,
            minutesPeriod: hoursAndMinutesToMinutes(timeRange?.period)
          }
        })
        .sort((a, b) => b.minutesPeriod - a.minutesPeriod)
        .map(timeRange => {
          delete timeRange.minutesPeriod;
          return timeRange;
        })

      return {
        ...state,
        timeRanges: [{ since: '', period: '', price: '' }, ...timeRangesArray]
      }
    }
    case REMOVE_TIME_RANGE: {
      const toInt = when(test(/\d/), Number.parseInt);
      const pathArray = split('.', action.id).map(toInt);
      const pathIndex = pathArray[1];
      const timeRanges = remove(pathIndex, 1, state.timeRanges);

      return {
        ...state,
        timeRanges,
      };
    }
    case ADD_SPECIFIC_TIME_RANGE: {
      const specificTimeRangesArray = state.specificTimeRanges?.reverse();

      const specificTimeRangesSortedArray = specificTimeRangesArray
        .map(timeRange => {
          return {
            ...timeRange,
            minutesPeriod: hoursAndMinutesToMinutes(timeRange?.since)
          }
        })
        .sort((a, b) => b.minutesPeriod - a.minutesPeriod)
        .map(timeRange => {
          delete timeRange.minutesPeriod;
          return timeRange;
        });

      return {
        ...state,
        specificTimeRanges: [{ since: '', period: '', price: '' }, ...specificTimeRangesSortedArray]
      }
    }
    case REMOVE_SPECIFIC_TIME_RANGE: {
      const toInt = when(test(/\d/), Number.parseInt);
      const pathArray = split('.', action.id).map(toInt);
      const pathIndex = pathArray[1];
      const specificTimeRanges = remove(pathIndex, 1, state.specificTimeRanges);

      return {
        ...state,
        specificTimeRanges,
      };
    }
    case HANDLE_UPDATE: {
      const { typePrice } = action.data;
      const params = compose(
        omit(['specialPrices', 'covenant', 'establishmentId']),
        over(lensProp('tolerance'), convertMinutesToPeriod),
        over(lensProp('maximumPeriod'), convertMinutesToPeriod),
        over(lensProp('maximumValue'), convertToReal)
      )(action.data);

      const [emptyTimeRange, ...filledTimeRanges] = updateItems(action.data)?.timeRanges;

      const formattedTimeRanges = [
        emptyTimeRange,
        ...filledTimeRanges?.reverse()
      ];

      const [emptySpecificTimeRanges, ...filledSpecificTimeRanges] = updateItems(action.data)?.specificTimeRanges;

      const formattedSpecificTimeRanges = [
        emptySpecificTimeRanges,
        ...filledSpecificTimeRanges
          ?.reverse()
          ?.map(timeRange => {
            return {
              ...timeRange,
              minutesPeriod: hoursAndMinutesToMinutes(timeRange?.since)
            }
          })
          ?.sort((a, b) => b.minutesPeriod - a.minutesPeriod)
          ?.map(timeRange => {
            delete timeRange.minutesPeriod;
            return timeRange;
          })
      ];

      return {
        ...state,
        isUpdating: true,
        updateType: typePrice,
        submit: { ...state.submit, ...params },
        timeRanges: formattedTimeRanges,
        specificTimeRanges: formattedSpecificTimeRanges
      }
    }
    case OPEN_SPECIAL_FORM: {
      const { isSpecial } = state;
      const { linkedTable } = action;
      const isSelected = isSpecial.includes(linkedTable);
      let isSpecialResult = defaultState.isSpecial;

      if (!isSelected) {
        isSpecialResult = [linkedTable];
      }

      if (!action.checked) {
        isSpecialResult = [];
      }

      return {
        ...defaultState,
        linkedTable,
        isSpecial: isSpecialResult,
        tables: state.tables,
      };
    }
    case HANDLE_SPECIAL_UPDATE: {
      const { typePrice, description } = action.data;
      const params = compose(
        over(lensProp('maximumValue'), convertToReal),
        over(lensProp('startTime'), convertEmptyTime),
        over(lensProp('endTime'), convertEmptyTime),
        over(lensProp('tolerance'), convertToEmptyString)
      )(action.data);

      return {
        ...state,
        isUpdating: true,
        isSpecial: [typePrice],
        updateType: description,
        linkedTable: typePrice,
        submit: { ...state.submit, ...params },
        timeRanges: updateItems(action.data).timeRanges,
        specificTimeRanges: updateItems(action.data).specificTimeRanges,
      };
    }
    case SET_COVENANT: {
      return {
        ...state,
        isLoading: false,
        covenant: action.data,
      };
    }
    case CLEAR_COVENANT: {
      return {
        ...state,
        covenant: {
          ...defaultState.covenant,
          typePrice: state.covenant.typePrice
        },
      };
    }
    case CLEAR_PRICE_TABLE: {
      return {
        ...defaultState,
        tables: state.tables,
        isWarningMessageRead: state.isWarningMessageRead
      };
    }
    default: {
      return state;
    }
  }
}