import * as type from './constants';

export const setIsLoading = isLoading => ({
  type: type.SET_IS_LOADING,
  isLoading
});

export const setComissionedPayrollList = data => ({
  type: type.SET_COMISSIONED_PAYROLL_LIST,
  data
});

export const clearComissionedPayrollList = () => ({
  type: type.CLEAR_COMISSIONED_PAYROLL_LIST
});