import { SET_IS_LOADING, SET_SUPPLIER_LIST } from './constants';

const defaultState = {
  isLoading: false,
  total: 0,
  list: []
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_SUPPLIER_LIST: {
      const {
        total,
        data
      } = action.data;

      const formattedData = data?.map(supplier => {
        return {
          uuid: supplier?.uuid,
          name: supplier?.contacts[0]?.name || '-',
          corporateName: supplier?.corporateName || '-',
          cnpj: supplier?.cnpj || '-',
          phone: supplier?.contacts[0]?.phone || '-'
        }
      });

      return {
        ...state,
        total,
        list: formattedData
      }
    }
    default: {
      return state;
    }
  }
}