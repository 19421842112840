import { toast } from 'react-toastify';
import { setVehicles, setCards, clearCardRegister, clearVehicleRegister, toggleToRemoveVehicle, toggleToRemoveCard, handleVehicleLoading, handleCardLoading } from './actions';
import { fetchVehicles, fetchCards, createVehicle, deleteVehicle, createCard, deleteCard } from './services';

export const loadVehicles = params => dispatch => {
  dispatch(handleVehicleLoading(true));

  return fetchVehicles(params)
  .then(({ data }) => {
    dispatch(setVehicles(data));
  })
  .catch(() => dispatch(handleVehicleLoading(false)));
};

export const registerVehicle = params => dispatch => {
  dispatch(handleVehicleLoading(true));

  return createVehicle(params)
  .then(() => {
    toast.success('Veículo cadastrado com sucesso');
    dispatch(loadVehicles(params));
    dispatch(clearVehicleRegister());
  })
  .catch(() => dispatch(handleVehicleLoading(false)));
};

export const removeVehicle = params => dispatch => {
  dispatch(handleVehicleLoading(true));

  return deleteVehicle(params)
  .then(() => {
    toast.success('Veículo removido com sucesso');
    dispatch(loadVehicles(params));
    dispatch(toggleToRemoveVehicle(false, null));
  })
  .catch(() => dispatch(handleVehicleLoading(false)));
};

export const loadCards = params => dispatch => {
  dispatch(handleCardLoading(true));

  return fetchCards(params)
  .then(({ data }) => {
    dispatch(setCards(data));
  })
  .catch(() => dispatch(handleCardLoading(false)));
};

export const registerCard = params => dispatch => {
  dispatch(handleCardLoading(true));

  return createCard(params)
  .then(() => {
    toast.success('Cartão cadastrado com sucesso');
    dispatch(loadCards(params));
    dispatch(clearCardRegister());
  })
  .catch(() => dispatch(handleCardLoading(false)));
};

export const removeCard = params => dispatch => {
  dispatch(handleCardLoading(true));

  return deleteCard(params)
  .then(() => {
    toast.success('Cartão removido com sucesso');
    dispatch(loadCards(params));
    dispatch(toggleToRemoveCard(false, null));
  })
  .catch(() => dispatch(handleCardLoading(false)));
};