import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value
});

export const selectChange = id => ({
  type: types.SELECT_CHANGE,
  id
});

export const setRestrictions = data => ({
  type: types.SET_RESTRICTIONS,
  data
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const clearRestrictions = () => ({
  type: types.CLEAR_RESTRICTIONS
});