import * as types from './constants';

export const setIsInitialOnboardingOpen = status => ({
  type: types.SET_IS_INITIAL_ONBOARDING_OPEN,
  status
});

export const setIsFinalOnboardingOpen = status => ({
  type: types.SET_IS_FINAL_ONBOARDING_OPEN,
  status
});

export const setIsFromRegistration = status => ({
  type: types.SET_IS_FROM_REGISTRATION,
  status
});