import * as yup from 'yup';

export const validations = yup.object().shape({
  domainType: yup
    .number()
    .required('O tipo de domínio é obrigatório')
    .nullable(),
  domain: yup.string().required('O domínio é obrigatório'),
  toleranceTime: yup.string().required('A tolerância é obrigatória'),
  typePrices: yup.array().required('A tabela de cobrança é obrigatória'),
  typePriceExcess: yup.string().required('A tabela excedente é obrigatória'),
});
