import * as type from './constants';

export const setPayroll = payroll => ({
  type: type.SET_PAYROLL,
  payroll
});

export const setPartialPaymentList = list => ({
  type: type.SET_PARTIAL_PAYMENT_LIST,
  list
});

export const setPayrollList = data => ({
  type: type.SET_PAYROLL_LIST,
  data
});

export const setIsLoading = isLoading => ({
  type: type.SET_IS_LOADING,
  isLoading
});

export const setIsLoadingSinglePayroll = isLoading => ({
  type: type.SET_IS_LOADING_SINGLE_PAYROLL,
  isLoading
});

export const setEmployeeUuid = uuid => ({
  type: type.SET_EMPLOYEE_UUID,
  uuid
});

export const clearPayroll = () => ({
  type: type.CLEAR_PAYROLL
});

export const clearPayrollList = () => ({
  type: type.CLEAR_PAYROLL_LIST
});