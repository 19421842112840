import * as types from './constants';

export const setConfigurations = data => ({
  type: types.SET_CONFIGURATIONS,
  data
});

export const setImageUrl = path => ({
  type: types.SET_IMAGE_URL,
  path
});

export const setImagePath = path => ({
  type: types.SET_IMAGE_PATH,
  path
});

export const setIsLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  isLoading
});