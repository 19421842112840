import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop } from '@material-ui/core';
import Loader from 'components/Loader';

import BusinessInfo from './BusinessInfo';
import CityHallRegistration from './CityHallRegistration';
import Authentication from './Authentication';

import { loadProvisionalReceiptTables } from 'pages/ProvisionalReceiptConfig/store/thunk';

export default function BasicInfo({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { basicInfoErrors, isCityLoaded } = useSelector(state => state.provisionalReceiptConfig);

  useEffect(() => {
    dispatch(loadProvisionalReceiptTables(userId));
  }, []);

  useEffect(() => {
    if(Object.keys(formikProps?.errors).length > 0) {
      const firstError = Object.keys(formikProps?.errors)[0];

      const firstErrorFieldSelector = window.document.querySelector(`[name=${firstError}]`);
      const firstErrorFieldId = window.document.getElementById(firstError);

      if(firstErrorFieldSelector) {
        firstErrorFieldSelector.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstErrorFieldSelector.focus({ preventScroll: true });
      }

      if(firstErrorFieldId) {
        firstErrorFieldId.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstErrorFieldId.focus({ preventScroll: true });
      }
    }
  }, [formikProps?.errors]);

  useEffect(() => {
    const errors = Object.keys(basicInfoErrors).filter(error => !!basicInfoErrors[error]);

    if(errors.length > 0) {
      const firstError = errors[0];

      const firstErrorFieldSelector = window.document.querySelector(`[name=${firstError}]`);
      const firstErrorFieldId = window.document.getElementById(firstError);

      if(firstErrorFieldSelector) {
        firstErrorFieldSelector.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstErrorFieldSelector.focus({ preventScroll: true });
      }

      if(firstErrorFieldId) {
        firstErrorFieldId.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstErrorFieldId.focus({ preventScroll: true });
      }
    }
  }, [basicInfoErrors]);

  return(
    <>
      <Backdrop
        open={!isCityLoaded}
        style={{ zIndex: 10000 }}
      >
        <Loader
          spinner
          isLoading={!isCityLoaded}
        />
      </Backdrop>
      <BusinessInfo formikProps={formikProps} />
      <CityHallRegistration formikProps={formikProps} />
      <Authentication formikProps={formikProps} />
    </>
  );
}