import * as type from './constants';

export const setInitialEstablishments = data => ({
  type: type.SET_INITIAL_ESTABLISHMENTS,
  data
});

export const setAllEstablishments = data => ({
  type: type.SET_ALL_ESTABLISHMENTS,
  data
});

export const setAppPermissionEstablishments = data => ({
  type: type.SET_APP_PERMISSION_ESTABLISHMENTS,
  data
});

export const setNewEstablishment = data => ({
  type: type.SET_NEW_ESTABLISHMENT,
  data
});

export const setSingleEstablishment = data => ({
  type: type.SET_SINGLE_ESTABLISHMENT,
  data
});

export const setUpdatedEstablishment = data => ({
  type: type.SET_UPDATED_ESTABLISHMENT,
  data
});

export const setUuid = uuid => ({
  type: type.SET_UUID,
  uuid
});

export const setTermsOfUseDate = date => ({
  type: type.SET_TERMS_OF_USE_DATE,
  date
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status
});

export const updateToken = token => ({
  type: type.UPDATE_TOKEN,
  token
});

export const handleLogout = () => ({
  type: type.HANDLE_LOGOUT
});

export const setIsTermsOfUseChecked = checked => ({
  type: type.SET_IS_TERMS_OF_USE_CHECKED,
  checked
});