import qs from 'qs';
import { changeBaseAPI, newAPI } from '../../../services';

export const fetchReceiptInvoicePrint = async params => {
  const result = await newAPI.get(`${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/invoices/${params.invoiceDateTime}/print`);
  return result.data;
}

export const fetchClientInvoiceServiceOrders = async params => {
  const result = await newAPI.get(`${params.userId}/establishment/${params.establishmentId}/invoices/${params.clientId}/${params.invoiceDateTime}/serviceorders`);
  return result.data.data;
}

export const fetchClientInvoices = async url => {
  const result = await newAPI.get(url);
  return result.data;
}

export const fetchInvoiceDetails = async params => {
  const result = await newAPI.get(`${params.userId}/establishment/${params.establishmentId}/invoices/${params.clientId}/${params.invoiceDateTime}`);
  return result.data;
}

export const fetchInvoiceServiceContractDetails = async (userId, establishmentId, clientId, invoiceDateTime) => {
  const result = await changeBaseAPI('get', `/${userId}/establishment/${establishmentId}/clients/${clientId}/invoices/${invoiceDateTime}`);
  return result.data;
}

export const requestRemoveServiceContract = async (userId, establishmentId, serviceContractId) => {
  const result = await newAPI.delete(`${userId}/establishment/${establishmentId}/contracts/${serviceContractId}`);
  return result.data;
}

export const updateInvoice = async (userId, establishmentId, clientId, invoiceDateTime, params) => {
  const result = await newAPI.put(`${userId}/establishment/${establishmentId}/invoices/${clientId}/${invoiceDateTime}`, qs.stringify(params));
  return result.data;
}

export const requestToPayInvoice = async (userId, establishmentId, clientId, invoiceDateTime, params) => {
  const result = await newAPI.post(`${userId}/establishment/${establishmentId}/invoices/pay/${clientId}/${invoiceDateTime}`, qs.stringify(params));
  return result.data;
}

export const requestUndoPayment = async (userId, establishmentId, clientId, invoiceDateTime, params) => {
  const result = await newAPI.post(`${userId}/establishment/${establishmentId}/invoices/undopayment/${clientId}/${invoiceDateTime}`, qs.stringify(params));
  return result.data;
}

export const requestToSendEmail = async (userId, establishmentId, clientId, invoiceDateTime, params) => {
  const result = await newAPI.post(`${userId}/establishment/${establishmentId}/clients/${clientId}/invoices/${invoiceDateTime}/send`, qs.stringify(params));
  return result.data;
}

export const requestToRestoreInvoice = async (userId, establishmentId, clientId, invoiceDateTime, params) => {
  const result = await newAPI.post(`${userId}/establishment/${establishmentId}/invoices/restore/${clientId}/${invoiceDateTime}`, qs.stringify(params));
  return result.data;
}

export const requestToCancelInvoice = async (userId, establishmentId, clientId, invoiceDateTime, params) => {
  const result = await newAPI.post(`${userId}/establishment/${establishmentId}/invoices/cancel/${clientId}/${invoiceDateTime}`, qs.stringify(params));
  return result.data;
}