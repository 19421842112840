import * as type from './constants';

export const setServices = (data, total) => ({
  type: type.SET_SERVICES,
  data,
  total
});

export const clearServicesSelected = () => ({
  type: type.CLEAR_SERVICES_SELECTED
});

export const selectServices = (serviceId, value) => ({
  type: type.SELECT_SERVICES,
  serviceId,
  value
});

export const selectSingleService = serviceId => ({
  type: type.SELECT_SINGLE_SERVICE,
  serviceId
});

export const handleServiceUpdate = service => ({
  type: type.HANDLE_SERVICE_UPDATE,
  service
});

export const toggleRemoveModal = status => ({
  type: type.TOGGLE_TO_REMOVE_SERVICE,
  status
});

export const handleLoading = status => ({
  type: type.LOADING_INFORMATIONS,
  status
});

export const clearForm = () => ({
  type: type.CLEAR_FORM
});

export const resetServicesSelected = () => ({
  type: type.RESET_SERVICES_SELECTED
});

export const setServicesCategories = categories => ({
  type: type.SET_SERVICES_CATEGORIES,
  categories
});