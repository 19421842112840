import {
  SET_PAYROLL,
  SET_PARTIAL_PAYMENT_LIST,
  SET_PAYROLL_LIST,
  SET_IS_LOADING,
  SET_IS_LOADING_SINGLE_PAYROLL,
  SET_EMPLOYEE_UUID,
  CLEAR_PAYROLL,
  CLEAR_PAYROLL_LIST
} from './constants';

import { groupWith, prop } from 'ramda';

import moment from 'moment';

const defaultState = {
  submit: {
    name: null,
    employeeRoleName: null,
    dueDate: moment(),
    paymentDateTime: moment(),
    amount: null,
    paidAmount: null,
    partialPayment: null,
    payrollCategoryId: null,
    account: null,
    situationId: null,
    uuid: null,
    transaction: {},
    partialPaymentList: []
  },
  employeeUuid: null,
  isLoading: true,
  isLoadingSinglePayroll: false,
  list: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_PAYROLL: {
      return {
        ...state,
        submit: {
          ...state.submit,
          ...action?.payroll
        }
      }
    }
    case SET_PARTIAL_PAYMENT_LIST: {
      return {
        ...state,
        submit: {
          ...state.submit,
          partialPaymentList: action.list
        }
      }
    }
    case SET_PAYROLL_LIST: {
      const payrollByYears = groupWith((payroll, nextPayroll) => {
        const startDate = prop('dueDate', payroll).split('-')[0];
        const nextDate = prop('dueDate', nextPayroll).split('-')[0];
        return startDate === nextDate;
      });

      const formattedPayrolls = payrollByYears(action.data);

      return {
        ...state,
        submit: {
          ...state.submit
        },
        list: formattedPayrolls
      }
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        submit: {
          ...state.submit
        },
        isLoading: action.isLoading
      }
    }
    case SET_IS_LOADING_SINGLE_PAYROLL: {
      return {
        ...state,
        submit: {
          ...state.submit
        },
        isLoadingSinglePayroll: action.isLoading
      }
    }
    case SET_EMPLOYEE_UUID: {
      return {
        ...state,
        submit: {
          ...state.submit
        },
        employeeUuid: action.uuid
      }
    }
    case CLEAR_PAYROLL: {
      return {
        ...state,
        submit: {
          ...defaultState.submit
        }
      }
    }
    case CLEAR_PAYROLL_LIST: {
      return {
        ...state,
        list: []
      }
    }
    default: {
      return state;
    }
  }
}