import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { handleCovenant } from '../../store/actions';
import { upsertCovenant } from '../../store/thunk';

export default function Covenant({ isOpen }) {
  const dispatch = useDispatch();

  const {
    isLoading,
    typePrice,
    activated,
    accessCode,
    hasCovenant
  } = useSelector(state => state.priceTable.covenant);

  const [covenantAccessCode, setCovenantAccessCode] = useState(null);
  const [isCovenantActivated, setIsCovenantActivated] = useState(0);

  useEffect(() => {
    if(accessCode) {
      setCovenantAccessCode(accessCode);
    }
  }, [accessCode]);

  useEffect(() => {
    setIsCovenantActivated(!!activated);
  }, [activated, hasCovenant]);

  const handleCovenantSave = () => {
    const params = {
      typePrice,
      activated: +isCovenantActivated
    }

    if(accessCode) {
      return dispatch(upsertCovenant({ ...params, ...{ accessCode: +covenantAccessCode, originalAccessCode: +accessCode } }));
    }

    return dispatch(upsertCovenant(params));
  }

  const generateCovenantCode = () => {
    const randomSixDigitNumber = Math.floor(100000 + Math.random() * 900000);
    setCovenantAccessCode(randomSixDigitNumber);
  }

  return(
    <Modal
      id="covenant"
      title="Tabela convênio"
      maxWidth="sm"
      open={isOpen}
      onClose={() => dispatch(handleCovenant(false))}
      overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      modalActions={
        <Grid>
          <Button
            type="button"
            color="default"
            loading={isLoading}
            style={{ marginRight: 8 }}
            onClick={() => dispatch(handleCovenant(false))}
          >
            Cancelar
          </Button>
          <Button
            id="generate"
            type="button"
            color="primary"
            loading={isLoading}
            disabled={!isCovenantActivated && !hasCovenant}
            onClick={handleCovenantSave}
          >
            Salvar
          </Button>
        </Grid>
      }
    >
      <Loader isLoading={isLoading}>
        <Grid>
          <Typography color="primary">
            Ao ativar o convênio, a tabela vai ser selecionada na tela de saída do veículo no aplicativo operacional.
          </Typography>
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                id="activated"
                onChange={event => {
                  const activatedValue = event.target.checked ? 1 : 0;
                  setIsCovenantActivated(activatedValue);
                }}
                checked={!!isCovenantActivated}
              />
            }
            label="Ativar convênio"
          />
        </Grid>
        {hasCovenant && (
          <Grid container direction="row" alignItems="center">
            <Input
              disabled
              withoutFullWidth
              margin="dense"
              style={{ marginRight: 8 }}
              value={covenantAccessCode}
            />
            <Button
              id="generate"
              type="button"
              color="primary"
              loading={isLoading}
              onClick={generateCovenantCode}
            >
              Alterar código de acesso
            </Button>
          </Grid>
        )}
      </Loader>
    </Modal>
  );
}

Covenant.defaultProps = {
  isOpen: false
};

Covenant.propTypes = {
  isOpen: PropTypes.bool
};