import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { set, lensProp } from 'ramda';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Status from '../../../../../components/Status';
import Card from '../../../../../components/Card';
import Loader from '../../../../../components/Loader';
import Select from '../../../../../components/Select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { loadUpdateFilters } from 'pages/ProvisionalReceiptConfig/store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import './ProvisionalReceiptFilter.scss';

export default function ProvisionalReceiptFilter({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading, configType } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  const { automaticGenerate } = formikProps.values;
  const setAutomaticGenerate = (name, value) => set(lensProp(name), value, automaticGenerate);

  const availablePaymentMethods = useSelector(state => state.filters)
    ?.selects
    ?.find(filter => filter?.id == 'paymentMethod')
    ?.options
    ?.filter(option => !!option?.value);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadUpdateFilters(userId, establishmentId));
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    (async () => {
      if(+formikProps?.values?.automaticGenerate?.serviceOrders == 1) {
        await formikProps.setFieldValue('automaticGenerate', setAutomaticGenerate('paymentMethods', []));
      }
    })();
  }, [formikProps?.values?.automaticGenerate?.serviceOrders]);

  useEffect(() => {
    (async () => {
      if(+formikProps?.values?.automaticGenerate?.selectedTransactions == 1) {
        await formikProps.setFieldValue('automaticGenerate', setAutomaticGenerate('serviceOrders', false));
      }
    })();
  }, [formikProps?.values?.automaticGenerate?.selectedTransactions]);

  const handleAutomaticGenerateCheckbox = e => {
    const { name, checked } = e.target;
    formikProps.setFieldValue('automaticGenerate', setAutomaticGenerate(name, +checked));
  }

  const handleAutomaticGenerateSelect = e => {
    const { name, value } = e.target;
    formikProps.setFieldValue('automaticGenerate', setAutomaticGenerate(name, value));
  }

  return(
    <Card title="Configuração de filtro de envio automático de NFS-e">
      <Grid item xs={12}>
        <Loader isLoading={isLoading}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color='textSecondary' style={{ margin: '10px 0 10px 0' }}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color="#F5B941"
                  size="lg"
                  style={{ marginRight: 10 }}
                />
                Atenção!
                <ul style={{ margin: 0 }}>
                  <li>Após a edição ou cadastro, sincronize o aplicativo operacional para começar a gerar as RPS e, quando sincronizada as notas fiscais.</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir de todas as Ordens de serviços pagas</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={+formikProps?.values?.automaticGenerate?.selectedTransactions == 1}
                      name="serviceOrders"
                      color="primary"
                      checked={formikProps?.values?.automaticGenerate?.serviceOrders || false}
                      onChange={handleAutomaticGenerateCheckbox}
                    />
                  }
                  label="Todas as ordens de serviços"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir de todas as Faturas pagas</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Switch
                      name="invoices"
                      color="primary"
                      checked={formikProps?.values?.automaticGenerate?.invoices || false}
                      onChange={handleAutomaticGenerateCheckbox}
                    />
                  }
                  label="Recebimento apenas de faturas"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir quando o cliente solicitar e incluir os dados dele</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Switch
                      name="selectedTransactions"
                      color="primary"
                      checked={formikProps?.values?.automaticGenerate?.selectedTransactions || false}
                      onChange={handleAutomaticGenerateCheckbox}
                    />
                  }
                  label="Recebimento quando solicitada a nota fiscal"
                />
              </Tooltip>
            </Grid>
            <Tooltip
              title={<Typography>Emitir apenas quando tiver um desses meios de pagamento, tanto fatura quanto Ordem de Serviço</Typography>}
              placement="top-start"
              enterTouchDelay={0}
            >
              <Grid item xs={12}>
                <Select
                  disabled={+formikProps?.values?.automaticGenerate?.serviceOrders == 1}
                  multiple
                  name="paymentMethods"
                  label={
                    +formikProps?.values?.automaticGenerate?.serviceOrders == 1
                      ? "Todos"
                      : "Recebimento apenas dos respectivos meios de pagamento"
                  }
                  options={availablePaymentMethods}
                  value={formikProps?.values?.automaticGenerate?.paymentMethods || []}
                  onChange={handleAutomaticGenerateSelect}
                />
              </Grid>
            </Tooltip>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Status
                  name="statusRps"
                  value={formikProps?.values?.statusRps || 0}
                  onChange={formikProps?.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                disabled={isSameCnpj}
                name="automaticSendNfse"
                label="Envio automático de notas fiscais"
                options={[
                  { label: 'Desativado', value: 0 },
                  { label: 'Ativado', value: 1 }
                ]}
                value={formikProps?.values?.automaticSendNfse || 0}
                onChange={formikProps?.handleChange}
              />
            </Grid>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}

ProvisionalReceiptFilter.propTypes = {
  formikProps: PropTypes.shape().isRequired
}