import { fetchTags, fetchCategories } from './services';
import { setNotificationTags, setNotificationCategories } from './actions';

export const loadTags = params => dispatch => {
  return fetchTags(params).then(({ data }) => {
    return dispatch(setNotificationTags(data.content));
  });
}

export const loadCategories = params => dispatch => {
  return fetchCategories(params).then(data => {
    return dispatch(setNotificationCategories(data));
  });
}