import * as yup from 'yup';
import { phoneRegExp, documentRegExp, postalCodeRegExp } from '../../../helpers/regex';

const emptyStringToNull = (value, originalValue) => {
  if(typeof originalValue === 'string' && originalValue === '') {
    return null;
  }

  return value;
}

export const validations = yup.object().shape({
  establishmentId: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  establishmentName: yup
    .string()
    .required('O nome do negócio é obrigatório')
    .nullable(),
  establishmentTypes: yup
    .string()
    .required('O tipo do negócio é obrigatório')
    .nullable(),
  companyName: yup
    .string()
    .nullable(),
  documentId: yup
    .string()
    .matches(documentRegExp, {
      message: 'O CNPJ precisa ser válido',
      excludeEmptyString: true,
    })
    .nullable(),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: 'O telefone precisa ser válido',
      excludeEmptyString: true,
    })
    .nullable(),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .nullable(),
  postalCode: yup
    .string()
    .matches(postalCodeRegExp, {
      message: 'O CEP precisa ser válido',
      excludeEmptyString: true,
    })
    .nullable(),
  street: yup
    .string()
    .required('O endereço é obrigatório')
    .nullable(),
  complement: yup
    .string()
    .nullable(),
  district: yup
    .string()
    .required('O bairro é obrigatório')
    .nullable(),
  city: yup
    .string()
    .required('A cidade é obrigatória')
    .nullable(),
  state: yup
    .string()
    .required('O estado (UF) é obrigatório')
    .nullable(),
  vacanciesMarks: yup
    .number()
    .when('establishmentTypes', {
      is: establishmentTypes => !(establishmentTypes?.length == 1 && +establishmentTypes[0] == 3),
      then: yup
        .number()
        .moreThan(0, 'O número de vagas tem que ser maior que ${more}')
        .required('O número de vagas é obrigatório e pode ser alterado posteriormente')
        .transform((value, originalValue) => originalValue === 0 || originalValue === '' ? null : value)
        .nullable()
    })
});

export const businessValidations = yup.object().shape({
  companyName: yup
    .string()
    .required('A razão social é obrigatória')
    .nullable(),
  documentId: yup
    .string()
    .matches(documentRegExp, {
      message: 'O CNPJ precisa ser válido',
      excludeEmptyString: true,
    })
    .required('O CNPJ é obrigatório')
    .nullable(),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: 'O telefone precisa ser válido',
      excludeEmptyString: true,
    })
    .required('O telefone é obrigatório')
    .nullable(),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .required('O e-mail é obrigatório')
    .nullable(),
  postalCode: yup
    .string()
    .matches(postalCodeRegExp, 'O CEP precisa ser válido')
    .required('O CEP é obrigatório')
    .nullable(),
  street: yup
    .string()
    .required('O endereço é obrigatório')
    .nullable(),
  number: yup
    .number()
    .required('O número é obrigatório')
    .nullable(),
  complement: yup
    .string()
    .nullable(),
  district: yup
    .string()
    .required('O bairro é obrigatório')
    .nullable(),
  city: yup
    .string()
    .required('A cidade é obrigatória')
    .nullable(),
  state: yup
    .string()
    .required('O estado é obrigatório')
    .nullable()
});