import { compose, set, over, lensProp, lensPath, pick } from 'ramda';
import { REGISTER_FIELD_CHANGE, CHANGE_DATE, CLEAR_FREQUENCY, SET_CHARGES, HANDLE_LOADING, CLEAR_CHARGES } from './constants';
import { convertToEmptyString, convertToRealWithouSign, convertTimeToDB } from '../../../../../../helpers/converters';
import { formatDate } from '../../../../../../helpers/formatters';

const defaultState = {
  isLoading: false,
  createdInvoiceDate: null,
  submit: {
    invoiceDate: '',
    invoiceDueDay: '',
    frequencyId: 1,
    contractDueDate: '',
    toleranceDays: '',
    penalty: '',
    penaltyType: 1,
    amount: '',
    closingDays: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      if (action.field === 'penalty' || action.field === 'penaltyType') {
        return set(
          lensPath(['submit', action.field]),
          action.value ? parseInt(action.value, 10) : '',
          state
        );
      }

      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case CHANGE_DATE: {
      return set(
        lensPath(['submit', 'invoiceDate']),
        convertTimeToDB(action.date),
        state
      );
    }
    case CLEAR_FREQUENCY: {
      if (action.id !== 1) {
        return set(lensPath(['submit', 'invoiceDueDay']), '', state);
      }

      return state;
    }
    case SET_CHARGES: {
      const charges = compose(
        over(lensProp('penalty'), amount =>
          !amount || amount === '0.00' ? '' : amount
        ),
        over(lensProp('toleranceDays'), convertToEmptyString),
        over(lensProp('amount'), convertToRealWithouSign),
        over(lensProp('contractDueDate'), contract =>
          contract === '0001-01-01' || !contract ? '' : formatDate(contract)
        ),
        pick([
          'invoiceDueDay',
          'frequencyId',
          'contractDueDate',
          'toleranceDays',
          'penalty',
          'penaltyType',
          'amount',
          'closingDays',
        ])
      );
      const insertInvoiceDueDay = compose(
        set(
          lensProp('createdInvoiceDate'),
          action.data.invoiceDueDay === 0 ? null : true
        ),
        set(lensProp('submit'), charges(action.data))
      );

      return insertInvoiceDueDay(state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_CHARGES: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};