import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { options } from './resources';

import { useStyles } from './styles';

const Status = props => {
  const classes = useStyles(props);

  const { name, value, onChange, disabled, label } = props;
  const labelId = `${name || 'status'}-label`;

  return(
    <FormControl fullWidth variant="outlined" classes={classes}>
      <InputLabel id={labelId}>Selecione o status:</InputLabel>
      <Select
        name={name || 'status'}
        labelId={labelId}
        label={label || 'Selecione o status'}
        value={value}
        onChange={onChange}
        classes={{ select: classes.select, iconOutlined: classes.iconOutlined }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        disabled={disabled}
      >
        {options.map((option, index) => (
          <MenuItem id={`menu-item-${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Status;