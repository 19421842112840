import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { styles } from '../styles';

const Supplier = ({ exportHeaders, exportData }) => {
  const tableWidth = 100 / exportHeaders.length;

  const formatSupplierLocation = address => {
    if(!address?.addressNumber && !address?.address && !address?.district && !address?.city) {
      return '-';
    }

    if(!address?.addressNumber) {
      return `${address?.address} - ${address?.district}, ${address?.city}`;
    }

    return `${address?.address}, ${address?.addressNumber} - ${address?.district}, ${address?.city}`;
  }

  const items = exportData?.map(supplier => {
    return [
      supplier?.contacts[0]?.name || '-',
      supplier?.corporateName || '-',
      supplier?.cnpj || '-',
      supplier?.contacts[0]?.phone || '-',
      formatSupplierLocation(supplier?.addresses[0])
    ]
  });

  return(
    <Document>
      <Page>
        <View style={{ padding: 10 }}>
          <View style={[styles.table, { marginBottom: 0 }]}>
            <View style={styles.tableRow}>
              {exportHeaders?.map(header => (
                <View style={[styles.tableColHeader, { width: `${tableWidth}%` }]}>
                  <Text style={styles.tableCellHeader}>{header.label}</Text>
                </View>
              ))}
            </View>
            {items?.map(item => (
              <View style={styles.tableRow}>
                {item.map(cell => (
                  <View style={[styles.tableCol, { width: `${tableWidth}%` }]}>
                    <Text style={styles.tableCell}>{cell}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default Supplier;