import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from '../../ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function InvoiceUpdate({ invoice, lastRegister, activityData }) {
  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader
          activityData={activityData}
        />
        <Grid container style={{ marginTop: 10 }}>
          <Grid xs={12}>
            <Typography color='primary' style={{ marginBottom: 5, borderBottom: '1px solid #E4E7EA' }}>
              <b>Fatura Anterior</b>
            </Typography>
            <Grid style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
              <Row>
                <Typography color='primary'>
                  <b>Cliente</b>
                </Typography>
                <Typography color='textSecondary' style={{ whiteSpace: 'nowrap', marginLeft: 5 }}>
                  {lastRegister?.invoice?.clientName}
                </Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Dia do vencimento</b>
                </Typography>
                <Typography color='textSecondary'>{moment(lastRegister?.invoice?.dueDateTime).format('DD/MM/YYYY')}</Typography>
              </Row>
              {(+invoice?.clientTypeId == 4 || +invoice?.clientTypeId == 5) && (
                <Row>
                  <Typography color='primary'>
                    <b>Dia do fechamento</b>
                  </Typography>
                  <Typography color='textSecondary'>{moment(lastRegister?.invoice?.closingDateTime).format('DD/MM/YYYY')}</Typography>
                </Row>
              )}
              <Row>
                <Typography color='primary'>
                  <b>Valor</b>
                </Typography>
                <Typography color='textSecondary'>{convertToReal(lastRegister?.invoice?.amount)}</Typography>
              </Row>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography color='primary' style={{ marginTop: 10, marginBottom: 5, borderBottom: '1px solid #E4E7EA' }}>
              <b>Fatura Atual</b>
            </Typography>
            <Grid style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8, marginTop: 10 }}>
              <Row>
                <Typography color='primary'>
                  <b>Cliente</b>
                </Typography>
                <Typography color='textSecondary' style={{ whiteSpace: 'nowrap', marginLeft: 5 }}>
                  {invoice?.clientName}
                </Typography>
              </Row>
              <Row>
                <Typography color='primary'>
                  <b>Dia do vencimento</b>
                </Typography>
                <Typography color='textSecondary'>{moment(invoice?.dueDateTime).format('DD/MM/YYYY')}</Typography>
              </Row>
              {(+invoice?.clientTypeId == 4 || +invoice?.clientTypeId == 5) && (
                <Row>
                  <Typography color='primary'>
                    <b>Dia do fechamento</b>
                  </Typography>
                  <Typography color='textSecondary'>{moment(invoice?.closingDateTime).format('DD/MM/YYYY')}</Typography>
                </Row>
              )}
              <Row>
                <Typography color='primary'>
                  <b>Valor</b>
                </Typography>
                <Typography color='textSecondary'>{invoice?.amount}</Typography>
              </Row>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

InvoiceUpdate.propTypes = {
  invoice: PropTypes.object.isRequired,
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};