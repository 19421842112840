import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchCategories = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const createCategory = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/categories/new`, qs.stringify(params));
  return response.data;
}

export const updateCategory = async (userId, establishmentId, params, categoryId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/categories/${categoryId}`, qs.stringify(params));
  return response.data;
}