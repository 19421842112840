import * as type from './constants';

export const setIsSinglePayrollSheetLoading = isLoading => ({
  type: type.SET_IS_SINGLE_PAYROLL_SHEET_LOADING,
  isLoading
});

export const setPayrollSheetEmployeeUuid = uuid => ({
  type: type.SET_PAYROLL_SHEET_EMPLOYEE_UUID,
  uuid
});

export const setCommissionedUuid = uuid => ({
  type: type.SET_COMMISSIONED_UUID,
  uuid
});

export const setPayrollSheet = payroll => ({
  type: type.SET_PAYROLL_SHEET,
  payroll
});

export const clearPayrollSheet = () => ({
  type: type.CLEAR_PAYROLL_SHEET
});

export const setPayrollSheetList = data => ({
  type: type.SET_PAYROLL_SHEET_LIST,
  data
});

export const setPayrollSheetListIsLoading = isLoading => ({
  type: type.SET_PAYROLL_SHEET_LIST_IS_LOADING,
  isLoading
});