import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: spacing(8),
    marginTop: 'auto !important',
  },
  footerText: {
    color: '#c8ced3',
  },
}));
