import * as yup from 'yup';

export const storageValidations = yup.object().shape({
  quantityToSell: yup
    .string()
    .required('A estoque atual é obrigatório')
    .nullable(),
  minQuantityToSell: yup
    .string()
    .required('O estoque mínimo é obrigatório')
    .nullable(),
  quantityToUse: yup
    .string()
    .required('A estoque atual é obrigatório')
    .nullable(),
  minQuantityToUse: yup
    .string()
    .required('O estoque mínimo é obrigatório')
    .nullable()
});