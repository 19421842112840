import * as yup from 'yup';

export const passwordValidations = yup.object().shape({
  reason: yup
    .string()
    .required('O motivo é obrigatório')
    .nullable(),
  userPassword: yup
    .string()
    .required('A senha é obrigatória')
    .nullable(),
});

export const receiptValidations = yup.object().shape({
  transactionId: yup
    .number()
    .nullable(),
  description: yup
    .string()
    .required('A descrição é obrigatória')
    .nullable(),
  categoryId: yup
    .string()
    .required('Uma categoria é obrigatória')
    .nullable(),
  previsionDateTime: yup
    .string()
    .required('Data do vencimento é obrigatória')
    .nullable(),
  accountId: yup
    .string()
    .required('Escolha uma conta')
    .nullable(),
  amount: yup
    .string()
    .nullable()
    .test(function(amount) {
      return amount == '0,00' || amount == null
        ? this.createError({
          message: 'O valor precisa ser maior que zero',
          path: 'amount'
        })
        : true
    }),
  received: yup.bool()
});

export const paymentValidations = yup.object().shape({
  transactionId: yup
    .number()
    .nullable(),
  description: yup
    .string()
    .required('A descrição é obrigatória')
    .nullable(),
  categoryId: yup
    .string()
    .required('Uma categoria é obrigatória')
    .nullable(),
  previsionDateTime: yup
    .string()
    .required('Data do vencimento é obrigatória')
    .nullable(),
  accountId: yup
    .string()
    .required('Escolha uma conta')
    .nullable(),
  amount: yup
    .string()
    .nullable()
    .test(function(amount) {
      return amount == '0,00' || amount == null
        ? this.createError({
          message: 'O valor precisa ser maior que zero',
          path: 'amount'
        })
        : true
    }),
  paid: yup.bool(),
});

export const dateConfirmationValidation = yup.object().shape({
  confirmationDateTime: yup
    .string()
    .required('A Data de conciliação é obrigatória')
    .nullable(),
});