import * as yup from 'yup';
import { phoneRegExp } from '../../../helpers/regex';

const withoutEmployeeId = text => ({
  is: value => value === null,
  then: yup.string().required(text),
});

export const validations = yup.object().shape({
  employeeId: yup
    .number()
    .nullable(),
  profileId: yup
    .number()
    .required('Escolha o perfil do usuário')
    .nullable(),
  name: yup
    .string()
    .required('O nome é obrigatório'),
  email: yup
    .string()
    .transform(email => email.replace(/ /g, ''))
    .email('O e-mail precisa ser válido')
    .required('O e-mail é obrigatório'),
  phone: yup
    .string()
    .when(['mainUser'], {
      is: mainUser => +mainUser == 1,
      then: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(phoneRegExp, 'O telefone precisa ser válido')
    }),
  status: yup
    .bool(),
  password: yup
    .string()
    .when('employeeId', withoutEmployeeId('A senha é obrigatória')),
  passwordConfirm: yup
    .string()
    .when('password', (password, schema) =>
      password ? schema.required('Confirme a senha') : schema
    )
    .oneOf(
      [yup.ref('password'), null],
      'A senha e a confirmação devem ser iguais'
    ),
  establishments: yup
    .array()
    .nullable()
    .required('Escolha o(s) estabelecimento(s) para vincular a conta'),
  userRestrictions: yup
    .array()
    .nullable()
});