import { compose, set, lensProp } from 'ramda';
import { SET_CLIENT, SELECT_CLIENT, HANDLE_LOADING } from './constants';
import { formatterClients } from '../../../helpers/formatters';

const defaultState = {
  clients: [],
  total: 0,
  isLoading: false,
  isDetailing: false,
  clientTypeId: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_CLIENT: {
      const clients = compose(
        set(lensProp('isLoading'), false),
        set(lensProp('total'), action.total),
        set(lensProp('clients'), formatterClients(action.data))
      );

      return clients(state);
    }
    case SELECT_CLIENT: {
      return set(lensProp('clientTypeId'), action.clientTypeId, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    default: {
      return state;
    }
  }
}