import React from 'react';
import './config/ReactotronConfig';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import { ReportingObserver as ReportingObserverIntegration } from '@sentry/integrations';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import moment from 'moment';
import 'moment/locale/pt-br';

import { store, persistor } from './store';
import { theme, Root } from './theme/globalStyles';

import ErrorBoundary from './components/ErrorBoundary';
import Routes from './routes';

moment.locale('pt-BR');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://31245d68b9dd427b81cf33d41f59c1a4@sentry.io/2274907',
    debug: true,
    integrations: [
      new HttpClientIntegration({
        failedRequestStatusCodes: [500]
      }),
      new ReportingObserverIntegration({
        types: ['crash']
      })
    ]
  });
}

console.error = () => {};
console.warn = () => {};

const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Root>
              <CssBaseline />
              <Routes />
            </Root>
          </ThemeProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;