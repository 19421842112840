import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setPaymentMethods = (data, total) => ({
  type: types.SET_PAYMENT_METHODS,
  data,
  total,
});

export const handleUpdate = data => ({
  type: types.HANDLE_UPDATE,
  data,
});

export const handleRegistration = status => ({
  type: types.HANDLE_REGISTRATION,
  status,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const clearRegistration = () => ({
  type: types.CLEAR_REGISTRATION,
});
