import qs from 'qs';
import { changeBaseAPI, newAPI } from '../../../services';

export const fetchProvisionalReceiptTables = async userId => {
  const response = await newAPI.get(`${userId}/nfe/tax_settings/auxiliary_tables`);
  return response.data;
}

export const fetchProvisionalReceiptInfo = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings`);
  return response.data;
}

export const fetchCities = async userId => {
  const response = await newAPI.get(`${userId}/nf/nfse/cities`);
  return response.data;
}

export const fetchEconomicActivities = userId =>
  changeBaseAPI('get').then(response => response.data);

export const createRPSConfig = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings`, qs.stringify(params));
  return response.data;
}

export const updateRPSConfig = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateCertificate = async (userId, establishmentId, params) => {
  const formData = new FormData();

  formData.append('file', params?.certificateFile);
  formData.append('password', params?.autenticatorPassword);

  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings/certificate`, formData);
  return response.data;
}

export const requestToUpdatePassword = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/auth/password`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateToken = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/auth/token`, qs.stringify(params));
  return response.data;
}

export const fetchUpdateFilters = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings/send_settings`);
  return response.data;
}

export const requestToUpdateFilters = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings/send_settings`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateLogo = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/nfse/tax_settings/logo`, params);
  return response.data;
}

export const fetchConfigRps = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings/rps_settings`);
  return response.data;
}

export const createConfigRps = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/tax_settings/rps_settings`, qs.stringify(params));
  return response.data;
}

export const loadRegimeEstadual = async (userId, codigoIbge) => {
  const response = await newAPI.get(`${userId}/nf/nfse/cities/${codigoIbge}`);
  return response.data;
}

export const requestUpdateRegimeEstadual = async (userId, codigoIbge, params) => {
  const response = await newAPI.put(`${userId}/nf/nfse/cities/${codigoIbge}`, qs.stringify(params));
  return response.data;
}

export const fetchEstablishmentsWithConfig = async userId => {
  const response = await newAPI.get(`${userId}/nf/nfse/tax_settings`);
  return response.data;
}

export const requestAttachEstablishment = async (userId, establishmentId, taxSettingUuid) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/attach/${taxSettingUuid}`);
  return response.data;
}

export const requestDetachEstablishment = async (userId, establishmentId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/detach`);
  return response.data;
}

export const requetSendNfse = async (userId, establishmentId, transactionId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/${transactionId}/send`);
  return response.data;
}

export const requestConfirmTermsOfUse = async userId => {
  const response = await newAPI.post(`${userId}/nf/chk_terms_of_use`);
  return response.data;
}