import qs from 'qs';
import { newAPI } from 'services';

export const fetchConfigurations = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/settings`);
  return response.data;
}

export const updateConfigurations = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/settings`, qs.stringify(params));
  return response.data;
}

export const sendFile = async (userId, establishmentId, params) => {
  const { file } = params;
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', 'image');
  formData.append('maxSize', '3');

  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/settings/upload`, formData);
  return response.data;
}