import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import red from '@material-ui/core/colors/red';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';

import { loadSessions, finishSession } from '../store/thunk';
import { loadFilters } from '../../../components/Filter/store/thunk';

import { formatFilters } from '../../../helpers/formatters';

const headers = [
  { title: 'Estabelecimento', field: 'establishmentName' },
  { title: 'Operador', field: 'userName' },
  { title: 'Abertura da sessão', field: 'startDateTime' },
  { title: 'Fechamento da Sessão', field: 'endDateTime' }
];

const width = window.innerWidth;
const tableSessionActionWidth = width < 768 ? 120 : 100;

export default function SessionsRegistration() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { selects, qp } = useSelector(state => state.filters);
  const { list, isLoading, total } = useSelector(state => state.sessions);

  const filters = formatFilters(['situation', 'user'], selects);

  const [sessionsRegistrationPage, setSessionsRegistrationPage] = useState(1);
  const sessionsRegistrationPageSize = 10;
  const sessionsRegistrationPageCount = Math.ceil(total / sessionsRegistrationPageSize);

  const handleLoadSessions = ({ page, qp }) => {
    dispatch(loadSessions({ userId, establishmentId, page, pageSize: sessionsRegistrationPageSize, qp, establishments }));
  }

  useEffect(() => {
    handleLoadSessions({ page: 1, qp });
    dispatch(loadFilters(userId, establishmentId));
  }, [userId, establishmentId]);

  useEffect(() => {
    handleLoadSessions({ page: sessionsRegistrationPage, qp });
  }, [sessionsRegistrationPage]);

  const handleFinishSession = rowData => {
    const { sessionId, establishmentId } = rowData;

    const endDateTime = {
      endDateTime: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    const params = { sessionId, userId, establishmentId, endDateTime }

    dispatch(finishSession(params)).then(() =>
      handleLoadSessions({ page: sessionsRegistrationPage, qp })
    );
  }

  return(
    <Paper>
      {isMobile ? (
        <FilterMobile
          filter
          filters={filters}
          handlePageRequest={handleLoadSessions}
        />
      ) : (
        <Filter
          filter
          filters={filters}
          handlePageRequest={handleLoadSessions}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={list}
        textActions="Encerrar Sessão"
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faTimesCircle}
                color={red[600]}
                size="xs"
              />
            ),
            tooltip: 'Encerrar sessão',
            onClick: (event, rowData) => handleFinishSession(rowData),
            hidden: rowData.endDateTime !== 'Usuário Ativo',
          }),
        ]}
        actionColumnWidth={tableSessionActionWidth}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={sessionsRegistrationPageCount}
          page={sessionsRegistrationPage}
          onChange={(_, page) => setSessionsRegistrationPage(page)}
        />
      </Grid>
    </Paper>
  );
}