import { toast } from 'react-toastify';

import {
  fetchSupplier,
  fetchSupplierList,
  createSupplier,
  updateSupplier
} from './services';

import {
  setSupplierLoading,
  setSupplier,
  setSuppliers,
  setSupplierErrors,
  clearSupplierErrors
} from './actions';

export const loadSupplier = (userId, establishmentId, supplierUuid) => dispatch => {
  dispatch(setSupplierLoading(true));

  return fetchSupplier(userId, establishmentId, supplierUuid)
    .then(({ data }) => dispatch(setSupplier(data)))
    .finally(() => dispatch(setSupplierLoading(false)));
}

export const loadSupplierList = (userId, establishmentId) => dispatch => {
  dispatch(setSupplierLoading(true));

  return fetchSupplierList(userId, establishmentId)
    .then(({ data }) => dispatch(setSuppliers(data)))
    .finally(() => dispatch(setSupplierLoading(false)));
}

export const upsertSupplier = (userId, establishmentId, supplierUuid, params) => dispatch => {
  dispatch(setSupplierLoading(true));

  dispatch(clearSupplierErrors());

  if(!supplierUuid) {
    return createSupplier(userId, establishmentId, params)
      .then(({ data }) => {
        dispatch(loadSupplierList(userId, establishmentId));
        toast.success('Fornecedor cadastrado com successo!');
        return Promise.resolve(data);
      })
      .catch(error => {
        if(error?.httpCode) {
          dispatch(setSupplierErrors(error?.errors));
        }

        toast.error('Houve um problema ao cadastrar o fornecedor');

        return Promise.reject();
      })
      .finally(() => dispatch(setSupplierLoading(false)));
  }

  return updateSupplier(userId, establishmentId, supplierUuid, params)
    .then(({ data }) => {
      dispatch(loadSupplierList(userId, establishmentId));
      toast.success('Fornecedor atualizado com successo!');
      return Promise.resolve(data);
    })
    .catch(error => {
      if(error?.httpCode) {
        dispatch(setSupplierErrors(error?.errors));
      }

      toast.error('Houve um problema ao atualizar o fornecedor');

      return Promise.reject();
    })
    .finally(() => dispatch(setSupplierLoading(false)));
}