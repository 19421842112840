import { compose, merge, set, lensProp, pick } from 'ramda';
import {
  REGISTER_FIELD_CHANGE,
  SET_COST_CENTER,
  HANDLE_SEARCH,
  HANDLE_PAGINATION,
  LOADING_INFORMATIONS,
  CLEAR_COST_CENTERS,
  HANDLE_REGISTRATION,
  HANDLE_UPDATE,
  CLEAR_REGISTRATION,
} from './constants';

const defaultState = {
  costCenters: [],
  total: null,
  isLoading: false,
  registration: false,
  status: 1,
  costCenterName: '',
  costCenterId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      let { value } = action;

      if (action.field === 'status') {
        value = parseInt(value, 10);
      }

      return set(lensProp(action.field), value, state);
    }
    case SET_COST_CENTER: {
      return compose(
        set(lensProp('total'), action.data.total),
        set(lensProp('costCenters'), action.data.content),
        set(lensProp('isLoading'), false)
      )(state);
    }
    case HANDLE_REGISTRATION: {
      return set(lensProp('registration'), action.status, state);
    }
    case HANDLE_UPDATE: {
      const account = pick(
        ['costCenterId', 'establishmentId', 'costCenterName', 'status'],
        action.data
      );
      return compose(
        set(lensProp('registration'), true),
        merge(state)
      )(account);
    }
    case CLEAR_REGISTRATION: {
      return compose(
        set(lensProp('registration'), false),
        set(lensProp('costCenterId'), null),
        set(lensProp('costCenterName'), '')
      )(state);
    }
    case HANDLE_SEARCH:
      return {
        ...state,
        isSearching: action.status,
        page: 1,
      };
    case HANDLE_PAGINATION:
      return {
        ...state,
        page: action.page,
        pageSize: action.pageSize,
      };
    case LOADING_INFORMATIONS:
      return {
        ...state,
        isLoading: action.status,
      };
    case CLEAR_COST_CENTERS:
      return defaultState;
    default:
      return state;
  }
};
