import { toast } from 'react-toastify';
import { handleTypePricesLink, setTypePricesLink } from './actions';
import { createTypePricesLink } from './services';

export const registerTypesPricesLink = params => dispatch => {
  dispatch(handleTypePricesLink(true));

  return createTypePricesLink(params)
    .then(({ data }) => {
      toast.success('Vinculo cadastrado com sucesso');
      dispatch(setTypePricesLink(data.typePricesLink));
    })
    .catch(() => dispatch(handleTypePricesLink(false)));
};