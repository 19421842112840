import { REGISTER_TYPE_PERMISSION } from './constants';

const defaultState = {
  typePermission: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_TYPE_PERMISSION: {
      return {
        typePermission: state.typePermission,
      };
    }
    default: {
      return state;
    }
  }
};
