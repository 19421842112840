import { newAPI } from '../../../services';

export const fetchComissionedFilters = async (userId, establishmentId) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/employees/utils/tables`
    : `${userId}/employees/utils/tables`;

  const response = await newAPI.get(url);
  return response.data;
}

export const fetchComissionedList = async (userId, establishmentId, page, limit, qp) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/employees?pg=${page}&limit=${limit}${qp}`
    : `${userId}/employees?pg=${page}&limit=${limit}${qp}`;

  const response = await newAPI.get(url);
  return response.data;
}

export const fetchExport = async (userId, establishmentId, page, limit, qp) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/employees/export?pg=${page}&limit=${limit}${qp}`);
  return response.data;
}