import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';

import { getImageExtensionFromBase64 } from 'helpers/common';
import { convertToBrl, formatCurrency } from 'helpers/converters';
import { formatPermanence, formatVehicle } from 'helpers/formatters';

import { styles } from '../styles';

export default function OperationalExport({ exportData }) {
  const {
    establishment,
    resume: { totalSoUpdated, totalSoCanceled, totalSoPg, totalAmountSoPg },
    startDate,
    endDate,
    content,
    mediumPeriod,
    totalPeriod
  } = exportData;

  const TableColumnsHeaderItems = !establishment
    ? ['Estabelecimento', 'Nº', 'Cliente', 'Veículo', 'Data Entrada', 'Data Saída', 'Permanência', 'Operador', 'Operador Saída', 'Cobrança', 'Serviços', 'Pagamento', 'Observação', 'Valor']
    : ['Nº', 'Cliente', 'Veículo', 'Data Entrada', 'Data Saída', 'Permanência', 'Operador', 'Operador Saída', 'Cobrança', 'Serviços', 'Pagamento', 'Observação', 'Valor'];

  const TableColumnsNumber = TableColumnsHeaderItems.length;
  const TableColumnsWidth = 100 / TableColumnsNumber;

  const base64Extension = getImageExtensionFromBase64(establishment?.base64Logo);

  const calculateTableColumnsWidth = column => {    
    const PaymentMethodNameColumnDecreaseWidth = 1;
    const ValueColumnDecreaseWidth = 2;
    const NumberColumnDecreaseWith = 2;

    const ServicesColumnIncreaseWidth = ValueColumnDecreaseWidth + PaymentMethodNameColumnDecreaseWidth;
    const VehicleColumnIncreaseWidth = NumberColumnDecreaseWith;

    if(column == 'Serviços') {
      return `${TableColumnsWidth + ServicesColumnIncreaseWidth}%`;
    }

    if(column == 'Valor') {
      return `${TableColumnsWidth - ValueColumnDecreaseWidth}%`;
    }

    if(column == 'Veículo') {
      return `${TableColumnsWidth + VehicleColumnIncreaseWidth}%`;
    }

    if(column == 'Pagamento') {
      return `${TableColumnsWidth - PaymentMethodNameColumnDecreaseWidth}%`;
    }

    if(column == 'Nº') {
      return `${TableColumnsWidth - NumberColumnDecreaseWith}%`;
    }

    return `${TableColumnsWidth}%`;
  }

  return(
    <Document>
      <Page style={{ padding: 10 }} orientation='landscape'>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: 10 }}>
          <View style={{ width: '50%' }}>
            <Text style={[styles.fontSize13, { marginLeft: 5 }]}>Histórico Operacional</Text>
            <Text style={[styles.fontSize8, { marginLeft: 5, marginTop: 5, marginBottom: 5 }]}>
              {!!establishment?.establishmentName ? (
                `Estabelecimento: ${establishment?.establishmentName}`
              ) : (
                'Todos os estabelecimentos'
              )}
            </Text>
            <Text style={[styles.fontSize8, { marginLeft: 5 }]}>Período: {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '50%' }}>
            {!!establishment?.base64Logo && (
              <Image
                style={{
                  objectFit: 'contain',
                  height: 50
                }}
                src={`data:image/${base64Extension};base64,${establishment?.base64Logo}`}
              />
            )}
          </View>
        </View>
        <View style={[styles.table, { display: 'flex', flexDirection: 'column', width: '100%', marginBottom: -11 }]}>
          <View style={{ display: 'flex', flexDirection: 'row', width: `${(100 / TableColumnsNumber) * TableColumnsNumber}%` }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Total de OS alterada</Text>
              </View>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>{totalSoUpdated?.toString() || '-'}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Total de OS paga</Text>
              </View>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>{totalSoPg?.toString() || '-'}</Text>
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: `${(100 / TableColumnsNumber) * TableColumnsNumber}%` }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Total de OS cancelada</Text>
              </View>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>{totalSoCanceled?.toString() || '-'}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>Total de OS</Text>
              </View>
              <View style={[styles.tableCol, { display: 'flex', flexDirection: 'row', width: '50%' }]}>
                <Text style={[styles.fontSize8, styles.tableCell]}>{formatCurrency(totalAmountSoPg)?.toString() || '-'}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View style={[styles.table, styles.noMargin, { display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }]}>
            {TableColumnsHeaderItems.map(tableHeader => {
              return(
                <View
                  style={[
                    styles.tableColHeader,
                    { width: calculateTableColumnsWidth(tableHeader) }
                  ]}
                >
                  <Text style={[styles.tableCellHeader, styles.fontSize8]}>{tableHeader}</Text>
                </View>
              );
            })}
          </View>
          {content.map(item => {
            return(
              <View style={[styles.table, styles.noMargin, styles.noBorderTop]}>
                <View style={styles.tableRow}>
                  {!establishment && (
                    <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Estabelecimento') }]}>
                      <Text style={[styles.tableCell, styles.fontSize8]}>{item?.establishmentName}</Text>
                    </View>
                  )}
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Nº') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{item?.serviceOrderCode || '-'}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Cliente') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{item?.clientName || '-'}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Veículo'), display: 'flex', flexDirection: 'column' }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>
                      {formatVehicle({ vehicleColor: item?.vehicleColor, vehicleModel: item?.vehicleModel })}
                    </Text>
                    <Text style={[styles.tableCell, styles.fontSize8]}>
                      {!!item?.plate ? ` ${item?.plate}` : ''}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Data Entrada'), display: 'flex', flexDirection: 'column' }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{moment(item?.entryDateTime).format('DD/MM/YYYY')}</Text>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{!!item?.entryDateTime?.split(' ')[1] ? item?.entryDateTime?.split(' ')[1] : ''}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Data Saída'), display: 'flex', flexDirection: 'column' }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{moment(item?.exitDateTime).format('DD/MM/YYYY')}</Text>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{!!item?.exitDateTime?.split(' ')[1] ? item?.exitDateTime?.split(' ')[1] : ''}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Permanência') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{formatPermanence([item?.exitDateTime, item?.entryDateTime])}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Operador') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{(item?.userName)}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Operador Saída') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{(item?.userOutputName)}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Cobrança') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{item?.typePrice || '-'}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Serviços') }]}>
                    {item?.services?.map(service => {
                      return(
                        <Text style={[styles.tableCell, styles.fontSize8, { marginTop: 5 }]}>
                          {service?.description} {service?.quantity ? `(${service?.quantity})` : ''} - {convertToBrl(+service?.amount)}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Pagamento') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{item?.paymentMethodName || '-'}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Observação') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>{item?.observations?.observation || '-'}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: calculateTableColumnsWidth('Valor') }]}>
                    <Text style={[styles.tableCell, styles.fontSize8]}>
                      {convertToBrl(parseFloat(item?.amount) + parseFloat(item?.amountServices))}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
        </View>
        <View style={[styles.footer, styles.borderLeft, { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10 }]}>
          <Text style={styles.text}>Período total: {totalPeriod}</Text>
          <Text style={styles.text}>Período médio: {mediumPeriod}</Text>
        </View>
      </Page>
    </Document>
  );
}