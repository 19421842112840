import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchNotificationMessage = async params => {
  const response = await newAPI(`${params.userId}/establishment/${params.establishmentId}/notifications/${params.notificationId}/generate`);
  return response?.data?.data;
}

export const fetchNotifications = async params => {
  const response = params.qp
    ? await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/notifications?pg=${params.page || 1}&limit=${params.limit || 10}${params.qp}`)
    : await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/notifications?pg=${params.page || 1}&limit=${params.limit || 10}&notificationSituationId=${params.notificationSituationId}&previsionSituationId=${params.previsionSituationId}`);

  return response?.data?.data;
}

export const fetchNotificationSituations = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/notifications/situations`);
  return response?.data?.data;
}

export const updateNotificationSituation = async params => {
  const request = {
    actionReason: params.actionReason,
    notificationSituationId: params.notificationSituationId,
    previsionDateTime: params.previsionDateTime
  }

  const response = await newAPI.put(`/${params.userId}/establishment/${params.establishmentId}/notifications/${params.id}/situation`, qs.stringify(request));
  return response?.data?.data;
}