import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchComissioned = async (userId, establishmentId, uuid) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/employees/${uuid}`
    : `${userId}/employees/${uuid}`;

  const response = await newAPI.get(url);
  return response.data;
}

export const createComissioned = async (userId, establishmentId, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/employees`
    : `${userId}/employees`;

  const response = await newAPI.post(url, qs.stringify(params));
  return response.data;
}

export const updateComissioned = async (userId, establishmentId, uuid, params) => {
  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/employees/${uuid}`
    : `${userId}/employees/${uuid}`;

  delete params.firstPaymentDate;

  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}