import { toast } from 'react-toastify';
import { compose, assoc } from 'ramda';
import { setRestrictions } from '../../Restrictions/store/actions';
import { setClientId, setAddress, setBasicInfo, handlePostalCode, handleLoading } from './actions';
import { fetchClient, createClient, updateClient } from './services';
import { fetchAddress } from '../../../../../../helpers/externalServices';

export const loadClient = clientParams => dispatch => {
  dispatch(handleLoading(true));

  return fetchClient(clientParams)
    .then(({ data }) => {
      dispatch(setRestrictions(data));
      dispatch(setBasicInfo(data));
      return data;
    })
    .finally(() => dispatch(handleLoading(false)));
};

export const upsertClient = (params, serviceOrderId) => dispatch => {
  let formatParams = compose(assoc('serviceOrderId', serviceOrderId));

  delete params['phone'];

  dispatch(handleLoading(true));

  if (!formatParams(params).clientId) {
    delete params['clientId'];

    return createClient(formatParams(params))
      .then(({ data }) => {
        toast.success('Cliente cadastrado com sucesso!');
        dispatch(setBasicInfo(data));
        dispatch(setClientId(data.clientId));
        dispatch(handleLoading(false));
      })
      .finally(() => dispatch(handleLoading(false)));
  } else {
    return updateClient(formatParams(params))
      .then(({ data }) => {
        toast.success('Cliente atualizado com sucesso!');
        dispatch(setBasicInfo(data));
        dispatch(handleLoading(false));
      })
      .finally(() => dispatch(handleLoading(false)));
  }
};

export const loadAddress = postalCode => dispatch => {
  dispatch(handlePostalCode({ disabled: true, status: true }));

  return fetchAddress(postalCode)
    .then(data => {
      if (data.erro) {
        dispatch(handlePostalCode({ disabled: true, status: false }));
        return toast.error('Não conseguimos encontrar o seu endereço.');
      }

      const { logradouro, bairro, localidade, uf } = data;

      dispatch(
        setAddress({
          street: logradouro,
          district: bairro,
          city: localidade,
          state: uf
        })
      );

      return dispatch(handlePostalCode({ disabled: false, status: false }));
    })
    .catch(() => toast.error('Problemas no servidor, tente novamente mais tarde.'))
    .finally(() => dispatch(handlePostalCode({ disabled: false, status: false })));
};

export const loadAddressWithoutPersist = postalCode => dispatch => {
  dispatch(handlePostalCode({ disabled: true, status: true }));

  return fetchAddress(postalCode)
    .then(data => {
      if (data.erro) {
        dispatch(handlePostalCode({ disabled: true, status: false }));
        toast.error('Não conseguimos encontrar o seu endereço.');
      }

      dispatch(handlePostalCode({ disabled: false, status: false }));

      return data;
    })
    .catch(() =>
      toast.error('Problemas no servidor, tente novamente mais tarde.')
    );
};