import * as yup from 'yup';

const requiredCondition = text => ({
  is: true,
  then: yup.string().required(text)
});

export const validations = yup.object().shape({
  isPaying: yup.bool(),
  isEmailing: yup.bool(),
  isCanceling: yup.bool(),
  isCancelingPayment: yup.bool(),
  paymentDateTime: yup
    .string()
    .when('isPaying', requiredCondition('Escolha a data de pagamento')),
  paymentMethod: yup
    .string()
    .when(['isPaying', 'isCanceling', 'isEmailing'], {
      is: (isPaying, isCanceling, isEmailing) => isPaying && !isCanceling && !isEmailing,
      then: yup.string().required('Escolha o meio de pagamento')
    })
    .nullable(),
  amount: yup
    .string()
    .when('isPaying', requiredCondition('Digite o valor pago')),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .when('isEmailing', requiredCondition('Digite o e-mail para enviar'))
    .nullable(),
  reason: yup
    .string()
    .when(['isCanceling', 'isCancelingPayment'], {
      is: (isCanceling, isCancelingPayment) => isCanceling || isCancelingPayment,
      then: yup.string().required('Digite o motivo do cancelamento')
    }),
  userPassword: yup
    .string()
    .when(['isCanceling', 'isCancelingPayment'], {
      is: (isCanceling, isCancelingPayment) => isCanceling || isCancelingPayment,
      then: yup.string().required('Digite a senha para cancelar')
    })
});