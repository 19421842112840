import { compose, set, map, lensProp, toUpper } from 'ramda';
import {
  REGISTER_FIELD_CHANGE,
  SET_VEHICLES,
  SET_CARDS,
  CLEAR_CARD_REGISTER,
  CLEAR_VEHICLE_REGISTER,
  HANDLE_VEHICLE_LOADING,
  HANDLE_CARD_LOADING,
  TOGGLE_TO_REMOVE_VEHICLE,
  TOGGLE_TO_REMOVE_CARD,
} from './constants';

const defaultState = {
  vehicles: [],
  cards: [],
  plateToRemove: null,
  cardToRemove: null,
  isRemovingVehicle: false,
  loadingVehicles: false,
  isRemovingCard: false,
  loadingCards: false,
  plate: '',
  vehicleModel: '',
  vehicleColor: '',
  cardCode: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      return set(lensProp(action.field), toUpper(action.value), state);
    }
    case SET_VEHICLES: {
      const vehicles = compose(
        set(lensProp('loadingVehicles'), false),
        set(lensProp('vehicles'), action.data)
      );

      return vehicles(state);
    }
    case SET_CARDS: {
      const formattedCards = map(cardCode => ({ cardCode }), action.data);
      const cards = compose(
        set(lensProp('loadingCards'), false),
        set(lensProp('cards'), formattedCards)
      );

      return cards(state);
    }
    case CLEAR_VEHICLE_REGISTER: {
      return compose(
        set(lensProp('plate'), ''),
        set(lensProp('vehicleModel'), ''),
        set(lensProp('vehicleColor'), ''),
        set(lensProp('loadingVehicles'), false)
      )(state);
    }
    case CLEAR_CARD_REGISTER: {
      return compose(
        set(lensProp('cardCode'), ''),
        set(lensProp('loadingCards'), false)
      )(state);
    }
    case HANDLE_VEHICLE_LOADING: {
      return set(lensProp('loadingVehicles'), action.status, state);
    }
    case HANDLE_CARD_LOADING: {
      return set(lensProp('loadingCards'), action.status, state);
    }
    case TOGGLE_TO_REMOVE_VEHICLE: {
      const vehicleToRemove = compose(
        set(lensProp('plateToRemove'), action.plate),
        set(lensProp('isRemovingVehicle'), action.status)
      );

      return vehicleToRemove(state);
    }
    case TOGGLE_TO_REMOVE_CARD: {
      const cardToRemove = compose(
        set(lensProp('isRemovingCard'), action.status),
        set(lensProp('cardToRemove'), action.cardCode)
      );

      return cardToRemove(state);
    }
    default: {
      return state;
    }
  }
};
