import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import './Breadcrumb.scss';

const Breadcrumb = ({ mainPath, previousPaths }) => {
  const classes = useStyles();

  return (
    mainPath.name && (
      <Breadcrumbs separator="›" className={classes.breadcrumb}>
        <Link to="/" className={classes.link}>
          <Typography color="primary" className={classes.bold}>
            Visão geral
          </Typography>
        </Link>
        {previousPaths.length > 0 &&
          previousPaths.map(prevPath => (
            <Link to={prevPath.path} className={classes.link}>
              <Typography color="primary" className={classes.bold}>
                {prevPath.name}
              </Typography>
            </Link>
          ))}
        <Typography color="inherit">{mainPath.name}</Typography>
      </Breadcrumbs>
    )
  );
};

Breadcrumb.defaultProps = {
  mainPath: null,
  previousPaths: [],
};

Breadcrumb.propTypes = {
  mainPath: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  previousPaths: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
};

export default Breadcrumb;
