import * as yup from 'yup';

export const isTimeRangeFilledByZeros = time => {
  if(time == '') {
    return true;
  }

  const isTimeRangeFilledByZeros =
    time
    .split('')
    .filter(time => time != ':')
    .every(time => time == '0');

  return isTimeRangeFilledByZeros;
}

export const isPriceFilledByZeros = price => {
  if(price == '') {
    return true;
  }

  const isPriceFilledByZeros =
    price
    .split('')
    .filter(price => price != ',')
    .filter(price => price != '.')
    .every(price => price == '0');

  return isPriceFilledByZeros;
}

export const validations = yup.object().shape({
  typePrice: yup.string().required('O nome da tabela não pode ser vazio'),
  description: yup.string(),
  maximumPeriod: yup.string(),
  maximumValue: yup.string(),
  tolerance: yup.string(),
  items: yup.array()
});

export const specialValidations = yup.object().shape({
  description: yup
    .string()
    .required('A descrição da tabela não pode ser vazio'),
  weekDays: yup
    .array()
    .min(1, 'Escolha os dias para a condição especial')
    .nullable(),
  startTime: yup.string(),
  endTime: yup.string(),
  maximumPeriod: yup.string(),
  maximumValue: yup.string(),
  tolerance: yup.string(),
  items: yup.array(),
});

export const validateTimeRanges = yup
  .array()
  .test(
    'timeRanges',
    'Preencha o período e o valor para adicionar',
    // timeRange => timeRange.length > 1 || !isTimeRangeFilledByZeros(timeRange[0].period)
    timeRange => true
  );

export const validateSpecificTimeRanges = yup
  .array()
  .test(
    'timeRanges',
    // 'Preencha o período e o valor para adicionar',
    'Preencha o valor para adicionar',
    specificTimeRange =>
      // specificTimeRange.length > 1 ||
      // (!isTimeRangeFilledByZeros(specificTimeRange[0].since) &&
      // !isTimeRangeFilledByZeros(specificTimeRange[0].period) &&
      // !isPriceFilledByZeros(specificTimeRange[0].price))
      !isPriceFilledByZeros(specificTimeRange[0].price)
  );