import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Modal from '../Modal';
import Button from '../Button';

const ModalConfirmation = ({
  open,
  onClose,
  isLoading,
  handleConfirmation,
  children
}) => (
  <Modal
    title="Confirmação de remoção"
    maxWidth="sm"
    open={open}
    onClose={onClose}
    modalActions={
      <>
        <Button
          className="mr-3"
          color="tertiary"
          variant="outlined"
          loading={isLoading}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button color="error" loading={isLoading} onClick={handleConfirmation}>
          Remover
        </Button>
      </>
    }
  >
    <Typography>{children}</Typography>
  </Modal>
);

ModalConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleConfirmation: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.string
};

ModalConfirmation.defaultProps = {
  open: false,
  onClose: () => {},
  handleConfirmation: () => {},
  isLoading: false,
  children: ''
};

export default ModalConfirmation;