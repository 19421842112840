import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '../../../components/Card';
import Loader from '../../../components/Loader';
import Table from './Table';
import Form from './Form';

import { clearPriceTable } from '../store/actions';
import { loadTables } from '../store/thunk';

export default function PriceTable() {
  const dispatch = useDispatch();

  const { path } = useRouteMatch();
  const isPriceTableInitialRegistration = path.split('/').includes('cadastro-inicial');

  const { establishmentId } = useSelector(state => state.businessInfo);
  const { tables, isLoading } = useSelector(state => state.priceTable);

  useEffect(() => {
    dispatch(clearPriceTable());
  }, []);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadTables());
    }
  }, [establishmentId]);

  return(
    <>
      <Grid
        container
        style={{
          flexDirection: 'column',
          marginBottom: 15,
          backgroundColor: '#f6ebe9',
          border: '1px solid #efd7d7',
          borderRadius: 10
        }}
      >
        <Grid item spacing={2}>
          <ul>
            <li style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              <b>Sincronize o aplicativo operacional nas "2 setas circulares" na tela principal após criação e edição da sua tabela de preço.</b>
            </li>
            <li style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              <b>O campo “a partir de” precisa ser igual ao maior valor do “Até”. Assista <a href='https://www.youtube.com/watch?v=GHycEdOrhTg' target='_blank'>este vídeo</a> para entender e evitar erros.</b>
            </li>
            <li style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              <b>Aprenda a criar tolerância <a href='https://www.youtube.com/watch?v=YugWjPQJsE4' target='_blank'>neste vídeo</a>.</b>
            </li>
            <li style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              <b>Assista <a href='https://www.youtube.com/watch?v=v05frQ4quKc' target='_blank'>este vídeo</a> para criar os mensalistas.</b>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card title={isPriceTableInitialRegistration ? "Cadastre uma tabela de estacionamento" : "Cadastre uma tabela de preço"}>
            <Form />
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Minhas tabelas de estacionamento">
            <Grid item xs={12}>
              <Loader isLoading={isLoading}>
                {tables.length > 0 ? (
                  tables.map((table, index) => <Table key={index} data={table} />)
                ) : (
                  <Grid>
                    <Typography variant="h6" color="textSecondary">Não há tabelas cadastradas</Typography>
                    <Typography variant="subtitle1" color="textSecondary">Cadastre a sua primeira tabela ao lado!</Typography>
                  </Grid>
                )}
              </Loader>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}