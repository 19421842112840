import qs from 'qs';
import { newAPI } from '../../../../../../services';

export const fetchVehicles = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/vehicles`);
  return response.data;
}

export const fetchCards = async params => {
  const response = await newAPI.get(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/cards`);
  return response.data;
}

export const createVehicle = async params => {
  const response = await newAPI.post(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/vehicles/new`, qs.stringify(params));
  return response.data;
}

export const deleteVehicle = async params => {
  const response = await newAPI.delete(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/vehicles/${params.plate}`);
  return response.data;
}

export const createCard = async params => {
  const response = await newAPI.post(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/cards/new`, qs.stringify(params));
  return response.data;
}

export const createTypePricesLink = (params, clientId) =>
  newAPI
    .post(`/clients/${clientId}`, qs.stringify(params))
    .then(response => response.data);

export const deleteCard = async (params) => {
  const response = await newAPI.delete(`/${params.userId}/establishment/${params.establishmentId}/clients/${params.clientId}/cards/${params.cardToRemove}`);
  return response.data;
}