import * as yup from 'yup';

export const consumptionValidations = yup.object().shape({
  consumptionByService: yup.array().of(
    yup.object().shape({
      serviceId: yup
        .string()
        .required('O serviço é obrigatório')
        .nullable(),
      toBeUsedContent: yup
        .string()
        .required('O consumo por serviço é obrigatório')
        .nullable(),
      measurementUnitIdConsumptionService: yup
        .string()
        .required('A unidade de medida do consumo é obrigatória')
        .nullable()
    })
  )
});