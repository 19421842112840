import { compose, set, over, lensPath, lensProp, prop } from 'ramda';
import { convertMinutesToPeriod } from '../../../helpers/converters';
import {
  TEXT_CHANGE,
  SET_CONFIGURATIONS,
  HANDLE_LOADING,
  CLEAR_CONFIGURATIONS,
} from './constants';

const defaultState = {
  isLoading: false,
  hasConfiguration: false,
  submit: {
    domainType: '',
    domain: '',
    toleranceTime: '',
    timeBetweenReservations: '',
    typePrices: [],
    typePriceExcess: '',
    differenceFee: '',
    overbookingFee: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TEXT_CHANGE: {
      if (action.field === 'domainType') {
        const domainException = compose(
          set(lensPath(['submit', action.field]), action.value),
          set(lensPath(['submit', 'domain']), '')
        );

        return domainException(state);
      }

      return set(lensPath(['submit', action.field]), action.value, state);
    }
    case SET_CONFIGURATIONS: {
      const toInt = param => parseInt(prop(param, action.data), 10);
      const submit = compose(
        over(lensProp('overbookingFee'), p =>
          p ? toInt('overbookingFee') : ''
        ),
        over(lensProp('timeBetweenReservations'), p =>
          p ? convertMinutesToPeriod(p) : ''
        ),
        over(lensProp('differenceFee'), p => (p ? toInt('differenceFee') : '')),
        over(lensProp('typePrices'), typePrices =>
          typePrices.length > 0 ? typePrices : []
        ),
        over(lensProp('toleranceTime'), p =>
          p ? convertMinutesToPeriod(p) : ''
        )
      );
      const configurations = compose(
        set(lensProp('submit'), submit(action.data)),
        set(lensProp('hasConfiguration'), true),
        set(lensProp('isLoading'), false)
      );

      return configurations(state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_CONFIGURATIONS:
      return defaultState;
    default: {
      return state;
    }
  }
};
