import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { Row } from './Resources';

export default function ActivitiesRegistrationHeader({ activityData }) {
  const getDate = (updatedAt, createdAt) => {
    if(!!updatedAt) {
      return moment(updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    return moment(createdAt).format('DD/MM/YYYY HH:mm:ss');
  }

  return(
    <div style={{ border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#FCFCFC', padding: 8 }}>
      <Row>
        <Typography color='primary'>
          <b>Data e hora</b>
        </Typography>
        <Typography color='textSecondary'>
          {getDate(activityData?.updatedAt, activityData?.createdAt)}
        </Typography>
      </Row>
      <Row>
        <Typography color='primary'>
          <b>Responsável</b>
        </Typography>
        <Typography color='textSecondary'>
          {activityData?.userName}
        </Typography>
      </Row>
      <Row>
        <Typography color='primary'>
          <b>Observação</b>
        </Typography>
        <Typography color='textSecondary'>
          {activityData?.description}
        </Typography>
      </Row>
    </div>
  );
}

ActivitiesRegistrationHeader.propTypes = {
  activityData: PropTypes.object.isRequired
}