import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import qs from 'qs';

import { Paper, Grid, Typography } from '@material-ui/core';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import Loader from 'components/Loader';
import VehicleDamageExport from './Export';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';

import { loadVehicleDamageList } from '../store/thunk';
import { handleExporting } from 'components/Filter/store/actions';

import { inputs } from './resources';
import { formatVehicle } from 'helpers/formatters';
import { componentToHtmlBlob } from 'helpers/converters';

export default function VehicleDamage() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { qp, isSearching, isExporting } = useSelector(state => state.filters);
  const { list, vehicle, isLoading } = useSelector(state => state.vehicleDamage);

  const requestVehicleDamageList = qp => {
    dispatch(loadVehicleDamageList({ userId, establishmentId, isExport: false, qp }));
  }

  const handleLoadVehicleDamageExport = async () => {
    dispatch(handleExporting(true));

    const data = await dispatch(loadVehicleDamageList({ userId, establishmentId, isExport: true, qp }))
      .finally(() => dispatch(handleExporting(false)));

    return componentToHtmlBlob(<VehicleDamageExport data={data} />);
  }

  useEffect(() => {
    requestVehicleDamageList(qp);
  }, [establishmentId]);

  return(
    <Paper>
      {isMobile ? (
        <FilterMobile
          filter
          filters={inputs}
          htmlExport={list?.length > 0}
          htmlExportFunction={handleLoadVehicleDamageExport}
          htmlExportLoading={isExporting}
          handlePageRequest={({ qp }) => requestVehicleDamageList(qp)}
        />
      ) : (
        <Filter
          filter
          filters={inputs}
          htmlExport={list?.length > 0}
          htmlExportFunction={handleLoadVehicleDamageExport}
          htmlExportLoading={isExporting}
          handlePageRequest={({ qp }) => requestVehicleDamageList(qp)}
        />
      )}
      <Loader isLoading={isLoading}>
        <Grid container>
          {(isSearching && list?.length == 0) && (
            <Grid xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '15px 0 15px 0' }}>
              <Typography variant='h6' color='primary'>Veículo ou OS sem registro de avarias.</Typography>
              <FontAwesomeIcon
                icon={faCar}
                color="#1E5168"
                size='3x'
              />
            </Grid>
          )}
          {(establishmentId && !!qs.parse(qp)?.plate && Object.keys(vehicle || {}).length > 0) && (
            <Grid container style={{ margin: '30px 0 30px 0' }}>
              <Grid xs={12} style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Typography variant='h6' color='primary'>Placa: {vehicle?.plate}</Typography>
                <Typography variant='h6' color='primary'>Modelo: {vehicle?.vehicleModel}</Typography>
                <Typography variant='h6' color='primary'>Cor: {vehicle?.vehicleColor}</Typography>
              </Grid>
            </Grid>
          )}
          {list?.map(item => {
            return(
              <Grid xs={12} md={6}>
                <Grid container style={{ padding: 5 }}>
                  {!!item?.photoUrl ? (
                    <Grid item xs={6}>
                      <img
                        src={item?.photoUrl}
                        style={{
                          height: 'auto',
                          width: '100%'
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%', height: 'auto', border: 'solid 1px #505050' }}>
                      <Typography style={{ color: '#505050' }} variant='h3'>Sem Foto</Typography>
                    </Grid>
                  )}
                  <Grid item xs={6} style={{ paddingLeft: 5 }}>
                    {!!item?.plate && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Placa: {item?.plate}</Typography>
                    )}
                    {!!item?.vehicleModel && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Veículo: {formatVehicle({ vehicleColor: item?.vehicleColor, vehicleModel: item?.vehicleModel })}</Typography>
                    )}
                    {!!item?.partName && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Área: {item?.partName}</Typography>
                    )}
                    {!!item?.categoryName && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Parte: {item?.categoryName}</Typography>
                    )}
                    {!!item?.typeName && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Tipo: {item?.typeName}</Typography>
                    )}
                    {!!item?.observation && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Observação: {item?.observation}</Typography>
                    )}
                    {!!item?.registerDateTime && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Data de Criação: {moment(item?.registerDateTime).format('DD/MM/YYYY')}</Typography>
                    )}
                    {!!item?.registerDateTime && (
                      <Typography style={{ margin: 0, color: '#505050' }}>Horário: {moment(item?.registerDateTime).format('HH:mm:ss')}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Loader>
    </Paper>
  );
}