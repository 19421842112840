import { toast } from 'react-toastify';
import { createCharge } from './services';
import { setCharges, handleLoading } from './actions';
import { fetchClient } from '../../BasicInfo/store/services';

export const loadCharges = params => dispatch => {
  dispatch(handleLoading(true));

  return fetchClient(params)
    .then(({ data }) => {
      dispatch(setCharges(data));
      dispatch(handleLoading(false));
      return data;
    })
    .catch(() => dispatch(handleLoading(false)));
};

export const upsertCharge = params => dispatch => {
  dispatch(handleLoading(true));

  return createCharge(params)
    .then(() => {
      toast.success('Cobrança criada com sucesso.');
      dispatch(handleLoading(false));
    })
    .catch(() => dispatch(handleLoading(false)));
};