import { toast } from 'react-toastify';

import { fetchVehicleDamageList } from './services';
import { setIsLoading, setVehicleDamageList, setVehicle } from './actions';

export const loadVehicleDamageList = ({ userId, establishmentId, isExport, qp }) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchVehicleDamageList(userId, establishmentId, qp)
    .then(({ data }) => {
      if(!isExport) {
        dispatch(setVehicle(data?.vehicle[0]));
        dispatch(setVehicleDamageList(data?.content || []));
      }

      if(isExport) {
        if(!!data) {
          return {
            ...data,
            establishmentId,
            qp
          }
        }

        toast.error('Nenhuma avaria encontrada.');
        return {};
      }
    })
    .finally(() => dispatch(setIsLoading(false)));
}