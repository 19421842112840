import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loader from 'components/Loader';

import { formatEmptyValue } from '../../../../helpers/formatters';
import { formatCurrency } from '../../../../helpers/converters';

const Resume = ({ resume, isLoading }) => {
  return(
    <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #13BC4B', padding: '10px 0 10px 0' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Total de faturamento
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {formatCurrency(resume.totalAmountSoPg)}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #13BC4B', padding: '10px 0 10px 0' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Total de OS pagas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {formatEmptyValue(resume.totalSoPg)}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #F5B941', padding: '10px 0 10px 0' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Total de OS alteradas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {formatEmptyValue(resume.totalSoUpdated)}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #F66C6B', padding: '10px 0 10px 0' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Total de OS canceladas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {formatEmptyValue(resume.totalSoCanceled)}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ borderBottom: '5px solid #757575', padding: '10px 0 10px 0' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Período médio: {''}
              <b>
                {
                  resume.averagePeriod
                    ? formatEmptyValue(moment.duration(parseFloat(resume.averagePeriod), 'seconds').format('D [dia(s)], H [h e] m [min]'))
                    : 'Sem período'
                }
              </b>
            </Typography>
          </Loader>
        </Paper>
      </Grid>
    </Grid>
  );
}

Resume.defaultProps = {
  resume: null
};

Resume.propTypes = {
  resume: PropTypes.shape({
    totalAmountSoPg: PropTypes.number,
    totalAmountSoRotary: PropTypes.number,
    totalAmountSoRecurrent: PropTypes.number,
    totalAmountSoCovenant: PropTypes.number,
    totalSoPg: PropTypes.number,
    totalSoUpdated: PropTypes.number,
    totalSoCanceled: PropTypes.number,
    averagePeriod: PropTypes.number,
    totalPeriod: PropTypes.string
  })
};

export default Resume;