import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: 0,
    padding: spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    color: palette.grey[500],
  },
  scrollBody: {
    overflowY: 'initial !important',
  },
}));
