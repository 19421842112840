import * as types from './constants';

export const setComissionsConfig = data => ({
  type: types.SET_COMISSIONS_CONFIG,
  data
});

export const clearComissionsConfig = () => ({
  type: types.CLEAR_COMISSIONS_CONFIG
});

export const setComissionsConfigLoading = isLoading => ({
  type: types.SET_COMISSIONS_CONFIG_LOADING,
  isLoading
});

export const setComissionsErrors = errors => ({
  type: types.SET_COMISSIONS_ERRORS,
  errors
});

export const clearComissionsErrors = () => ({
  type: types.CLEAR_COMISSIONS_ERRORS
});