import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen } from '@fortawesome/free-solid-svg-icons';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Button from '../../../components/Button';
import MTable from '../../../components/MTable';
import CategoriesRegistration from './CategoriesRegistration';

import { setBaseAPI } from '../../../services';
import { formatFilters } from '../../../helpers/formatters';
import { loadCategories } from '../store/thunk';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { handleRegistration, handleUpdate } from '../store/actions';

import { convertList } from '../../../helpers/converters';

import './Categories.scss';

const headers = [
  { title: 'Descrição', field: 'categoryName', sortable: false },
  { title: 'Tipo de lançamento', field: 'transactionType', sortable: false },
  { title: 'Centro de Custo', field: 'costCenter', sortable: false }
];

const width = window.innerWidth;
const tableRowWidth = width < 768 ? 120 : 150;

export default function Categories() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { list: fetchList, total, isLoading, registration } = useSelector(state => state.categories);
  const { selects, qp } = useSelector(state => state.filters);

  const filters = formatFilters(['transactionType', 'costCenter', 'status'], selects);
  const list = convertList(['transactionType', 'costCenter'], filters, fetchList);

  const [categoriesPage, setCategoriesPage] = useState(1);
  const categoriesPageSize = 10;
  const categoriesPageCount = Math.ceil(total / categoriesPageSize);

  const requestLoadCategories = ({ page, qp }) => {
    dispatch(loadCategories({ userId, establishmentId, page, pageSize: categoriesPageSize, qp }));
  }

  useEffect(() => {
    const getData = async () => {
      await setBaseAPI(userId, establishmentId);
      await requestLoadCategories({ page: 1, qp });
      await dispatch(loadFilters(userId, establishmentId));
    }

    if(establishmentId) {
      getData();
    }
  }, [establishmentId]);

  useEffect(() => {
    if(establishmentId) {
      requestLoadCategories({ page: categoriesPage, qp });
    }
  }, [categoriesPage]);

  const handleRegister = () => {
    dispatch(handleRegistration(true));
  }

  const onUpdate = category => {
    dispatch(handleUpdate(category));
  }

  return (
    <>
      {registration && <CategoriesRegistration isOpen={registration} />}
      <Paper>
        {isMobile ? (
          <FilterMobile
            filter
            hasSearchTerm
            filters={filters}
            handlePageRequest={requestLoadCategories}
            actionButton={
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={handleRegister}
              >
                Adicionar Categoria
              </Button>
            }
          />
        ) : (
          <Filter
            filter
            hasSearchTerm
            filters={filters}
            handlePageRequest={requestLoadCategories}
            actionButton={
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={handleRegister}
              >
                Adicionar Categoria
              </Button>
            }
          />
        )}
        <MTable
          loading={isLoading}
          headers={headers}
          data={list}
          actions={[
            rowData => ({
              icon: () => (
                <FontAwesomeIcon
                  icon={faPen}
                  color="#022A5C"
                  size="xs"
                />
              ),
              tooltip: 'Editar',
              onClick: (event, rowData) => onUpdate(rowData)
            })
          ]}
          actionColumnWidth={tableRowWidth}
        />
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={categoriesPageCount}
            page={categoriesPage}
            onChange={(_, page) => setCategoriesPage(page)}
          />
        </Grid>
      </Paper>
    </>
  );
}