import * as type from './constants';

export const setEmployeeId = employeeId => ({
  type: type.SET_EMPLOYEE_ID,
  employeeId
});

export const setEmployee = data => ({
  type: type.SET_EMPLOYEE,
  data
});

export const setRestrictions = data => ({
  type: type.SET_RESTRICTIONS,
  data
});

export const setEmptyEmployee = () => ({
  type: type.SET_EMPTY_EMPLOYEE
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status
});

export const handleRestrictionsLoading = status => ({
  type: type.HANDLE_RESTRICTIONS_LOADING,
  status
});