import { newAPI } from '../../../services';

export const fetchEmployees = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchEmployeeRegister = async (userId, registerId) => {
  const response = await newAPI.get(`${userId}/users/${registerId}`);
  return response.data;
}