import { toast } from 'react-toastify';

import {
  fetchServiceOrders,
  fetchResumeOrders,
  fetchExport
} from './services';

import {
  setServiceOrders,
  setResumeOrders,
  handleLoading,
  handleResumeLoading
} from './actions';

import { handleExporting } from '../../../components/Filter/store/actions';

export const loadServiceOrders = ({ page, pageSize, qp = '', extraProps }) => dispatch => {
  const { startDate, endDate, exportation, userId, establishmentId } = extraProps;

  let url = `${userId}/serviceorders/list?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/serviceorders/list?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;
  }

  if(exportation) {
    dispatch(handleExporting(true));

    url = `/serviceorders?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;

    return fetchServiceOrders(url)
      .then(({ data }) => {
        dispatch(handleExporting(false));
        return data;
      })
      .catch(() => dispatch(handleExporting(false)));
  }

  dispatch(handleLoading(true));

  return fetchServiceOrders(url)
    .then(({ data }) => {
      return dispatch(setServiceOrders(data.content, data.total));
    })
    .catch(() => dispatch(handleLoading(false)));
}

export const loadResumeOrders = (userId, establishmentId, qp, extraProps) => dispatch => {
  dispatch(handleResumeLoading(true));

  const { startDate, endDate } = extraProps;

  let url = `${userId}/establishment/${establishmentId}/serviceorders/resume?startDate=${startDate}&endDate=${endDate}`;

  if(extraProps) {
    url = `${userId}/establishment/${establishmentId}/serviceorders/resume?startDate=${startDate}&endDate=${endDate}${qp}`;
  }

  if(!establishmentId) {
    url = `${userId}/serviceorders/resume?startDate=${startDate}&endDate=${endDate}`;

    if(extraProps) {
      url = `${userId}/serviceorders/resume?startDate=${startDate}&endDate=${endDate}${qp}`;
    }
  }

  return fetchResumeOrders(url)
    .then(({ data }) => dispatch(setResumeOrders(data)))
    .finally(() => dispatch(handleResumeLoading(false)));
}

export const loadExport = (userId, establishmentId, extraProps) => dispatch => {
  dispatch(handleExporting(true));

  return fetchExport(userId, establishmentId, extraProps)
    .then(({ data }) => data)
    .catch(() => toast.error('Não foi possível realizar a exportação.'))
    .finally(() => dispatch(handleExporting(false)));
}