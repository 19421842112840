import { toast } from 'react-toastify';
import { fetchNotificationMessage, fetchNotifications, fetchNotificationSituations, updateNotificationSituation } from './services';
import { setNotification, setNotificationSituations, setIsNotificationListLoading } from './actions';

export const loadNotificationMessage = params => {
  return fetchNotificationMessage(params).then(data => {
    return data;
  });
}

export const loadNotifications = params => dispatch => {
  dispatch(setIsNotificationListLoading(true));

  return fetchNotifications(params).then(data => {
		dispatch(setNotification(data));
    dispatch(setIsNotificationListLoading(false));
  });
}

export const loadNotificationSituations = params => dispatch => {
  fetchNotificationSituations(params).then(data => {
    return dispatch(setNotificationSituations(data));
  });
}

export const updateNotificationSituationId = params => dispatch => {
  updateNotificationSituation(params).then(() => {
    const requestUpdateNotificationsList = {
      userId: params.userId,
      establishmentId: params.establishmentId,
      notificationSituationId: params.notificationSituationId,
      previsionSituationId: params.previsionSituationId,
      qp: params.qp,
      page: params.page,
      limit: params.limit
    }

    dispatch(loadNotifications(requestUpdateNotificationsList));

    toast.success('Lembrete atualizado com sucesso');
  }).catch(() => toast.error('Erro ao atualizar lembrete'));
}