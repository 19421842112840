import { toast } from 'react-toastify';
import { createCostCenter, updateCostCenter, fetchCostCenters } from './services';
import { handleLoading, handleRegistration, clearCostCenters, setCostCenter } from './actions';

export const loadCostCenters = ({ userId, establishmentId, page, pageSize, qp = '' }) => dispatch => {
  dispatch(handleLoading(true));

  const url = `${userId}/establishment/${establishmentId}/costcenters?pg=${page}&limit=${pageSize}${qp}`;

  return fetchCostCenters(url)
    .then(({ data }) => dispatch(setCostCenter(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertCostCenter = (userId, establishmentId, params, properties) => dispatch => {
  const { page, pageSize, qp } = properties;

  dispatch(handleLoading(true));

  if(!params.costCenterId) {
    return createCostCenter(userId, establishmentId, params)
      .then(() => {
        toast.success('Centro de custo cadastrado com sucesso.');
        dispatch(handleRegistration(false));
        dispatch(clearCostCenters());
        dispatch(loadCostCenters({ userId, establishmentId, page, pageSize, qp }));
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateCostCenter(userId, establishmentId, params, params.costCenterId)
    .then(() => {
      toast.success('Centro de custo atualizado com sucesso.');
      dispatch(handleRegistration(false));
      dispatch(clearCostCenters());
      dispatch(loadCostCenters({ userId, establishmentId, page, pageSize, qp }));
    })
    .finally(() => dispatch(handleLoading(false)));
}