import axios from 'axios';
import { toast } from 'react-toastify';

const baseAPI = process.env.REACT_APP_NEW_BASE_API;

export const newAPI = axios.create({
  baseURL: baseAPI,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Referrer-Policy': 'strict-origin-when-cross-origin'
  }
});

export const setNewAPI = () => {
  newAPI.interceptors.response.use(null, error => {
    if(error.message) {
      const expireRequestTime = error.message.split(' ').includes('timeout');

      if(expireRequestTime) {
        toast.error('Tempo limite de carregamento excedido. Repita a operação, por favor');
      }
    }

    if(error.response) {
      if(error.response.request.responseURL?.includes('/consult')) {
        return;
      }

      if(error.response.status === 500) {
        toast.error('Aconteceu algo inesperado. Entre em contato com o suporte.');
      } else if(error.response.status === 400) {
        if(!!JSON.parse(JSON.stringify(error))?.response?.data?.msg) {
          toast.error(JSON.parse(JSON.stringify(error))?.response?.data?.msg);
        }

        if(error.response.data.code === '23000') {
          toast.error('Aconteceu algo inesperado. Entre em contato com o suporte.');
          return Promise.reject(error);
        }

        if(error.response.data.data.code === '26001') {
          return toast.warn(error.response.data.data.msg);
        }

        if(error.response.data.data.code === '17001' || error.response.data.data.code === '19001') {
          return Promise.reject(error);
        }

        if(error.response.data.data.code === '01005') {
          window.location.href = '/?notauthorized=true';
        }

        if(error.response.data.data.code === '25001') {
          return;
        }

        if(error.response.data.data.code === '06001') {
          return;
        }

        if(error.response.data.data.msg) {
          toast.error(error.response.data.data.msg);
          return Promise.reject(error);
        }

        if(error.response.data.data) {
          toast.error(error.response.data.data);
          return Promise.reject(error);
        }

        toast.error(error.response.data.data.msg);
      } else if(error.response.status === 401) {
        window.location.href = '/?notauthorized=true';
      } else if(error.response.status === 404) {
        toast.error('Aconteceu algo inesperado. Entre em contato com o suporte.');
        return Promise.reject(error);
      } else if(error.response.status === 422) {
        return Promise.reject(error.response.data.errors);
      } else {
        toast.error('Estamos tendo problemas, tente novamente em breve.');
      }
    }

    return Promise.reject(error);
  });
}

export const setBaseNewAPI = (userId, establishmentId) => {
  let NEW_BASE_URL = `${baseAPI}/${userId}`;

  if(establishmentId) {
    NEW_BASE_URL = `${baseAPI}/${userId}/establishment/${establishmentId}`;
  }

  return (newAPI.defaults.baseURL = NEW_BASE_URL);
}

export const changeBaseNewAPI = (method, url, params) => {
  return newAPI({ method, url, baseURL: baseAPI, data: params }, params);
}