import { combineReducers } from 'redux';
import basicInfoReducers from '../components/steps/BasicInfo/store/reducer';
import vehicleReducers from '../components/steps/VehicleRegister/store/reducer';
import automationsReducers from '../components/steps/Automations/store/reducer';
import restrictionsReducers from '../components/steps/Restrictions/store/reducer';
import chargesReducers from '../components/steps/Charges/store/reducer';
import linkTableReducers from '../components/steps/LinkTable/store/reducer';
import linkServicesReducers from '../components/steps/LinkServices/store/reducer';

export default combineReducers({
  basicInfo: basicInfoReducers,
  vehicle: vehicleReducers,
  automations: automationsReducers,
  restrictions: restrictionsReducers,
  charges: chargesReducers,
  linkTable: linkTableReducers,
  linkServices: linkServicesReducers,
});
