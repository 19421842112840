export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'rpsSeries',
    label: 'Série RPS',
    placeholder: '0',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'rpsNumber',
    label: 'N˚ RPS',
    placeholder: '0',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'nfNumber',
    label: 'N˚ NFS-e',
    placeholder: '0',
    value: ''
  },
  {
    advanced: false,
    type: 'select',
    id: 'typeDateTime',
    label: 'Filtrar por',
    placeholder: 'Filtrar por',
    value: 'paymentDateTime',
    options: [
      {
        label: 'Competência',
        value: 'paymentDateTime',
      },
      {
        label: 'Emissão',
        value: 'nfDateTime',
      }
    ]
  }
];