import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { head, prop } from 'ramda';
import { formatFilters } from 'helpers/formatters';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export default function EmployeesDescription({ profileId }) {
  const classes = useStyles();

  const { selects } = useSelector(state => state.filters);
  const profiles = prop('options', head(formatFilters(['profile'], selects)));

  const profileTitle = profiles?.filter(profile => profile.value == profileId)[0]?.label;

  return(
    <>
      {profileId && (
        <Grid container className={classes.infoCard}>
          {profileTitle && profileTitle != '--' && (
            <Typography className={classes.infoCardTextTitle}>Características do {profileTitle}</Typography>
          )}
          {profileId == 2 && (
            <Typography className={classes.infoCardText}>
              Pode alterar e cancelar as Ordens de Serviço no aplicativo operacional. <br />
              Único que acessa o site Admin.
            </Typography>
          )}
          {profileId == 3 && (
            <Typography className={classes.infoCardText}>
              Não pode alterar e cancelar as Ordens de Serviço. <br />
              Não pode editar ou excluir serviços após dar entrada no veículo. <br />
              Não pode acessar o site Admin.
            </Typography>
          )}
          {(profileId == 4 || profileId == 6) && (
            <Typography className={classes.infoCardText}>
              Somente pode dar entrada no veículo. <br />
              Não pode editar ou excluir serviços após dar entrada no veículo. <br />
              Não pode dar saída no veículo. <br />
              Não abre e fecha o caixa. <br />
              Não pode acessar o site Admin.
            </Typography>
          )}
          {profileId == 5 && (
            <Typography className={classes.infoCardText}>
              Pode alterar e cancelar as Ordens de Serviço no aplicativo operacional. <br />
              Não pode acessar o site Admin.
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
}

EmployeesDescription.propTypes = {
  profileId: PropTypes.number.isRequired
};