import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useStyles, useInputStyles } from '../styles';

export default function TableItem({ label, value }) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  return (
    <TextField
      disabled
      fullWidth
      margin="dense"
      variant="outlined"
      InputProps={{
        classes: inputClasses,
        startAdornment: (
          <InputAdornment
            disableTypography
            position="start"
            className={classes.textFieldLabel}
          >
            {label}
          </InputAdornment>
        ),
      }}
      value={value}
    />
  );
}

TableItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
