import React from 'react';
import { numericFormatter } from 'react-number-format';
import { Grid, Typography } from '@material-ui/core';
import Loader from 'components/Loader';

export default function StorageHistoryResume({ resume, isSell, isConsume, isResumeLoading }) {
  const {
    previousQuantity,
    entries,
    exits,
    currentQuantity
  } = resume;

  return(
    <Grid container style={{ marginBottom: 10 }}>
      <Grid xs={12} md={3} style={{ padding: 10 }}>
        <Grid container>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, border: '1.5px solid #E4E7EA', borderBottom: '5px solid #757575', borderRadius: 5 }}>
            {isResumeLoading ? (
              <Loader isLoading={isResumeLoading} />
            ) : (
              <>
                <Typography color='primary'>Saldo Anterior</Typography>
                <Typography color='primary' variant='h4'>
                  {isSell && parseInt(previousQuantity || 0)}
                  {isConsume && numericFormatter(previousQuantity?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={3} style={{ padding: 10 }}>
        <Grid container>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, border: '1.5px solid #E4E7EA', borderBottom: '5px solid #757575', borderRadius: 5 }}>
            {isResumeLoading ? (
              <Loader isLoading={isResumeLoading} />
            ) : (
              <>
                <Typography color='primary'>Entrada</Typography>
                <Typography color='primary' variant='h4'>
                  {isSell && parseInt(entries || 0)}
                  {isConsume && numericFormatter(entries?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={3} style={{ padding: 10 }}>
        <Grid container>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, border: '1.5px solid #E4E7EA', borderBottom: '5px solid #757575', borderRadius: 5 }}>
            {isResumeLoading ? (
              <Loader isLoading={isResumeLoading} />
            ) : (
              <>
                <Typography color='primary'>Saída</Typography>
                <Typography color='primary' variant='h4'>
                  {isSell && parseInt(exits || 0)}
                  {isConsume && numericFormatter(exits?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={3} style={{ padding: 10 }}>
        <Grid container>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, border: '1.5px solid #E4E7EA', borderBottom: '5px solid #757575', borderRadius: 5 }}>
            {isResumeLoading ? (
              <Loader isLoading={isResumeLoading} />
            ) : (
              <>
                <Typography color='primary'>Saldo Atual</Typography>
                <Typography color='primary' variant='h4'>
                  {isSell && parseInt(currentQuantity || 0)}
                  {isConsume && numericFormatter(currentQuantity?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}