import { toast } from 'react-toastify';

import {
  fetchProduct,
  createProduct,
  updateProduct,
  fetchServiceProduct,
  createServiceProduct,
  updateServiceProduct,
  removeServiceProduct
} from './services';

import {
  setProductLoading,
  setProduct,
  setProductBasicAndStorageResponse,
  setProductConsumption,
  setProductBasicAndStorageResponseErrors,
  clearProductBasicAndStorageResponseErrors,
  setProductConsumptionErrors,
  clearProductConsumptionErrors
} from './actions';

export const loadProduct = (userId, establishmentId, storageProductUuid) =>  dispatch => {
  dispatch(setProductLoading(true));

  return fetchProduct(userId, establishmentId, storageProductUuid)
    .then(({ data }) => dispatch(setProduct(data)))
    .finally(() => dispatch(setProductLoading(false)));
}

export const upsertProduct = (userId, establishmentId, storageProductUuid, params) => dispatch => {
  dispatch(setProductLoading(true));

  if(!storageProductUuid) {
    return createProduct(userId, establishmentId, params)
      .then(({ data }) => {
        dispatch(clearProductBasicAndStorageResponseErrors());
        dispatch(setProductBasicAndStorageResponse(data));

        toast.success('Produto criado com sucesso!');

        return Promise.resolve(data);
      })
      .catch(data => {
        if(+data?.httpCode == 422) {
          toast.error('Houve um problema ao criar o produto');
          dispatch(setProductBasicAndStorageResponseErrors(data?.errors));
        }

        return Promise.reject();
      })
      .finally(() => dispatch(setProductLoading(false)));
  }

  return updateProduct(userId, establishmentId, storageProductUuid, params)
    .then(({ data }) => {
      dispatch(clearProductBasicAndStorageResponseErrors());
      dispatch(setProductBasicAndStorageResponse(data));

      toast.success('Produto atualizado com sucesso!');

      return Promise.resolve(data);
    })
    .catch(data => {
      if(+data?.httpCode == 422) {
        toast.error('Houve um problema ao atualizar o produto');
        dispatch(setProductBasicAndStorageResponseErrors(data?.errors));
      }

      return Promise.reject();
    })
    .finally(() => dispatch(setProductLoading(false)));
}

export const loadServiceProduct = (userId, establishmentId, storageProductUuid) => dispatch => {
  dispatch(setProductLoading(true));

  return fetchServiceProduct(userId, establishmentId, storageProductUuid)
    .then(({ data }) => dispatch(setProductConsumption(data || [])))
    .finally(() => dispatch(setProductLoading(false)));
}

export const insertServiceProduct = (userId, establishmentId, params) => dispatch => {
  dispatch(setProductLoading(true));

  return createServiceProduct(userId, establishmentId, params)
    .then(() => {
      dispatch(clearProductConsumptionErrors());
      dispatch(loadServiceProduct(userId, establishmentId, params?.storageProductUuid));

      toast.success('Serviço criado com sucesso.');

      return Promise.resolve();
    })
    .catch(data => dispatch(setProductConsumptionErrors(data)))
    .finally(() => dispatch(setProductLoading(false)));
}

export const editServiceProduct = (userId, establishmentId, id, params) => dispatch => {
  dispatch(setProductLoading(true));

  return updateServiceProduct(userId, establishmentId, id, params)
    .then(() => {
      dispatch(clearProductConsumptionErrors());
      dispatch(loadServiceProduct(userId, establishmentId, params?.storageProductUuid));

      toast.success('Serviço editado com sucesso.');

      return Promise.resolve();
    })
    .catch(data => dispatch(setProductConsumptionErrors(data)))
    .finally(() => dispatch(setProductLoading(false)));
}

export const deleteServiceProduct = (userId, establishmentId, params) => dispatch => {
  dispatch(setProductLoading(true));

  return removeServiceProduct(userId, establishmentId, params?.id)
    .then(() => {
      dispatch(clearProductConsumptionErrors());
      dispatch(loadServiceProduct(userId, establishmentId, params?.storageProductUuid));

      toast.success('Serviço removido com sucesso.');
    })
    .finally(() => dispatch(setProductLoading(false)));
}