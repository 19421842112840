import * as types from './constants';

export const setIsLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  isLoading
});

export const setConfigType = config => ({
  type: types.SET_CONFIG_TYPE,
  config
});

export const setSelectedEstablishmentConfig = establishment => ({
  type: types.SET_SELECTED_ESTABLISHMENT_CONFIG,
  establishment
});

export const setDoesConfigExists = config => ({
  type: types.SET_DOES_CONFIG_EXISTS,
  config
});

export const setProvisionalReceiptProduct = data => ({
  type: types.SET_PROVISIONAL_RECEIPT_PRODUCT,
  data
});

export const setCertificate = data => ({
  type: types.SET_CERTIFICATE,
  data
});

export const setCertificateFile = data => ({
  type: types.SET_CERTIFICATE_FILE,
  data
});

export const setBasicInfo = data => ({
  type: types.SET_BASIC_INFO,
  data
});

export const setBasicInfoErrors = errors => ({
  type: types.SET_BASIC_INFO_ERRORS,
  errors
});

export const clearBasicInfoErrors = errors => ({
  type: types.CLEAR_BASIC_INFO_ERRORS,
  errors
});

export const setFilters = data => ({
  type: types.SET_FILTERS,
  data
});

export const setTables = data => ({
  type: types.SET_TABLES,
  data
});

export const setTaxSettingsEstablishments = data => ({
  type: types.SET_TAX_SETTINGS_ESTABLISHMENTS,
  data
});

export const clearProvisionalReceiptProduct = () => ({
  type: types.CLEAR_PROVISIONAL_RECEIPT_PRODUCT
});