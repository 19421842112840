import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography, Tooltip, FormControlLabel, Switch } from '@material-ui/core';
import Loader from '../../../../../components/Loader';
import Card from '../../../../../components/Card';
import Status from '../../../../../components/Status';
import Select from '../../../../../components/Select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { loadFilters } from '../../../store/thunk';

export default function Filter({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading, configType } = useSelector(state => state.provisionalReceiptProductConfig);

  const isSameEstablishmentConfig = +configType == 0;

  const availablePaymentMethods = useSelector(state => state.filters)
    ?.selects
    ?.find(filter => filter?.id == 'paymentMethod')
    ?.options;

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  return(
    <Card title="Filtro de Envio" style={{ marginBottom: 50 }}>
      <Grid item xs={12}>
        <Loader isLoading={isLoading}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color='textSecondary' style={{ margin: '10px 0 10px 0' }}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color="#F5B941"
                  size="lg"
                  style={{ marginRight: 10 }}
                />
                Atenção!
                <ul style={{ margin: 0 }}>
                  <li>Após a edição ou cadastro, sincronize o aplicativo operacional para começar a gerar as RPS e, quando sincronizada as notas fiscais.</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="orders"
                    color="primary"
                    checked={formikProps.values.orders}
                    onChange={formikProps.handleChange}
                  />
                }
                label="Todas as ordens de venda"
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir quando o cliente solicitar e incluir os dados dele</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Switch
                      name="selectedTransactions"
                      color="primary"
                      checked={formikProps.values.selectedTransactions}
                      onChange={formikProps.handleChange}
                    />
                  }
                  label="Recebimento quando solicitada a nota fiscal"
                />
              </Tooltip>
            </Grid>
            <Tooltip
              title={<Typography>Emitir apenas quando tiver um desses meios de pagamento, tanto fatura quanto Ordem de Serviço</Typography>}
              placement="top-start"
              enterTouchDelay={0}
            >
              <Grid item xs={12}>
                <Select
                  multiple
                  name="paymentMethods"
                  label="Recebimento apenas dos respectivos meios de pagamento"
                  options={availablePaymentMethods}
                  value={formikProps.values.paymentMethods || []}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Tooltip>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Status
                  disabled={isSameEstablishmentConfig}
                  name="nfeActive"
                  value={formikProps.values.nfeActive}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}