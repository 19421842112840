import * as types from './constants';

export const setTransaction = data => ({
  type: types.SET_TRANSACTION,
  data
});

export const setResume = data => ({
  type: types.SET_RESUME,
  data
});

export const setAccountId = id => ({
  type: types.SET_ACCOUNT_ID,
  id
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status
});

export const handleRegistration = (status, modalType) => ({
  type: types.HANDLE_REGISTRATION,
  status,
  modalType
});

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value
});

export const clearRegistration = () => ({
  type: types.CLEAR_REGISTRATION
});

export const clearCashierDetails = () => ({
  type: types.CLEAR_CASHIER_DETAILS
});

export const handleConfirm = status => ({
  type: types.HANDLE_CONFIRM,
  status
});

export const onConvertAndChangeData = (field, date) => ({
  type: types.HANDLE_CHANGE_DATE,
  field,
  date
});

export const clearTransaction = () => ({
  type: types.CLEAR_TRANSACTION
});

export const selectSourceId = sourceId => ({
  type: types.SELECT_SOURCE_ID,
  sourceId
});

export const selectCategorySourceId = categorySourceId => ({
  type: types.SELECT_CATEGORY_SOURCE_ID,
  categorySourceId
});

export const selectTransactionId = transactionId => ({
  type: types.SELECT_TRANSACTION_ID,
  transactionId
});

export const setTransactionType = transactionTypeId => ({
  type: types.SET_TRANSACTION_TYPE,
  transactionTypeId
});

export const setCashierDetails = data => ({
  type: types.SET_CASHIER_DETAILS,
  data
});

export const setStoreCashierId = id => ({
  type: types.SET_STORE_CASHIER_ID,
  id
});

export const setCashierId = id => ({
  type: types.SET_CASHIER_ID,
  id
});

export const setWithdral = data => ({
  type: types.SET_WITHDRAL,
  data
});

export const setTransactionDetails = data => ({
  type: types.SET_TRANSACTION_DETAILS,
  data
});

export const handleDetailing = status => ({
  type: types.HANDLE_DETAILING,
  status
});

export const setRemove = () => ({
  type: types.SET_REMOVE
});

export const clearChanges = () => ({
  type: types.CLEAR_CHANGES
});

export const setAttachment = attachment => ({
  type: types.SET_ATTACHMENT,
  attachment
});

export const setOpeningAttachments = attachments => ({
  type: types.SET_OPENING_ATTACHMENTS,
  attachments
});

export const setClosingAttachments = attachments => ({
  type: types.SET_CLOSING_ATTACHMENTS,
  attachments
});

export const setNewOpeningAttachments = attachments => ({
  type: types.SET_NEW_OPENING_ATTACHMENTS,
  attachments
});

export const setNewClosingAttachments = attachments => ({
  type: types.SET_NEW_CLOSING_ATTACHMENTS,
  attachments
});