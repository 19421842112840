import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const buttonBackgroundColor = ({ props, palette, type }) => {
  let bg = props.color;
  const { success, error } = palette;

  if(props.variant == 'outlined' && props.color == 'error') {
    return '#FFFFFF';
  }

  if(props.variant == 'outlined' && props.color == 'tertiary') {
    return '#FFFFFF';
  }

  if(props.color == 'success') {
    bg = type == 'main' ? success.main : success.dark;
  }

  if(props.color == 'error') {
    bg = type == 'main' ? error.main : error.dark;
  }

  return bg;
}

const buttonColor = ({ props, palette }) => {
  if(props.variant == 'outlined' && props.color == 'error') {
    return palette.error.main;
  }

  if(props.variant == 'outlined' && props.color == 'tertiary') {
    return '#757575';
  }

  if(props.color != 'inherit' && props.color != 'default') {
    return '#FFFFFF';
  }

  return palette.primary.main;
}

const buttonBorder = ({ props, palette }) => {
  if(props.variant == 'outlined' && props.color == 'error') {
    return `1px solid ${palette.error.main}`;
  }

  if(props.variant == 'outlined' && props.color == 'tertiary') {
    return '1px solid #757575';
  }
}

export const StyledButton = withStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: props => buttonBackgroundColor({ props, palette, type: 'main' }),
    color: props => buttonColor({ props, palette }),
    border: props => buttonBorder({ props, palette }),
    padding: spacing(1),
    fontSize: spacing(1.8),
    margin: props => props.verticalGutter && spacing(1, 0),
    '&:hover': {
      backgroundColor: props => buttonBackgroundColor({ props, palette, type: 'hover' })
    }
  },
  label: {
    textTransform: 'uppercase'
  }
}))(Button);