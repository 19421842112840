import * as types from './constants';

export const registerFieldChange = (field, value) => ({
  type: types.REGISTER_FIELD_CHANGE,
  field,
  value,
});

export const setFieldValidation = (field, value) => ({
  type: types.SET_FIELD_VALIDATION,
  field,
  value,
});

export const setAddress = address => ({
  type: types.SET_ADDRESS,
  address,
});

export const setBasicInfo = data => ({
  type: types.SET_BASIC_INFO,
  data,
});

export const setClientId = clientId => ({
  type: types.SET_CLIENT_ID,
  clientId,
});

export const handleLoading = status => ({
  type: types.HANDLE_LOADING,
  status,
});

export const handlePostalCode = ({ status }) => ({
  type: types.HANDLE_POSTAL_CODE,
  status,
});

export const clearAddress = () => ({
  type: types.CLEAR_ADDRESS,
});

export const clearBasicInfo = () => ({
  type: types.CLEAR_BASIC_INFORMATION,
});

export const setIsEdit = isEdit => ({
  type: types.SET_IS_EDIT,
  isEdit
});