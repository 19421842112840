export const REGISTER_FIELD_CHANGE = 'SERVICE_ORDER/REGISTER_FIELD_CHANGE';
export const SET_SERVICE_ORDER = 'SERVICE_ORDER/SET_SERVICE_ORDER';
export const SET_SERVICE_ORDER_ID = 'SERVICE_ORDER/SET_SERVICE_ORDER_ID';
export const SET_UPDATE = 'SERVICE_ORDER/SET_UPDATE';
export const SET_REMOVE = 'SERVICE_ORDER/SET_REMOVE';
export const SET_RECEIPT = 'SERVICE_ORDER/SET_RECEIPT';
export const REMOVE_SERVICE = 'SERVICE_ORDER/REMOVE_SERVICE';
export const HANDLE_LOADING = 'SERVICE_ORDER/HANDLE_LOADING';
export const CLEAR_SERVICE_ORDER = 'SERVICE_ORDER/CLEAR_SERVICE_ORDER';
export const CLEAR_CHANGES = 'SERVICE_ORDER/CLEAR_CHANGES';
export const HANDLE_CHANGE_DATE = 'SERVICE_ORDER/HANDLE_CHANGE_DATE';
