import {
  fetchComissionedFilters,
  fetchComissionedList,
  fetchExport
} from './services';

import {
  setIsLoading,
  setComissionedList,
  setComissionedFilters,
  clearComissionedList
} from './actions';

import { handleExporting } from 'components/Filter/store/actions';

export const loadComissionedFilters = ({ userId, establishmentId }) => dispatch => {
  return fetchComissionedFilters(userId, establishmentId)
    .then(({ data }) => dispatch(setComissionedFilters(data)));
}

export const loadComissionedList = ({ userId, establishmentId, page, limit, qp }) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchComissionedList(userId, establishmentId, page, limit, qp)
    .then(({ data }) => dispatch(setComissionedList(data.content, data.total)))
    .catch(() => dispatch(clearComissionedList()))
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadExport = ({ userId, establishmentId, page, limit, qp, payrollSituations }) => dispatch => {
  dispatch(handleExporting(true));

  return fetchExport(userId, establishmentId, page, limit, qp)
    .then(({ data }) => {
      return {
        payrollSituations,
        data
      }
    })
    .finally(() => dispatch(handleExporting(false)));
}