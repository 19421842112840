import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Input from 'components/Input';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';

export default function Storage({ formikProps }) {
  const history = useHistory();

  const isEdit = history.location.pathname == '/editar-produto';

  const { values, errors, handleChange, setFieldValue } = formikProps;

  const { basicAndStorageResponseErrors, isLoading } = useSelector(state => state.productsRegistration);
  const { storages, measurementUnits } = useSelector(state => state.products.filters);
  const submitBasic = useSelector(state => state.productsRegistration.basic);

  const formattedStorages = storages?.map(storage => {
    return {
      value: storage?.id,
      label: storage?.name
    }
  });

  const formattedMeasurementUnits = measurementUnits?.map(unit => {
    return {
      value: unit?.id,
      label: `${unit?.description} (${unit?.abbreviation})`
    }
  });

  const selectedMeasurementUnit = measurementUnits
    ?.find(unit => +unit?.id == +submitBasic?.measurementUnitId)
    ?.description;

  return(
    <Card title="Cadastre as informações de quantidade do estoque">
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Select
                  disabled
                  name="storageId"
                  label="Estoque"
                  options={formattedStorages}
                  value={submitBasic.storageId}
                  error={errors?.storageId || !!basicAndStorageResponseErrors?.storageId}
                  helperText={errors?.storageId || basicAndStorageResponseErrors?.storageId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumericInput
                  disabled={isEdit}
                  name="quantityToUse"
                  type="number"
                  label="Estoque Atual"
                  value={values?.quantityToUse}
                  onChange={value => setFieldValue("quantityToUse", value)}
                  error={errors?.quantityToUse || !!basicAndStorageResponseErrors?.quantityToUse}
                  helperText={errors?.quantityToUse || basicAndStorageResponseErrors?.quantityToUse}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                <Input
                  name="minQuantityToUse"
                  type="number"
                  label="Estoque Mínimo"
                  value={values?.minQuantityToUse}
                  onChange={handleChange}
                  error={errors?.minQuantityToUse || !!basicAndStorageResponseErrors?.minQuantityToUse}
                  helperText={errors?.minQuantityToUse || basicAndStorageResponseErrors?.minQuantityToUse}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  disabled
                  name="measurementUnitId"
                  label="Unidade de Medida"
                  options={formattedMeasurementUnits}
                  value={submitBasic?.measurementUnitId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumericInput
                  disabled
                  name="netContent"
                  type="number"
                  label="Quantidade de unidade de medida por produto"
                  value={submitBasic?.netContent}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="primary">
                  Total em estoque: {!!+values?.quantityToUse ? numericFormatter((+values?.quantityToUse * submitBasic?.netContent).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }) : '0'}
                  {` ${selectedMeasurementUnit?.toLowerCase()} (s)`}
                </Typography>
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}