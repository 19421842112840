import {
  SET_SELL_ORDERS_LIST,
  SET_SELL_ORDERS_RESUME,
  SET_SELL_ORDER,
  CLEAR_SELL_ORDER,
  SET_IS_LOADING,
  SET_SELL_ORDER_MODAL_IS_LOADING
} from './constants';

import moment from 'moment';
import { numericFormatter } from 'react-number-format';
import { formatPhone } from 'helpers/formatters';

const defaultState = {
  isLoading: false,
  isModalLoading: false,
  list: [],
  total: 0,
  resume: {
    totalAmount: 0,
    countPaid: 0,
    count: 0,
    mediumAmount: 0
  },
  order: {
    orderId: '',
    orderCode: '',
    totalAmount: '',
    situationId: '',
    situationName: '',
    registrationDateTime: '',
    userId: '',
    userName: '',
    discountAmount: '',
    productsAmount: '',
    paymentMethodId: '',
    establishmentPaymentMethodId: '',
    paymentMethodName: '',
    paymentDateTime: '',
    transactionUserName: '',
    transactionId: '',
    items: [],
    discount: {
      id: '',
      amount: '',
      typeId: '',
      typeName: ''
    },
    client: {
      clientId: '',
      name: '',
      documentId: '',
      email: '',
      phone: ''
    }
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_SELL_ORDERS_LIST: {
      const { content, total } = action.data;

      const formatRegistrationDateTime = dateTime => {
        return `${moment(dateTime).format('DD/MM/YYYY')} \n ${dateTime?.split(' ')[1]}`;
      }

      const formatClient = (name, phone) => {
        if(phone == [] || phone == '[]' || phone == null || phone == '') {
          return name;
        }

        if(!!name && !!phone) {
          return `${name} \n ${formatPhone(phone[0])}`;
        }

        return '';
      }

      return {
        ...state,
        list: content?.map(item => {
          return {
            ...item,
            registrationDateTime: formatRegistrationDateTime(item?.registrationDateTime),
            client: formatClient(item?.clientName, item?.clientPhones),
            totalAmount: `R$ ${numericFormatter(item?.totalAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}`
          }
        }),
        total
      }
    }
    case SET_SELL_ORDERS_RESUME: {
      return {
        ...state,
        resume: action.data
      }
    }
    case SET_SELL_ORDER: {
      return {
        ...state,
        order: action.data
      }
    }
    case CLEAR_SELL_ORDER: {
      return {
        ...state,
        order: defaultState.order
      }
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_SELL_ORDER_MODAL_IS_LOADING: {
      return {
        ...state,
        isModalLoading: action.isLoading
      }
    }
    default: {
      return state;
    }
  }
}