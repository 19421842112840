import React from 'react';
import Modal from 'components/Modal';
import SupplierRegistration from './SupplierRegistration';

export default function SupplierRegistrationModal({ isSupplierModalOpen, setIsSupplierModalOpen, supplierUuid }) {
  return(
    <Modal
      id='supplier'
      title='Insira as informações básicas do fornecedor de produto'
      scroll='body'
      maxWidth='md'
      open={isSupplierModalOpen}
      onClose={() => setIsSupplierModalOpen(false)}
    >
      <SupplierRegistration
        setIsSupplierModalOpen={setIsSupplierModalOpen}
        supplierUuid={supplierUuid}
      />
    </Modal>
  );
}