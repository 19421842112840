import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.primary.light,
    marginTop: spacing(2),
    marginBottom: spacing(2),
    padding: spacing(1),
    borderRadius: 8,
  },
  specialRoot: {
    backgroundColor: palette.secondary.light,
    marginTop: spacing(2),
    marginBottom: spacing(2),
    padding: spacing(1),
    borderRadius: 8,
  },
  title: {
    color: palette.primary.main,
    fontWeight: 600,
  },
  daysButton: {
    marginRight: spacing(1),
  },
  divider: {
    marginTop: spacing(2),
  },
  textFieldLabel: {
    color: palette.primary.main,
    fontWeight: 500,
  },
}));

export const useInputStyles = makeStyles(({ palette }) => ({
  disabled: {
    color: palette.primary.main,
  },
}));
