export const TEXT_CHANGE = 'CASHFLOW/TEXT_CHANGE';
export const SET_CASHFLOW = 'CASHFLOW/SET_CASHFLOW';
export const HANDLE_LOADING = 'CASHFLOW/HANDLE_LOADING';
export const CHANGE_PERIOD_TYPE = 'CASHFLOW/CHANGE_PERIOD_TYPE';
export const SET_CURRENT_YEAR_IN_DAY_TYPE =
  'CASHFLOW/SET_CURRENT_YEAR_IN_DAY_TYPE';
export const NEXT_POINT = 'CASHFLOW/NEXT_POINT';
export const PREV_POINT = 'CASHFLOW/PREV_POINT';
export const SET_YEAR_RANGE = 'CASHFLOW/SET_YEAR_RANGE';
export const BUILD_RESUME = 'CASHFLOW/BUILD_RESUME';
export const CLEAR_BUILD_RESUME_FILTERS = 'CASHFLOW/CLEAR_BUILD_RESUME_FILTERS';
export const CHANGE_DATE = 'CASHFLOW/CHANGE_DATE';
export const CLEAR_DATE = 'CASHFLOW/CLEAR_DATE';
