import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchSessions = async (userId, establishmentId, url) => {
  let req = `${userId}${url}`;

  if(establishmentId) {
    req = `${userId}/establishment/${establishmentId}${url}`;
  }

  const response = await newAPI.get(req);
  return response.data;
}

export const finishSessionRequest = async params => {
  const { sessionId, userId, establishmentId, endDateTime } = params;

  const response = await newAPI.put(`/${userId}/establishment/${establishmentId}/sessions/${sessionId}`, qs.stringify(endDateTime));
  return response.data;
}