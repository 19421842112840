export const REGISTER_FIELD_CHANGE = 'FILTER/REGISTER_FIELD_CHANGE';
export const SET_FILTERS = 'FILTER/SET_FILTERS';
export const SET_SUPERLOGICA = 'FILTER/SET_SUPERLOGICA';
export const SET_GRAPHIC_TIME_RANGE = 'FILTER/SET_GRAPHIC_TIME_RANGE';
export const HANDLE_SEARCH = 'FILTER/HANDLE_SEARCH';
export const HANDLE_FILTER_BUTTON_CLICK = 'FILTER/HANDLE_FILTER_BUTTON_CLICK';
export const HANDLE_EXPORTING = 'FILTER/HANDLE_EXPORTING';
export const CLEAR_SEARCH = 'FILTER/CLEAR_SEARCH';
export const TOGGLE_ADVANCED = 'FILTER/TOGGLE_ADVANCED';
export const HANDLE_PERSIST_SEARCH_QUERY_PARAMS = 'FILTER/HANDLE_PERSIST_SEARCH_QUERY_PARAMS';
export const SET_IS_CLIENT_EXPORTATION_ERROR = 'FILTER/SET_IS_CLIENT_EXPORTATION_ERROR';