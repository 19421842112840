import * as yup from 'yup';

const withoutCategoryId = text => ({
  is: value => value === null,
  then: yup.string().required(text),
});

export const validations = yup.object().shape({
  categoryId: yup.number().nullable(),
  categoryName: yup
    .string()
    .when('categoryId', withoutCategoryId('A descrição é obrigatória')),
  transactionTypeId: yup
    .number()
    .nullable()
    .required('Escolha um tipo de lançamento'),
  status: yup.string(),
});
