import {
  SET_IS_LOADING,
  SET_COMISSIONED_PAYROLL,
  CLEAR_COMISSIONED_PAYROLL,
  SET_COMISSIONED_PAYROLL_ERRORS,
  CLEAR_COMISSIONED_PAYROLL_ERRORS
} from './constants';

const defaultState = {
  isLoading: false,
  submit: {
    employeeId: '',
    profileId: '',
    dueDays: '',
    establishments: []
  },
  comissionedPayrollErrors: {
    employeeError: null,
    profileError: null,
    dueDaysError: null,
    establishmentsError: null
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_COMISSIONED_PAYROLL: {
      return {
        ...state,
        submit: action.data
      }
    }
    case CLEAR_COMISSIONED_PAYROLL: {
      return {
        ...state,
        submit: defaultState.submit
      }
    }
    case SET_COMISSIONED_PAYROLL_ERRORS: {
      return {
        ...state,
        comissionedPayrollErrors: {
          employeeError: action.data?.employeeId ? action.data?.employeeId[0] : null,
          profileError: action.data?.profileId ? action.data?.profileId[0] : null,
          dueDaysError: action.data?.dueDays ? action.data?.dueDays[0] : null,
          establishmentsError: action.data?.establishments ? action.data?.establishments[0] : null
        }
      }
    }
    case CLEAR_COMISSIONED_PAYROLL_ERRORS: {
      return {
        ...state,
        comissionedPayrollErrors: {
          employeeError: null,
          profileError: null,
          dueDaysError: null,
          establishmentsError: null
        }
      }
    }
    default: {
      return state;
    }
  }
}