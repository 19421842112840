import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Card from 'components/Card';
import Loader from 'components/Loader';
import WarningMessage from 'components/WarningMessage';
import CardFooter from 'components/CardFooter';
import Button from 'components/Button';

import { loadConfigurations, upsertConfigurations } from '../../store/thunk';

import { checkedValueByType } from 'helpers/common';

export default function Operation() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);
  const submit = useSelector(state => state.operational.operation);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  const handleSubmit = values => {
    const params = {
      serviceOnly: checkedValueByType(values?.serviceOnly, 'number'),
      manualCovenant: checkedValueByType(values?.manualCovenant, 'number'),
      manualTime: checkedValueByType(values?.manualTime, 'number'),
      prePaidExit: checkedValueByType(values?.prePaidExit, 'number'),
      recurrentClientEntryOption: checkedValueByType(values?.recurrentClientEntryOption, 'number'),
      requireReceiptCovantTypePrice: checkedValueByType(values?.requireReceiptCovantTypePrice, 'number'),
      requireReceiptExpense: checkedValueByType(values?.requireReceiptExpense, 'number'),
      requiredClientRegisterInfo: checkedValueByType(values?.requiredClientRegisterInfo, 'number'),
      page: 'Operação'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  const initialValues = {
    serviceOnly: checkedValueByType(submit?.serviceOnly, 'boolean'),
    manualCovenant: checkedValueByType(submit?.manualCovenant, 'boolean'),
    manualTime: checkedValueByType(submit?.manualTime, 'boolean'),
    prePaidExit: checkedValueByType(submit?.prePaidExit, 'boolean'),
    recurrentClientEntryOption: checkedValueByType(submit?.recurrentClientEntryOption, 'boolean'),
    requireReceiptCovantTypePrice: checkedValueByType(submit?.requireReceiptCovantTypePrice, 'boolean'),
    requireReceiptExpense: checkedValueByType(submit?.requireReceiptExpense, 'boolean'),
    requiredClientRegisterInfo: checkedValueByType(submit?.requiredClientRegisterInfo, 'boolean')
  }

  return(
    <Card title="Operação">
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ ...formikProps }) => {
              return(
                <Form>
                  <Grid container>
                    {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
                      <WarningMessage
                        message={`Última alteração feita por ${settingHistoryUserName} em ${moment(settingHistoryDateTime).format('LLL')}`}
                      />
                    )}
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Permitir somente serviços</b>
                      </Typography>
                      <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                        Permite para quando selecionar um serviço, automaticamente o estacionamento não será cobrado
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="serviceOnly"
                            color="primary"
                            value={formikProps?.values?.serviceOnly || ''}
                            checked={formikProps?.values?.serviceOnly || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Permitir convênio manual</b>
                      </Typography>
                      <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                        Permite que o operador manualmente indique qual é o convênio que o motorista está utilizando
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="manualCovenant"
                            color="primary"
                            value={formikProps?.values?.manualCovenant || ''}
                            checked={formikProps?.values?.manualCovenant || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Permitir horário manual</b>
                      </Typography>
                      <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                        Permite que o operador manualmente indique qual é o horário corrente do aparelho (celular/tablet)
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="manualTime"
                            color="primary"
                            value={formikProps?.values?.manualTime || ''}
                            checked={formikProps?.values?.manualTime || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Permitir a entrada sem cartão e impressão para clientes recorrentes</b>
                      </Typography>
                      <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                        Opção de não obrigar a entrega do cartão quando o cliente for mensalista. O sistema reconhece pela placa e não exige o cartão para a entrada
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="recurrentClientEntryOption"
                            color="primary"
                            value={formikProps?.values?.recurrentClientEntryOption || ''}
                            checked={formikProps?.values?.recurrentClientEntryOption || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Forçar pagamento antecipado com saída do veículo</b>
                      </Typography>
                      <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                        Forçar que ao dar entrada no veículo o operador dê entrada com pagamento antecipado e que o veículo saia automaticamente após a entrada
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="prePaidExit"
                            color="primary"
                            value={formikProps?.values?.prePaidExit || ''}
                            checked={formikProps?.values?.prePaidExit || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Obrigar comprovante ao selecionar uma tabela convênio</b>
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requireReceiptCovantTypePrice"
                            color="primary"
                            value={formikProps?.values?.requireReceiptCovantTypePrice || ''}
                            checked={formikProps?.values?.requireReceiptCovantTypePrice || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Obrigar comprovante ao fazer um registro de despesa no aplicativo operacional</b>
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requireReceiptExpense"
                            color="primary"
                            value={formikProps?.values?.requireReceiptExpense || ''}
                            checked={formikProps?.values?.requireReceiptExpense || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                        <b>Obrigar cadastro de cliente com nome e telefone</b>
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiredClientRegisterInfo"
                            color="primary"
                            value={formikProps?.values?.requiredClientRegisterInfo || ''}
                            checked={formikProps?.values?.requiredClientRegisterInfo || ''}
                            onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                          />
                        }
                        label={
                          <Typography color="textSecondary">Permitir</Typography>
                        }
                      />
                    </Grid>
                    <Grid xs={12}>
                      <CardFooter>
                        <Button type="submit" color="primary">
                          Salvar
                        </Button>
                      </CardFooter>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Loader>
      </Grid>
    </Card>
  );
}