import * as yup from 'yup';

export const certificateValidations = yup.object().shape({
  password: yup
    .string()
    .when('certificate', {
      is: certificate => !!certificate,
      then: yup.string().required('A senha é obrigatória')
    })
    .nullable(),
  passwordVerify: yup
    .string()
    .when('password', (password, schema) =>
      password ? schema.required('Confirme a senha') : schema
    )
    .oneOf(
      [yup.ref('password'), null],
      'A senha e a confirmação devem ser iguais'
    )
    .nullable()
});